import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="content-footer d-print-none" id="footerMain">
                <div>Eglaf Technology © 2020.</div>
            </footer>

        );
    }
}

export default Footer;