import React, { Component } from 'react';
import { Link, Route, Switch } from "react-router-dom";
import config from './config';

class Header extends Component {

    constructor() {
        super();
        this.state = {
            LoginUserName: "",
            LoginRoleName: ""
        }
    }

    componentDidMount() {
        let img = localStorage.getItem("ProfileImage");
        if (img !== "" && img !== "null" && img !== null && img !== undefined) {
            document.getElementById("image1").src = config.contentUrl + img;
        } else {

            document.getElementById("image1").src = "assets/media/image/no-image-icon.jpeg";
        }
        var myLoginToken = localStorage.getItem("LoginName");
        var LoginRoleName = localStorage.getItem("LoginRoleName");

        this.setState({
            LoginUserName: myLoginToken
        });

        this.setState({
            LoginRoleName: LoginRoleName
        });
    }

    render() {
        return (

            <div className="header d-print-none" id="headerMain">
                <div className="logo-wrapper">
                    <a href="javascript:void(0);" className="navigation-toggler">
                        <i className="ti-menu" />
                    </a>
                    <div className="logo">
                        <Link to="/">
                            <img src="assets/media/image/logo.png" alt="logo" />
                        </Link>
                    </div>
                </div>
                <div className="header-action-left d-xs-none">
                    <div className="col-md-12 col-lg-12 row">
                        <div className="d-flex ml-auto mr-auto">
                            <h4 className="text-white mt-2 mb-0 mr-2">London :</h4>
                            <div id="clock">
                                <p className="unit" id="hours" />
                                <p className="unit" id="minutes" />
                            </div>
                        </div>
                        <div className="d-flex ml-auto mr-auto">
                            <h4 className="text-white mt-2 mb-0 mr-2">Paris :</h4>
                            <div id="clock">
                                <p className="unit" id="Phours" />
                                <p className="unit" id="Pminutes" />
                            </div>
                        </div>
                        <div className="d-flex ml-auto mr-auto">
                            <h4 className="text-white mt-2 mb-0 mr-2">Chicago :</h4>
                            <div id="clock">
                                <p className="unit" id="Chours" />
                                <p className="unit" id="Cminutes" />
                            </div>
                        </div>
                        <div className="d-flex ml-auto mr-auto">
                            <h4 className="text-white mt-2 mb-0 mr-2">Kuala Lumpur :</h4>
                            <div id="clock">
                                <p className="unit" id="Khours" />
                                <p className="unit" id="Kminutes" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-action-right">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a href="javascript:void(0);" className="nav-link mobile-header-search-btn" title="Search">
                                <i className="nav-icon ti-search" />
                            </a>
                        </li>
                        <li className="nav-item dropdown d-none d-md-block">
                            <a href="javascript:void(0);" className="nav-link" title="Apps" data-toggle="dropdown">
                                <i className="nav-icon ti-layout-grid2" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-large">
                                <div className="pt-4 pb-3 pl-2 pr-2">
                                    <div className="row">
                                        <div className="col-3">
                                            <a href="/Client-Overview">
                                                <div className="text-center mb-4">
                                                    <figure className="avatar border-0">
                                                        <span className="avatar-title bg-primary text-white rounded xs-rounded">
                                                            <i className="fa fa-user-o" />
                                                        </span>
                                                    </figure>
                                                    <div className="mt-2">Client Overview</div>
                                                </div>
                                            </a>
                                            {/* <Link to="/Client-Overview">
                                                <div className="text-center mb-4">
                                                    <figure className="avatar border-0">
                                                        <span className="avatar-title bg-primary text-white rounded xs-rounded">
                                                            <i className="ti-user" />
                                                        </span>
                                                    </figure>
                                                    <div className="mt-2">Client Overview</div>
                                                </div>
                                            </Link> */}
                                        </div>
                                        <div className="col-3">
                                            <a href="/Trade-Staging">
                                                <div className="text-center mb-4">
                                                    <figure className="avatar border-0">
                                                        <span className="avatar-title bg-success text-white rounded">
                                                            <i className="fa fa-dollar" />
                                                        </span>
                                                    </figure>
                                                    <div className="mt-2">Trade Staging</div>
                                                </div>
                                            </a>
                                            {/* <Link to="/Trade-Staging">
                                                <div className="text-center mb-4">
                                                    <figure className="avatar border-0">
                                                        <span className="avatar-title bg-success text-white rounded">
                                                            <i className="fa fa-dollar" />
                                                        </span>
                                                    </figure>
                                                    <div className="mt-2">Trade Staging</div>
                                                </div>
                                            </Link> */}
                                        </div>
                                        <div className="col-3">
                                            <a href="/Trade-List">
                                                <div className="text-center mb-4">
                                                    <figure className="avatar border-0">
                                                        <span className="avatar-title bg-secondary text-white rounded">
                                                            <i className="fa fa-dollar" />
                                                        </span>
                                                    </figure>
                                                    <div className="mt-2">Trade List</div>
                                                </div>
                                            </a>
                                            {/* <Link to="/Trade-List">
                                                <div className="text-center mb-4">
                                                    <figure className="avatar border-0">
                                                        <span className="avatar-title bg-secondary text-white rounded">
                                                            <i className="fa fa-list-alt" />
                                                        </span>
                                                    </figure>
                                                    <div className="mt-2">Trade List</div>
                                                </div>
                                            </Link> */}
                                        </div>
                                        <div className="col-3">
                                            <a href="javascript:void(0);">
                                                <div className="text-center mb-4">
                                                    <figure className="avatar border-0">
                                                        <span className="avatar-title bg-info text-white rounded">
                                                            <i className="fa fa-briefcase" />
                                                        </span>
                                                    </figure>
                                                    <div className="mt-2">Broker Free</div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-3">
                                            <a href="/Create-Invoice">
                                                <div className="text-center mb-4">
                                                    <figure className="avatar border-0">
                                                        <span className="avatar-title bg-dark text-white rounded">
                                                            <i className="fa fa-file-text-o" />
                                                        </span>
                                                    </figure>
                                                    <div className="mt-2">Create Invoice</div>
                                                </div>
                                            </a>
                                            {/* <Link to="/Create-Invoice">
                                                <div className="text-center mb-4">
                                                    <figure className="avatar border-0">
                                                        <span className="avatar-title bg-dark text-white rounded">
                                                            <i className="fa fa-file-text-o" />
                                                        </span>
                                                    </figure>
                                                    <div className="mt-2">Create Invoice</div>
                                                </div>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a href="javascript:void(0);" className="nav-link nav-link-notify" title="Chats" data-sidebar-target="#chats">
                                <i className="nav-icon ti-comment" />
                            </a>
                        </li> */}
                        <li className="nav-item dropdown">
                            {/* <a href="javascript:void(0);" className="nav-link" title="Notifications" data-toggle="dropdown">
                                <i className="nav-icon ti-bell" />
                            </a> */}
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-big">
                                <div className="border-bottom px-4 py-3 text-center d-flex justify-content-between align-items-center">
                                    <p className="mb-0">Notifications (2)</p>
                                    <a href="javascript:void(0);" className="link-1" data-toggle="tooltip" title="Notification Settings">
                                        <i className="ti-settings" />
                                    </a>
                                </div>
                                <div className="dropdown-scroll">
                                    <ul className="list-group list-group-flush">
                                        <li className="px-4 py-3 list-group-item">
                                            <a href="javascript:void(0);" className="d-flex hide-show-toggler">
                                                <div className="flex-shrink-0">
                                                    <figure className="avatar avatar-sm mr-3">
                                                        <span className="avatar-title bg-info-bright text-info rounded-circle">
                                                            <i className="ti-user" />
                                                        </span>
                                                    </figure>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0 line-height-20 font-weight-bold d-flex justify-content-between">
                                                        You joined a group
                                                        <i title="Mark as read" data-toggle="tooltip" className="hide-show-toggler-item fa fa-circle-o font-size-11" />
                                                    </p>
                                                    <span className="text-muted small">
                                                        <i className="ti-time mr-1" /> Today
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="px-4 py-3 list-group-item">
                                            <a href="javascript:void(0);" className="d-flex hide-show-toggler">
                                                <div className="flex-shrink-0">
                                                    <figure className="avatar avatar-sm mr-3">
                                                        <span className="avatar-title bg-warning-bright text-warning rounded-circle">
                                                            <i className="ti-server" />
                                                        </span>
                                                    </figure>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0 line-height-20 font-weight-bold d-flex justify-content-between">
                                                        Storage is running low!
                                                        <i title="Mark as read" data-toggle="tooltip" className="hide-show-toggler-item fa fa-circle-o font-size-11" />
                                                    </p>
                                                    <span className="text-muted small">
                                                        <i className="ti-time mr-1" /> Today
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="px-4 py-3 list-group-item">
                                            <a href="javascript:void(0);" className="d-flex hide-show-toggler">
                                                <div className="flex-shrink-0">
                                                    <figure className="avatar avatar-sm mr-3">
                                                        <span className="avatar-title bg-secondary-bright text-secondary rounded-circle">
                                                            <i className="ti-file" />
                                                        </span>
                                                    </figure>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0 line-height-20 d-flex justify-content-between">
                                                        1 person sent a file
                                                        <i title="Mark as unread" data-toggle="tooltip" className="hide-show-toggler-item fa fa-check font-size-11" />
                                                    </p>
                                                    <span className="text-muted small">
                                                        <i className="ti-time mr-1" /> Yesterday
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="px-4 py-3 list-group-item">
                                            <a href="javascript:void(0);" className="d-flex hide-show-toggler">
                                                <div className="flex-shrink-0">
                                                    <figure className="avatar avatar-sm mr-3">
                                                        <span className="avatar-title bg-success-bright text-success rounded-circle">
                                                            <i className="ti-download" />
                                                        </span>
                                                    </figure>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0 line-height-20 d-flex justify-content-between">
                                                        Reports ready to download
                                                        <i title="Mark as unread" data-toggle="tooltip" className="hide-show-toggler-item fa fa-check font-size-11" />
                                                    </p>
                                                    <span className="text-muted small">
                                                        <i className="ti-time mr-1" /> Yesterday
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="px-4 py-3 list-group-item">
                                            <a href="javascript:void(0);" className="d-flex hide-show-toggler">
                                                <div className="flex-shrink-0">
                                                    <figure className="avatar avatar-sm mr-3">
                                                        <span className="avatar-title bg-info-bright text-info rounded-circle">
                                                            <i className="ti-lock" />
                                                        </span>
                                                    </figure>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0 line-height-20 d-flex justify-content-between">
                                                        2 steps verification
                                                        <i title="Mark as unread" data-toggle="tooltip" className="hide-show-toggler-item fa fa-check font-size-11" />
                                                    </p>
                                                    <span className="text-muted small">
                                                        <i className="ti-time mr-1" /> 20 min ago
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="px-4 py-3 text-right border-top">
                                    <ul className="list-inline small">
                                        <li className="list-inline-item mb-0">
                                            <a href="javascript:void(0);" className="link-1">Mark All Read</a>
                                        </li>
                                        <li className="list-inline-item mb-0">
                                            <a href="javascript:void(0);" className="link-1">Alls</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a href="javascript:void(0);" className="nav-link dropdown-toggle d-flex align-items-center" title="User menu" data-toggle="dropdown">
                                <figure className="avatar avatar-sm">
                                    <img id="image1" src="assets/media/image/user/man_avatar3.jpg" className="rounded-circle" alt="image" />
                                    {/* <i class="user-icon fa fa-user-circle-o" aria-hidden="true"></i> */}
                                </figure>
                                <span className="ml-2 d-sm-inline d-none">{this.state.LoginUserName}</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">

                                {this.state.LoginRoleName == "Broker" ?
                                    <a href="/Profile" className="dropdown-item">
                                        <i class="ti-user mr-2" aria-hidden="true"></i> Profile</a>
                                    : ""
                                }
                                <a href="/Login" className="dropdown-item">
                                    <i class="mr-2 fa fa-power-off" aria-hidden="true"></i> Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="d-block d-xl-none flex-fill">
                    <ul className="navbar-nav">
                        <li className="nav-item ml-auto">
                            <a href="javascript:void(0);" className="nav-link header-toggler">
                                <i className="ti-arrow-down" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Header;