import React, { Component } from 'react';
import config from './config';
import swal from 'sweetalert';
import { Link, Route, Switch } from "react-router-dom";
import { useHistory } from 'react-router-dom';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            Email: "",
            Password: "",
            RememberMe: false
        }
    }

    CheckLogin = event => {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        if (this.state.Email != null && this.state.Email != "" && this.state.Email != undefined && this.state.Password != null && this.state.Password != "" && this.state.Password != undefined) {

            let objLogin = {
                Username: this.state.Email,
                Password: this.state.Password,
                RememberMe: this.state.RememberMe
            };

            const data = JSON.stringify(objLogin);
            var POSTURL = config.apiUrl + "authenticate";
            debugger;

            fetch(POSTURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
                body: data,
                async: false,
                traditional: true,
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((results) => {
                        localStorage.setItem('AccessToken', results.token);
                        localStorage.setItem('LoginRoleName', results.roleName);
                        localStorage.setItem('LogedID', results.id);
                        localStorage.setItem('LoginEmailID', results.email);
                        localStorage.setItem('LoginCompanyID', results.companyId);
                        localStorage.setItem('LoginName', results.firstName + " " + results.lastName);

                        var myLoginToken = localStorage.getItem("AccessToken");
                        let FtechURL = config.apiUrl + "Commodity";
                        fetch(FtechURL, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                "Authorization": "Bearer " + myLoginToken,
                                "loginuserid": localStorage.getItem("LogedID"),
                                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                                "commodityid": localStorage.getItem("CommodityID")
                            },
                        }).then((resp) => {
                            if (resp.status === 200) {
                                resp.json().then((resultss) => {
                                    if (resultss != null) {
                                        if (resultss.length > 0) {
                                            localStorage.setItem('CommodityID', resultss[0].id);
                                            localStorage.setItem('CommodityName', resultss[0].name);
                                        }
                                    }
                                    localStorage.setItem('iSFirstLogin', "1");
                                    let CompanyID = localStorage.getItem("LoginCompanyID");
                                    let IndividualID = localStorage.getItem("LogedID");
                                    var FetchCompanyURL = config.apiUrl + "Company/" + CompanyID + "/Individual/" + IndividualID;
                                    var myLoginToken = localStorage.getItem("AccessToken");

                                    fetch(FetchCompanyURL, {
                                        method: "GET",
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Authorization": "Bearer " + myLoginToken,
                                            "loginuserid": localStorage.getItem("LogedID"),
                                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                                            "commodityid": localStorage.getItem("CommodityID")
                                        },
                                    }).then((resp3) => {
                                        pageloaddiv.style.display = "none";
                                        if (resp3.status === 200) {
                                            resp3.json().then((result3) => {
                                                localStorage.setItem("ProfileImage", result3.profileImage);
                                                this.props.history.push({
                                                    pathname: '/Home',
                                                    UserProfileImage: result3.profileImage
                                                });
                                            });
                                        }
                                    });
                                });
                            }
                        });
                    });
                } else {
                    pageloaddiv.style.display = "none";
                    swal({
                        title: "Failed!",
                        text: "Sorry! Username or password is incorrect",
                        icon: "error",
                        button: "Okay!",
                    });

                    localStorage.removeItem('AccessToken');
                    localStorage.removeItem('LoginRoleName');
                    localStorage.removeItem('LogedID');
                    localStorage.removeItem('LoginEmailID');
                    localStorage.removeItem('LoginCompanyID');
                    localStorage.removeItem('LoginName');
                    localStorage.removeItem('CommodityID');
                    localStorage.removeItem('CommodityName');
                    localStorage.removeItem('iSFirstLogin');
                }
            });
        }
    }

    componentDidMount() {
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('LoginRoleName');
        localStorage.removeItem('LogedID');
        localStorage.removeItem('LoginEmailID');
        localStorage.removeItem('LoginCompanyID');
        localStorage.removeItem('LoginName');
        localStorage.removeItem('CommodityID');
        localStorage.removeItem('CommodityName');
        localStorage.removeItem('iSFirstLogin');
    }

    render() {
        return (
            <div className="auth-block">
                <div id="pageloaddiv" style={{ display: 'none' }}>
                    <span><img src="../assets/loader.gif" width={150} height={150} /></span>
                </div>
                <div className="auth-cover" style={{ background: 'url(assets/media/image/auth.jpg)' }} />
                <div className="auth-form">
                    <div className="form-wrapper">
                        {/* logo */}
                        <div id="logo">
                            <img src="assets/media/image/logo.png" alt="logo" style={{ width: 250 }} />
                        </div>
                        <h4>Sign in</h4>
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-lg" placeholder="Username or email" value={this.state.Email} onChange={(e) => { this.setState({ Email: e.target.value }); }} required autofocus />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control form-control-lg" placeholder="Password" value={this.state.Password} onChange={(e) => { this.setState({ Password: e.target.value }); }} required />
                            </div>
                            <div className="form-group d-flex justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" defaultChecked={this.state.RememberMe} onChange={(e) => { this.setState({ RememberMe: e.target.checked }); }} />
                                    <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                </div>
                                <a href="/Recover-Password">Forgot password</a>
                            </div>
                            <a type="button" className="btn btn-primary btn-lg" onClick={() => this.CheckLogin()}>Sign in</a>
                            {/* <hr />
                             <p className="text-muted">Login with your social media account.</p>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <a href="javascript:void(0);" className="btn btn-floating btn-facebook">
                                        <i className="fa fa-facebook" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="javascript:void(0);" className="btn btn-floating btn-twitter">
                                        <i className="fa fa-twitter" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="javascript:void(0);" className="btn btn-floating btn-dribbble">
                                        <i className="fa fa-dribbble" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="javascript:void(0);" className="btn btn-floating btn-linkedin">
                                        <i className="fa fa-linkedin" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="javascript:void(0);" className="btn btn-floating btn-google">
                                        <i className="fa fa-google" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="javascript:void(0);" className="btn btn-floating btn-behance">
                                        <i className="fa fa-behance" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="javascript:void(0);" className="btn btn-floating btn-instagram">
                                        <i className="fa fa-instagram" />
                                    </a>
                                </li>
                            </ul>
                            <hr /> 
                            <p className="text-muted">Don't have an account?</p>
                            <a href="/Registration" className="btn btn-light btn-sm">Register now!</a>*/}
                        </form>
                        {/* ./ form */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;