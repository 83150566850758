import React from "react";
import "./App.css";
import Login from '../src/cmp/Login';
import Register from '../src/cmp/Register';
import Index from '../src/cmp/index';
import ClientOverview from '../src/cmp/ClientOverview';
import Userdetails from '../src/cmp/Userdetails';
import AddCompany from '../src/cmp/AddCompany';
import CreateInvoice from '../src/cmp/CreateInvoice';
import ManageInvoice from '../src/cmp/ManageInvoice';
import AllTradeInvoices from '../src/cmp/AllTradeInvoices';
import VAT from '../src/cmp/VAT';
import TradeStaging from '../src/cmp/TradeStaging';
import TradeList from '../src/cmp/TradeList';
import BankHoliday from '../src/cmp/BankHoliday';
import Profile from '../src/cmp/Profile';
import RecoverPassword from '../src/cmp/RecoverPassword';
import Header from '../src/cmp/Header';
import Sidebar from '../src/cmp/Sidebar';
import Navigation from '../src/cmp/Navigation';
import CompanyDetail from '../src/cmp/CompanyDetail';
import TradeStagingView from '../src/cmp/TradeStagingView'
import TradeListView from '../src/cmp/TradeListView';
import CompanyList from '../src/cmp/CompanyList';
import Contacts from '../src/cmp/Contacts';
import ContactDetail from '../src/cmp/Contact-Detail';
import ChangePassword from '../src/cmp/ChangePassword';
// import ViewCreateInvoice from '../cmp/ViewCreateInvoice';

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

function App() {
  return (
    // <div className="layout-wrapper">
    //   <Sidebar />
    //   <Header />
    //   <div className="content-wrapper">        
    //     <Navigation />
        <Router>
          <Switch>
            <Route exact path="/Login" component={Login} />
            <Route exact path="/Registration" component={Register} />
            <Route exact path="/Recover-Password" component={RecoverPassword} />
            <Route exact path="/Home" component={Index} />
            <Route exact path="/" render={() => (<Redirect to="/Login" />)} />
            <Route exact path="/Client-Overview" component={ClientOverview} />
            <Route exact path="/User-Details" component={Userdetails} />
            <Route exact path="/Add-Company" component={AddCompany} />
            <Route exact path="/Create-Invoice" component={CreateInvoice} />
            <Route exact path="/Manage-Invoice" component={ManageInvoice} />
            {/* <Route exact path="/Create-Invoice-View" component={ViewCreateInvoice} /> */}
            <Route exact path="/All-Trade-Invoices" component={AllTradeInvoices} />
            <Route exact path="/VAT" component={VAT} />
            <Route exact path="/Trade-Staging" component={TradeStaging} />
            <Route exact path="/Trade-Staging-View" component={TradeStagingView} />
            <Route exact path="/Trade-List" component={TradeList} />
            <Route exact path="/Trade-List-View" component={TradeListView} />
            <Route exact path="/Bank-Holiday" component={BankHoliday} />
            <Route exact path="/Profile" component={Profile} />
            <Route exact path="/Company-Detail" component={CompanyDetail} />
            <Route exact path="/Companies" component={CompanyList} />
            <Route exact path="/Contacts" component={Contacts} />
            <Route exact path="/ContactDetail" component={ContactDetail} />
            <Route exact path="/ChangePassword" component={ChangePassword} />
          </Switch>
        </Router>
    //   </div>
    // </div>
  );
}

export default App;
