import React, { Component } from 'react';
import { Link, Route, Switch } from "react-router-dom";
import config from './config';

class Navigation extends Component {

    constructor() {
        super();
        this.state = {
            CommodityList: [],
            LoginRoleName: ""
        }
    }

    bindCommodityList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let FtechURL = config.apiUrl + "Commodity";

        fetch(FtechURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    if (result != null) {
                        if (result.length > 0) {

                            let CommodityID = localStorage.getItem("CommodityID");

                            if (CommodityID == null) {
                                localStorage.setItem('CommodityID', result[0].id);
                                localStorage.setItem('CommodityName', result[0].name);

                                // this.setState(
                                //     { CurrentPageNo: event },
                                //     function () {
                                //         console.log(this.state.CurrentPageNo);
                                //         this.BindTradeList();
                                //     }
                                // );
                            }
                        }
                    }

                    this.setState({ CommodityList: result });
                });
            }
        });



        // .then(res => res.json())
        // .then(json => this.setState({ CommodityList: json }));



        // let CommodityName = localStorage.getItem("CommodityName");
        // if (CommodityID == null || CommodityID == undefined || CommodityID == "") {
        //     localStorage.setItem('CommodityID', CommodityID);
        //     localStorage.setItem('CommodityName', CommodityName);
        // }

    }

    componentDidMount() {
        let img = localStorage.getItem("ProfileImage");
        if (img !== "" && img !== "null" && img !== null && img !== undefined) {
            document.getElementById("image2").src = config.contentUrl + img;
        } else {

            document.getElementById("image2").src = "assets/media/image/no-image-icon.jpeg";
        }

        this.bindCommodityList();

        let LogedID = localStorage.getItem("LogedID");
        let LoginCompanyID = localStorage.getItem("LoginCompanyID");


        localStorage.setItem('IndividualID', LogedID);
        localStorage.setItem('CompanyID', LoginCompanyID);
        var LoginRoleName = localStorage.getItem("LoginRoleName");
        this.setState({ LoginRoleName: LoginRoleName });
    }

    setCommodity(id, name) {
        localStorage.setItem('CommodityID', id);
        localStorage.setItem('CommodityName', name);
    }

    InitializeXero(){
        let client_id = "CAD5672B1804407CBF8D537297D1B291";
        let client_secret = "K9Z5Otw1FwSCaf2U4qlHFD_brYSpCTA4VKGTeloGc7FldM01";
        // let redirect_uri = "https://www.xero.eagletechsolutions.uk/xerohandler";
        let redirect_uri = "https://localhost:3000/Create-Invoice";
        let scope = 'openid profile email accounting.transactions offline_access';
        let state = "12345678";
        // var myURL = "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=CAD5672B1804407CBF8D537297D1B291&redirect_uri=https://www.xero.eagletechsolutions.uk/xerohandler&state=12345678&&scope=openid profile email accounting.transactions offline_access";        
        // var myURL = "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=CAD5672B1804407CBF8D537297D1B291&redirect_uri=https://localhost:3000/Home&state=12345678&&scope=openid profile email accounting.transactions offline_access";        

        // var myURL = "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=CAD5672B1804407CBF8D537297D1B291&redirect_uri=https://euv2.totikasystems.com/home&state=12345678&&scope=openid profile email accounting.transactions offline_access";
        // var myURL = "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=CAD5672B1804407CBF8D537297D1B291&redirect_uri=https://localhost:3000/home&state=12345678&&scope=offline_access accounting.transactions openid profile email accounting.contacts accounting.settings accounting.contacts";
        var myURL = "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=CAD5672B1804407CBF8D537297D1B291&redirect_uri=https://euv2.totikasystems.com/home&state=12345678&&scope=offline_access accounting.transactions openid profile email accounting.contacts accounting.settings accounting.contacts";
        
        console.log(myURL);
        window.location = myURL;    
    }

    render() {
        return (
            <div className="navigation">
                <div className="navigation-apps-bar">
                    <ul>
                        <li className="mb-3">
                            <a href="/Profile" title="Profile" data-toggle="tooltip" data-placement="right">
                                <figure className="avatar avatar-sm">
                                    <img id="image2" src="assets/media/image/user/man_avatar3.jpg" className="rounded-circle" alt="image" />
                                    {/* <i class="user-icon fa fa-user-circle-o" aria-hidden="true"></i> */}
                                </figure>
                            </a>
                        </li>

                        {this.state.LoginRoleName == "Broker" ?
                            this.state.CommodityList.map(item => (
                                item.id == localStorage.getItem('CommodityID') ?
                                    <li>
                                        <a href="/Home" title="Profile" title={item.name} data-toggle="tooltip" data-placement="right" attr-id={item.id} onClick={() => this.setCommodity(item.id, item.name)}><img src={"assets/media/image/" + item.name + ".png"} style={{ width: 49, padding: 2, borderRadius: '9px', boxShadow: '0px 5px 21px -1px rgb(44 150 210)' }} /></a>
                                    </li>
                                    :
                                    <li>
                                        <a href="/Home" title="Profile" title={item.name} data-toggle="tooltip" data-placement="right" attr-id={item.id} onClick={() => this.setCommodity(item.id, item.name)}><img src={"assets/media/image/" + item.name + ".png"} style={{ width: 49, padding: 2 }} /></a>
                                    </li>
                            ))
                            : ""}

                        <li>
                            <a href="javascript:void(0)" title="Profile" title='XERO' data-toggle="tooltip" data-placement="right" onClick={() => this.InitializeXero()}><img src="assets/media/image/xero_logo.svg" style={{ width: 49, padding: 2 }} /></a>
                        </li>

                        {/* {this.state.CommodityList.map(item => (
                            item.id == localStorage.getItem('CommodityID') ?
                                <li>
                                    <a href="/" title="Profile" title={item.name} data-toggle="tooltip" data-placement="right" attr-id={item.id} onClick={() => this.setCommodity(item.id, item.name)}><img src={"assets/media/image/" + item.name + ".png"} style={{ width: 49, padding: 2, borderRadius: '9px', boxShadow: '0px 5px 21px -1px rgb(44 150 210)' }} /></a>
                                </li>
                                :
                                <li>
                                    <a href="/" title="Profile" title={item.name} data-toggle="tooltip" data-placement="right" attr-id={item.id} onClick={() => this.setCommodity(item.id, item.name)}><img src={"assets/media/image/" + item.name + ".png"} style={{ width: 49, padding: 2 }} /></a>
                                </li>
                        ))} */}
                        <li className="d-md-none d-lg-none">
                            <a href title="Client Overview" data-toggle="tooltip" data-placement="right">
                                <figure className="avatar border-0">
                                    <span className="avatar-title bg-primary text-white rounded">
                                        <i className="ti-user" />
                                    </span>
                                </figure>
                            </a>
                        </li>
                        <li className="d-md-none d-lg-none">
                            <a href title="Trade Staging" data-toggle="tooltip" data-placement="right">
                                <figure className="avatar border-0">
                                    <span className="avatar-title bg-success  text-white rounded">
                                        <i className="fa fa-dollar" />
                                    </span>
                                </figure>
                            </a>
                        </li>
                        <li className="d-md-none d-lg-none">
                            <a href title="Trade List" data-toggle="tooltip" data-placement="right">
                                <figure className="avatar border-0">
                                    <span className="avatar-title bg-secondary  text-white rounded">
                                        <i className="fa fa-list-alt" />
                                    </span>
                                </figure>
                            </a>
                        </li>
                        <li className="d-md-none d-lg-none">
                            <a href title="Broker Free" data-toggle="tooltip" data-placement="right">
                                <figure className="avatar border-0">
                                    <span className="avatar-title bg-info  text-white rounded">
                                        <i className="fa fa-briefcase" />
                                    </span>
                                </figure>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="navigation-menu-body">
                    {this.state.LoginRoleName == "Broker" ?
                        <ul>
                            <li>
                                <a href="/Home" className="active">
                                    <span className="nav-link-icon">
                                        <i className="ti-home" />
                                    </span>
                                    <span>Home</span>
                                </a>
                            </li>
                            <li>
                                <a href="/Profile" >
                                    <span className="nav-link-icon">
                                        <i className="ti-user" />
                                    </span>
                                    <span>My Profile</span>
                                </a>
                                <ul>
                                    <li>
                                        <a href="/Profile"> My Details </a>
                                    </li>
                                    <li>
                                        <a href="/Profile?companydetail=true"> Company Details </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="/Client-Overview">
                                    <span className="nav-link-icon">
                                        <i className="fa fa-user-o" />
                                    </span>
                                    <span>Clients</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <span className="nav-link-icon">
                                        <i className="fa fa-usd" />
                                    </span>
                                    <span>Trade Execution</span>
                                </a>
                                <ul>
                                    <li>
                                        <a href="/Trade-Staging"> Trade Staging </a>
                                    </li>
                                    <li>
                                        <a href="/Trade-List"> Trade List </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <span className="nav-link-icon">
                                        <i className="fa fa-file-text-o" aria-hidden="true" />
                                    </span>
                                    <span>Invoicing</span>
                                </a>
                                <ul>
                                    <li>
                                        <a href="/Create-Invoice"> Create Invoice </a>
                                    </li>
                                    <li>
                                        <a href="/Manage-Invoice"> Manage Invoice </a>
                                    </li>
                                    <li>
                                        <a href="/All-Trade-Invoices"> All Trade Invoice </a>
                                    </li>
                                    <li>
                                        <a href="/VAT"> Manage VAT </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <span className="nav-link-icon">
                                        <i className="fa fa-money" aria-hidden="true" />
                                    </span>
                                    <span>Currency</span>
                                </a>
                                <ul>
                                    <li>
                                        <a href="https://apply.ebury.com/sfdc/servlet/SmartForm.html?formCode=CS-Totika-GB-EN" target="_blank">Application</a>
                                    </li>
                                    <li>
                                        <a href="https://ototikasystems.ebury.com/login/" target="_blank">Manage Currency</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <span className="nav-link-icon">
                                        <i className="ti-bar-chart-alt" />
                                    </span>
                                    <span>Analysis</span>
                                </a>
                            </li>
                            <li>
                                <a href="/Bank-Holiday">
                                    <span className="nav-link-icon">
                                        <i className="fa fa-bank" />
                                    </span>
                                    <span>Bank Holiday</span>
                                </a>
                            </li>
                        </ul>
                        :
                        this.state.LoginRoleName == "Administrator" ?
                            <ul>
                                <li>
                                    <a href="/Home" className="active">
                                        <span className="nav-link-icon">
                                            <i className="ti-home" />
                                        </span>
                                        <span>Home</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/Companies">
                                        <span className="nav-link-icon">
                                            <i className="fa fa-user-o" />
                                        </span>
                                        <span>Companies</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/Contacts">
                                        <span className="nav-link-icon">
                                            <i className="fa fa-user-o" />
                                        </span>
                                        <span>Contacts</span>
                                    </a>
                                </li>
                            </ul>
                            : ""
                    }
                </div>
            </div>
        );
    }
}

export default Navigation;