import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import config from './config';
import swal from 'sweetalert';
import { Link, Route, Switch } from "react-router-dom";

class AddCompany extends Component {
    constructor() {
        super();
        this.state = {
            ID: null,
            CompanyName: null,
            Postcode: null,
            Website: null,
            Email: null,
            CompanyRegNo: null,
            TelNo: null,
            VatNo: null,
            FaxNo: null,
            TaxIdNo: null,
            Address: null,
            SystemPrivileges: null,
            City: null,
            CompanyLogoPath: null,
            State: null,
            CopmanyLetterHeadPath: null,
            Country: null,
            AddressList: [],
            BankList: [],
            CompanyContacts: [],
            EmailsList: [],
            DistributionList: [],
            CountryList: [],
            RateList: [],
        }
    }
    bindData() {
        var myLoginToken = localStorage.getItem("AccessToken");       
        var LogedID = localStorage.getItem("LogedID");
        var LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = localStorage.getItem("CommodityID");
        fetch(config.apiUrl + 'Address/GetAddressByCompanyId?ID=1', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": LogedID,
                "logincompanyid": LoginCompanyID,
                "commodityid": CommodityID
            },
        })
            .then(res => res.json())
            .then(json => this.setState({ AddressList: json }));
    }
    bindBank() {
        var myLoginToken = localStorage.getItem("AccessToken");
        var LogedID = localStorage.getItem("LogedID");
        var LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = localStorage.getItem("CommodityID");
        fetch(config.apiUrl + 'Bank/GetBankByCompanyId?ID=1', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": LogedID,
                "logincompanyid": LoginCompanyID,
                "commodityid": CommodityID
            },
        })
            .then(res => res.json())
            .then(json => this.setState({ BankList: json }));
    }
    bindCompanyContact() {
        var myLoginToken = localStorage.getItem("AccessToken");
        var LogedID = localStorage.getItem("LogedID");
        var LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = localStorage.getItem("CommodityID");
        fetch(config.apiUrl + 'CompanyContact/GetCompanyContactByCompanyId?ID=1', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": LogedID,
                "logincompanyid": LoginCompanyID,
                "commodityid": CommodityID
            },
        })
            .then(res => res.json())
            .then(json => this.setState({ CompanyContacts: json }));
    }
    bindEmails() {
        var myLoginToken = localStorage.getItem("AccessToken");
        var LogedID = localStorage.getItem("LogedID");
        var LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = localStorage.getItem("CommodityID");
        fetch(config.apiUrl + 'CompanyEmail/GetCompanyEmailByCompanyId?ID=1', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": LogedID,
                "logincompanyid": LoginCompanyID,
                "commodityid": CommodityID
            },
        })
            .then(res => res.json())
            .then(json => this.setState({ EmailsList: json }));
    }
    bindDistributionList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        var LogedID = localStorage.getItem("LogedID");
        var LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = localStorage.getItem("CommodityID");
        fetch(config.apiUrl + 'DistributionList/GetDistributionListCompanyId?ID=1', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": LogedID,
                "logincompanyid": LoginCompanyID,
                "commodityid": CommodityID
            },
        })
            .then(res => res.json())
            .then(json => this.setState({ DistributionList: json }));
    }
    bindCountryList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        var LogedID = localStorage.getItem("LogedID");
        var LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = localStorage.getItem("CommodityID");
        fetch(config.apiUrl + 'BrokerageRate/GetCountryList', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": LogedID,
                "logincompanyid": LoginCompanyID,
                "commodityid": CommodityID
            },
        })
            .then(res => res.json())
            .then(json => this.setState({ CountryList: json }));
    }
    bindRateList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        var LogedID = localStorage.getItem("LogedID");
        var LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = localStorage.getItem("CommodityID");
        fetch(config.apiUrl + 'BrokerageRate/GetClientBrokerageRateByCompanyId?Id=1', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": LogedID,
                "logincompanyid": LoginCompanyID,
                "commodityid": CommodityID
            },
        })
            .then(res => res.json())
            .then(json => this.setState({ RateList: json }));

    }
    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken");
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.bindData();
            this.bindBank();
            this.bindCompanyContact();
            this.bindEmails();
            this.bindDistributionList();
            this.bindCountryList();
            this.bindRateList();
        } else {
            this.props.history.push('/login');
        }
    }
    submit() {
        this.state.ID = 0;
        this.state.SystemPrivileges = "Trader";
        var sector = document.getElementById("ddlCountry");
        var sectorVal = sector.options[sector.selectedIndex].value;
        var addressLine1 = document.getElementById("Line1").value;
        var addressLine2 = document.getElementById("Line2").value;
        var addressLine3 = document.getElementById("Line3").value;

        this.state.Country = sectorVal;
        this.state.Address = addressLine1 + "," + addressLine2 + "," + addressLine3;

        var formData = new FormData();
        formData.append('CompanyName', this.state.CompanyName);
        formData.append('Postcode', this.state.Postcode);
        formData.append('Website', this.state.Website);
        formData.append('Email', this.state.Email);
        formData.append('CompanyRegNo', this.state.CompanyRegNo);
        formData.append('TelNo', this.state.TelNo);
        formData.append('VatNo', this.state.VatNo);
        formData.append('FaxNo', this.state.FaxNo);
        formData.append('TaxIdNo', this.state.TaxIdNo);
        formData.append('Address', this.state.Address);
        formData.append('SystemPrivileges', this.state.SystemPrivileges);
        formData.append('City', this.state.City);
        formData.append('CompanyLogoPath', this.state.CompanyLogoPath);
        formData.append('State', this.state.State);
        formData.append('CopmanyLetterHeadPath', this.state.CopmanyLetterHeadPath);
        formData.append('Country', this.state.Country);

        var myLoginToken = localStorage.getItem("AccessToken");
        var LogedID = localStorage.getItem("LogedID");
        var LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = localStorage.getItem("CommodityID");
        fetch(config.apiUrl + "Company/CreateCompany", {
            method: "POST",
            body: formData, headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": LogedID,
                "logincompanyid": LoginCompanyID,
                "commodityid": CommodityID
            },
        }).then((resp) => {
            resp.json().then((results) => {

            });
        });
    }
    SaveAddress() {
        var IsTred = document.getElementById("AddIsTredConfirmation");
        var IsTredConfirm = false;
        if (IsTred.checked == true) {
            IsTredConfirm = true;
        } else {
            IsTredConfirm = false;
        }

        var alldata = {
            Name: document.getElementById("AddName").value,
            Add: document.getElementById("AddAddress").value,
            City: document.getElementById("AddCity").value,
            State: document.getElementById("AddState").value,
            Postcode: document.getElementById("AddPostcode").value,
            Country: document.getElementById("AddCountry").value,
            VATNo: document.getElementById("AddVATNo").value,
            TaxIDNo: document.getElementById("AddTaxIdNo").value,
            IsTredConfirmation: IsTredConfirm,
            CompanyId: 1,
            Id: 0
        }
        const data = JSON.stringify(alldata);
        var ID = document.getElementById("AddId").value;
        if (ID == "0") {
            var myLoginToken = localStorage.getItem("AccessToken");
            var LogedID = localStorage.getItem("LogedID");
            var LoginCompanyID = localStorage.getItem("LoginCompanyID");
            let CommodityID = localStorage.getItem("CommodityID");
            fetch(config.apiUrl + "Address/CreateAddress", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": LogedID,
                    "logincompanyid": LoginCompanyID,
                    "commodityid": CommodityID
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindData();
                });
            });
        }
        else {
            var myLoginToken = localStorage.getItem("AccessToken");
            var LogedID = localStorage.getItem("LogedID");
            var LoginCompanyID = localStorage.getItem("LoginCompanyID");
            let CommodityID = localStorage.getItem("CommodityID");
            fetch(config.apiUrl + "Address/UpdateAddress?Id=" + ID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": LogedID,
                    "logincompanyid": LoginCompanyID,
                    "commodityid": CommodityID
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindData();
                });
            });
        }


    }
    handleChangeCompanyLogo = (event) => {
        this.setState({
            CompanyLogoPath: event.target.files[0]
        })
    }
    handleChangeCopmanyLetterHead = (event) => {
        this.setState({
            CopmanyLetterHeadPath: event.target.files[0]
        })
    }
    Edit(ID) {
        var myLoginToken = localStorage.getItem("AccessToken");
        var LogedID = localStorage.getItem("LogedID");
        var LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = localStorage.getItem("CommodityID");
        fetch(config.apiUrl + 'Address/GetAddressById?ID=' + ID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": LogedID,
                "logincompanyid": LoginCompanyID,
                "commodityid": CommodityID
            },
        }).then((resp1) => {
            resp1.json().then((result) => {
                document.getElementById("AddId").value = result.id;
                document.getElementById("AddName").value = result.name;
                document.getElementById("AddAddress").value = result.add;
                document.getElementById("AddCity").value = result.city;
                document.getElementById("AddState").value = result.state;
                document.getElementById("AddPostcode").value = result.postcode;
                document.getElementById("AddCountry").value = result.country;
                document.getElementById("AddVATNo").value = result.vatNo;
                document.getElementById("AddTaxIdNo").value = result.taxIDNo;
                if (result.isTredConfirmation == true) {
                    document.getElementById("AddIsTredConfirmation").checked = true;
                }
                else {
                    document.getElementById("AddIsTredConfirmation").checked = false;
                }
            });
        });
    }
    Delete(ID) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var myLoginToken = localStorage.getItem("AccessToken");
                    var LogedID = localStorage.getItem("LogedID");
                    var LoginCompanyID = localStorage.getItem("LoginCompanyID");
                    let CommodityID = localStorage.getItem("CommodityID");
                    fetch(config.apiUrl + 'Address/DeleteAddress?ID=' + ID, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": LogedID,
                            "logincompanyid": LoginCompanyID,
                            "commodityid": CommodityID
                        },
                    }).then((resp1) => {
                        resp1.json().then((results) => {
                            this.bindData();
                        });
                    });
                }
            });
    }
    SaveBank() {
        var Account_Type = document.getElementById("AccountType");
        var AccType = Account_Type.options[Account_Type.selectedIndex].value;
        var CC = document.getElementById("Country");
        var CCType = CC.options[CC.selectedIndex].value;

        var alldata = {
            BankName: document.getElementById("BankName").value,
            Address: document.getElementById("BankAddress").value,
            City: document.getElementById("City").value,
            State: document.getElementById("State").value,
            Postcode: document.getElementById("Postcode").value,
            Country: CCType,
            AccountType: AccType,
            AccountNumber: document.getElementById("BankAccountNo").value,
            BIC: document.getElementById("BIC").value,
            IBAN: document.getElementById("IBAN").value,
            CompanyId: 1,
        }
        const data = JSON.stringify(alldata);
        var ID = document.getElementById("BankId").value;
        if (ID == "0") {
            var myLoginToken = localStorage.getItem("AccessToken");
            var LogedID = localStorage.getItem("LogedID");
            var LoginCompanyID = localStorage.getItem("LoginCompanyID");
            let CommodityID = localStorage.getItem("CommodityID");
            fetch(config.apiUrl + "Bank/CreateBank", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": LogedID,
                    "logincompanyid": LoginCompanyID,
                    "commodityid": CommodityID
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindBank();
                });
            });
        }
        else {
            var myLoginToken = localStorage.getItem("AccessToken");
            var LogedID = localStorage.getItem("LogedID");
            var LoginCompanyID = localStorage.getItem("LoginCompanyID");
            let CommodityID = localStorage.getItem("CommodityID");
            fetch(config.apiUrl + "Bank/UpdateBank?Id=" + ID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": LogedID,
                    "logincompanyid": LoginCompanyID,
                    "commodityid": CommodityID
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindBank();
                });
            });
        }
    }
    EditBank(ID) {
        var myLoginToken = localStorage.getItem("AccessToken");
        var LogedID = localStorage.getItem("LogedID");
        var LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = localStorage.getItem("CommodityID");
        fetch(config.apiUrl + 'Bank/GetBankById?ID=' + ID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": LogedID,
                "logincompanyid": LoginCompanyID,
                "commodityid": CommodityID
            },
        }).then((resp1) => {
            resp1.json().then((result) => {
                document.getElementById("BankId").value = result.id;
                document.getElementById("BankName").value = result.bankName;
                document.getElementById("BankAddress").value = result.address;
                document.getElementById("City").value = result.city;
                document.getElementById("State").value = result.state;
                document.getElementById("Postcode").value = result.postcode;
                document.getElementById("BankAccountNo").value = result.accountNumber;
                document.getElementById("BIC").value = result.bic;
                document.getElementById("IBAN").value = result.iban;
                document.getElementById("Country").value = result.country;
                document.getElementById("AccountType").value = result.accountType;
            });
        });
    }
    DeleteBank(ID) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var myLoginToken = localStorage.getItem("AccessToken");
                    var LogedID = localStorage.getItem("LogedID");
                    var LoginCompanyID = localStorage.getItem("LoginCompanyID");
                    let CommodityID = localStorage.getItem("CommodityID");
                    fetch(config.apiUrl + 'Bank/DeleteBank?ID=' + ID, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": LogedID,
                            "logincompanyid": LoginCompanyID,
                            "commodityid": CommodityID
                        },
                    }).then((resp1) => {
                        resp1.json().then((results) => {
                            this.bindBank();
                        });
                    });
                }
            });
    }
    SaveCompanyContact() {
        var ConCountry = document.getElementById("ConCountry");
        var CountryVal = ConCountry.options[ConCountry.selectedIndex].value;

        var alldata = {
            FullName: document.getElementById("ConFullName").value,
            Email: document.getElementById("ConEmail").value,
            TelNo: document.getElementById("ConTelNo").value,
            TelNo2: document.getElementById("ConTelNo2").value,
            MOBNo: document.getElementById("ConMobNo").value,
            FAXNo: document.getElementById("ConFAXNo").value,
            SkypeId: document.getElementById("ConSkypeId").value,
            YahooId: document.getElementById("ConYahooId").value,
            AddressLine1: document.getElementById("ConAddressLine1").value,
            AddressLine2: document.getElementById("ConAddressLine2").value,
            AddressLine3: document.getElementById("ConAddressLine3").value,
            City: document.getElementById("ConCity").value,
            Postcode: document.getElementById("ConPostcode").value,
            Country: CountryVal,
            CompanyId: 1,
        }
        const data = JSON.stringify(alldata);
        var ID = document.getElementById("CompanyContactId").value;
        if (ID == "0") {
            var myLoginToken = localStorage.getItem("AccessToken");
            var LogedID = localStorage.getItem("LogedID");
            var LoginCompanyID = localStorage.getItem("LoginCompanyID");
            let CommodityID = localStorage.getItem("CommodityID");
            fetch(config.apiUrl + "CompanyContact/CreateCompanyContact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": LogedID,
                    "logincompanyid": LoginCompanyID,
                    "commodityid": CommodityID
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindCompanyContact();
                });
            });
        }
        else {
            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(config.apiUrl + "CompanyContact/UpdateCompanyContact?Id=" + ID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindCompanyContact();
                });
            });
        }
    }
    EditCompanyContact(ID) {
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(config.apiUrl + 'CompanyContact/GetCompanyContactById?ID=' + ID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp1) => {
            resp1.json().then((result) => {
                document.getElementById("CompanyContactId").value = result.id;
                document.getElementById("ConFullName").value = result.fullName;
                document.getElementById("ConEmail").value = result.email;
                document.getElementById("ConTelNo").value = result.telNo;
                document.getElementById("ConTelNo2").value = result.telNo2;
                document.getElementById("ConMobNo").value = result.mobNo;
                document.getElementById("ConFAXNo").value = result.faxNo;
                document.getElementById("ConSkypeId").value = result.skypeId;
                document.getElementById("ConYahooId").value = result.yahooId;
                document.getElementById("ConAddressLine1").value = result.addressLine1;
                document.getElementById("ConAddressLine2").value = result.addressLine2;
                document.getElementById("ConAddressLine3").value = result.addressLine3;
                document.getElementById("ConCity").value = result.city;
                document.getElementById("ConPostcode").value = result.postcode;
                document.getElementById("ConCountry").value = result.country;
            });
        });
    }
    DeleteCompanyContact(ID) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var myLoginToken = localStorage.getItem("AccessToken");
                    fetch(config.apiUrl + 'CompanyContact/DeleteCompanyContact?ID=' + ID, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": localStorage.getItem("LogedID"),
                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                            "commodityid": localStorage.getItem("CommodityID")
                        },
                    }).then((resp1) => {
                        resp1.json().then((results) => {
                            this.bindCompanyContact();
                        });
                    });
                }
            });
    }
    SaveEmail() {
        var EEmailType = document.getElementById("EEmailType");
        var EType = EEmailType.options[EEmailType.selectedIndex].value;

        var alldata = {
            GroupName: document.getElementById("EGroupName").value,
            Email: document.getElementById("EEmail").value,
            EmailType: EType,
            CompanyId: 1,
        }
        const data = JSON.stringify(alldata);
        var ID = document.getElementById("EEmailId").value;
        if (ID == "0") {
            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(config.apiUrl + "CompanyEmail/CreateCompanyEmail", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindEmails();
                });
            });
        }
        else {
            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(config.apiUrl + "CompanyEmail/UpdateCompanyEmail?Id=" + ID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindEmails();
                });
            });
        }
    }
    EditEmail(ID) {
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(config.apiUrl + 'CompanyEmail/GetCompanyEmailById?ID=' + ID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp1) => {
            resp1.json().then((result) => {
                document.getElementById("EEmailId").value = result.id;
                document.getElementById("EGroupName").value = result.groupName;
                document.getElementById("EEmail").value = result.email;
                document.getElementById("EEmailType").value = result.emailType;
            });
        });
    }
    DeleteEmail(ID) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var myLoginToken = localStorage.getItem("AccessToken");
                    fetch(config.apiUrl + 'CompanyEmail/DeleteCompanyEmail?ID=' + ID, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": localStorage.getItem("LogedID"),
                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                            "commodityid": localStorage.getItem("CommodityID")
                        },
                    }).then((resp1) => {
                        resp1.json().then((results) => {
                            this.bindEmails();
                        });
                    });
                }
            });
    }
    
    SaveDistribution() {
        var items = document.getElementsByName('ChkEmails');
        var selectedItems = "";
        for (var i = 0; i < items.length; i++) {
            if (items[i].type == 'checkbox' && items[i].checked == true)
                selectedItems += items[i].value + ",";
        }

        var EEmailType = document.getElementById("DType");
        var EType = EEmailType.options[EEmailType.selectedIndex].value;

        var alldata = {
            EmailId: selectedItems,
            DistributionPurpose: EType,
            CompanyId: 1,
        }
        const data = JSON.stringify(alldata);
        var ID = document.getElementById("DID").value;
        if (ID == "0") {
            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(config.apiUrl + "DistributionList/CreateDistributionList", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindDistributionList();
                });
            });
        }
        else {
            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(config.apiUrl + "DistributionList/UpdateDistributionList?Id=" + ID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindDistributionList();
                });
            });
        }

    }
    EditDList(ID) {
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(config.apiUrl + 'DistributionList/GetDistributionListById?ID=' + ID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp1) => {
            resp1.json().then((result) => {
                var emailID = result.emailId;
                var items = document.getElementsByName('ChkEmails');
                for (var k = 0; k < items.length; k++) {
                    var CheckID = items[k].value;
                    if (emailID == CheckID) {
                        document.getElementById(CheckID).checked = true;
                    }
                    else {
                        document.getElementById(CheckID).checked = false;
                    }
                }
                document.getElementById("DType").value = result.distributionPurpose;
                document.getElementById("DID").value = result.id;
            });
        });
    }
    DeleteDList(ID) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var myLoginToken = localStorage.getItem("AccessToken");
                    fetch(config.apiUrl + 'DistributionList/DeleteDistributionList?ID=' + ID, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": localStorage.getItem("LogedID"),
                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                            "commodityid": localStorage.getItem("CommodityID")
                        },
                    }).then((resp1) => {
                        resp1.json().then((results) => {
                            this.bindDistributionList();
                        });
                    });
                }
            });
    }
    SaveRate() {
        var CurrencyType = document.getElementById("Currency");
        var CurrencyId = CurrencyType.options[CurrencyType.selectedIndex].value;
        var alldata = {
            IRate: document.getElementById("IRate").value,
            ARate: document.getElementById("ARate").value,
            CountryId: CurrencyId,
            CompanyId: 1,
        }
        const data = JSON.stringify(alldata);
        var ID = document.getElementById("RID").value;
        if (ID == "0") {
            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(config.apiUrl + "BrokerageRate/CreateClientBrokerageRate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindRateList();
                });
            });
        }
        else {
            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(config.apiUrl + "BrokerageRate/UpdateClientBrokerageRate?Id=" + ID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                resp.json().then((result) => {
                    this.bindRateList();
                });
            });
        }
    }
    render() {
        var CountryID = 0;
        return (
            <div className="wrapper-pro">
                <Sidebar />
                <div className="content-inner-all">
                    <Header />
                    <div className="breadcome-area mg-b-30 small-dn" style={{ display: '' }}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="breadcome-list shadow-reset">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="breadcome-heading">
                                                    <form role="search" className>
                                                        <input type="text" placeholder="Search..." className="form-control" />
                                                        <a href="javascript:void(0);"><i className="fa fa-search" /></a>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <ul className="breadcome-menu">
                                                    <li><Link to="/">Home</Link> <span className="bread-slash">/</span>
                                                    </li>
                                                    <li><span className="bread-blod">Add Company Details</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-menu-area" style={{ display: '' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="mobile-menu">
                                        <nav id="dropdown">
                                            <ul className="mobile-menu-nav">
                                                <li><a href="javascript:void(0);">Home <span className="admin-project-icon adminpro-icon " /></a>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#demo" href="javascript:void(0);">Mailbox <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="demo" className="collapse dropdown-header-top">
                                                        <li><a href="javascript:void(0);">Inbox</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">View Mail</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Compose Mail</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#others" href="javascript:void(0);">Miscellaneous <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="others" className="collapse dropdown-header-top">
                                                        <li><a href="javascript:void(0);">Profile</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Contact Client</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Contact Client v.1</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Project List</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Project Details</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#Miscellaneousmob" href="javascript:void(0);">Interface <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="Miscellaneousmob" className="collapse dropdown-header-top">
                                                        <li><a href="javascript:void(0);">Google Map</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Data Maps</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Pdf Viewer</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">X-Editable</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Code Editor</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Tree View</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Preloader</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Images Cropper</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#Chartsmob" href="javascript:void(0);">Charts <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="Chartsmob" className="collapse dropdown-header-top">
                                                        <li><a href="javascript:void(0);">Bar Charts</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Line Charts</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Area Charts</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Rounded Charts</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">C3 Charts</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Sparkline Charts</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Peity Charts</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#Tablesmob" href="JavaScript:Void(0);">Tables <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="Tablesmob" className="collapse dropdown-header-top">
                                                        <li><a href="static-table.html">Static Table</a>
                                                        </li>
                                                        <li><a href="data-table.html">Data Table</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#formsmob" href="javascript:void(0);">Forms <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="formsmob" className="collapse dropdown-header-top">
                                                        <li><a href="javascript:void(0);">Basic Form Elements</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Advanced Form Elements</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Password Meter</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Multi Upload</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Text Editor</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Dual List Box</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#Appviewsmob" href="javascript:void(0);">App views <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="Appviewsmob" className="collapse dropdown-header-top">
                                                        <li><a href="javascript:void(0);">Basic Form Elements</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Advanced Form Elements</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Password Meter</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Multi Upload</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Text Editor</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Dual List Box</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#Pagemob" href="javascript:void(0);">Pages <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="Pagemob" className="collapse dropdown-header-top">
                                                        <li><a href="javascript:void(0);">Login</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Register</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Captcha</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Checkout</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Contacts</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Review</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Order</a>
                                                        </li>
                                                        <li><a href="javascript:void(0);">Comment</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="breadcome-area mg-b-30 des-none" style={{ display: '' }}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="breadcome-list map-mg-t-40-gl shadow-reset">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <div className="breadcome-heading">
                                                    <form role="search" className>
                                                        <input type="text" placeholder="Search..." className="form-control" />
                                                        <a href><i className="fa fa-search" /></a>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <ul className="breadcome-menu">
                                                    <li><a href="javascript:void(0);">EU Power</a> <span className="bread-slash">/</span>
                                                    </li>
                                                    <li><span className="bread-blod">Add Company Details</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="data-table-area mg-b-15">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="admintab-wrap mg-b-40">
                                        <ul className="nav nav-tabs custom-menu-wrap custon-tab-menu-style1">
                                            <li className="active"><a data-toggle="tab" href="#Basic"><span className="adminpro-icon adminpro-info tab-custon-ic" />Basic info</a>
                                            </li>
                                            <li><a data-toggle="tab" href="#Address"><span className="adminpro-icon adminpro-map tab-custon-ic" />Address List</a>
                                            </li>
                                            <li><a data-toggle="tab" href="#Personal"><span className="adminpro-icon adminpro-analytics-bridge tab-custon-ic" />Company Personal</a>
                                            </li>
                                            <li><a data-toggle="tab" href="#Rates"><span className="adminpro-icon adminpro-dollar-symbol tab-custon-ic" />Brokerage Rates</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="Basic" className="tab-pane in active custon-tab-style1">
                                                <div className="sparkline8-graph">
                                                    <div className="basic-login-form-ad">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                <div className="basic-login-inner">
                                                                    <form action="#">
                                                                        <div className="form-group-inner">
                                                                            <label>Company Name</label>
                                                                            <input type="email" className="form-control"
                                                                                onChange={(e) => {
                                                                                    this.setState({ CompanyName: e.target.value });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>Website</label>
                                                                            <input type="text" className="form-control" placeholder="Website"
                                                                                onChange={(e) => {
                                                                                    this.setState({ Website: e.target.value });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>E- Mail</label>
                                                                            <input type="Email" className="form-control"
                                                                                onChange={(e) => {
                                                                                    this.setState({ Email: e.target.value });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group-inner mb-0">
                                                                            <label>Tel No.</label>
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <div className="form-select-list">
                                                                                        <select className="form-control custom-select-value" name="account">
                                                                                            <option>EU</option>
                                                                                            <option>USA</option>
                                                                                            <option>UAE</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group-">
                                                                                        <input type="text" className="form-control" placeholder={+96} disabled />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group-inner">
                                                                                        <input type="text" className="form-control"
                                                                                            onChange={(e) => {
                                                                                                this.setState({ TelNo: e.target.value });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group-inner mb-0">
                                                                            <label>Fax No.</label>
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <div className="form-select-list">
                                                                                        <select className="form-control custom-select-value" name="account">
                                                                                            <option>EU</option>
                                                                                            <option>USA</option>
                                                                                            <option>UAE</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group-">
                                                                                        <input type="text" className="form-control" placeholder={+96} disabled />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group-inner">
                                                                                        <input type="text" className="form-control"
                                                                                            onChange={(e) => {
                                                                                                this.setState({ FaxNo: e.target.value });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group-inner mb-0">
                                                                            <label>Address</label>
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <div className="form-select-list">
                                                                                        <div className="form-group-inner">
                                                                                            <input type="text" className="form-control" placeholder="Line2" id="Line1" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group-inner">
                                                                                        <input type="text" className="form-control" placeholder="Line2" id="Line2" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group-inner">
                                                                                        <input type="text" className="form-control" placeholder="Line3" id="Line3" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>City</label>
                                                                            <input type="text" className="form-control"
                                                                                onChange={(e) => {
                                                                                    this.setState({ City: e.target.value });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>State</label>
                                                                            <input type="text" className="form-control"
                                                                                onChange={(e) => {
                                                                                    this.setState({ State: e.target.value });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                <div className="basic-login-inner">
                                                                    <form action="#">
                                                                        <div className="form-group-inner">
                                                                            <label>Postcode</label>
                                                                            <input type="text" className="form-control"
                                                                                onChange={(e) => {
                                                                                    this.setState({ Postcode: e.target.value });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>Country</label>
                                                                            <div className="form-select-list">
                                                                                <select className="form-control custom-select-value" name="ddlCountry" id="ddlCountry">
                                                                                    <option value="EU">EU</option>
                                                                                    <option value="USA">USA</option>
                                                                                    <option value="UAE">UAE</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>Company Reg No.</label>
                                                                            <input type="number" className="form-control"
                                                                                onChange={(e) => {
                                                                                    this.setState({ CompanyRegNo: e.target.value });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>VAT No.</label>
                                                                            <input type="number" className="form-control"
                                                                                onChange={(e) => {
                                                                                    this.setState({ VatNo: e.target.value });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>Tax ID No.</label>
                                                                            <input type="number" className="form-control"
                                                                                onChange={(e) => {
                                                                                    this.setState({ TaxIdNo: e.target.value });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>System Privileges</label>
                                                                            <input type="number" className="form-control" placeholder="Trader" disabled />
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>Company Logo</label>
                                                                            <div className="row">
                                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                    <div className="file-upload-inner file-upload-inner-right ts-forms">
                                                                                        <div className="input append-small-btn">
                                                                                            <div className="file-button">
                                                                                                Browse
                                        <input type="file" onChange={this.handleChangeCompanyLogo} />
                                                                                            </div>
                                                                                            <input type="text" id="append-small-btn" placeholder="no file selected" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group-inner">
                                                                            <label>Company Letterhead</label>
                                                                            <div className="row">
                                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                    <div className="file-upload-inner file-upload-inner-right ts-forms">
                                                                                        <div className="input append-small-btn">
                                                                                            <div className="file-button">
                                                                                                Browse
                                        <input type="file" onChange={this.handleChangeCopmanyLetterHead} />
                                                                                            </div>
                                                                                            <input type="text" id="append-small-btn" placeholder="no file selected" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mg-t-30" style={{ display: 'flex' }}>
                                                            <div className="inline-remember-me">
                                                                <button className="btn btn-primary btn-md" onClick={() => this.submit()} type="button">Save</button>
                                                            </div>
                                                            <div className="inline-remember-me">
                                                                <button className="btn btn-danger ml-2 pull- login-submit-cs">Reset</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="Address" className="tab-pane custon-tab-style1">
                                                <div className="myaddress" style={{ padding: 10 }}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h1 style={{ marginBottom: 24 }}>Multiple Address Distribution List</h1>
                                                        </div>
                                                        <div className="col-md-6"> <button className="btn btn-success ml-2 pull-right login-submit-cs " data-toggle="modal" data-target="#AddressList"><i className="fa fa-plus-circle" /> &nbsp;Add Address</button></div>
                                                    </div>
                                                    <div className="sparkline9-graph">
                                                        <div className="static-table-list">
                                                            <table className="table sparkle-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Full Address</th>
                                                                        <th>Trade Confirmation</th>
                                                                        <th>Invoice</th>
                                                                        <th>VAT No.</th>
                                                                        <th>Tax ID No.</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.AddressList.map(item => (
                                                                        <tr>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.add}</td>
                                                                            {item.isTredConfirmation ? (
                                                                                <td><span class="badge badge-success">True</span></td>
                                                                            ) : (
                                                                                <td><span class="badge badge-danger">False</span></td>
                                                                            )}
                                                                            <td></td>
                                                                            <td>{item.vatNo} </td>
                                                                            <td>{item.taxIDNo}</td>
                                                                            <td><a href="javascript:void(0);" onClick={() => this.Edit(item.id)} className="btn  btn-primary btn-sm btn-custon-rounded-four text-white" data-toggle="modal" data-target="#AddressList">Update</a>&nbsp;&nbsp;
                                                                                <a href="javascript:void(0);" onClick={() => this.Delete(item.id)} className="btn  btn-danger btn-sm btn-custon-rounded-four text-white">Delete</a>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="myaddress" style={{ padding: 10 }}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h1 style={{ marginBottom: 24 }}>Bank Account Details</h1>
                                                        </div>
                                                        <div className="col-md-6"> <button className="btn btn-success ml-2 pull-right login-submit-cs " data-toggle="modal" data-target="#Bank"><i className="fa fa-plus-circle" /> &nbsp;Add Bank </button></div>
                                                    </div>
                                                    <div className="sparkline9-graph">
                                                        <div className="static-table-list">
                                                            <table className="table sparkle-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Bank Name </th>
                                                                        <th> Account Type</th>
                                                                        <th> Account No. </th>
                                                                        <th>IBAN</th>
                                                                        <th>BIC</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.BankList.map(item => (
                                                                        <tr>
                                                                            <td>{item.bankName}</td>
                                                                            <td>{item.accountType}</td>
                                                                            <td>{item.accountNumber}</td>
                                                                            <td>{item.iban}</td>
                                                                            <td>{item.bic} </td>
                                                                            <td><a href="javascript:void(0);" onClick={() => this.EditBank(item.id)} className="btn  btn-primary btn-sm btn-custon-rounded-four text-white" data-toggle="modal" data-target="#Bank">Update</a>&nbsp;&nbsp;
                                                                                <a href="javascript:void(0);" onClick={() => this.DeleteBank(item.id)} className="btn  btn-danger btn-sm btn-custon-rounded-four text-white">Delete</a>
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="Personal" className="tab-pane custon-tab-style1">
                                                <div className="Personal" style={{ padding: 10 }}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h1 style={{ marginBottom: 24 }}>Company Personal</h1>
                                                        </div>
                                                        <div className="col-md-6"> <button className="btn btn-success ml-2 pull-right login-submit-cs " data-toggle="modal" data-target="#Contact"><i className="fa fa-plus-circle" /> &nbsp;Add Personal Info</button></div>
                                                    </div>
                                                    <div className="sparkline9-graph">
                                                        <div className="static-table-list">
                                                            <table className="table sparkle-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th> E-Mail</th>
                                                                        <th> Tel No. 1</th>
                                                                        <th>Tel No. 2</th>
                                                                        <th>Mob No.</th>
                                                                        <th>Skype ID </th>
                                                                        <th>ICE ID</th>
                                                                        <th>Address</th>
                                                                        <th>Role</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.CompanyContacts.map(item => (
                                                                        <tr>
                                                                            <td>{item.fullName}</td>
                                                                            <td>{item.email}</td>
                                                                            <td>{item.telNo}</td>
                                                                            <td>{item.telNo2}</td>
                                                                            <td>{item.mobNo}</td>
                                                                            <td>{item.skypeId}</td>
                                                                            <td>{item.yahooId}</td>
                                                                            <td>{item.addressLine1}, {item.addressLine2}, {item.addressLine3}</td>
                                                                            <td>Trader</td>
                                                                            <td><a href="javascript:void(0);" onClick={() => this.EditCompanyContact(item.id)} className="btn  btn-primary btn-sm btn-custon-rounded-four text-white" data-toggle="modal" data-target="#Contact">Update</a>&nbsp;&nbsp;
                                                                                <a href="javascript:void(0);" onClick={() => this.DeleteCompanyContact(item.id)} className="btn  btn-danger btn-sm btn-custon-rounded-four text-white">Delete</a>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className style={{ padding: 10 }}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h1 style={{ marginBottom: 24 }}>E-mail Setup</h1>
                                                        </div>
                                                        <div className="col-md-6"> <button className="btn btn-success ml-2 pull-right login-submit-cs " data-toggle="modal" data-target="#Email"><i className="fa fa-plus-circle" /> &nbsp;Add New Email</button></div>
                                                    </div>
                                                    <div className="sparkline9-graph">
                                                        <div className="static-table-list">
                                                            <table className="table sparkle-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th> E-Mail</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.EmailsList.map(item => (
                                                                        <tr>
                                                                            <td>{item.groupName}</td>
                                                                            <td>{item.email}</td>
                                                                            <td>
                                                                                <a href="JavaScript:Void(0);" onClick={() => this.EditEmail(item.id)} className="btn  btn-primary btn-sm btn-custon-rounded-four text-white" data-toggle="modal" data-target="#Email">Update</a>&nbsp;&nbsp;
                                                                                <a href="JavaScript:Void(0);" onClick={() => this.DeleteEmail(item.id)} className="btn  btn-danger btn-sm btn-custon-rounded-four text-white">Delete</a>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="Distribution" style={{ padding: 10 }}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h1 style={{ marginBottom: 24 }}>Distribution List Settings</h1>
                                                        </div>
                                                        <div className="col-md-6"> <button className="btn btn-success ml-2 pull-right login-submit-cs " data-toggle="modal" data-target="#DistributionList"><i className="fa fa-plus-circle" /> &nbsp;Add Distribution List</button></div>
                                                    </div>
                                                    <div className="sparkline9-graph">
                                                        <div className="static-table-list">
                                                            <table className="table sparkle-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>E-Mail</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.DistributionList.map(item => (
                                                                        <tr>
                                                                            <td>{item.distributionPurpose}</td>
                                                                            <td>{item.companyEmail.email} </td>
                                                                            <td>
                                                                                <a href="JavaScript:Void(0);" onClick={() => this.EditDList(item.id)} className="btn  btn-primary btn-sm btn-custon-rounded-four text-white" data-toggle="modal" data-target="#DistributionList">Update</a>&nbsp;&nbsp;
                                                                                 <a href="JavaScript:Void(0);" onClick={() => this.DeleteDList(item.id)} className="btn  btn-danger btn-sm btn-custon-rounded-four text-white">Delete</a>
                                                                            </td>
                                                                        </tr>
                                                                    ))}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="Rates" className="tab-pane custon-tab-style1">
                                                <div className="Personal" style={{ padding: 10 }}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h1 style={{ marginBottom: 24 }}>Brokerage Rates
                      </h1>
                                                        </div>
                                                        <div className="col-md-6"> <button className="btn btn-success ml-2 pull-right login-submit-cs " data-toggle="modal" data-target="#Rate"><i className="fa fa-plus-circle" /> &nbsp;Add New Rate</button></div>
                                                    </div>
                                                    <div className="sparkline9-graph">
                                                        <div className="static-table-list" style={{ overflow: 'auto' }}>
                                                            <table className="table sparkle-table" style={{ width: '100%' }}>
                                                                <thead>
                                                                    <tr role="row">
                                                                        <th>#</th>
                                                                        {this.state.CountryList.map(item => (
                                                                            <th>{item.name}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Initiator</td>
                                                                        {this.state.RateList.map(items => (
                                                                            <td>{items.iRate}</td>
                                                                        ))}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Aggressor</td>
                                                                        {this.state.RateList.map(items => (
                                                                            <td>{items.aRate}</td>
                                                                        ))}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="AddressList" className="modal modal-adminpro-general default-popup-PrimaryModal fade" role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header header-color-modal bg-color-1">
                                            <h4 className="modal-title">Multiple Address Distribution List</h4>
                                            <div className="modal-close-area modal-close-df">
                                                <a className="close" data-dismiss="modal" href="JavaScript:Void(0);"><i className="fa fa-close" /></a>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <div className="basic-login-inner">
                                                <form action="#">
                                                    <div className="form-group-inner">
                                                        <label>Name</label>
                                                        <input type="hidden" id="AddId" name="AddId" value="0" />
                                                        <input type="text" className="form-control" placeholder="Name" id="AddName" name="AddName" />
                                                    </div>
                                                    <div className="form-group-inner ">
                                                        <label>Address</label>
                                                        <textarea className="form-control" placeholder="Address" id="AddAddress" name="AddAddress" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>City</label>
                                                        <input type="text" className="form-control" placeholder="City" id="AddCity" name="AddCity" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>State</label>
                                                        <input type="text" className="form-control" placeholder="State" id="AddState" name="AddState" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Postcode</label>
                                                        <input type="text" className="form-control" placeholder="Postcode" id="AddPostcode" name="AddPostcode" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Country</label>
                                                        <input type="text" className="form-control" placeholder="Country" id="AddCountry" name="AddCountry" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>VAT No</label>
                                                        <input type="text" className="form-control" placeholder="VAT No" id="AddVATNo" name="AddVATNo" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Tax ID No.</label>
                                                        <input type="text" className="form-control" placeholder="Tax ID No" id="AddTaxIdNo" name="AddTaxIdNo" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Purpose</label>
                                                        <div className="i-checks ">
                                                            <label>
                                                                <input type="checkbox" id="AddIsTredConfirmation" name="AddIsTredConfirmation" /> <i /> Trade Confirmation </label>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <a onClick={() => this.SaveAddress()} href="JavaScript:Void(0);" data-dismiss="modal">Save</a>
                                            <a data-dismiss="modal" href="JavaScript:Void(0);">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Bank" className="modal modal-adminpro-general default-popup-PrimaryModal fade" role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header header-color-modal bg-color-1">
                                            <h4 className="modal-title">Add Bank Details</h4>
                                            <div className="modal-close-area modal-close-df">
                                                <a className="close" data-dismiss="modal" href="JavaScript:Void(0);"><i className="fa fa-close" /></a>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <div className="basic-login-inner">
                                                <form action="#">
                                                    <div className="form-group-inner">
                                                        <label>Bank Name</label>
                                                        <input type="hidden" id="BankId" name="BankId" value="0" />
                                                        <input type="text" className="form-control" placeholder="Bank Name" id="BankName" name="BankName" />
                                                    </div>
                                                    <div className="form-group-inner ">
                                                        <label>Address</label>
                                                        <textarea className="form-control" placeholder="Address" id="BankAddress" name="BankAddress" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>City</label>
                                                        <input type="text" className="form-control" placeholder="City" id="City" name="City" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>State</label>
                                                        <input type="text" className="form-control" placeholder="State" id="State" name="State" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Postcode</label>
                                                        <input type="text" className="form-control" placeholder="Postcode" id="Postcode" name="Postcode" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Country</label>
                                                        <div className="form-select-list">
                                                            <select className="form-control custom-select-value" name="Country" id="Country">
                                                                <option>EU</option>
                                                                <option>USA</option>
                                                                <option>UAE</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Account Type</label>
                                                        <div className="form-select-list">
                                                            <select className="form-control custom-select-value" name="AccountType" id="AccountType">
                                                                <option>EUR</option>
                                                                <option>CHF</option>
                                                                <option>UAE</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Account No.</label>
                                                        <input type="text" className="form-control" placeholder="Account No" id="BankAccountNo" name="BankAccountNo" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>BIC</label>
                                                        <input type="text" className="form-control" placeholder="BIC" id="BIC" name="BIC" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>IBAN</label>
                                                        <input type="text" className="form-control" placeholder="IBAN" id="IBAN" name="IBAN" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <a onClick={() => this.SaveBank()} href="JavaScript:Void(0);" data-dismiss="modal">Save</a>
                                            <a data-dismiss="modal" href="JavaScript:Void(0);">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Contact" className="modal modal-adminpro-general default-popup-PrimaryModal fade" role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header header-color-modal bg-color-1">
                                            <h4 className="modal-title">Add Contact Details</h4>
                                            <div className="modal-close-area modal-close-df">
                                                <a className="close" data-dismiss="modal" href="JavaScript:Void(0);"><i className="fa fa-close" /></a>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <div className="basic-login-inner">
                                                <form action="#">
                                                    <div className="form-group-inner">
                                                        <label>Full Name </label>
                                                        <input type="hidden" id="CompanyContactId" name="CompanyContactId" value="0" />
                                                        <input type="text" className="form-control" placeholder="Full Name" id="ConFullName" name="ConFullName" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Email </label>
                                                        <input type="email" className="form-control" placeholder="Email" id="ConEmail" name="ConEmail" />
                                                    </div>
                                                    <div className="form-group-inner mb-0">
                                                        <label>Tel No.</label>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-select-list">
                                                                    <select className="form-control custom-select-value">
                                                                        <option>EU</option>
                                                                        <option>USA</option>
                                                                        <option>UAE</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group-">
                                                                    <input type="text" className="form-control" placeholder="" disabled />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group-inner">
                                                                    <input type="text" className="form-control" placeholder="Tel No" name="ConTelNo" id="ConTelNo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-inner mb-0">
                                                        <label>Tel No.2</label>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-select-list">
                                                                    <select className="form-control custom-select-value" name="account">
                                                                        <option>EU</option>
                                                                        <option>USA</option>
                                                                        <option>UAE</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group-">
                                                                    <input type="text" className="form-control" placeholder="" disabled />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group-inner">
                                                                    <input type="text" className="form-control" placeholder="Tel No.2" name="ConTelNo2" id="ConTelNo2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-inner mb-0">
                                                        <label>MOB No.</label>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-select-list">
                                                                    <select className="form-control custom-select-value" name="account">
                                                                        <option>EU</option>
                                                                        <option>USA</option>
                                                                        <option>UAE</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group-">
                                                                    <input type="text" className="form-control" placeholder="" disabled />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group-inner">
                                                                    <input type="text" className="form-control" placeholder="MOB No" name="ConMobNo" id="ConMobNo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-inner mb-0">
                                                        <label>FAX No.</label>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-select-list">
                                                                    <select className="form-control custom-select-value" name="account">
                                                                        <option>EU</option>
                                                                        <option>USA</option>
                                                                        <option>UAE</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group-">
                                                                    <input type="text" className="form-control" placeholder="" disabled />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group-inner">
                                                                    <input type="text" className="form-control" placeholder="FAX No" name="ConFAXNo" id="ConFAXNo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Skype ID</label>
                                                        <input type="text" className="form-control" placeholder="Skype ID" name="ConSkypeId" id="ConSkypeId" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>ICE ID</label>
                                                        <input type="text" className="form-control" placeholder="ICE ID" name="ConYahooId" id="ConYahooId" />
                                                    </div>
                                                    <div className="form-group-inner mb-0">
                                                        <label>Address</label>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-select-list">
                                                                    <div className="form-group-inner">
                                                                        <input type="text" className="form-control" placeholder="Line1" name="ConAddressLine1" id="ConAddressLine1" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group-inner">
                                                                    <input type="text" className="form-control" placeholder="Line2" name="ConAddressLine2" id="ConAddressLine2" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group-inner">
                                                                    <input type="text" className="form-control" placeholder="Line3" name="ConAddressLine3" id="ConAddressLine3" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>City</label>
                                                        <input type="text" className="form-control" placeholder="City" name="ConCity" id="ConCity" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Postcode</label>
                                                        <input type="text" className="form-control" placeholder="Postcode" name="ConPostcode" id="ConPostcode" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Country</label>
                                                        <div className="form-select-list">
                                                            <select className="form-control custom-select-value" name="ConCountry" id="ConCountry">
                                                                <option>EU</option>
                                                                <option>USA</option>
                                                                <option>UAE</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <a onClick={() => this.SaveCompanyContact()} href="JavaScript:Void(0);" data-dismiss="modal">Save</a>
                                            <a data-dismiss="modal" href="JavaScript:Void(0);">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Email" className="modal modal-adminpro-general default-popup-PrimaryModal fade" role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header header-color-modal bg-color-1">
                                            <h4 className="modal-title">Add Contact Details</h4>
                                            <div className="modal-close-area modal-close-df">
                                                <a className="close" data-dismiss="modal" href="JavaScript:Void(0);"><i className="fa fa-close" /></a>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <div className="basic-login-inner">
                                                <form action="#">
                                                    <div className="form-group-inner">
                                                        <label>Email Type</label>
                                                        <div className="form-select-list">
                                                            <select className="form-control custom-select-value" name="EEmailType" id="EEmailType">
                                                                <option>Group</option>
                                                                <option>Individual</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Group Name </label>
                                                        <input type="hidden" id="EEmailId" name="EEmailId" value="0" />
                                                        <input type="text" className="form-control" placeholder="Group Name" name="EGroupName" id="EGroupName" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Email </label>
                                                        <input type="email" className="form-control" placeholder="Email" name="EEmail" id="EEmail" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <a onClick={() => this.SaveEmail()} href="JavaScript:Void(0);" data-dismiss="modal">Save</a>
                                            <a data-dismiss="modal" href="JavaScript:Void(0);">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="DistributionList" className="modal modal-adminpro-general default-popup-PrimaryModal fade" role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header header-color-modal bg-color-1">
                                            <h4 className="modal-title">Setup Distribution List</h4>
                                            <div className="modal-close-area modal-close-df">
                                                <a className="close" data-dismiss="modal" href="JavaScript:Void(0);"><i className="fa fa-close" /></a>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <div className="basic-login-inner">
                                                <form action="#">
                                                    <div className="form-group-inner">
                                                        <label>Distribution Purpose</label>
                                                        <div className="form-select-list">
                                                            <input type="hidden" id="DID" name="CompanyContactId" value="0" />
                                                            <select className="form-control custom-select-value" name="DType" id="DType">
                                                                <option value="Trade Confirm (LNG)">Trade Confirm (LNG)</option>
                                                                <option value="Trade Confirm (Power)">Trade Confirm (Power)</option>
                                                                <option value="Invoice (Gas)">Invoice (Gas)</option>
                                                                <option value="Invoice (LNG)">Invoice (LNG)</option>
                                                                <option value="Invoice (Power)">Invoice (Power)</option>
                                                                <option value="Market Report">Market Report</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <label>User</label>
                                                    <div className="static-table-list">
                                                        <table className="table border-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>PDF </th>
                                                                    <th>XLSX</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.EmailsList.map(item => (
                                                                    <tr>
                                                                        <td>
                                                                            <label>
                                                                                <input type="checkbox" className="i-checks" name="ChkEmails" value={item.id} id={item.id} />
                                                                            </label> &nbsp;{item.email}
                                                                        </td>
                                                                        <td>PDF file</td>
                                                                        <td>Excel File</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <a onClick={() => this.SaveDistribution()} href="JavaScript:Void(0);" data-dismiss="modal">Save</a>
                                            <a data-dismiss="modal" href="JavaScript:Void(0);">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Rate" className="modal modal-adminpro-general default-popup-PrimaryModal fade" role="dialog">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header header-color-modal bg-color-1">
                                            <h4 className="modal-title">Add Brokerage Fee</h4>
                                            <div className="modal-close-area modal-close-df">
                                                <a className="close" data-dismiss="modal" href="JavaScript:Void(0);"><i className="fa fa-close" /></a>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <div className="basic-login-inner">
                                                <form action="#">
                                                    <div className="form-group-inner">
                                                        <label>Company Name </label>
                                                        <input type="text" className="form-control" placeholder="A2A S.p.a" disabled />
                                                    </div>
                                                    <label>Currecy</label>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group-inner">
                                                                <div className="form-select-list">
                                                                    <select className="form-control custom-select-value" name="Currency" id="Currency">
                                                                        <option value="0">--- Select Currecy ---</option>
                                                                        {this.state.CountryList.map(item => (
                                                                            <option value={item.id}>{item.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                        </div>
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Initiator Rate</label>
                                                        <input type="hidden" id="RID" name="RID" value="0" />
                                                        <input type="text" className="form-control" placeholder="Initiator Rate" id="IRate" name="IRate" />
                                                    </div>
                                                    <div className="form-group-inner">
                                                        <label>Aggressor Rate</label>
                                                        <input type="text" className="form-control" placeholder="Aggressor Rate" id="ARate" name="ARate" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <a onClick={() => this.SaveRate()} href="JavaScript:Void(0);" data-dismiss="modal">Save</a>
                                            <a data-dismiss="modal" href="JavaScript:Void(0);">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}

export default AddCompany;