import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import config from './config';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
var update = require('immutability-helper');

class CompanyDetail extends Component {
    constructor() {
        super();
        this.state = {
            // id: null,
            // add1: null, 
            // add2: null,
            // add3: null,
            // city: null,
            // companies: null,
            // country: null,
            // countryname:null,
            // createdBy: null,
            // dateCreated: null,
            // email: null,
            // emailHome: null,
            // emailWork: null,
            // fax: null,
            // faxCode: null,
            // faxCountry: null,
            // firstName: null,
            // individualCompanyMap: null,
            // isDelete: null,
            // lastName: null,
            // mobileNo: null,
            // mobileNoCode: null,
            // mobileNoCountry: null,
            // postcode: null,
            // responseEmail: null,
            // skypeId: null,
            // roleName: null,
            // state: null,
            // telephone1: null,
            // telephone1Code: null,
            // telephone1Country: null,
            // telephone2: null,
            // telephone2Code: null,
            // telephone2Code: null,
            // telephone2Country: null,
            // updatedBy: null,
            // updatedDate: null,
            // yahooId: null,
            companydetailid: 0,
            companydetailname: null,
            companydetailcompanyId: 0,
            companydetailcopmanyLetterHeadPath: null,
            companydetailcompanyLogoPath: null,
            companydetailcompanyRegNo: null,
            companydetailwebsite: null,
            companydetailemail: null,
            companydetailtelNo: null,
            companydetailfaxNo: null,
            companydetailaddress: null,
            companydetailcity: null,
            companydetailpostcode: null,
            companydetailstate: null,
            companydetailcountry: null,
            companydetailcountryname: null,
            companydetailvatNo: null,
            companydetailtaxIdNo: null,
            companydetailsystemPrivileges: null,
            Banks: [],
            CompanyPersonal: [],
            CompanyList: [],
            LoginInfo: [],
            CountryList: [],
            AccountTypeList: [],
            CompanyLogo: null,
            CompanyLetterhead: null,
            contentUrl: config.contentUrl,
            DistType: null,
            DistributionList: [],
            DistCompanyPersonalList: [],
            DistSelectedCompanyPersonalList: [],
            BrokerageRateList: [],
            BrokerageCurrencyList: [],
            BrokerageCountryList: [],
            MultipleAddressList: [],
            NameError: "",
            Address1Error: "",
            CityError: "",
            StateError: "",
            PincodeError: "",
            AccountNoError: "",
            BICError: "",
            IBANError: "",
            EmailError: "",
            CompanyCityError: "",
            CompanyStateError: "",
            CompanyPostcodeError: "",
            CPFNameError: "",
            CPLNameError: "",
            CPEmailError: "",
            CPCityError: "",
            CPStateError: "",
            CPPostcodeError: "",
            countrySelected: null,
            countryAddressSelected: null,
            accountTypeSelected: null,
            BankCountry: null,
            CompanyTelCountrySelected: null,
            CompanyFaxCountrySelected: null,
            CompanyTelCountry: null,
            CompanyFaxCountry: null,
            CompanyTelCode: null,
            CompanyFaxCode: null,
        }
    }

    onChangeCountry1 = (event) => {
        this.setState({ usertelephone1Selected: event });
        this.setState({ telephone1Country: event.value });
        this.setState({ telephone1Code: "+" + event.code });
    }


    onChangeCountry2 = (event) => {
        this.setState({ usertelephone2Selected: event });
        this.setState({ telephone2Country: event.value });
        this.setState({ telephone2Code: "+" + event.code });
    }

    onChangeCountry3 = (event) => {
        this.setState({ usermobileNoSelected: event });
        this.setState({ mobileNoCountry: event.value });
        this.setState({ mobileNoCode: "+" + event.code });
    }

    onChangeFaxCountry = (event) => {
        this.setState({ userfaxCountrySelected: event });
        this.setState({ faxCountry: event.value });
        this.setState({ faxCode: "+" + event.code });
    }

    onChangeCompanyLogo = event => {
        let FetchURL = config.apiUrl + "companylogo";
        let CompanyID = localStorage.getItem("EditCompanyID");

        const formData = new FormData();
        formData.append("files", event.target.files[0]);
        formData.append("id", CompanyID);
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchURL, {
            method: 'POST',
            body: formData,
            processData: false,
            contentType: false,
            // headers: {
            //     "Content-Type": "application/json",
            //     // "Authorization": "Bearer " + myLoginToken,
            //     // "loginuserid": localStorage.getItem("LogedID"),
            //     // "logincompanyid": localStorage.getItem("LoginCompanyID"),
            //     // "commodityid": localStorage.getItem("CommodityID")
            // },
        }).then((resp) => {
            debugger;
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ companydetailcompanyLogoPath: result });
                });
            }
        });
    };

    onChangeCompanyLetterHeadPath = event => {
        let FetchURL = config.apiUrl + "companyletterhead";
        let CompanyID = localStorage.getItem("EditCompanyID");
        const formData = new FormData();
        formData.append("files", event.target.files[0]);
        formData.append("id", CompanyID);
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchURL, {
            method: 'POST',
            body: formData,
            processData: false,
            contentType: false,
            // headers: {
            //     "Content-Type": "application/json",
            //     "Authorization": "Bearer " + myLoginToken,
            //     "loginuserid": localStorage.getItem("LogedID"),
            //     "logincompanyid": localStorage.getItem("LoginCompanyID"),
            //     "commodityid": localStorage.getItem("CommodityID")
            // },
        }).then((resp) => {
            debugger;
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ companydetailcopmanyLetterHeadPath: result });
                });
            }
        });
    };

    BindCountryList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Country";
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    var ListOfCountry = [];
                    result.map((item) => {
                        let CountryId = item.id.toString();
                        ListOfCountry.push({
                            value: CountryId,
                            label: item.name,
                            code: item.code,
                            isO3: item.isO3,
                            iso: item.iso,
                            numcode: item.numcode,
                        });
                    });

                    this.setState({ CountryList: ListOfCountry });
                });
            }
        });
    }

    BindAccountTypeList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let FetchURL = config.apiUrl + "BrokerageRate/GetCurrencyList/" + CommodityID;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    var ListOfAcounts = [];
                    result.map((item) => {
                        ListOfAcounts.push({
                            value: item.id,
                            label: item.name,
                        });
                    });

                    this.setState({ AccountTypeList: ListOfAcounts });
                });
            }
        });
    }

    // BindCompanyDetails() {
    //     let CompanyID = localStorage.getItem("EditCompanyID");
    //     let FetchCompanyURL = config.apiUrl + "Company?Id=" + CompanyID;
    //     fetch(FetchCompanyURL, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //     }).then((resp) => {
    //         if (resp.status === 200) {
    //             resp.json().then((result) => {

    //                 this.setState({ CompanyList: result })
    //             });
    //         }
    //     });
    // }

    // UpdateComapanyDetail() {
    //     let CompanyID = localStorage.getItem("EditCompanyID");
    //     let CompanyDetailID = this.state.CompanyDetailID;
    //     var AddBankURL = config.apiUrl + "Company";

    //     var val = {
    //         Id: CompanyDetailID,
    //         name: this.state.name,
    //         companyId: CompanyID,
    //         copmanyLetterHeadPath: this.state.copmanyLetterHeadPath,
    //         companyLogoPath: this.state.companyLogoPath,
    //         companyRegNo: this.state.companyRegNo,
    //         website: this.state.website,
    //         email: this.state.email,
    //         telNo: this.state.telNo,
    //         faxNo: this.state.faxNo,
    //         address: this.state.address,
    //         city: this.state.city,
    //         postcode: this.state.postcode,
    //         state: this.state.state,
    //         country: this.state.country,
    //         vatNo: this.state.vatNo,
    //         taxIdNo: this.state.taxIdNo,
    //         systemPrivileges: this.state.systemPrivileges
    //     }

    //     const data = JSON.stringify(val);

    //     fetch(AddBankURL, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: data,
    //     }).then((resp) => {
    //         if (resp.status === 200) {
    //             resp.json().then((results) => {
    //                 // this.SaveCompanyLogo();
    //                 // this.SaveCompanyLetterHeadPath();

    //                 swal({
    //                     title: "Done!",
    //                     text: "Company details have been updated",
    //                     icon: "success",
    //                     button: "Okay!",
    //                 });
    //                 this.EditCompanyList();
    //             });
    //         }
    //     });
    // }

    BindCompanyDetails() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CompanyID = localStorage.getItem("EditCompanyID");
        let FetchCompanyURL = config.apiUrl + "Company?Id=" + CompanyID;
        fetch(FetchCompanyURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ CompanyTelCountry: result.telCountry });
                    this.setState({ CompanyFaxCountry: result.faxCountry });

                    if (result.telCountry !== null && result.telCountry !== undefined && result.telCountry !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.telCountry);
                        if (countryDetails !== undefined) {
                            this.setState({ CompanyTelCountrySelected: countryDetails });
                        }
                    }

                    if (result.faxCountry !== null && result.faxCountry !== undefined && result.faxCountry !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.faxCountry);
                        if (countryDetails !== undefined) {
                            this.setState({ CompanyFaxCountrySelected: countryDetails });
                        }
                    }


                    if (result.telCode !== null && result.telCode !== undefined && result.telCode !== "") {
                        if (result.telCode.includes("+")) {
                            this.setState({ CompanyTelCode: result.telCode });
                        } else {
                            this.setState({ CompanyTelCode: "+" + result.telCode });
                        }
                    }

                    if (result.faxCode !== null && result.faxCode !== undefined && result.faxCode !== "") {
                        if (result.faxCode.includes("+")) {
                            this.setState({ CompanyFaxCode: result.faxCode });
                        } else {
                            this.setState({ CompanyFaxCode: "+" + result.faxCode });
                        }
                    }

                    this.setState({ companydetailid: result.id });
                    this.setState({ companydetailname: result.name });
                    this.setState({ companydetailcompanyId: result.companyId });
                    this.setState({ companydetailcopmanyLetterHeadPath: result.copmanyLetterHeadPath });

                    fetch(config.contentUrl + result.companyLogoPath, {
                        type: 'HEAD',
                    }).then((resp) => {
                        if (resp.status === 404) {
                            this.setState({ companydetailcompanyLogoPath: null });
                        }
                        else {
                            this.setState({ companydetailcompanyLogoPath: result.companyLogoPath });
                        }
                    });

                    this.setState({ companydetailcompanyRegNo: result.companyRegNo });
                    this.setState({ companydetailwebsite: result.website });
                    this.setState({ companydetailemail: result.email });
                    this.setState({ companydetailtelNo: result.telNo });
                    this.setState({ companydetailfaxNo: result.faxNo });
                    this.setState({ companydetailaddress: result.address });
                    this.setState({ companydetailcity: result.city });
                    this.setState({ companydetailpostcode: result.postcode });
                    this.setState({ companydetailstate: result.state });
                    if (result.country !== null && result.country !== undefined && result.country !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.country);
                        if (countryDetails !== undefined) {
                            this.setState({ countrySelected: countryDetails });
                        }
                    }
                    this.setState({ companydetailcountry: result.country });
                    this.setState({ companydetailcountryname: result.countryName });
                    this.setState({ companydetailvatNo: result.vatNo });
                    this.setState({ companydetailtaxIdNo: result.taxIdNo });
                    this.setState({ companydetailsystemPrivileges: result.systemPrivileges });
                });
            }
        });
    }

    UpdateComapanyDetail() {
        let CompanyID = localStorage.getItem("EditCompanyID");
        let companydetailid = this.state.companydetailid;
        var AddBankURL = config.apiUrl + "UpdateCompany";

        var val = {
            Id: companydetailid,
            name: this.state.companydetailname,
            companyId: this.state.companydetailcompanyId,
            copmanyLetterHeadPath: this.state.companydetailcopmanyLetterHeadPath,
            companyLogoPath: this.state.companydetailcompanyLogoPath,
            companyRegNo: this.state.companydetailcompanyRegNo,
            website: this.state.companydetailwebsite,
            email: this.state.companydetailemail,
            telNo: this.state.companydetailtelNo,
            faxNo: this.state.companydetailfaxNo,
            address: this.state.companydetailaddress,
            city: this.state.companydetailcity,
            postcode: this.state.companydetailpostcode,
            state: this.state.companydetailstate,
            country: this.state.companydetailcountry,
            vatNo: this.state.companydetailvatNo,
            taxIdNo: this.state.companydetailtaxIdNo,
            systemPrivileges: this.state.companydetailsystemPrivileges,
            telCountry: this.state.CompanyTelCountry,
            faxCountry: this.state.CompanyFaxCountry,
            telCode: this.state.CompanyTelCode,
            faxCode: this.state.CompanyFaxCode
        }

        const data = JSON.stringify(val);
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(AddBankURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((results) => {
                    swal({
                        title: "Done!",
                        text: "Company details have been updated",
                        icon: "success",
                        button: "Okay!",
                    });
                    // var btnCloseCompanyPopup = document.getElementById("btnCloseCompanyPopup");
                    // btnCloseCompanyPopup.click();
                    this.BindCompanyDetails();
                });
            }
        });

    }

    SaveCompanyDetail() {

        let CompanyID = localStorage.getItem("EditCompanyID");
        let CompanyPersonalID = this.state.CompanyPersonalID;
        var AddBankURL = config.apiUrl + "Company/" + CompanyID + "/UpdateIndividual";

        var val = {
            Id: 1,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            telephone1Country: this.state.telephone1Country,
            telephone1Code: this.state.telephone1Code,
            telephone1: this.state.telephone1,
            telephone2Country: this.state.telephone2Country,
            telephone2Code: this.state.telephone2Code,
            telephone2: this.state.telephone2,
            mobileNoCountry: this.state.mobileNoCountry,
            mobileNoCode: this.state.mobileNoCode,
            mobileNo: this.state.mobileNo,
            emailWork: this.state.emailWork,
            emailHome: this.state.emailHome,
            faxCountry: this.state.faxCountry,
            faxCode: this.state.faxCode,
            fax: this.state.fax,
            skypeId: this.state.skypeId,
            yahooId: this.state.yahooId,
            add1: this.state.add1,
            add2: "",
            add3: "",
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            postcode: this.state.postcode,
            isCompanyPersonnel: true,
            responseEmail: this.state.responseEmail,
        }

        const data = JSON.stringify(val);
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(AddBankURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((results) => {
                    if (results == true) {
                        swal({
                            title: "Done!",
                            text: "Company Personnel details have been updated",
                            icon: "success",
                            button: "Okay!",
                        });
                    } else {
                        swal({
                            title: "Sorry!",
                            text: "Company Personnel details have not been updated",
                            icon: "error",
                            button: "okay",
                        });
                    }
                    this.BindCompanyDetails();
                });
            }
        });
    }

    BindCompanyPersonal() {
        let CompanyID = localStorage.getItem("EditCompanyID");
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/companyPersonnel";
        fetch(FetchBankURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ CompanyPersonal: result })
                });
            }
        });
    }

    EditCompanyPersonal(id) {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CompanyID = localStorage.getItem("EditCompanyID");
        let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/Individual/" + id;
        fetch(FetchBankURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ CompanyPersonalID: result.id });
                    this.setState({ firstName: result.firstName });
                    this.setState({ lastName: result.lastName });
                    this.setState({ email: result.email });
                    this.setState({ telephone1Country: result.telephone1Country });
                    if (result.telephone1Code !== null && result.telephone1Code !== undefined && result.telephone1Code !== "") {
                        if (result.telephone1Code.includes("+")) {
                            this.setState({ telephone1Code: result.telephone1Code });
                        } else {
                            this.setState({ telephone1Code: "+" + result.telephone1Code });
                        }
                    }
                    this.setState({ telephone1: result.telephone1 });
                    this.setState({ telephone2Country: result.telephone2Country });
                    if (result.telephone2Code !== null && result.telephone2Code !== undefined && result.telephone2Code !== "") {
                        if (result.telephone2Code.includes("+")) {
                            this.setState({ telephone2Code: result.telephone2Code });
                        } else {
                            this.setState({ telephone2Code: "+" + result.telephone2Code });
                        }
                    }
                    this.setState({ telephone2: result.telephone2 });
                    this.setState({ mobileNoCountry: result.mobileNoCountry });
                    if (result.mobileNoCode !== null && result.mobileNoCode !== undefined && result.mobileNoCode !== "") {
                        if (result.mobileNoCode.includes("+")) {
                            this.setState({ mobileNoCode: result.mobileNoCode });
                        } else {
                            this.setState({ mobileNoCode: "+" + result.mobileNoCode });
                        }
                    }
                    this.setState({ mobileNo: result.mobileNo });
                    this.setState({ emailWork: result.emailWork });
                    this.setState({ emailHome: result.emailHome });
                    this.setState({ faxCountry: result.faxCountry });
                    if (result.faxCode !== null && result.faxCode !== undefined && result.faxCode !== "") {
                        if (result.faxCode.includes("+")) {
                            this.setState({ faxCode: result.faxCode });
                        } else {
                            this.setState({ faxCode: "+" + result.faxCode });
                        }
                    }
                    this.setState({ fax: result.fax });
                    this.setState({ skypeId: result.skypeId });
                    this.setState({ yahooId: result.yahooId });
                    this.setState({ add1: result.add1 });
                    this.setState({ add2: result.add2 });
                    this.setState({ add3: result.add3 });
                    this.setState({ city: result.city });
                    this.setState({ state: result.state });
                    this.setState({ country: result.country });
                    this.setState({ postcode: result.postcode });
                    this.setState({ isCompanyPersonnel: result.isCompanyPersonnel });
                    this.setState({ responseEmail: result.responseEmail });
                    this.setState({ countryName: result.country });
                });
            }
        });
    }

    DeleteCompanyPersonal(ID) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover Company Personal!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var myLoginToken = localStorage.getItem("AccessToken");
                    let CompanyID = localStorage.getItem("EditCompanyID");
                    let FetchBankURL = config.apiUrl + "DeleteIndividual?id=" + ID;
                    fetch(FetchBankURL, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": localStorage.getItem("LogedID"),
                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                            "commodityid": localStorage.getItem("CommodityID")
                        },
                    }).then((resp) => {
                        swal("Company Personal detail have been deleted!", {
                            icon: "success",
                        });
                        this.BindCompanyPersonal();
                    });
                    this.BindCompanyPersonal();
                } else {
                    swal("Company Personal detail have been safe!", {
                        icon: "error",
                    });
                }
            });
    }

    onChangeCPLastName = (event) => {
        this.setState({ lastName: event.target.value });
    }

    onChangeCPFirstName = (event) => {
        this.setState({ firstName: event.target.value });
    }

    onChangeCPEmail = (event) => {
        this.setState({ email: event.target.value });
    }

    onChangeCPCity = (event) => {
        this.setState({ city: event.target.value });
    }

    onChangeCPState = (event) => {
        this.setState({ state: event.target.value });
    }

    onChangeCPPostcode = (event) => {
        this.setState({ postcode: event.target.value });
    }

    SaveCompanyPersonal() {
        if (this.state.firstName === "" || this.state.firstName === null) {
            this.setState({ CPFNameError: "Required field" });
        } else {
            this.setState({ CPFNameError: "" });
        }

        if (this.state.lastName === "" || this.state.lastName === null) {
            this.setState({ CPLNameError: "Required field" });
        } else {
            this.setState({ CPLNameError: "" });
        }

        if (this.state.email === "" || this.state.email === null) {
            this.setState({ CPEmailError: "Required field" });
        } else {
            this.setState({ CPEmailError: "" });
        }

        if (this.state.city === "" || this.state.city === null) {
            this.setState({ CPCityError: "Required field" });
        } else {
            this.setState({ CPCityError: "" });
        }

        if (this.state.state === "" || this.state.state === null) {
            this.setState({ CPStateError: "Required field" });
        } else {
            this.setState({ CPStateError: "" });
        }

        if (this.state.postcode === "" || this.state.postcode === null) {
            this.setState({ CPPostcodeError: "Required field" });
        } else {
            this.setState({ CPPostcodeError: "" });
        }

        if (this.state.firstName !== "" && this.state.firstName !== null
            && this.state.lastName !== "" && this.state.lastName !== null
            && this.state.email !== "" && this.state.email !== null
            && this.state.city !== "" && this.state.city !== null
            && this.state.state !== "" && this.state.state !== null
            && this.state.postcode !== "" && this.state.postcode !== null) {

            let CompanyID = localStorage.getItem("EditCompanyID");
            let CompanyPersonalID = this.state.CompanyPersonalID;
            var AddBankURL = config.apiUrl + "Company/" + CompanyID + "/Individual";
            var Method = "POST";
            var id = 0;

            if (CompanyPersonalID != null && CompanyPersonalID != 0 && CompanyPersonalID != undefined) {
                id = CompanyPersonalID;
                AddBankURL = config.apiUrl + "Company/" + CompanyID + "/UpdateIndividual";
                Method = "POST";
            }

            var val = {
                Id: id,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                telephone1Country: this.state.telephone1Country,
                telephone1Code: this.state.telephone1Code,
                telephone1: this.state.telephone1,
                telephone2Country: this.state.telephone2Country,
                telephone2Code: this.state.telephone2Code,
                telephone2: this.state.telephone2,
                mobileNoCountry: this.state.mobileNoCountry,
                mobileNoCode: this.state.mobileNoCode,
                mobileNo: this.state.mobileNo,
                emailWork: this.state.emailWork,
                emailHome: this.state.emailHome,
                faxCountry: this.state.faxCountry,
                faxCode: this.state.faxCode,
                fax: this.state.fax,
                skypeId: this.state.skypeId,
                yahooId: this.state.yahooId,
                add1: this.state.add1,
                add2: "",
                add3: "",
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                postcode: this.state.postcode,
                isCompanyPersonnel: true,
                responseEmail: this.state.responseEmail,
            }

            const data = JSON.stringify(val);
            var myLoginToken = localStorage.getItem("AccessToken");

            fetch(AddBankURL, {
                method: Method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((results) => {
                        swal({
                            title: "Done!",
                            text: "Company Personal details have been updated",
                            icon: "success",
                            button: "Okay!",
                        });
                        var btnCloseCompanyPersonnel = document.getElementById("btnCloseCompanyPersonnel");
                        btnCloseCompanyPersonnel.click();
                        this.BindCompanyPersonal();
                    });
                }
            });
        }
    }

    ResetCompanyPersonal() {
        this.setState({ CompanyPersonalID: 0 });
        this.setState({ companyId: 0 });
        this.setState({ firstName: "" });
        this.setState({ address: "" });
        this.setState({ lastName: "" });
        this.setState({ email: "" });
        this.setState({ telephone1Country: "" });
        this.setState({ telephone1Code: "" });
        this.setState({ telephone1: "" });
        this.setState({ telephone2Country: "" });
        this.setState({ telephone2Code: "" });
        this.setState({ telephone2: "" });
        this.setState({ mobileNoCountry: "" });
        this.setState({ mobileNoCode: "" });
        this.setState({ mobileNo: "" });
        this.setState({ emailWork: "" });
        this.setState({ emailHome: "" });
        this.setState({ faxCountry: "" });
        this.setState({ faxCode: "" });
        this.setState({ fax: "" });
        this.setState({ skypeId: "" });
        this.setState({ yahooId: "" });
        this.setState({ add1: "" });
        this.setState({ city: "" });
        this.setState({ country: "" });
        this.setState({ postcode: "" });
        this.setState({ responseEmail: "" });

        // document.getElementById("CompanyPersonalID").value = 0;
        // document.getElementById("firstName").value = null;
        // document.getElementById("lastName").value = null;
        // document.getElementById("email").value = null;
        // document.getElementById("telephone1Code").value = null;
        // document.getElementById("telephone1").value = null;
        // document.getElementById("telephone2Country").value = null;
        // document.getElementById("telephone2Code").value = null;
        // document.getElementById("telephone2").value = null;
        // document.getElementById("mobileNoCountry").value = null;
        // document.getElementById("mobileNoCode").value = null;
        // document.getElementById("mobileNo").value = null;
        // document.getElementById("emailWork").value = null;
        // document.getElementById("emailHome").value = null;
        // document.getElementById("faxCountry").value = null;
        // document.getElementById("faxCode").value = null;
        // document.getElementById("fax").value = null;
        // document.getElementById("skypeId").value = null;
        // document.getElementById("yahooId").value = null;
        // document.getElementById("add1").value = null;
        // document.getElementById("city").value = null;
        // document.getElementById("state").value = null;
        // document.getElementById("country").value = null;
        // document.getElementById("postcode").value = null;
        // document.getElementById("responseEmail").value = null;
    }

    BindBanks() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CompanyID = localStorage.getItem("EditCompanyID");
        let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/Bank";
        fetch(FetchBankURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ Banks: result })
                });
            }
        });
        this.ResetBank();
    }

    onchangeAccountNo = (event) => {
        this.setState({ AccountNoError: "" });
        this.setState({ accountNumber: event.target.value });
    }

    onchangeBIC = (event) => {
        this.setState({ bic: event.target.value });
    }

    onchangeIBAN = (event) => {
        this.setState({ iban: event.target.value });
    }

    SaveBank() {

        let CompanyID = localStorage.getItem("EditCompanyID");
        var AddBankURL = config.apiUrl + "Company/" + CompanyID + "/bank";
        let BankID = this.state.BankID;
        var Method = "POST";
        let id = 0;

        if (BankID > 0) {
            id = BankID;
            AddBankURL = config.apiUrl + "Company/" + CompanyID + "/UpdateBank";
            Method = "POST";
        }

        var val = {
            Id: id,
            CompanyId: parseInt(CompanyID),
            bankName: this.state.bankName,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            postcode: this.state.postcode,
            country: this.state.country,
            accountType: this.state.accountType,
            accountNumber: this.state.accountNumber,
            bic: this.state.bic,
            iban: this.state.iban,
        }

        var myLoginToken = localStorage.getItem("AccessToken");
        const data = JSON.stringify(val);

        fetch(AddBankURL, {
            method: Method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((results) => {
                    if (results == true) {
                        swal({
                            title: "Good job!",
                            text: "Bank have been added",
                            icon: "success",
                            button: "Okay!",
                        });
                        this.BindBanks();
                        var btnCloseBank = document.getElementById("btnCloseBank");
                        btnCloseBank.click();
                    } else {
                        swal({
                            title: "Sorry!",
                            text: "Bank have not been added",
                            icon: "error",
                            button: "okay",
                        });
                    }
                });
            }
        });

    }

    EditBank(id) {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CompanyID = localStorage.getItem("EditCompanyID");
        let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/Bank/" + id;
        fetch(FetchBankURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ BankID: result.id });
                    this.setState({ companyId: result.companyId });
                    this.setState({ bankName: result.bankName });
                    this.setState({ address: result.address });
                    this.setState({ city: result.city });
                    this.setState({ state: result.state });
                    if (result.country !== null && result.country !== undefined && result.country !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.label === result.country);
                        if (countryDetails !== undefined) {
                            this.setState({ BankCountry: countryDetails });
                        }
                    }
                    this.setState({ country: result.country });
                    this.setState({ postcode: result.postcode });
                    if (result.accountType !== null && result.accountType !== undefined && result.accountType !== "") {
                        let AccountDetails = this.state.AccountTypeList.find(country => country.label === result.accountType);
                        if (AccountDetails !== undefined) {
                            this.setState({ accountTypeSelected: AccountDetails });
                        }
                    }
                    this.setState({ accountType: result.accountType });
                    this.setState({ accountNumber: result.accountNumber });
                    this.setState({ bic: result.bic });
                    this.setState({ iban: result.iban });
                });
            }
        });
    }

    DeleteBank(ID) {

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover bank deatil!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var myLoginToken = localStorage.getItem("AccessToken");
                    let CompanyID = localStorage.getItem("EditCompanyID");
                    let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/DeleteBank/" + ID;
                    fetch(FetchBankURL, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": localStorage.getItem("LogedID"),
                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                            "commodityid": localStorage.getItem("CommodityID")
                        },
                    }).then((resp) => {
                        swal("Bank Account have been deleted!", {
                            icon: "success",
                        });
                        this.BindBanks();
                    });
                    this.ResetBank();
                } else {
                    swal("Bank Account have been safe!", {
                        icon: "error",
                    });
                }
            });
    }

    ResetBank() {
        this.setState({ BankID: 0 });
        this.setState({ companyId: 0 });
        this.setState({ bankName: null });
        this.setState({ address: null });
        this.setState({ city: null });
        this.setState({ state: null });
        this.setState({ country: "" });
        this.setState({ postcode: null });
        this.setState({ accountType: "" });
        this.setState({ accountNumber: "" });
        this.setState({ bic: "" });
        this.setState({ iban: "" });
        this.setState({ AccountNoError: "" });
        this.setState({ BICError: "" });
        this.setState({ IBANError: "" });
        document.getElementById("BankName").value = null;
        document.getElementById("BankAddress").value = null;
        document.getElementById("BankCity").value = null;
        document.getElementById("BankState").value = null;
        document.getElementById("BankPostcode").value = null;
        document.getElementById("BankCountry").value = "";
        document.getElementById("BankAccountType").value = "";
        document.getElementById("BankAccountNo").value = null;
        document.getElementById("BankBIC").value = null;
        document.getElementById("BankIBAN").value = null;
        document.getElementById("BankID").value = 0;
    }

    // EditCompanyList() {
    //     let CompanyID = localStorage.getItem("EditCompanyID");
    //     let FetchCompanyURL = config.apiUrl + "Company?Id=" + CompanyID;
    //     fetch(FetchCompanyURL, {
    //         method: 'GET',
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //     }).then((resp) => {
    //         if (resp.status === 200) {
    //             resp.json().then((result) => {
    //                 this.setState({ CompanyDetailID: result.id });
    //                 this.setState({ name: result.name });
    //                 this.setState({ companyId: result.companyId });
    //                 this.setState({ copmanyLetterHeadPath: result.copmanyLetterHeadPath });
    //                 this.setState({ companyLogoPath: result.companyLogoPath });
    //                 this.setState({ companyRegNo: result.companyRegNo });
    //                 this.setState({ website: result.website });
    //                 this.setState({ email: result.email });
    //                 this.setState({ telNo: result.telNo });
    //                 this.setState({ faxNo: result.faxNo });
    //                 this.setState({ address: result.address });
    //                 this.setState({ city: result.city });
    //                 this.setState({ postcode: result.postcode });
    //                 this.setState({ state: result.state });
    //                 this.setState({ companystate: result.state });
    //                 this.setState({ country: result.country });
    //                 this.setState({ countryname: result.countryName });
    //                 this.setState({ vatNo: result.vatNo });
    //                 this.setState({ taxIdNo: result.taxIdNo });
    //                 this.setState({ systemPrivileges: result.systemPrivileges });
    //             });
    //         }
    //     });
    // }

    AddCompany() {

        // var val = {
        //     Id: id,
        //     CompanyId: parseInt(CompanyID),
        //     bankName: this.state.bankName,
        //     address: this.state.address,
        //     city: this.state.city,
        //     state: this.state.state,
        //     postcode: this.state.postcode,
        //     country: this.state.country,
        //     accountType: this.state.accountType,
        //     accountNumber: this.state.accountNumber,
        //     bic: this.state.bic,
        //     iban: this.state.iban,
        // }

        var val = {
            Id: document.getElementById("CompanyListID").value,
            Name: document.getElementById("CCompany").value,
            Postcode: document.getElementById("CPostcode").value,
            Website: document.getElementById("CWebSite").value,
            Country: document.getElementById("CCountry").value,
            Email: document.getElementById("CEmail").value,
            CompanyRegNo: document.getElementById("CCompanyRegNo").value,
            TelNo: document.getElementById("CTelNo").value,
            VatNo: document.getElementById("CVatNo").value,
            FaxNo: document.getElementById("CFaxNo").value,
            TaxIdNo: document.getElementById("CTaxNo").value,
            Address: document.getElementById("CAddress").value,
            SystemPrivileges: document.getElementById("CSystemPrivileges").value,
            City: document.getElementById("CCity").value,
            CompanyLogoPath: "Null",
            State: document.getElementById("CState").value,
            CopmanyLetterHeadPath: "Null",
            companyLogoPath: "Null",
            CompanyTypeId: 1,
            CommodityId: 1
        }

        const data = JSON.stringify(val);
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(config.apiUrl + "Company/Company", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((results) => {
                    swal({
                        title: "Good job!",
                        text: "Company have been added",
                        icon: "success",
                        button: "Okay!",
                    });
                    this.BindCompanyDetails();
                });
            }
        });
    }

    ResetCompanyList() {
        // this.setState({ CompanyListID: 0 });
        // this.setState({ name: null });
        // this.setState({ copmanyLetterHeadPath: null });
        // this.setState({ companyRegNo: null });
        // this.setState({ website: null });
        // this.setState({ email: null });
        // this.setState({ telNo: null });
        // this.setState({ faxNo: null });
        // this.setState({ address: null });
        // this.setState({ city: null });
        // this.setState({ postcode: null });
        // this.setState({ state: null });
        // this.setState({ country: null });
        // this.setState({ vatNo: null });
        // this.setState({ taxIdNo: null });
        // this.setState({ systemPrivileges: null });
        // document.getElementById("CompanyListID").value = 0;
        // document.getElementById("name").value = null;
        // document.getElementById("copmanyLetterHeadPath").value = null;
        // document.getElementById("companyRegNo").value = null;
        // document.getElementById("website").value = null;
        // document.getElementById("email").value = null;
        // document.getElementById("telNo").value = null;
        // document.getElementById("faxNo").value = null;
        // document.getElementById("address").value = null;
        // document.getElementById("city").value = null;
        // document.getElementById("postcode").value = null;
        // document.getElementById("state").value = null;
        // document.getElementById("country").value = null;
        // document.getElementById("vatNo").value = null;
        // document.getElementById("taxIdNo").value = null;
        // document.getElementById("systemPrivileges").value = null;
    }

    BindDistributionList() {
        this.ResetDistDetail();
        let CompanyID = parseInt(localStorage.getItem("EditCompanyID"));
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/alldistributionlist/" + CommodityID;
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchBankURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ DistributionList: result })
                });
            }
        });
    }

    DeleteDistributionList(ID) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover distribution list deatil!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let CompanyID = parseInt(localStorage.getItem("EditCompanyID"));
                    let CommodityID = parseInt(localStorage.getItem("CommodityID"));
                    // let DeleteURL = config.apiUrl + "Company/DeleteDistributionList/" + ID;
                    let DeleteURL = config.apiUrl + "Company/DeleteDistributionList?distributionTypeid=" + ID + "&companyId=" + CompanyID + "&commodityId=" + CommodityID;
                    var myLoginToken = localStorage.getItem("AccessToken");

                    fetch(DeleteURL, {
                        method: 'DELETE',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": localStorage.getItem("LogedID"),
                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                            "commodityid": localStorage.getItem("CommodityID")
                        },
                    }).then((resp) => {
                        if (resp.status === 200) {
                            resp.json().then((result) => {
                                if (result == true) {
                                    swal("distribution list have been deleted!", {
                                        icon: "success",
                                    });
                                } else {
                                    swal("distribution list is safe!", {
                                        icon: "success",
                                    });
                                }
                            });
                        }

                        this.BindDistributionList();
                    });
                } else {
                    swal("distribution list have been safe!", {
                        icon: "error",
                    });
                }
            });
    }

    EditDistributionList() {
        let CompanyID = localStorage.getItem("EditCompanyID");
        var FetchCompanyPersonalURL = config.apiUrl + "Company/" + CompanyID + "/individualcompanyPersonnellist";
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchCompanyPersonalURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ DistCompanyPersonalList: result });
                });
            }
        });
    }

    SaveDistDetail() {
        // let CompanyID = localStorage.getItem("EditCompanyID");
        // let CommodityId = localStorage.getItem("CommodityID");

        let CompanyID = parseInt(localStorage.getItem("EditCompanyID"));
        let CommodityId = parseInt(localStorage.getItem("CommodityID"));
        let DistType = this.state.DistType;
        var POSTURL = "";

        if (DistType == "Trade Confirm") {
            POSTURL = config.apiUrl + "Company/" + CompanyID + "/distributionlist/" + CommodityId + "/Trade";
        } else if (DistType == "Invoice") {
            POSTURL = config.apiUrl + "Company/" + CompanyID + "/distributionlist/" + CommodityId + "/Invoice";
        } else if (DistType == "Market Report") {
            POSTURL = config.apiUrl + "Company/" + CompanyID + "/distributionlist/" + CommodityId + "/MarketReport";
        } else {
            POSTURL = config.apiUrl + "Company/" + CompanyID + "/distributionlist/" + CommodityId + "/Trade";
        }

        if (this.state.DistSelectedCompanyPersonalList.length > 0) {
            var val = {
                CompanyId: CompanyID,
                CommodityId: CommodityId,
                IndividualId: this.state.DistSelectedCompanyPersonalList,
                DistributionTypeId: 0
            }

            const data = JSON.stringify(val);
            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(POSTURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {

                if (resp.status === 200) {
                    resp.json().then((results) => {
                        if (results == true) {
                            swal({
                                title: "Good job!",
                                text: "Distribution list have been added",
                                icon: "success",
                                button: "Okay!",
                            });
                        } else {
                            swal({
                                title: "Good job!",
                                text: "Distribution list is not added.",
                                icon: "error",
                                button: "Okay!",
                            });
                        }
                        this.BindDistributionList();
                    });
                }
            });
        } else {
            swal({
                title: "Sorry!",
                text: "Selected email address.",
                icon: "error",
                button: "Ok!",
            });
        }
    }

    addSelectedDistCompanyPersonalList(ID) {
        var index = this.state.DistSelectedCompanyPersonalList.indexOf(ID);
        if (index > -1) {
            this.state.DistSelectedCompanyPersonalList.splice(index, 1);
        } else {
            this.state.DistSelectedCompanyPersonalList.push(ID);
        }
    }

    ResetDistDetail() {
        this.setState({ DistCompanyPersonalList: [] });
        this.setState({ DistSelectedCompanyPersonalList: [] });
        this.setState({ DistType: "Trade Confirm" });
    }

    BindBrokerageCurrency() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let FetchURL = config.apiUrl + "BrokerageRate/GetCurrencyList/" + CommodityID;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ BrokerageCurrencyList: result })
                });
            }
        });
    }

    onChangeiCurrency(id, countryid, myVal) {

        var data = this.state.BrokerageRateList;
        var commentIndex = 0;

        commentIndex = data.findIndex(function (c) {
            if (id == 0) {
                return c.countryId == countryid;
            } else {
                return c.id == id;
            }
        });

        var updatedComment = update(data[commentIndex], { iCurrencyID: { $set: myVal } });

        var newData = update(data, {
            $splice: [[commentIndex, 1, updatedComment]]
        });
        this.setState({ BrokerageRateList: newData });
    }

    onChangeaCurrency(id, countryid, myVal) {

        var data = this.state.BrokerageRateList;
        var commentIndex = 0;

        commentIndex = data.findIndex(function (c) {
            if (id == 0) {
                return c.countryId == countryid;
            } else {
                return c.id == id;
            }
        });

        var updatedComment = update(data[commentIndex], { aCurrencyID: { $set: myVal } });

        var newData = update(data, {
            $splice: [[commentIndex, 1, updatedComment]]
        });
        this.setState({ BrokerageRateList: newData });
    }

    onChangeiRate(id, countryid, myVal) {
        //Ajay
        var data = this.state.BrokerageRateList;
        data.map((item) => {
            if (id === item.id) {
                item.iRate = myVal;
            }
        });

        this.setState({ BrokerageRateList: data });
    }

    onChangeaRate(id, countryid, myVal) {

        var data = this.state.BrokerageRateList;
        data.map((item) => {
            if (id === item.id) {
                item.aRate = myVal;
            }
        });

        this.setState({ BrokerageRateList: data });
    }

    BindBrokerageCountry() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let FetchURL = config.apiUrl + "BrokerageRate/GetBrokrageCountryList/" + CommodityID;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ BrokerageCountryList: result })
                });
            }
        });
    }

    BindBrokerageRate() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CompanyID = parseInt(localStorage.getItem("EditCompanyID"));
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let FetchURL = config.apiUrl + "BrokerageRate/GetClientBrokerageRateByCompanyId/" + CompanyID + "/" + CommodityID;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    let allList = result;
                    allList.map((item) => {
                        if (item.iRate !== null && item.iRate !== undefined && item.iRate !== "") {
                            item.iRate = Number(item.iRate).toFixed(4);
                        }
                        if (item.aRate !== null && item.aRate !== undefined && item.aRate !== "") {
                            item.aRate = Number(item.aRate).toFixed(4);
                        }
                    });

                    this.setState({ BrokerageRateList: allList })
                });
            }
        });
    }

    SaveBrokerageRate() {
        var POSTURL = config.apiUrl + "BrokerageRate/CreateClientBrokerageRateList";
        let CompanyID = parseInt(localStorage.getItem("EditCompanyID"));
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        var Method = "POST";
        let tempArray = [];

        this.state.BrokerageRateList.forEach(function (item) {
            let iRate = item.iRate;
            let aRate = item.aRate;

            // if (item.iRate == "" || item.iRate == null || item.iRate == undefined) {
            //     iRate = 0;
            // }

            // if (item.aRate == "" || item.aRate == null || item.aRate == undefined) {
            //     aRate = 0;
            // }

            let tempObject = {
                Id: item.id,
                CompanyID: CompanyID,
                CommodityID: CommodityID,
                CountryId: item.countryId,
                IRate: parseFloat(iRate),
                ARate: parseFloat(aRate),
                ICurrencyID: parseInt(item.iCurrencyID),
                ICurrencyName: item.iCurrencyName,
                ICurrencySymbol: "",
                ACurrencyID: parseInt(item.aCurrencyID),
                ACurrencyName: item.aCurrencyName,
                ACurrencySymbol: "",
                CountryName: item.countryName
            };
            tempArray.push(tempObject);
        });

        const data = JSON.stringify(tempArray);
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(POSTURL, {
            method: Method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
            async: false,
            traditional: true,
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((results) => {
                    swal({
                        title: "Done!",
                        text: "Brokerage Rate(s) Updated",
                        icon: "success",
                        button: "Okay!",
                    });
                    this.BindBrokerageRate();
                });
            }
        });
    }

    ResetBrokerageRate() {
        this.BindBrokerageRate();
    }

    BindMultipleAddressList() {
        let CompanyID = localStorage.getItem("EditCompanyID");
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Company/" + CompanyID + "/Address";
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ MultipleAddressList: result })
                });
            }
        });
        this.ResetMultipleAddress();
    }

    SaveMultipleAddress() {

        let CompanyID = localStorage.getItem("EditCompanyID");
        var AddURL = config.apiUrl + "Company/" + CompanyID + "/Address";
        let AddID = this.state.addressId;
        var Method = "POST";
        let id = 0;

        if (AddID > 0) {
            id = AddID;
            AddURL = config.apiUrl + "Company/" + CompanyID + "/UpdateAddress";
            Method = "POST";
        }

        let addisInvoice = false;
        if (this.state.addisInvoice) {
            addisInvoice = true;
        }

        var val = {
            id: parseInt(id),
            address1: this.state.address1,
            address2: this.state.address2,
            address3: this.state.address3,
            brokerCompanyId: this.state.brokerCompanyId,
            city: this.state.addcity,
            companyId: parseInt(CompanyID),
            country: this.state.addcountry,
            isInvoice: addisInvoice,
            istradeconfirm: false,
            name: this.state.addname,
            postCode: this.state.addpostCode,
            state: this.state.addstate,
            tax: this.state.addtax,
            vat: this.state.addvat,
        }

        const data = JSON.stringify(val);
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(AddURL, {
            method: Method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((results) => {
                    if (results == true) {
                        swal({
                            title: "Good job!",
                            text: "Address been added",
                            icon: "success",
                            button: "Okay!",
                        });
                        this.BindMultipleAddressList();
                        var btnCloseAddress = document.getElementById("btnCloseAddress");
                        btnCloseAddress.click();
                    } else {
                        swal({
                            title: "Sorry!",
                            text: "Address not been added",
                            icon: "error",
                            button: "okay",
                        });
                    }
                });
            }
        });

    }

    ResetMultipleAddress() {
        this.setState({ addressId: 0 });
        this.setState({ address1: "" });
        this.setState({ address2: "" });
        this.setState({ address3: "" });
        this.setState({ brokerCompanyId: 0 });
        this.setState({ addcity: "" });
        this.setState({ addcompanyId: 0 });
        this.setState({ addcountry: "" });
        this.setState({ addisInvoice: false });
        this.setState({ addistradeconfirm: false });
        this.setState({ addname: "" });
        this.setState({ addpostCode: "" });
        this.setState({ addstate: "" });
        this.setState({ addtax: "" });
        this.setState({ addvat: "" });
        this.setState({ NameError: "" });
        this.setState({ CityError: "" });
        this.setState({ Address1Error: "" });
        this.setState({ StateError: "" });
        this.setState({ PincodeError: "" });

    }

    EditMultipleAddress(id) {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CompanyID = localStorage.getItem("EditCompanyID");
        let FetchURL = config.apiUrl + "Company/" + CompanyID + "/Address/" + id;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ addressId: result.id });
                    this.setState({ address1: result.address1 });
                    this.setState({ address2: result.address2 });
                    this.setState({ address3: result.address3 });
                    this.setState({ brokerCompanyId: result.brokerCompanyId });
                    this.setState({ addcity: result.city });
                    this.setState({ addcompanyId: result.companyId });
                    if (result.country !== null && result.country !== undefined && result.country !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.country);
                        if (countryDetails !== undefined) {
                            this.setState({ countryAddressSelected: countryDetails });
                        }
                    }
                    this.setState({ addcountry: result.country });
                    this.setState({ addisInvoice: result.isInvoice });
                    this.setState({ addistradeconfirm: result.istradeconfirm });
                    this.setState({ addname: result.name });
                    this.setState({ addpostCode: result.postCode });
                    this.setState({ addstate: result.state });
                    this.setState({ addtax: result.tax });
                    this.setState({ addvat: result.vat });
                });
            }
        });
    }

    DeleteMultipleAddress(ID) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover multiple address deatil!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var myLoginToken = localStorage.getItem("AccessToken");
                    let CompanyID = localStorage.getItem("EditCompanyID");
                    let FetchURL = config.apiUrl + "DeleteCompanyAddress?id=" + ID;
                    fetch(FetchURL, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": localStorage.getItem("LogedID"),
                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                            "commodityid": localStorage.getItem("CommodityID")
                        },
                    }).then((resp) => {
                        swal("multiple address detail have been deleted!", {
                            icon: "success",
                        });
                        this.BindMultipleAddressList();
                    });
                    this.ResetMultipleAddress();
                } else {
                    swal("multiple address detail have been safe!", {
                        icon: "error",
                    });
                }
            });
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            let params = queryString.parse(this.props.location.search);
            if (params != null) {
                var id = params.id;
                if (id != null && id != "" && id != undefined) {
                    localStorage.setItem('EditCompanyID', id);
                }
            }

            this.BindCountryList();
            this.BindAccountTypeList();
            // this.EditCompanyList();
            this.BindCompanyDetails();
            this.BindBanks();
            this.BindCompanyPersonal();
            this.BindDistributionList();
            this.BindBrokerageCountry();
            this.BindBrokerageCurrency();
            this.BindBrokerageRate();
            this.BindMultipleAddressList();
        } else {
            this.props.history.push('/login');
        }
    }

    onchangeName = (event) => {
        this.setState({ addname: event.target.value });
    }

    onchangeAddress1 = (event) => {
        this.setState({ address1: event.target.value });
    }

    onchangeCity = (event) => {
        this.setState({ addcity: event.target.value });
    }

    onchangeState = (event) => {
        this.setState({ addstate: event.target.value });
    }

    onchangePostcode = (event) => {
        this.setState({ addpostCode: event.target.value });
    }

    onChangeTelNoCompany = (event) => {

        this.setState(
            { companydetailtelNo: event },
            function () {
            }
        );

    }

    onChangeTelNoCompanyPersonnel = (event) => {

        this.setState(
            { telephone1: event },
            function () {
            }
        );

    }

    onChangeMobileCompanyPersonnel = (event) => {

        this.setState(
            { mobileNo: event },
            function () {
            }
        );

    }

    SetCountry = (event) => {
        this.setState({ countrySelected: event });
        this.setState({ companydetailcountry: event.value });
    }

    SetAddressCountry = (event) => {
        this.setState({ countryAddressSelected: event });
        this.setState({ addcountry: event.value });
    }

    SetBankCountry = (event) => {
        this.setState({ BankCountry: event });
        this.setState({ country: event.label });
    }

    SetAccountType = (event) => {
        this.setState({ accountTypeSelected: event });
        this.setState({ accountType: event.label });
    }

    SetTelephone1Country = (event) => {
        this.setState({ usertelephone1Selected: event });
        this.setState({ usertelephone1Country: event.value });
        this.setState({ usertelephone1Code: "+" + event.code });
    }

    SetTelephone2Country = (event) => {
        this.setState({ usertelephone2Selected: event });
        this.setState({ usertelephone2Country: event.value });
        this.setState({ usertelephone2Code: "+" + event.code });
    }

    SetMobileNoCountry = (event) => {
        this.setState({ usermobileNoSelected: event });
        this.setState({ usermobileNoCountry: event.value });
        this.setState({ usermobileNoCode: "+" + event.code });
    }

    SetFaxCountry = (event) => {
        this.setState({ userfaxCountrySelected: event });
        this.setState({ userfaxCountry: event.value });
        this.setState({ userfaxCode: "+" + event.code });
    }

    SetAddressCountyCountry = (event) => {
        this.setState({ countrySelected: event });
        this.setState({ country: event.value });
    }

    SetCompanyTelephone1Country = (event) => {
        this.setState({ CompanyTelCountrySelected: event });
        this.setState({ CompanyTelCountry: event.value });
        this.setState({ CompanyTelCode: "+" + event.code });
    }

    SetCompanyFaxCountry = (event) => {
        this.setState({ CompanyFaxCountrySelected: event });
        this.setState({ CompanyFaxCountry: event.value });
        this.setState({ CompanyFaxCode: "+" + event.code });
    }

    render() {

        const MultiAddressListColumns = [
            {
                name: 'Name',
                selector: 'name',
                sortable: true,
                right: false,
                width: "17%"
            },
            {
                name: 'Full Address',
                selector: 'address1',
                sortable: true,
                right: false,
                width: "16%"
            },
            {
                name: 'VAT No',
                selector: 'vat',
                sortable: true,
                right: false,
                width: "9%"
            },
            {
                name: 'Tax ID No',
                selector: 'tax',
                sortable: true,
                right: false,
                width: "11%"
            },
            {
                name: 'Trade Confirmation',
                selector: 'istradeconfirm',
                sortable: true,
                right: false,
                width: "17%",
                cell: item => <a href className='text-danger text-center w-100'><b><i className='ti-close'></i></b></a>
            },
            {
                name: 'Invoice',
                selector: 'isInvoice',
                sortable: true,
                right: false,
                width: "10%",
                cell: item => item.isInvoice == true ? <a href className='text-success text-center w-100'><b><i className='ti-check'></i></b></a> : <a href className='text-danger text-center w-100'><b><i className='ti-close'></i></b></a>
            },
            {
                name: '',
                selector: '',
                sortable: true,
                right: false,
                width: "20%",
                cell: item => <div> <a type="button" className="btn btn-primary  btn-sm" data-toggle="modal" data-target=".address" onClick={() => this.EditMultipleAddress(item.id)} ><i className="ti-reload mr-1" /> Update</a> <a type="button" className="btn btn-danger  btn-sm sweet-multiple" onClick={() => this.DeleteMultipleAddress(item.id)}><i className="ti-trash mr-1" /> Delete</a></div>
            },
        ];

        const DistributionListColumns = [
            {
                name: 'Distribution Purpose',
                selector: 'distributionTypeName',
                sortable: true,
                right: false,
                width: "15%"
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: true,
                right: false,
                width: "65%"
            },
            {
                name: '',
                selector: '',
                sortable: true,
                right: false,
                width: "20%",
                cell: item => <div> <a type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target=".List"><i className="ti-reload mr-1" onClick={() => this.EditDistributionList()} /> Update</a> <a type="button" className="btn btn-danger  btn-sm sweet-multiple" onClick={() => this.DeleteDistributionList(item.distributionTypeId)}><i className="ti-trash mr-1" /> Delete</a></div>
            },
        ];

        const BankListColumns = [
            {
                name: 'Bank Name',
                selector: 'bankName',
                sortable: true,
                right: false,
                width: "25%"
            },
            {
                name: 'Account Type',
                selector: 'accountType',
                sortable: true,
                right: false,
                width: "15%"
            },
            {
                name: 'Account No',
                selector: 'accountNumber',
                sortable: true,
                right: false,
                width: "15%"
            },
            {
                name: 'IBAN',
                selector: 'iban',
                sortable: true,
                right: false,
                width: "15%"
            },
            {
                name: 'BIC',
                selector: 'bic',
                sortable: true,
                right: false,
                width: "10%"
            },
            {
                name: '',
                selector: '',
                sortable: true,
                right: false,
                width: "20%",
                cell: item => <div> <a type="button" className="btn btn-primary  btn-sm" data-toggle="modal" data-target=".add-bank" onClick={() => this.EditBank(item.id)}><i className="ti-reload mr-1" /> Update</a> <a type="button" className="btn btn-danger  btn-sm sweet-multiple" onClick={() => this.DeleteBank(item.id)}><i className="ti-trash mr-1" /> Delete</a></div>
            },
        ];

        const CompanyPersonalListColumns = [
            {
                name: 'First Name',
                selector: 'firstName',
                sortable: true,
                right: false,
                // width: "150px"
                width: "10%"
            },
            {
                name: 'Last Name',
                selector: 'lastName',
                sortable: true,
                right: false,
                // width: "150px"
                width: "10%"

            },
            {
                name: 'E-Mail',
                selector: 'email',
                sortable: true,
                right: false,
                // width: "220px"
                width: "15%"
            },
            {
                name: 'Tel No. 1',
                selector: 'telephone1',
                sortable: true,
                right: false,
                // width: "120px"
                width: "10%"
                
            },
            {
                name: 'Mob No',
                selector: 'mobileNo',
                sortable: true,
                right: false,
                // width: "120px"
                width: "10%"
            },
            {
                name: 'Skype ID',
                selector: 'skypeId',
                sortable: true,
                right: false,
                // width: "120px"
                width: "10%"
            },
            {
                name: 'ICE ID',
                selector: 'yahooId',
                sortable: true,
                right: false,
                // width: "120px"
                width: "10%"
            },
            {
                name: 'Address',
                selector: 'add1',
                sortable: true,
                right: false,
                // width: "150px"
                width: "20%"
            },
            {
                name: 'Role',
                selector: '',
                sortable: true,
                right: false,
                // width: "100px"
                width: "10%"
            },
            {
                name: '',
                selector: '',
                sortable: true,
                right: false,
                // width: "190px",
                width: "10%",
                cell: item => <div> <a type="button" className="btn btn-primary  btn-sm" data-toggle="modal" data-target=".add-Personal" onClick={() => this.EditCompanyPersonal(item.id)} ><i className="ti-reload mr-1" /> Update</a> <a type="button" className="btn btn-danger  btn-sm sweet-multiple" onClick={() => this.DeleteCompanyPersonal(item.id)}><i className="ti-trash mr-1" /> Delete</a></div>
            },
        ];

        const NoBorderStyles = {
            borderTop: 'none',
        };

        return (
            <div className="layout-wrapper">
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />
                    <div className="content-body">
                        <div className="content p-0">
                            <div className="profile-container custom-shadow" style={{ background: 'url(assets/media/image/image1.jpg)' }}>
                                <div className="text-center mb-5">
                                    <figure className="mb-3  rounded-circle raje mainlogowhite">
                                        {/* <img src="assets/media/image/user/man_avatar3.jpg" className="rounded-circle" alt="..." /> */}
                                        {this.state.companydetailcompanyLogoPath != null && this.state.companydetailcompanyLogoPath != ""
                                            && this.state.companydetailcompanyLogoPath != undefined ?
                                            <img src={this.state.contentUrl + '' +
                                                this.state.companydetailcompanyLogoPath}
                                                className="rounded-circle logoheight" alt="..." onError="assets/media/image/logo.png" /> :
                                            <img src="assets/media/image/logo.png" className="heightnull" alt="..." />
                                        }
                                    </figure>
                                    <div>
                                        <h4 className="mb-0">{this.state.firstName} {this.state.lastName}</h4>
                                        <small className="opacity-7">{this.state.roleName}</small>
                                    </div>
                                </div>
                                <div className="profile-menu">
                                    <ul className="nav nav-pills justify-content-center" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="earnings-tab" data-toggle="tab" href="#earnings" role="tab" aria-selected="false">Company Details</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="earnings" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="d-flex">
                                                            <h4 className="mb-4">Company Details</h4>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-hover">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="font-weight-800" style={NoBorderStyles}>Company Name </td>
                                                                                <td style={NoBorderStyles}>:</td>
                                                                                <td style={NoBorderStyles}>{this.state.companydetailname}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Company Reg No </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailcompanyRegNo} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Website </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailwebsite}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">E-Mail </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailemail} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Tel No </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailtelNo}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Fax No </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailfaxNo} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Address </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailaddress}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">City </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailcity} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Postcode </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailpostcode}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">State </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailstate} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Country </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailcountryname}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">VAT No </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailvatNo} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Tax ID No </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailtaxIdNo}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">System Privilege </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailsystemPrivileges} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Company Letterhead </td>
                                                                                <td>:</td>
                                                                                {this.state.companydetailcopmanyLetterHeadPath === "" || this.state.companydetailcopmanyLetterHeadPath === null ?
                                                                                    (
                                                                                        <td>

                                                                                        </td>
                                                                                    ) : (
                                                                                        <td>
                                                                                            <a href={this.state.contentUrl + '' + this.state.companydetailcopmanyLetterHeadPath} target="_blank">
                                                                                                <i class="fa fa-file-pdf-o text-danger pdf-icon-font"></i>
                                                                                            </a>
                                                                                        </td>
                                                                                    )}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Company Logo </td>
                                                                                <td>:</td>
                                                                                {this.state.companydetailcompanyLogoPath === "" || this.state.companydetailcompanyLogoPath === null ?
                                                                                    (
                                                                                        <td>
                                                                                            <img src="assets/media/image/logo.png" className="display-logo" />
                                                                                        </td>
                                                                                    ) : (
                                                                                        <td>
                                                                                            <img src={this.state.contentUrl + '' + this.state.companydetailcompanyLogoPath}
                                                                                                className="display-logo" />
                                                                                        </td>
                                                                                    )}
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <a style={{ float: "right", marginRight: "16px" }} type="button" className="btn btn-primary mb-3" data-toggle="modal" data-target=".add-company" onClick={() => this.BindCompanyDetails()}><i className="ti-reload mr-1" /> Update</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="Distribution-box">
                                                            <div className="d-flex">
                                                                <h4 className="mb-4">Add New Company Address</h4>
                                                            </div>
                                                            <div className="card mt-2">
                                                                <div className="table-responsive">
                                                                    <DataTable
                                                                        columns={MultiAddressListColumns}
                                                                        data={this.state.MultipleAddressList}
                                                                        pagination="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="ml-auto">
                                                                    <a type="button" className="btn btn-primary mb-2" data-toggle="modal" data-target=".address" onClick={() => this.ResetMultipleAddress()}><i className="ti-plus mr-1" /> Add New Address</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mybank-box">
                                                            <div className="d-flex">
                                                                <h4 className="mb-4">Bank Account(s)</h4>
                                                            </div>
                                                            <div className="card mt-2">
                                                                <div className="table-responsive">
                                                                    <DataTable
                                                                        columns={BankListColumns}
                                                                        data={this.state.Banks}
                                                                        pagination="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="ml-auto">
                                                                    <a type="button" className="btn btn-primary mb-2" data-toggle="modal" data-target=".add-bank"><i className="ti-plus mr-1" onClick={() => this.ResetBank()} /> Add Bank</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="Personal-box">
                                                            <div className="d-flex">
                                                                <h4 className="mb-4">Company Personal</h4>
                                                            </div>
                                                            <div className="card mt-2">
                                                                <div className="table-responsive">
                                                                    <DataTable
                                                                        columns={CompanyPersonalListColumns}
                                                                        data={this.state.CompanyPersonal}
                                                                        pagination="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="ml-auto">
                                                                    <a type="button" className="btn btn-primary mb-2" data-toggle="modal" data-target=".add-Personal" onClick={() => this.ResetCompanyPersonal()}><i className="ti-plus mr-1" /> Add Personal Info</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="Distribution-box">
                                                            <div className="d-flex">
                                                                <h4 className="mb-4">Distribution List Settings</h4>
                                                            </div>
                                                            <div className="card mt-2">
                                                                <div className="table-responsive">
                                                                    <DataTable
                                                                        columns={DistributionListColumns}
                                                                        data={this.state.DistributionList}
                                                                        pagination="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="ml-auto">
                                                                    <a type="button" className="btn btn-primary mb-2" data-toggle="modal" data-target=".List" onClick={() => this.EditDistributionList()}><i className="ti-plus mr-1" /> Add Distribution List</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="Fee-box">
                                                            <div className="d-flex">
                                                                <h4 className="mb-4">Brokerage Rates</h4>
                                                            </div>
                                                            <div className="card mt-2">
                                                                <div className="card-body">
                                                                    <div className="table-responsive" style={{ display: 'block', overflowX: 'auto' }}>
                                                                        <table className="table table-hover">
                                                                            <thead className="thead-dark">
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'left' }}></th>
                                                                                    {this.state.BrokerageCountryList == null ? "" :
                                                                                        this.state.BrokerageCountryList.map(item => (
                                                                                            <th style={{ textAlign: 'left' }}>
                                                                                                {item.countryName}
                                                                                            </th>
                                                                                        ))
                                                                                    }
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ textAlign: 'left' }}>Initiator</td>
                                                                                    {this.state.BrokerageRateList == null ? "" :
                                                                                        this.state.BrokerageRateList.map(item => (
                                                                                            <td style={{ textAlign: 'left' }}>
                                                                                                {/* {item.id == "0" ? "" : <span>{item.iCurrencyName} {item.iRate != "" && item.iRate != "0" && item.iRate != undefined && item.iRate != null ? Number(item.iRate).toFixed(5) : "0.00000"}</span>} */}
                                                                                                {item.id == "0" ? "" : item.iRate != null && item.iRate != "" && item.iRate != undefined ? <span>{item.iCurrencyName} {Number(item.iRate).toFixed(4)}</span> : ""}
                                                                                            </td>
                                                                                        ))
                                                                                    }
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ textAlign: 'left' }}>Aggressor</td>
                                                                                    {this.state.BrokerageRateList == null ? "" :
                                                                                        this.state.BrokerageRateList.map(item => (
                                                                                            <td style={{ textAlign: 'left' }}>
                                                                                                {/* {item.id == "0" ? "" : <span>{item.aCurrencyName} {item.aRate != "" && item.aRate != "0" && item.aRate != undefined && item.aRate != null ? Number(item.aRate).toFixed(5) : "0.00000"}</span>} */}
                                                                                                {/* {item.id == "0" ? "" : <span>{item.aCurrencyName} {item.aRate}</span>} */}
                                                                                                {item.id == "0" ? "" : item.aRate != null && item.aRate != "" && item.aRate != undefined ? <span>{item.aCurrencyName} {Number(item.aRate).toFixed(4)}</span> : ""}
                                                                                            </td>
                                                                                        ))
                                                                                    }
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="ml-auto">
                                                                    <a type="button" className="btn btn-primary mb-2" data-toggle="modal" data-target=".Fee" onClick={() => this.BindBrokerageRate()}><i className="ti-plus mr-1" /> Add/Edit New Rate</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        <div className="modal fade add-company" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Update Company Detail</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.BindCompanyDetails()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Company Name</label>
                                                    <input type="text" className="form-control col-md-9" id="CCompany" placeholder="Company Name" value={this.state.companydetailname} disabled />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Website</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        id="CWebSite" placeholder="Website"
                                                        value={this.state.companydetailwebsite}
                                                        onChange={(e) => { this.setState({ companydetailwebsite: e.target.value }); }}
                                                    />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">E- Mail</label>
                                                    <input type="email"
                                                        className="form-control col-md-9"
                                                        id="CEmail"
                                                        placeholder="Email"
                                                        value={this.state.companydetailemail}
                                                        onChange={(e) => { this.setState({ companydetailemail: e.target.value }); }}
                                                    />
                                                    {this.state.EmailError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.EmailError}</span>}
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">Tel No.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.CompanyTelCountrySelected}
                                                            onChange={this.SetCompanyTelephone1Country}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Tel Code"
                                                            id="telephone1Code"
                                                            value={this.state.CompanyTelCode}
                                                            onChange={(e) => { this.setState({ CompanyTelCode: e.target.value }); }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Tel No."
                                                            id="telephone1"
                                                            value={this.state.companydetailtelNo}
                                                            onChange={(e) => { this.onChangeTelNoCompany(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">Fax No.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.CompanyFaxCountrySelected}
                                                            onChange={this.SetCompanyFaxCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="text" className="form-control" placeholder="FAX Code" id="faxCode" value={this.state.CompanyFaxCode} onChange={(e) => { this.setState({ CompanyFaxCode: e.target.value }); }} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="number"
                                                            className="form-control" placeholder="FAX No." id="fax"
                                                            value={this.state.companydetailfaxNo}
                                                            onChange={(e) => { this.setState({ companydetailfaxNo: e.target.value }); }} />
                                                    </div>
                                                </div>

                                               
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Address</label>
                                                    <input type="text" className="form-control col-md-9" id="CAddress" placeholder="Address" value={this.state.companydetailaddress} onChange={(e) => { this.setState({ companydetailaddress: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-md-9"
                                                        id="CCity" placeholder="City"
                                                        value={this.state.companydetailcity}
                                                        onChange={(e) => { this.setState({ companydetailcity: e.target.value }); }}
                                                    />
                                                    {this.state.CompanyCityError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.CompanyCityError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">State</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        id="CState" placeholder="State"
                                                        value={this.state.companydetailstate}
                                                        onChange={(e) => { this.setState({ companydetailstate: e.target.value }); }}
                                                    />
                                                    {this.state.CompanyStateError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.CompanyStateError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Postcode</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        id="CPostcode" placeholder="Postcode"
                                                        value={this.state.companydetailpostcode}
                                                        onChange={(e) => { this.setState({ companydetailpostcode: e.target.value }); }}
                                                    />
                                                    {this.state.CompanyPostcodeError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.CompanyPostcodeError}</span>}
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Country</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.countrySelected}
                                                            onChange={this.SetCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Country</label>
                                                    <select className="form-control col-md-9" id="CCountry" value={this.state.companydetailcountry} onChange={(e) => { this.setState({ companydetailcountry: e.target.value }); }}>
                                                        <option value>Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div> */}
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Company Reg No.</label>
                                                    <input type="number" className="form-control col-md-9" id="CCompanyRegNo" placeholder="Company Reg  No." value={this.state.companydetailcompanyRegNo} onChange={(e) => { this.setState({ companydetailcompanyRegNo: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">VAT No.</label>
                                                    <input type="number" className="form-control col-md-9" id="CVatNo" placeholder="VAT  No." value={this.state.companydetailvatNo} onChange={(e) => { this.setState({ companydetailvatNo: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Tax ID No.</label>
                                                    <input type="number" className="form-control col-md-9" id="CTaxNo" placeholder="Tax ID  No." value={this.state.companydetailtaxIdNo} onChange={(e) => { this.setState({ companydetailtaxIdNo: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">System Privileges</label>
                                                    <input type="text" className="form-control col-md-9" id="CSystemPrivileges" defaultValue="Broker" disabled value={this.state.companydetailsystemPrivileges} onChange={(e) => { this.setState({ companydetailsystemPrivileges: e.target.value }); }} readonly />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Company Logo</label>
                                                    <div className="custom-file col-md-9">
                                                        <input type="file" className="custom-file-input" id="CCompanyLogo" onChange={this.onChangeCompanyLogo} />
                                                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Company Letterhead</label>
                                                    <div className="custom-file col-md-9">
                                                        <input type="file" className="custom-file-input" id="CCompanyLetterhead" onChange={this.onChangeCompanyLetterHeadPath} />
                                                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <input type="hidden" value={this.state.companydetailid} id="companydetailid" />
                                        <input type="hidden" value={this.state.companydetailcompanyId} id="companydetailcompanyId" />
                                        <input type="hidden" value={this.state.companydetailcompanyLogoPath} id="companyLogoPath" />
                                        <input type="hidden" value={this.state.companydetailcopmanyLetterHeadPath} id="copmanyLetterHeadPath" />
                                        <button type="button" className="btn btn-primary mb-2" data-dismiss="modal" onClick={() => this.UpdateComapanyDetail()} id="btnCompanySave">Save</button>
                                        <button type="button" id="btnCloseCompanyPopup" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.BindCompanyDetails()} id="btnResetCompany">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade add-bank" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">{this.state.BankID > 0 ? 'Update Bank Account' : 'Add Bank Account'}</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.ResetBank()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Bank Name</label>
                                                    <input type="text" className="form-control col-md-9" id="BankName" placeholder="Bank Name" value={this.state.bankName} onChange={(e) => { this.setState({ bankName: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Address</label>
                                                    <input type="text" className="form-control col-md-9" id="BankAddress" placeholder="Address" value={this.state.address} onChange={(e) => { this.setState({ address: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">City</label>
                                                    <input type="text" className="form-control col-md-9" id="BankCity" placeholder="City" value={this.state.city} onChange={(e) => { this.setState({ city: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">State</label>
                                                    <input type="text" className="form-control col-md-9" id="BankState" placeholder="State" value={this.state.state} onChange={(e) => { this.setState({ state: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Postcode</label>
                                                    <input type="text" className="form-control col-md-9" id="BankPostcode" placeholder="Postcode" value={this.state.postcode} onChange={(e) => { this.setState({ postcode: e.target.value }); }} />
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Country</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.BankCountry}
                                                            onChange={this.SetBankCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Account Type</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Account Type"
                                                            value={this.state.accountTypeSelected}
                                                            onChange={this.SetAccountType}
                                                            options={this.state.AccountTypeList}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row" style={{ display: 'none' }}>
                                                    <label className="font-weight-800 col-md-3">Country</label>
                                                    <select className="form-control col-md-9" id="BankCountry" value={this.state.country} onChange={(e) => { this.setState({ country: e.target.value }); }}>
                                                        <option value="">Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group row" style={{ display: 'none' }}>
                                                    <label className="font-weight-800 col-md-3">Account Type</label>
                                                    <select className="form-control col-md-9" id="BankAccountType" value={this.state.accountType} onChange={(e) => { this.setState({ accountType: e.target.value }); }}>
                                                        <option value="">Select</option>
                                                        {this.state.AccountTypeList == null ? "" :
                                                            this.state.AccountTypeList.map(item => (
                                                                <option value={item.id} >{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Account No.</label>
                                                    <input type="number"
                                                        className="form-control col-md-9"
                                                        id="BankAccountNo" placeholder="Account No."
                                                        value={this.state.accountNumber}
                                                        onChange={this.onchangeAccountNo}
                                                    />
                                                    {this.state.AccountNoError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.AccountNoError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">BIC</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        id="BankBIC" placeholder="BIC"
                                                        value={this.state.bic}
                                                        onChange={this.onchangeBIC}
                                                    />
                                                    {this.state.BICError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.BICError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">IBAN</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        id="BankIBAN" placeholder="IBAN"
                                                        value={this.state.iban}
                                                        onChange={this.onchangeIBAN}
                                                    />
                                                    {this.state.IBANError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.IBANError}</span>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <input type="hidden" value={this.state.BankID} id="BankID" />
                                        <a type="button" className="btn btn-primary mb-2" onClick={() => this.SaveBank()}>Save</a>
                                        <button id="btnCloseBank" type="button" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.ResetBank()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade add-Personal" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">{this.state.CompanyPersonalID > 0 ? 'Update Contact Details' : 'Add Contact Details'}</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.ResetCompanyPersonal()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">First Name</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        placeholder="First Name"
                                                        id="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeCPFirstName}
                                                    />
                                                    {this.state.CPFNameError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.CPFNameError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Last Name</label>
                                                    <input type="email"
                                                        className="form-control col-md-9"
                                                        placeholder="Last Name" id="lastName"
                                                        id="lastName" value={this.state.lastName}
                                                        onChange={this.onChangeCPLastName}
                                                    />
                                                    {this.state.CPLNameError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.CPLNameError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Email</label>
                                                    <input type="email"
                                                        className="form-control col-md-9"
                                                        placeholder="Email" id="email" id="email"
                                                        value={this.state.email}
                                                        onChange={this, this.onChangeCPEmail}
                                                    />
                                                    {this.state.CPEmailError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.CPEmailError}</span>}
                                                </div>


                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">Tel No 1.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.usertelephone1Selected}
                                                            onChange={this.onChangeCountry1}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Tel Code"
                                                            id="telephone1Code"
                                                            value={this.state.telephone1Code}
                                                            onChange={(e) => { this.setState({ telephone1Code: e.target.value }); }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Tel No."
                                                            id="telephone1"
                                                            value={this.state.telephone1}
                                                            onChange={(e) => { this.onChangeTelNoCompanyPersonnel(e.target.value) }} />

                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">Tel No 2.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.usertelephone2Selected}
                                                            onChange={this.onChangeCountry2}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="text" className="form-control" placeholder="Tel Code" id="telephone2Code" value={this.state.telephone2Code} onChange={(e) => { this.setState({ telephone2Code: e.target.value }); }} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="number" className="form-control" placeholder="Tel No." id="telephone2" value={this.state.telephone2} onChange={(e) => { this.setState({ telephone2: e.target.value }); }} />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">MOB No.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.usermobileNoSelected}
                                                            onChange={this.onChangeCountry3}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="text" className="form-control" placeholder="MOB Code" id="mobileNoCode" value={this.state.mobileNoCode} onChange={(e) => { this.setState({ mobileNoCode: e.target.value }); }} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="number"
                                                            className="form-control"
                                                            placeholder="MOB No."
                                                            id="mobileNo"
                                                            value={this.state.usermobileNo}
                                                            onChange={(e) => { this.onChangeMobileCompanyPersonnel(e.target.value) }} />

                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">Fax No.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.userfaxCountrySelected}
                                                            onChange={this.onChangeFaxCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="text" className="form-control" placeholder="FAX Code" id="faxCode" value={this.state.faxCode} onChange={(e) => { this.setState({ faxCode: e.target.value }); }} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="number" className="form-control col-md-9" placeholder="FAX No." id="fax" value={this.state.fax} onChange={(e) => { this.setState({ fax: e.target.value }); }} />
                                                    </div>
                                                </div>


                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Skype ID</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="Skype ID" id="skypeId" value={this.state.skypeId} onChange={(e) => { this.setState({ skypeId: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">ICE ID</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="ICE ID" id="yahooId" value={this.state.yahooId} onChange={(e) => { this.setState({ yahooId: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Address</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="Address" id="add1" value={this.state.add1} onChange={(e) => { this.setState({ add1: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">City</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        placeholder="City" id="city"
                                                        value={this.state.city}
                                                        onChange={this.onChangeCPCity}
                                                    />
                                                    {this.state.CPCityError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.CPCityError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">State</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        placeholder="State" id="state"
                                                        value={this.state.state}
                                                        onChange={this.onChangeCPState}
                                                    />
                                                    {this.state.CPStateError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.CPStateError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Postcode</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        placeholder="Postcode" id="postcode"
                                                        value={this.state.postcode}
                                                        onChange={this.onChangeCPPostcode}
                                                    />
                                                    {this.state.CPPostcodeError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.CPPostcodeError}</span>}
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Country</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.userCountrySelected}
                                                            onChange={this.SetAddressCountyCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="form-group row">
                                            <label className="font-weight-800 col-md-3">Response Email</label>
                                            <input type="text" className="form-control col-md-9" placeholder="responseEmail" id="responseEmail" value={this.state.responseEmail} onChange={(e) => { this.setState({ responseEmail: e.target.value }); }} />
                                        </div> */}
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <input type="hidden" value={this.state.CompanyPersonalID} id="CompanyPersonalID" />
                                        <a type="button" className="btn btn-primary mb-2 sweet-success" onClick={() => this.SaveCompanyPersonal()}>Save</a>
                                        <button type="button" id="btnCloseCompanyPersonnel" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.ResetCompanyPersonal()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade user-detail" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-xl">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Update User Details</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.ResetCompanyPersonal()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">First Name</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="Full Name" id="firstName" value={this.state.firstName} onChange={(e) => { this.setState({ firstName: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Last Name</label>
                                                    <input type="email" className="form-control col-md-9" placeholder="Last Name" id="lastName" id="lastName" value={this.state.lastName} onChange={(e) => { this.setState({ lastName: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Email</label>
                                                    <input type="email" className="form-control col-md-9" placeholder="Email" id="email" id="email" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Work Email</label>
                                                    <input type="email" className="form-control col-md-9" placeholder="Email Work" id="emailWork" id="emailWork" value={this.state.emailWork} onChange={(e) => { this.setState({ emailWork: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Home Email</label>
                                                    <input type="email" className="form-control col-md-9" placeholder="Email Home" id="emailHome" id="emailHome" value={this.state.emailHome} onChange={(e) => { this.setState({ emailHome: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Country Tel No.</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="Country Tel No." id="telephone1Country" value={this.state.telephone1Country} onChange={(e) => { this.setState({ telephone1Country: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Tel Code.</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="Tel Code" id="telephone1Code" value={this.state.telephone1Code} onChange={(e) => { this.setState({ telephone1Code: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Tel No.</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="Tel No." id="telephone1" value={this.state.telephone1} onChange={(e) => { this.setState({ telephone1: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Country Tel No.2</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="Country Tel No." id="telephone2Country" value={this.state.telephone2Country} onChange={(e) => { this.setState({ telephone2Country: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Tel Code.2</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="Tel Code" id="telephone2Code" value={this.state.telephone2Code} onChange={(e) => { this.setState({ telephone2Code: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Tel No.2</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="Tel No." id="telephone2" value={this.state.telephone2} onChange={(e) => { this.setState({ telephone2: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Country MOB No.</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="Country MOB No." id="mobileNoCountry" value={this.state.mobileNoCountry} onChange={(e) => { this.setState({ mobileNoCountry: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">MOB Code</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="MOB Code" id="mobileNoCode" value={this.state.mobileNoCode} onChange={(e) => { this.setState({ mobileNoCode: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">MOB No.</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="MOB No." id="mobileNo" value={this.state.mobileNo} onChange={(e) => { this.setState({ mobileNo: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">FAX Country</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="FAX Country." id="faxCountry" value={this.state.faxCountry} onChange={(e) => { this.setState({ faxCountry: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">FAX Code</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="FAX Code" id="faxCode" value={this.state.faxCode} onChange={(e) => { this.setState({ faxCode: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">FAX No.</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="FAX No." id="fax" value={this.state.fax} onChange={(e) => { this.setState({ fax: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Skype ID</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="Skype ID" id="skypeId" value={this.state.skypeId} onChange={(e) => { this.setState({ skypeId: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">ICE ID</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="ICE ID" id="yahooId" value={this.state.yahooId} onChange={(e) => { this.setState({ yahooId: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Address</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="Address" id="add1" value={this.state.add1} onChange={(e) => { this.setState({ add1: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">City</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="City" id="city" value={this.state.city} onChange={(e) => { this.setState({ city: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">State</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="State" id="state" value={this.state.state} onChange={(e) => { this.setState({ state: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Postcode</label>
                                                    <input type="number" className="form-control col-md-9" placeholder="Postcode" id="postcode" value={this.state.postcode} onChange={(e) => { this.setState({ postcode: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Country</label>
                                                    <select className="form-control col-md-9" id="country" value={this.state.country} onChange={(e) => { this.setState({ country: e.target.value }); }}>
                                                        <option>Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Response Email</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="responseEmail" id="responseEmail" value={this.state.responseEmail} onChange={(e) => { this.setState({ responseEmail: e.target.value }); }} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <input type="hidden" value={this.state.CompanyPersonalID} id="CompanyPersonalID" />
                                        <a type="button" className="btn btn-primary mb-2 sweet-success" data-dismiss="modal" onClick={() => this.SaveCompanyDetail()}>Save</a>
                                        <button type="button" className="btn btn-danger mb-2" data-dismiss="modal" >Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade email-setup" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Add New Email</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Email Type</label>
                                                    <select className="js-example-basic-single">
                                                        <option>Select</option>
                                                        <option value={123}>Group</option>
                                                        <option value={123}>Individiual</option>
                                                    </select>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Group Name</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="Group Name" />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Email</label>
                                                    <input type="email" className="form-control col-md-9" placeholder="Email" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <a type="button" className="btn btn-primary mb-2 sweet-success" data-dismiss="modal">Save</a>
                                        <button type="button" className="btn btn-danger mb-2" data-dismiss="modal">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade List" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Setup Distribution List</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.ResetDistDetail()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Distribution Purpose</label>
                                                    <select className="form-control col-md-9" value={this.state.DistType} onChange={(e) => { this.setState({ DistType: e.target.value }); }}>
                                                        <option value="Trade Confirm">Trade Confirm</option>
                                                        <option value="Invoice">Invoice</option>
                                                        <option value="Market Report">Market Report</option>
                                                    </select>
                                                </div>

                                                <div className="table-responsive p-3">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Email(s)</th>
                                                                {/* <th scope="col">PDF</th>
                                                        <th scope="col">XLSX</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.DistCompanyPersonalList == null ? "" :
                                                                this.state.DistCompanyPersonalList.map(item => (
                                                                    <tr>
                                                                        <td scope="col">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" className="custom-control-input" id={"customCheck_" + item.id} onChange={() => this.addSelectedDistCompanyPersonalList(item.id)} />
                                                                                <label className="custom-control-label" htmlFor={"customCheck_" + item.id}>{item.email}</label>
                                                                            </div>
                                                                        </td>
                                                                        {/* <td><img src="assets/media/image/pdf.png" className="mypdf" /></td>
                                                                <td><img src="https://www.flaticon.com/svg/static/icons/svg/732/732220.svg" className="mypdf" /></td> */}
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <a type="button" className="btn btn-primary mb-2 sweet-success" data-dismiss="modal" onClick={() => this.SaveDistDetail()}>Save</a>
                                        <button type="button" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.ResetDistDetail()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade Fee" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Add/Update Brokerage Fee</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.ResetBrokerageRate()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Company Name</label>
                                                    <input type="text" className="form-control col-md-9" defaultValue={this.state.companydetailname} disabled />
                                                </div>
                                                <div className="table-responsive p-3">
                                                    <table className="table table-striped" style={{ width: '100%' }}>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" width="20%">Country</th>
                                                                <th scope="col" width="40%" colSpan="2">Seller</th>
                                                                {/* <th scope="col" width="20%"></th> */}
                                                                <th scope="col" width="40%" colSpan="2">Buyer</th>
                                                                {/* <th scope="col" width="20%"></th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.BrokerageRateList == null ? "" :
                                                                this.state.BrokerageRateList.map(item => (
                                                                    <tr>
                                                                        <td scope="col" width="20%">
                                                                            {item.countryName}
                                                                        </td>
                                                                        <td scope="col" width="20%">
                                                                            <select className="form-control" value={item.iCurrencyID} onChange={(e) => { this.onChangeiCurrency(item.id, item.countryId, e.target.value) }} >
                                                                                {this.state.BrokerageCurrencyList == null ? "" :
                                                                                    this.state.BrokerageCurrencyList.map(item => (
                                                                                        <option value={item.id} attr-symbol={item.Symbol} >{item.name}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </td>
                                                                        <td scope="col" width="20%">
                                                                            <input type="text" className="form-control" id="iRate" placeholder="" value={item.iRate !== null && item.iRate !== undefined && item.iRate !== "" ? item.iRate : ""} onChange={(e) => { this.onChangeiRate(item.id, item.countryId, e.target.value) }} />
                                                                        </td>
                                                                        <td scope="col" width="20%">
                                                                            <select className="form-control" value={item.aCurrencyID} onChange={(e) => { this.onChangeaCurrency(item.id, item.countryId, e.target.value) }}>
                                                                                {this.state.BrokerageCurrencyList == null ? "" :
                                                                                    this.state.BrokerageCurrencyList.map(item => (
                                                                                        <option value={item.id} attr-symbol={item.Symbol} >{item.name}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </td>
                                                                        <td scope="col" width="20%">
                                                                            <input type="text" className="form-control" id="aRate" placeholder="" value={item.aRate !== null && item.aRate !== undefined && item.aRate !== "" ? item.aRate : ""} onChange={(e) => { this.onChangeaRate(item.id, item.countryId, e.target.value) }} />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <a type="button" className="btn btn-primary mb-2 sweet-success" data-dismiss="modal" onClick={() => this.SaveBrokerageRate()}>Save</a>
                                        <button type="button" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.ResetBrokerageRate()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade address" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">{this.state.addressId > 0 ? 'Update Company Address' : 'Add New Company Address'}</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.ResetMultipleAddress()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Name</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        id="AddName"
                                                        placeholder="Name"
                                                        value={this.state.addname}
                                                        onChange={this.onchangeName}
                                                    />
                                                    {this.state.NameError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.NameError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Address 1</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-md-9"
                                                        id="addAddress1"
                                                        placeholder="Address 1"
                                                        value={this.state.address1}
                                                        onChange={this.onchangeAddress1}
                                                    />
                                                    {this.state.Address1Error.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.Address1Error}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Address 2</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-md-9"
                                                        id="addAddress2"
                                                        placeholder="Address 2"
                                                        value={this.state.address2}
                                                        onChange={(e) => { this.setState({ address2: e.target.value }); }}
                                                    />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Address 3</label>
                                                    <input type="text" className="form-control col-md-9" id="addAddress3" placeholder="Address 3" value={this.state.address3} onChange={(e) => { this.setState({ address3: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-md-9"
                                                        id="addCity"
                                                        placeholder="City"
                                                        value={this.state.addcity}
                                                        onChange={this.onchangeCity}
                                                    />
                                                    {this.state.CityError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.CityError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">State</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        id="addState" placeholder="State"
                                                        value={this.state.addstate}
                                                        onChange={this.onchangeState}
                                                    />
                                                    {this.state.StateError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.StateError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Postcode</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        id="addPostcode"
                                                        placeholder="Postcode"
                                                        value={this.state.addpostCode}
                                                        onChange={this.onchangePostcode}
                                                    />
                                                    {this.state.PincodeError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.PincodeError}</span>}
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Country</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.countryAddressSelected}
                                                            onChange={this.SetAddressCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Country</label>
                                                    <select className="form-control col-md-9" id="addCountry" value={this.state.addcountry} onChange={(e) => { this.setState({ addcountry: e.target.value }); }}>
                                                        <option value="">Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div> */}
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">VAT No.</label>
                                                    <input type="text" className="form-control col-md-9" id="VATNo" placeholder="VAT No." value={this.state.addvat} onChange={(e) => { this.setState({ addvat: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Tax ID No.</label>
                                                    <input type="text" className="form-control col-md-9" id="TaxID" placeholder="Tax ID No" value={this.state.addtax} onChange={(e) => { this.setState({ addtax: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Purpose</label>
                                                    <div className="custom-control custom-checkbox col-md-3">
                                                        <input type="checkbox" className="custom-control-input" id="chk111" checked={this.state.addistradeconfirm} onChange={(e) => { this.setState({ addistradeconfirm: e.target.checked }); }} disabled />
                                                        <label className="custom-control-label" htmlFor="chk111">Trade Confirmation</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox col-md-6">
                                                        <input type="checkbox" className="custom-control-input" id="chk222" checked={this.state.addisInvoice} onChange={(e) => { this.setState({ addisInvoice: e.target.checked }); }} />
                                                        <label className="custom-control-label" htmlFor="chk222">Invoice</label>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <input type="hidden" value={this.state.addressId} id="AddID" />
                                        <input type="hidden" value={this.state.brokerCompanyId} id="BrokerCompanyId" />

                                        <a type="button" className="btn btn-primary mb-2" onClick={() => this.SaveMultipleAddress()}>Save</a>
                                        <button id="btnCloseAddress" type="button" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.ResetMultipleAddress()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >


        );
    }
}

export default CompanyDetail;