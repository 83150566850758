import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import config from './config';
import swal from 'sweetalert';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';


class TradeListView extends Component {

    constructor() {
        super();
        this.state = {
            id: 0,
            action: "",
            aggSleeve: false,
            aggressorAction: "",
            aggressorCompanyId: 0,
            aggressorCompanyName: "",
            aggressorTraderId: 0,
            brokerId: 0,
            buyerBrokerCurrencyId: 0,
            buyerBrokerRate: 0,
            buyerCompany: "",
            buyerCompanyId: 0,
            buyerSellerInvoiceCount: 0,
            buyerTradeName: "",
            buyerTraderId: 0,
            commodityId: 0,
            currencyId: 0,
            dateCreated: null,
            deliveryEndDate: null,
            deliveryStartDate: null,
            firstSequenceItemId: 0,
            firstSequenceItemName: "",
            fromBrokenSpread: false,
            initSleeve: false,
            initiatorAction: "",
            initiatorBrokerId: 0,
            initiatorCompanyId: 0,
            initiatorCompanyName: "",
            initiatorTraderId: 0,
            instId: 0,
            instName: "",
            isDeleted: false,
            isManualPrice: false,
            isReturnFromLive: false,
            isViewOption: false,
            price: 0,
            sellerBrokerCurrencyId: 0,
            sellerBrokerRate: 0,
            sellerCompany: "",
            sellerCompanyId: 0,
            sellerTradeName: "",
            sellerTraderId: 0,
            totalVolume: "",
            tradeID: 0,
            tradeInfoId: 0,
            tradeStatus: "",
            tradedDate: null,
            updatedDate: null,
            volume: 0,
            sellerBroker: "",
            buyerBroker: "",
            relationshipID: "",
            manualDeal: "",
            blockTrade: "",
            voiceDeal: "",
            seqSpan: "",
            cPTY_DealCreationDT: "",
            timeStamp: "",
            CommodityName: ""
        }
    }

    loadTradeDetail(ID) {
        if (ID != null && ID != "" && ID != undefined && ID != 0) {
            let FetchURL = config.apiUrl + "Trade/" + ID;
            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(FetchURL, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((result) => {
                        this.setState({ id: result.id });
                        this.setState({ action: result.action });
                        this.setState({ aggSleeve: result.aggSleeve });
                        this.setState({ aggressorAction: result.aggressorAction });
                        this.setState({ aggressorCompanyId: result.aggressorCompanyId });
                        this.setState({ aggressorCompanyName: result.aggressorCompanyName });
                        this.setState({ aggressorTraderId: result.aggressorTraderId });
                        this.setState({ brokerId: result.brokerId });
                        this.setState({ buyerBrokerCurrencyId: result.buyerBrokerCurrencyId });
                        this.setState({ buyerBrokerRate: result.buyerBrokerRate });
                        this.setState({ buyerCompany: result.buyerCompany });
                        this.setState({ buyerCompanyId: result.buyerCompanyId });
                        this.setState({ buyerSellerInvoiceCount: result.buyerSellerInvoiceCount });
                        this.setState({ buyerTradeName: result.buyerTradeName });
                        this.setState({ buyerTraderId: result.buyerTraderId });
                        this.setState({ commodityId: result.commodityId });
                        this.setState({ currencyId: result.currencyId });
                        this.setState({ dateCreated: result.dateCreated });
                        this.setState({ deliveryEndDate: result.deliveryEndDate });
                        this.setState({ deliveryStartDate: result.deliveryStartDate });
                        this.setState({ firstSequenceItemId: result.firstSequenceItemId });
                        this.setState({ firstSequenceItemName: result.firstSequenceItemName });
                        this.setState({ fromBrokenSpread: result.fromBrokenSpread });
                        this.setState({ initSleeve: result.initSleeve });
                        this.setState({ initiatorAction: result.initiatorAction });
                        this.setState({ initiatorBrokerId: result.initiatorBrokerId });
                        this.setState({ initiatorCompanyId: result.initiatorCompanyId });
                        this.setState({ initiatorCompanyName: result.initiatorCompanyName });
                        this.setState({ initiatorTraderId: result.initiatorTraderId });
                        this.setState({ instId: result.instId });
                        this.setState({ instName: result.instName });
                        this.setState({ isDeleted: result.isDeleted });
                        this.setState({ isManualPrice: result.isManualPrice });
                        this.setState({ isReturnFromLive: result.isReturnFromLive });
                        this.setState({ isViewOption: result.isViewOption });
                        this.setState({ price: result.price });
                        this.setState({ sellerBrokerCurrencyId: result.sellerBrokerCurrencyId });
                        this.setState({ sellerBrokerRate: result.sellerBrokerRate });
                        this.setState({ sellerCompany: result.sellerCompany });
                        this.setState({ sellerCompanyId: result.sellerCompanyId });
                        this.setState({ sellerTradeName: result.sellerTradeName });
                        this.setState({ sellerTraderId: result.sellerTraderId });
                        this.setState({ totalVolume: result.totalVolume });
                        this.setState({ tradeID: result.tradeID });
                        this.setState({ tradeInfoId: result.tradeInfoId });
                        this.setState({ tradeStatus: result.tradeStatus });
                        this.setState({ tradedDate: result.tradedDate });
                        this.setState({ updatedDate: result.updatedDate });
                        this.setState({ volume: result.volume });
                        this.setState({ sellerBroker: result.sellerBroker });
                        this.setState({ buyerBroker: result.buyerBroker });
                        this.setState({ relationshipID: result.relationshipID });
                        this.setState({ manualDeal: result.manualDeal });
                        this.setState({ blockTrade: result.blockTrade });
                        this.setState({ voiceDeal: result.voiceDeal });
                        this.setState({ seqSpan: result.seqSpan });
                        this.setState({ cPTY_DealCreationDT: result.cptY_DealCreationDT });
                        this.setState({ timeStamp: result.timeStamp });
                    });
                }
            });
        }
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.setState({ CommodityName: localStorage.getItem('CommodityName') })

            let params = queryString.parse(this.props.location.search);
            if (params != null) {
                var id = params.id;
                if (id != null && id != "" && id != undefined) {
                    this.setState({ id: parseInt(id) });
                    this.loadTradeDetail(id);
                }
            }
        } else {
            this.props.history.push('/login');
        }
    }

    render() {
        return (
            <div className="layout-wrapper">
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />

                    <div className="content-body">
                        <div className="content ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* <div className="dis-flex">
                                        <h3 className="mb-4">Trade Staging</h3>
                                        <div className="mb-4 ml-auto">
                                            <a type="button" className="btn btn-danger ml-2 mb-2 " href="/Trade-Staging"> <i className="ti-angle-left mr-1" />Back</a>
                                        </div>
                                    </div> */}
                                            <form className="row">
                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Deal ID :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.tradeID}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Status :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.tradeStatus}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Traded Date :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{moment(this.state.tradedDate).format("DD/MM/YYYY HH:mm:ss")}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Last Update :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{moment(this.state.updatedDate).format("DD/MM/YYYY HH:mm:ss")}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Instrument :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.instName}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Period :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.firstSequenceItemName}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Del. Start :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    {/* <label>{moment(this.state.deliveryStartDate).format("DD/MM/YYYY HH:mm:ss")}</label> */}
                                                    <label>{this.state.deliveryStartDate}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Del. Finish :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    {/* <label>{moment(this.state.deliveryEndDate).format("DD/MM/YYYY HH:mm:ss")}</label> */}
                                                    <label>{this.state.deliveryEndDate}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    {/* <label className="font-weight-800">Volume :</label> */}

                                                    {this.state.CommodityName == "Eu Power" ?
                                                        <label className="font-weight-800">Volume :</label>
                                                        :
                                                        this.state.CommodityName == "Eu Gas" ?
                                                            <label className="font-weight-800">Contact Volume(MWH) :</label>
                                                            :
                                                            this.state.CommodityName == "LNG" ?
                                                                <label className="font-weight-800">Lot(s) :</label>
                                                                :
                                                                ""
                                                    }
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.volume}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    {/* <label className="font-weight-800">Total Volume :</label> */}

                                                    {this.state.CommodityName == "Eu Power" ?
                                                        <label className="font-weight-800">Total Volume :</label>
                                                        :
                                                        this.state.CommodityName == "Eu Gas" ?
                                                            <label className="font-weight-800">Total MWH Traded :</label>
                                                            :
                                                            this.state.CommodityName == "LNG" ?
                                                                <label className="font-weight-800">Total Volume (MMBtu) :</label>
                                                                :
                                                                ""
                                                    }
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.totalVolume}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    {/* <label className="font-weight-800">Price (MWH) :</label> */}
                                                    {this.state.CommodityName == "Eu Power" ?
                                                        <label className="font-weight-800">Price (MWH) :</label>
                                                        :
                                                        this.state.CommodityName == "Eu Gas" ?
                                                            <label className="font-weight-800">Price (MWH) :</label>
                                                            :
                                                            this.state.CommodityName == "LNG" ?
                                                                <label className="font-weight-800">Price (USD) :</label>
                                                                :
                                                                ""
                                                    }
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>
                                                        {this.state.price != "" && this.state.price != "0" && this.state.price != undefined && this.state.price != null ? Number(this.state.price).toFixed(2) : "0.00"}
                                                    </label>
                                                </div>
                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Buyer :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.buyerCompany}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Seller :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.sellerCompany}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Initiator :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.initiatorCompanyName}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Aggressor :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.aggressorCompanyName}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Buyer Broker :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.buyerBroker}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Seller Broker :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.sellerBroker}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Buyer Brokerage Fee :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.buyerBrokerRate != "" && this.state.buyerBrokerRate != "0" && this.state.buyerBrokerRate != undefined && this.state.buyerBrokerRate != null ? Number(this.state.buyerBrokerRate).toFixed(5) : "0.00000"}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Seller Brokerage Fee :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.sellerBrokerRate != "" && this.state.sellerBrokerRate != "0" && this.state.sellerBrokerRate != undefined && this.state.sellerBrokerRate != null ? Number(this.state.sellerBrokerRate).toFixed(5) : "0.00000"}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Relationship ID :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.relationshipID}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Manual Deal :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.manualDeal}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Block Trade :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.blockTrade}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Voice Deal :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.voiceDeal}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Sequence Span :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.seqSpan}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">UTI :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.cPTY_DealCreationDT}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                    <label className="font-weight-800">Time Stamp :</label>
                                                </div>
                                                <div className="form-group col-md-10 p-l-10">
                                                    <label>{this.state.timeStamp}</label>
                                                </div>

                                                <div className="form-group col-md-2 text-right">
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <a type="button" className="btn btn-danger ml-2 mb-2 " href={"/Trade-List"}> <i className="ti-angle-left mr-1" />Back</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default TradeListView;