import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import { Link, Route, Switch } from "react-router-dom";
import config from './config';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';

class ContactDetail extends Component {
    constructor() {
        super();
        this.state = {
            id: 0,
            firstName: "",
            lastName: "",
            email: "",
            telephone1Country: "",
            telephone1Code: "",
            telephone1: "",
            telephone2Country: "",
            telephone2Code: "",
            telephone2: "",
            mobileNoCountry: "",
            mobileNoCode: "",
            mobileNo: "",
            emailWork: "",
            emailHome: "",
            faxCountry: "",
            faxCode: "",
            fax: "",
            skypeId: "",
            yahooId: "",
            add1: "",
            add2: "",
            add3: "",
            city: "",
            state: "",
            country: "",
            postcode: "",
            isCompanyPersonnel: false,
            responseEmail: "",
            password: "",
            cpassword: "",
            systemPrivileges: "",
            CompanyId: "",
            AddNewRole: "",
            userroleName:"",
            CountryList: [],
            BrockerComapanyList: [],
            RoleList: [],
        }
    }

    ResetRole() {
        this.setState({ AddNewRole: "" });
    }

    SaveCompanyDetail() {
        // let CompanyID = localStorage.getItem("EditCompanyID");
        // let CompanyPersonalID = this.state.CompanyPersonalID;
        var AddBankURL = config.apiUrl + "Register";
        var retval = true;

        if (this.state.id == "") {
            var Password = this.state.password;
            var CPassword = this.state.cpassword;

            if (Password == null || Password == "" || Password == undefined) {
                retval = false;
            }

            if (CPassword == null || CPassword == "" || CPassword == undefined) {
                retval = false;
            }

            if (retval) {
                if (Password != CPassword) {
                    retval = false;
                }
            }
        }

        debugger;
        if (retval) {

            var val = {
                Id: this.state.id,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                telephone1Country: this.state.telephone1Country,
                telephone1Code: this.state.telephone1Code.toString(),
                telephone1: this.state.telephone1,
                telephone2Country: this.state.telephone2Country,
                telephone2Code: this.state.telephone2Code.toString(),
                telephone2: this.state.telephone2,
                mobileNoCountry: this.state.mobileNoCountry,
                mobileNoCode: this.state.mobileNoCode.toString(),
                mobileNo: this.state.mobileNo,
                emailWork: this.state.emailWork,
                emailHome: this.state.emailHome,
                faxCountry: this.state.faxCountry,
                faxCode: this.state.faxCode.toString(),
                fax: this.state.fax,
                skypeId: this.state.skypeId,
                yahooId: this.state.yahooId,
                add1: this.state.add1,
                add2: "",
                add3: "",
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                postcode: this.state.postcode,
                password: this.state.password,
                cpassword: this.state.cpassword,                
                userroleName: this.state.userroleName,
                isCompanyPersonnel: false,
                responseEmail: this.state.responseEmail,
            }

            const data = JSON.stringify(val);
            var myLoginToken = localStorage.getItem("AccessToken");

            fetch(AddBankURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((results) => {
                        if (results == true) {
                            swal({
                                title: "Done!",
                                text: "Company Personnel details have been updated",
                                icon: "success",
                                button: "Okay!",
                            });
                        } else {
                            swal({
                                title: "Sorry!",
                                text: "Company Personnel details have not been updated",
                                icon: "error",
                                button: "okay",
                            });
                        }
                        this.props.history.push('/Contacts');
                    });
                }
            });
        }
    }

    EditCompanyPersonal(id) {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CompanyID = localStorage.getItem("EditCompanyID");
        let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/Individual/" + id;

        fetch(FetchBankURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    // this.setState({ CompanyPersonalID: result.id });
                    // this.setState({ firstName: result.firstName });
                    // this.setState({ lastName: result.lastName });
                    // this.setState({ email: result.email });
                    // this.setState({ telephone1Country: result.telephone1Country });
                    // this.setState({ telephone1Code: result.telephone1Code });
                    // this.setState({ telephone1: result.telephone1 });
                    // this.setState({ telephone2Country: result.telephone2Country });
                    // this.setState({ telephone2Code: result.telephone2Code });
                    // this.setState({ telephone2: result.telephone2 });
                    // this.setState({ mobileNoCountry: result.mobileNoCountry });
                    // this.setState({ mobileNoCode: result.mobileNoCode });
                    // this.setState({ mobileNo: result.mobileNo });
                    // this.setState({ emailWork: result.emailWork });
                    // this.setState({ emailHome: result.emailHome });
                    // this.setState({ faxCountry: result.faxCountry });
                    // this.setState({ faxCode: result.faxCode });
                    // this.setState({ fax: result.fax });
                    // this.setState({ skypeId: result.skypeId });
                    // this.setState({ yahooId: result.yahooId });
                    // this.setState({ add1: result.add1 });
                    // this.setState({ add2: result.add2 });
                    // this.setState({ add3: result.add3 });
                    // this.setState({ city: result.city });
                    // this.setState({ state: result.state });
                    // this.setState({ country: result.country });
                    // this.setState({ postcode: result.postcode });
                    // this.setState({ isCompanyPersonnel: result.isCompanyPersonnel });
                    // this.setState({ responseEmail: result.responseEmail });
                    // this.setState({ countryName: result.country });
                });
            }
        });
    }

    onChangeCountry1 = (event) => {
        this.setState({ telephone1Country: event.target.value });
        if (event.target.value == "Select") {
            this.setState({ telephone1Code: '' });
        } else {
            let selectedIndex = event.target.selectedIndex;
            let data = this.state.CountryList[selectedIndex - 1]
            this.setState({ telephone1Code: data.code });
        }
    }

    onChangeCountry2 = (event) => {
        this.setState({ telephone2Country: event.target.value });
        if (event.target.value == "Select") {
            this.setState({ telephone2Code: '' });
        } else {
            let selectedIndex = event.target.selectedIndex;
            let data = this.state.CountryList[selectedIndex - 1]
            this.setState({ telephone2Code: data.code });
        }
    }

    onChangeCountry3 = (event) => {
        this.setState({ mobileNoCountry: event.target.value });
        if (event.target.value == "Select") {
            this.setState({ mobileNoCode: '' });
        } else {
            let selectedIndex = event.target.selectedIndex;
            let data = this.state.CountryList[selectedIndex - 1]
            this.setState({ mobileNoCode: data.code });
        }
    }

    onChangeFaxCountry = (event) => {
        this.setState({ faxCountry: event.target.value });
        if (event.target.value == "Select") {
            this.setState({ faxCode: '' });
        } else {
            let selectedIndex = event.target.selectedIndex;
            let data = this.state.CountryList[selectedIndex - 1]
            this.setState({ faxCode: data.code });
        }
    }

    BindCountryList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Country";
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ CountryList: result })
                });
            }
        });
    }

    onChangeCompany = (event) => {
        if (event.target.value == "Select") {
            this.setState({ CompanyId: '' });
            this.setState({ userroleName: '' });
        } else {
            this.setState({ CompanyId: event.target.value });
            this.setState({ userroleName: '' });
            this.BindRoleList(event.target.value);
        }
    }

    SaveRoleDetail() {
        let CompanyID = this.state.CompanyId;
        let IndividualID = localStorage.getItem("LogedID");
        var AddUserURL = config.apiUrl + "Company/" + CompanyID + "/Individual/" + IndividualID + "/AddRole";

        var val = {
            Id: 0,
            IndividualId: IndividualID,
            RoleName: this.state.AddNewRole
        }

        const data = JSON.stringify(val);
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(AddUserURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {
            debugger;
            if (resp.status === 200) {
                resp.json().then((results) => {
                    this.setState({ AddNewRole: "" })
                    if (results == true) {
                        swal({
                            title: "Done!",
                            text: "Role added have been successfully",
                            icon: "success",
                            button: "Okay!",
                        });
                    } else {
                        swal({
                            title: "Sorry!",
                            text: "Role name have not been added",
                            icon: "error",
                            button: "okay",
                        });
                    }
                    this.BindRoleList();
                });
            }
        });
    }

    BindRoleList(CompanyID) {
        // let CompanyID = localStorage.getItem("LoginCompanyID");
        let IndividualID = localStorage.getItem("LogedID");
        var FetchURL = config.apiUrl + "Company/" + CompanyID + "/Individual/" + IndividualID + "/Role?individualId=" + IndividualID;
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    debugger;
                    this.setState({ RoleList: result })
                });
            }
        });
    }

    BindBrockerComapanyList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "AllBrokerCompany";
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ BrockerComapanyList: result })
                });
            }
        });
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.BindCountryList();
            this.BindBrockerComapanyList();
            // this.BindRoleList();
        } else {
            this.props.history.push('/login');
        }
    }

    render() {
        return (
            <div className="layout-wrapper">
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />
                    <div className="content-body">
                        <div className="content ">
                            <div className="row">

                                <div className="col-lg-12 text-right">
                                    <div className="d-flex">
                                        <h4 className="mb-4">Contacts</h4>
                                        <div className="ml-auto">
                                            <a href="/Contacts" type="button" className="btn btn-danger mb-2"> Back</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <form className="row">
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Full Name</label>
                                                    <input type="text" className="form-control" placeholder="Full Name" id="firstName" value={this.state.firstName} onChange={(e) => { this.setState({ firstName: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Last Name</label>
                                                    <input type="email" className="form-control" placeholder="Last Name" id="lastName" id="lastName" value={this.state.lastName} onChange={(e) => { this.setState({ lastName: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Email</label>
                                                    <input type="email" className="form-control" placeholder="Email" id="email" id="email" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Country Tel No.</label>
                                                    {/* <input type="number" className="form-control" placeholder="Country Tel No." id="telephone1Country" value={this.state.telephone1Country} onChange={(e) => { this.setState({ telephone1Country: e.target.value }); }} /> */}
                                                    <select className="form-control" id="telephone1Country" value={this.state.telephone1Country} onChange={this.onChangeCountry1} >
                                                        <option>Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Tel Code.</label>
                                                    <input type="number" className="form-control" placeholder="Tel Code" id="telephone1Code" value={this.state.telephone1Code} onChange={(e) => { this.setState({ telephone1Code: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Tel No.</label>
                                                    <input type="number" className="form-control" placeholder="Tel No." id="telephone1" value={this.state.telephone1} onChange={(e) => { this.setState({ telephone1: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Country Tel No.2</label>
                                                    {/* <input type="number" className="form-control" placeholder="Country Tel No." id="telephone2Country" value={this.state.telephone2Country} onChange={(e) => { this.setState({ telephone2Country: e.target.value }); }} /> */}
                                                    <select className="form-control" id="telephone2Country" value={this.state.telephone2Country} onChange={this.onChangeCountry2} >
                                                        <option>Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Tel Code.2</label>
                                                    <input type="number" className="form-control" placeholder="Tel Code" id="telephone2Code" value={this.state.telephone2Code} onChange={(e) => { this.setState({ telephone2Code: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Tel No.2</label>
                                                    <input type="number" className="form-control" placeholder="Tel No." id="telephone2" value={this.state.telephone2} onChange={(e) => { this.setState({ telephone2: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Country MOB No.</label>
                                                    {/* <select className="form-control" id="country" value={this.state.mobileNoCountry} onChange={(e) => { this.setState({ mobileNoCountry: e.target.value, mobileNoCode: e.target.getAttribute("attr-code") }); }}> */}
                                                    <select className="form-control" id="country" value={this.state.mobileNoCountry} onChange={this.onChangeCountry3} >
                                                        <option>Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {/* <input type="number" className="form-control" placeholder="Country MOB No." id="mobileNoCountry" value={this.state.mobileNoCountry} onChange={(e) => { this.setState({ mobileNoCountry: e.target.value }); }} /> */}
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">MOB Code</label>
                                                    <input type="number" className="form-control" placeholder="MOB Code" id="mobileNoCode" value={this.state.mobileNoCode} onChange={(e) => { this.setState({ mobileNoCode: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">MOB No.</label>
                                                    <input type="number" className="form-control" placeholder="MOB No." id="mobileNo" value={this.state.mobileNo} onChange={(e) => { this.setState({ mobileNo: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">FAX Country</label>
                                                    {/* <input type="number" className="form-control" placeholder="FAX Country." id="faxCountry" value={this.state.faxCountry} onChange={(e) => { this.setState({ faxCountry: e.target.value }); }} /> */}
                                                    <select className="form-control" id="faxCountry" value={this.state.faxCountry} onChange={this.onChangeFaxCountry} >
                                                        <option>Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">FAX Code</label>
                                                    <input type="number" className="form-control" placeholder="FAX Code" id="faxCode" value={this.state.faxCode} onChange={(e) => { this.setState({ faxCode: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">FAX No.</label>
                                                    <input type="number" className="form-control" placeholder="FAX No." id="fax" value={this.state.fax} onChange={(e) => { this.setState({ fax: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Skype ID</label>
                                                    <input type="text" className="form-control" placeholder="Skype ID" id="skypeId" value={this.state.skypeId} onChange={(e) => { this.setState({ skypeId: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">ICE ID</label>
                                                    <input type="text" className="form-control" placeholder="ICE ID" id="yahooId" value={this.state.yahooId} onChange={(e) => { this.setState({ yahooId: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Address</label>
                                                    <input type="text" className="form-control" placeholder="Address" id="add1" value={this.state.add1} onChange={(e) => { this.setState({ add1: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">City</label>
                                                    <input type="text" className="form-control" placeholder="City" id="city" value={this.state.city} onChange={(e) => { this.setState({ city: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">State</label>
                                                    <input type="text" className="form-control" placeholder="State" id="state" value={this.state.state} onChange={(e) => { this.setState({ state: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Postcode</label>
                                                    <input type="number" className="form-control" placeholder="Postcode" id="postcode" value={this.state.postcode} onChange={(e) => { this.setState({ postcode: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Country</label>
                                                    <select className="form-control" id="country" value={this.state.country} onChange={(e) => { this.setState({ country: e.target.value }); }}>
                                                        <option>Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Password</label>
                                                    <input type="number" className="form-control" placeholder="Password" id="postcode" value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value }); }} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">Confirm Pasword</label>
                                                    <input type="number" className="form-control" placeholder="Confirm Pasword" id="postcode" value={this.state.cpassword} onChange={(e) => { this.setState({ cpassword: e.target.value }); }} />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label className="font-weight-800">System Privileges</label>
                                                    <select className="form-control" id="systemPrivileges" value={this.state.systemPrivileges} onChange={(e) => { this.setState({ systemPrivileges: e.target.value }); }}>
                                                        <option>Select</option>
                                                        <option value="Administrator" >Administrator</option>
                                                        <option value="Broker" >Broker</option>
                                                    </select>
                                                </div>

                                                {this.state.systemPrivileges == "Broker" ?
                                                    <div className="form-group col-md-4">
                                                        <label className="font-weight-800">Organisation</label>
                                                        <select className="form-control" id="companies" value={this.state.CompanyId} onChange={(e) => { this.setState({ CompanyId: e.target.value }); }} onChange={this.onChangeCompany}>
                                                            <option>Select</option>
                                                            {this.state.BrockerComapanyList == null ? "" :
                                                                this.state.BrockerComapanyList.map(item => (
                                                                    <option value={item.id} >{item.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    : ""}

                                                {this.state.systemPrivileges == "Broker" ?
                                                    <div className="form-group col-md-4">
                                                        <label className="font-weight-800">Role</label>
                                                        <select className="form-control" id="rolenamelist" value={this.state.userroleName} onChange={(e) => { this.setState({ userroleName: e.target.value }); }}>
                                                            <option>Select</option>
                                                            {this.state.RoleList == null ? "" :
                                                                this.state.RoleList.map(item => (
                                                                    <option value={item.id}>{item.roleName}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <a href="javascript:void(0)" className="clsRole" data-toggle="modal" data-target=".add-role" >Add New Role</a>
                                                    </div>
                                                    : ""
                                                }

                                                <div className="form-group col-md-12 text-right">
                                                    <input type="hidden" value={this.state.id} id="AddID" />
                                                    <a type="button" className="btn btn-primary mb-2 mr-2" onClick={() => this.SaveCompanyDetail()}>Save</a>
                                                    <a href="/Contacts" type="button" className="btn btn-danger mb-2" >Reset</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade add-role" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                    <div className="modal-dialog modal-md model-role">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">Add New Role</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.BindUserDetails()}>
                                    <i className="ti-close" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className>
                                    <form className="row">
                                        <div className="form-group col-md-12">
                                            <label className="font-weight-800">Role Name</label>
                                            <input type="text" className="form-control" placeholder="Full Name" id="firstName" value={this.state.AddNewRole} onChange={(e) => { this.setState({ AddNewRole: e.target.value }); }} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer mb-2">
                                <a type="button" className="btn btn-primary mb-2 sweet-success" data-dismiss="modal" onClick={() => this.SaveRoleDetail()}>Save</a>
                                <button type="button" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.ResetRole()}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default ContactDetail;