import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import config from './config';
import swal from 'sweetalert';
import Navigation from './Navigation';
import { Link, Route, Switch } from "react-router-dom";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Autocomplete from 'react-autocomplete';
import { Multiselect } from 'multiselect-react-dropdown';

class AllTradeInvoices extends Component {

    constructor() {
        super();
        this.state = {
            AllTradeInvoiceList: [],
            CompanyList: [],
            InstrumentList: [],
            PeriodList: [],
            FilterPeriodList: [],
            FilterInstrumentList: [],
            PriceList: [],
            TotalVolumeList: [],
            SellerRateList: [],
            BuyerRateList: [],
            ERateList: [],
            InvoiceNoList: [],
            Company: "",
            BuyerSeller: "",
            // TradedStartDate: moment().date(-10).format("YYYY/MM/DD").toString(),
            // TradedEndDate: moment().format("YYYY/MM/DD").toString(),
            TradedStartDate: moment(new Date).subtract(30, 'days').startOf('day').format('YYYY-MM-DD'),
            TradedEndDate: moment(new Date).format('YYYY-MM-DD'),
            InstrumentName: null,
            PeriodName: null,
            Price: "",
            Volume: "",
            BuyerBroFees: "",
            SellerBroFees: "",
            InvoiceNo: "",
            CurrentPageNo: 1,
            PerPageCount: 50,
            SortColumn: "tradedDate",
            SortColumnType: "Desc",
            TotalCount: 0,
            MainCheckBox: false,
            Height: '0px',
            BankList: [],
            TradeList: [],
            CurrencyPairList: [],
            DistributionList: [],
            ToAddressList: [],
            Currency: "",
            BankID: 0,
            VatID: 0,
            CurrencyPairID: "0",
            CurrencyPairText: "",
            invoiceNo: "",
            CompanyName: "",
            CompanyAddress: "",
            ToCompanyName: "",
            ToCompanyAddress: "",
            BankCurrency: "",
            InvoiceDate: "",
            DueDate: "",
            TotalVAT: 0,
            VAT: "",
            currencyId: "0",
            NewTotalValue: 0,
            TotalValue: 0,
            CurrencySymbol: "",
            ExchangeCurrencySymbol: "",
            ExchangeRate: "0",
            ExchangeVAT: "",
            ExchangeTotal: "",
            Description: "",
            ExchangeCurrencyId: "",
            ExchangeCurrencyPair: "",
            SearchValue: '',
        }
    }

    onSearch = (event) => {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        this.setState(
            { SearchValue: event.target.value },
            function () {
                console.log(this.state.SearchValue);
                debugger;
                this.BindAllTradeInvoiceList();
            }
        );
    }

    handlePageChange = event => {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        this.setState(
            { CurrentPageNo: event },
            function () {
                console.log(this.state.CurrentPageNo);
                this.BindAllTradeInvoiceList();
            }
        );
    }

    handlePageCountChange = event => {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "none";
        this.setState(
            { PerPageCount: event },
            function () {
                console.log(this.state.PerPageCount);
                this.BindAllTradeInvoiceList();
            }
        );
    }

    handleColumnSort = event => {

        this.setState(
            { SortColumn: event.selector },
            function () {
                let SortType = "Desc";
                if (this.state.SortColumnType == "Desc") {
                    SortType = "Asc";
                }
                this.setState(
                    { SortColumnType: SortType },
                    function () {
                        console.log(this.state.SortColumnType);
                        console.log(this.state.SortColumn);
                        this.BindAllTradeInvoiceList();
                    }
                )
            }
        );
    }

    onSelectPeriod(selectedList, selectedItem) {

        this.setState(
            { FilterPeriodList: selectedList },
            function () {
                console.log(this.state.PeriodName);
                this.BindAllTradeInvoiceList();
            }
        );
    }

    onRemovePeriod(selectedList, removedItem) {
        this.setState(
            { FilterPeriodList: selectedList },
            function () {
                console.log(this.state.PeriodName);
                this.BindAllTradeInvoiceList();
            }
        );
    }

    onSelectInstrument(selectedList, selectedItem) {
        this.setState(
            { FilterInstrumentList: selectedList },
            function () {
                console.log(this.state.InstrumentName);
                this.BindAllTradeInvoiceList();
            }
        );
    }

    onRemoveInstrument(selectedList, removedItem) {
        this.setState(
            { FilterInstrumentList: selectedList },
            function () {
                console.log(this.state.InstrumentName);
                this.BindAllTradeInvoiceList();
            }
        );
    }

    handleOnSelectInstrument(value, item) {
        this.setState(
            { InstrumentValue: value },
            function () {
                console.log(this.state.InstrumentValue);
                this.setState(
                    { InstrumentName: item.text },
                    function () {
                        console.log(this.state.InstrumentName);
                        this.BindAllTradeInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangePeriod(event, value) {
        this.setState({ PeriodValue: "0" });
        this.setState(
            { PeriodName: value },
            function () {
                console.log(this.state.PeriodName);
                this.BindPeriodList();
                if (this.state.PeriodName == "" || this.state.PeriodName == null) {
                    this.BindAllTradeInvoiceList();
                }
            }
        );
    }

    handleOnSelectPeriod(value, item) {
        this.setState(
            { PeriodValue: value },
            function () {
                console.log(this.state.PeriodValue);
                this.setState(
                    { PeriodName: item.text },
                    function () {
                        console.log(this.state.PeriodName);
                        this.BindAllTradeInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangePrice(event, value) {
        this.setState({ PriceValue: "0" });
        this.setState(
            { Price: value },
            function () {
                console.log(this.state.Price);
                this.BindPriceList();
                if (this.state.Price == "" || this.state.Price == null) {
                    this.BindAllTradeInvoiceList();
                }
            }
        );
    }

    handleOnSelectPrice(value, item) {
        this.setState(
            { PriceValue: value },
            function () {
                console.log(this.state.PriceValue);
                this.setState(
                    { Price: item.text },
                    function () {
                        console.log(this.state.Price);
                        this.BindAllTradeInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangeVolume(event, value) {
        this.setState({ VolumeValue: "" });
        this.setState(
            { Volume: value },
            function () {
                console.log(this.state.Volume);
                this.BindTotalVolumeList();
                if (this.state.Volume == "" || this.state.Volume == null) {
                    this.BindAllTradeInvoiceList();
                }
            }
        );
    }

    handleOnSelectVolume(value, item) {
        this.setState(
            { VolumeValue: value },
            function () {
                console.log(this.state.VolumeValue);
                this.setState(
                    { Volume: item.text },
                    function () {
                        console.log(this.state.Volume);
                        this.BindAllTradeInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangeBuyerFees(event, value) {
        this.setState({ BuyerBroFeesValue: "0" });
        this.setState(
            { BuyerBroFees: value },
            function () {
                console.log(this.state.BuyerBroFees);
                this.BindBuyerRateList();
                if (this.state.BuyerBroFees == "" || this.state.BuyerBroFees == null) {
                    this.BindAllTradeInvoiceList();
                }
            }
        );
    }

    handleOnSelectBuyerFees(value, item) {
        this.setState(
            { BuyerBroFeesValue: value },
            function () {
                console.log(this.state.BuyerBroFeesValue);
                this.setState(
                    { BuyerBroFees: item.text },
                    function () {
                        console.log(this.state.BuyerBroFees);
                        this.BindAllTradeInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangeSellerFees(event, value) {
        this.setState({ SellerBroFeesValue: "0" });
        this.setState(
            { SellerBroFees: value },
            function () {
                console.log(this.state.SellerBroFees);
                this.BindSellerRateList();
                if (this.state.SellerBroFees == "" || this.state.SellerBroFees == null) {
                    this.BindAllTradeInvoiceList();
                }
            }
        );
    }

    handleOnSelectSellerFees(value, item) {
        this.setState(
            { SellerBroFeesValue: value },
            function () {
                console.log(this.state.SellerBroFeesValue);
                this.setState(
                    { SellerBroFees: item.text },
                    function () {
                        console.log(this.state.SellerBroFees);
                        this.BindAllTradeInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangeInvoiceNo(event, value) {
        this.setState({ InvoiceNoValue: "0" });
        this.setState(
            { InvoiceNo: value },
            function () {
                console.log(this.state.InvoiceNo);
                this.BindInvoiceNoList();
                if (this.state.InvoiceNo == "" || this.state.InvoiceNo == null) {
                    this.BindAllTradeInvoiceList();
                }
            }
        );
    }

    handleOnSelectInvoiceNo(value, item) {
        this.setState(
            { InvoiceNoValue: value },
            function () {
                console.log(this.state.InvoiceNoValue);
                this.setState(
                    { InvoiceNo: item.text },
                    function () {
                        console.log(this.state.InvoiceNo);
                        this.BindAllTradeInvoiceList();
                    }
                );
            }
        );
    }

    BindCompanyList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "GetAllCompany";
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ CompanyList: result })
                });
            }
        });
    }

    BindInstrumentList() {
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "invoice/CreateInvoice/FilterInstrument?commodityid=" + commodityId;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ InstrumentList: result })
                });
            }
        });
    }

    BindPeriodList() {
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "invoice/CreateInvoice/FilterPeriod?commodityid=" + commodityId;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ PeriodList: result })
                });
            }
        });
    }

    BindPriceList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.Price;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterPrice?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ PriceList: result })
                });
            }
        });
    }

    BindTotalVolumeList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.Volume;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterTotalVolume?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalVolumeList: result })
                });
            }
        });
    }

    BindBuyerRateList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.BuyerBroFees;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterBuyerRate?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ BuyerRateList: result })
                });
            }
        });
    }

    BindSellerRateList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.SellerBroFees;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterSellerRate?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ SellerRateList: result })
                });
            }
        });
    }

    BindInvoiceNoList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.InvoiceNo;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterInvoiceNo?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ InvoiceNoList: result })
                });
            }
        });
    }

    ResetFilter() {
        this.setState({ Company: "" });
        this.setState({ BuyerSeller: "" });
        this.setState({ TradedStartDate: "" });
        this.setState({ TradedEndDate: "" });
        this.setState({ InstrumentName: "" });
        this.setState({ PeriodName: "" });
        this.setState({ Price: "" });
        this.setState({ Volume: "" });
        this.setState({ BuyerBroFees: "" });
        this.setState({ SellerBroFees: "" });
        this.setState({ InvoiceNo: "" });
        this.setState({ CurrentPageNo: 1 });
        this.setState({ PerPageCount: 10 });
        this.setState({ SortColumn: "tradedDate" });
        this.setState({ SortColumnType: "Desc" });
        this.setState({ TotalCount: 0 });
        this.BindCreateInvoiceResetList();
    }

    handleAllChecked = (event) => {
        let tempAllTradeInvoiceList = this.state.AllTradeInvoiceList
        tempAllTradeInvoiceList.forEach(CreateInvoice => CreateInvoice.isChecked = event.target.checked)
        this.setState({ AllTradeInvoiceList: tempAllTradeInvoiceList })
        this.setState({ MainCheckBox: event.target.checked })
    }

    handleCheckChieldElement = (event) => {
        let tempAllTradeInvoiceList = this.state.AllTradeInvoiceList;
        let myCheckedCount = 0;
        tempAllTradeInvoiceList.forEach(CreateInvoice => {
            if (CreateInvoice.dealID == event.target.value) {
                CreateInvoice.isChecked = event.target.checked
            }

            if (CreateInvoice.isChecked)
                myCheckedCount++;
        })

        if (tempAllTradeInvoiceList.length == myCheckedCount)
            this.setState({ MainCheckBox: true })
        else
            this.setState({ MainCheckBox: false })

        this.setState({ AllTradeInvoiceList: tempAllTradeInvoiceList })
    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 250;
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }

    onChangeStartDate = event => {
        this.setState(
            { TradedStartDate: event.target.value },
            function () {
                console.log(this.state.TradedStartDate);
                this.BindAllTradeInvoiceList();
            }
        );
    };

    onChangeEndDate = event => {
        this.setState(
            { TradedEndDate: event.target.value },
            function () {
                console.log(this.state.TradedEndDate);
                this.BindAllTradeInvoiceList();
            }
        );
    };

    BindAllTradeInvoiceList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let CompanyId = this.state.Company;
        let BuyerSeller = this.state.BuyerSeller;
        let StartDate = this.state.TradedStartDate;
        let EndDate = this.state.TradedEndDate;
        let TradeType = "";
        let InstrumentName = this.state.InstrumentName;
        let PeriodName = this.state.PeriodName;
        let Price = this.state.Price;
        let TotalVolume = this.state.Volume;
        let BuyerBroFee = this.state.BuyerBroFees;
        let SellerBroFee = this.state.SellerBroFees;
        let InvoiceNo = this.state.InvoiceNo;
        let Period = 0;
        let Instrument = 0;
        debugger;
        if (CompanyId == "" || CompanyId == null || CompanyId == undefined) {
            CompanyId = "0";
        }

        if (BuyerSeller == "" || BuyerSeller == null || BuyerSeller == undefined) {
            BuyerSeller = "";
        }

        if (StartDate == "" || StartDate == null || StartDate == undefined) {
            StartDate = "";
        }

        if (EndDate == "" || EndDate == null || EndDate == undefined) {
            EndDate = "";
        }

        // if (InstrumentName == "" || InstrumentName == null || InstrumentName == undefined) {
        //     InstrumentName = "";
        // }

        // if (PeriodName == "" || PeriodName == null || PeriodName == undefined) {
        //     PeriodName = "";
        // }

        if (this.state.FilterInstrumentList != null && this.state.FilterInstrumentList.length > 0) {
            var myArray = [];

            this.state.FilterInstrumentList.map(item => (
                myArray.push(item.value)
            ));

            InstrumentName = myArray.toString();
        }

        if (this.state.FilterPeriodList != null && this.state.FilterPeriodList.length > 0) {
            // PeriodName = this.state.FilterPeriodList.toString();

            var myArray = [];

            this.state.FilterPeriodList.map(item => (
                myArray.push(item.value)
            ));

            PeriodName = myArray.toString();
        }

        if (Price == "" || Price == null || Price == undefined) {
            Price = "0";
        }

        if (TotalVolume == "" || TotalVolume == null || TotalVolume == undefined) {
            TotalVolume = "";
        }

        if (BuyerBroFee == "" || BuyerBroFee == null || BuyerBroFee == undefined) {
            BuyerBroFee = "0";
        }

        if (SellerBroFee == "" || SellerBroFee == null || SellerBroFee == undefined) {
            SellerBroFee = "0";
        }

        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Invoice/AllInvoiceList?commodityId=" + commodityId + "&CompanyId=" + CompanyId + "&StartDate=" + StartDate + "&EndDate=" + EndDate + "&Instrument=" + InstrumentName + "&Period=" + PeriodName + "&Price=" + Price + "&TotalVolume=" + TotalVolume + "&BuyerBroFee=" + BuyerBroFee + "&SellerBroFee=" + SellerBroFee + "&TradeType=" + TradeType + "&InvoiceNo=" + InvoiceNo + "&SortingColumnName=" + this.state.SortColumn + "&AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount + "&SearchQuery=" + this.state.SearchValue;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            let pageloaddiv = document.getElementById("pageloaddiv");
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ AllTradeInvoiceList: result.invoiceListModel });
                    this.setState({ TotalCount: result.totalRecord });
                    this.setState({ MainCheckBox: false })
                    this.setGridHeight();
                });
            } else {
                this.setState({ AllTradeInvoiceList: [] });
                this.setState({ TotalCount: 0 });
                this.setState({ MainCheckBox: false })
                this.setGridHeight();
            }
        });
    }

    BindCreateInvoiceResetList() {
        this.setState({ AllTradeInvoiceList: [] });
        this.setState({ TotalCount: 0 });
        this.setGridHeight();
        // let commodityId = parseInt(localStorage.getItem("CommodityID"));
        // let CompanyId = this.state.Company;
        // let BuyerSeller = this.state.BuyerSeller;
        // let StartDate = this.state.TradedStartDate;
        // let EndDate = this.state.TradedEndDate;
        // let TradeType = "";
        // let InstrumentName = this.state.InstrumentName;
        // let PeriodName = this.state.PeriodName;
        // let Price = this.state.Price;
        // let TotalVolume = this.state.Volume;
        // let BuyerBroFee = this.state.BuyerBroFees;
        // let SellerBroFee = this.state.SellerBroFees;
        // let Period = 0;
        // let Instrument = 0;
        // let SortingColumnName = "TradedStartDate";
        // let AscDesc = "Asc";
        // let PageNo = 1;
        // let PageSize = -1;

        // if (CompanyId == "" || CompanyId == null || CompanyId == undefined) {
        //     CompanyId = "0";
        // }

        // if (BuyerSeller == "" || BuyerSeller == null || BuyerSeller == undefined) {
        //     BuyerSeller = "";
        // }

        // if (StartDate == "" || StartDate == null || StartDate == undefined) {
        //     StartDate = "";
        // }

        // if (EndDate == "" || EndDate == null || EndDate == undefined) {
        //     EndDate = "";
        // }

        // if (InstrumentName == "" || InstrumentName == null || InstrumentName == undefined) {
        //     InstrumentName = "";
        // }

        // if (PeriodName == "" || PeriodName == null || PeriodName == undefined) {
        //     PeriodName = "";
        // }

        // if (Price == "" || Price == null || Price == undefined) {
        //     Price = "0";
        // }

        // if (TotalVolume == "" || TotalVolume == null || TotalVolume == undefined) {
        //     TotalVolume = "";
        // }

        // if (BuyerBroFee == "" || BuyerBroFee == null || BuyerBroFee == undefined) {
        //     BuyerBroFee = "0";
        // }

        // if (SellerBroFee == "" || SellerBroFee == null || SellerBroFee == undefined) {
        //     SellerBroFee = "0";
        // }

        // let FetchURL = config.apiUrl + "Invoice/AllTradeInvoiceList?commodityId=" + commodityId + "&CompanyId=" + CompanyId + "&StartDate=" + StartDate + "&EndDate=" + EndDate + "&Instrument=" + Instrument + "&Period=" + Period + "&Price=" + Price + "&TotalVolume=" + TotalVolume + "&BuyerBroFee=" + BuyerBroFee + "&SellerBroFee=" + SellerBroFee + "&TradeType=" + TradeType + "&SortingColumnName=" + SortingColumnName + "&AscDesc=" + AscDesc + "&PageNo=" + PageNo + "&PageSize=" + PageSize;
        // fetch(FetchURL, {
        //     method: 'GET',
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // }).then((resp) => {
        //     if (resp.status === 200) {
        //         resp.json().then((result) => {
        //             this.setState({ AllTradeInvoiceList: result });
        //             this.setGridHeight();
        //         });
        //     } else {
        //         this.setState({ AllTradeInvoiceList: [] });
        //         this.setGridHeight();
        //     }
        // });
    }

    SetToCompanyAddress = (event) => {
        this.setState({ ToCompanyAddress: event.target.value });
    }

    CalculateVAT = (event) => {
        this.setState({ VatID: event.target.value });
        let vat = parseInt(event.target.value);
        let total = parseFloat(this.state.TotalVAT);
        let exchangerate = this.state.ExchangeRate;
        let invoiceDate = this.state.InvoiceDate;
        let currencySymbol = this.state.CurrencySymbol;
        let currencyPair = this.state.CurrencyPairText;

        if (exchangerate == "" || exchangerate == null) {
            exchangerate = "0";
        }

        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Invoice/CalculateVat?total=" + total + "&vat=" + vat + "&exchangerate=" + exchangerate + "&invoiceDate=" + invoiceDate + "&currencyPair=" + currencyPair + "&currencySymbol=" + currencySymbol;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ VAT: result.vat });
                    this.setState({ NewTotalValue: result.vatTotal });
                    this.setState({ ExchangeVAT: result.exchangeRateVat });
                    this.setState({ ExchangeTotal: result.exchangeRateTotal });
                    this.setState({ Description: result.bodyText });
                });
            } else {
                var Total = this.state.TotalValue;
                this.setState({ VAT: 0 });
                this.setState({ NewTotalValue: Total });
                this.setState({ ExchangeVAT: 0 });
                this.setState({ ExchangeTotal: 0 });
                this.setState({ Description: "N/A" });
            }
        });
    }

    CalculateExchange = (event) => {
        this.setState({ ExchangeRate: event.target.value });
        let vat = parseInt(this.state.VatID);
        let total = parseFloat(this.state.TotalVAT);
        let exchangerate = event.target.value;
        let invoiceDate = this.state.InvoiceDate;
        let currencySymbol = this.state.CurrencySymbol;
        let currencyPair = this.state.CurrencyPairText;

        if (exchangerate == "" || exchangerate == null) {
            exchangerate = "0";
        }

        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Invoice/CalculateVat?total=" + total + "&vat=" + vat + "&exchangerate=" + exchangerate + "&invoiceDate=" + invoiceDate + "&currencyPair=" + currencyPair + "&currencySymbol=" + currencySymbol;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ ExchangeVAT: result.exchangeRateVat });
                    this.setState({ ExchangeTotal: result.exchangeRateTotal });
                    this.setState({ Description: result.bodyText });
                });
            } else {
                this.setState({ ExchangeVAT: 0 });
                this.setState({ ExchangeTotal: 0 });
                this.setState({ Description: "N/A" });
            }
        });
    }

    OnChangeCurrencyPair = (event) => {
        var index = event.nativeEvent.target.selectedIndex;
        var myText = event.nativeEvent.target[index].text;
        this.setState({ CurrencyPairID: event.target.value });
        this.setState({ CurrencyPairText: myText });

        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchCurrencyURL = config.apiUrl + "BrokerageRate/Currency/" + event.target.value;
        fetch(FetchCurrencyURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ ExchangeCurrencySymbol: result.symbol });
                    let vat = parseInt(this.state.VatID);
                    let total = parseFloat(this.state.TotalVAT);
                    let exchangerate = this.state.ExchangeRate;
                    let invoiceDate = this.state.InvoiceDate;
                    let currencySymbol = result.symbol;
                    let currencyPair = this.state.CurrencyPairText;

                    if (exchangerate == "" || exchangerate == null) {
                        exchangerate = "0";
                    }

                    var myLoginTokenNew = localStorage.getItem("AccessToken");
                    let FetchURL = config.apiUrl + "Invoice/CalculateVat?total=" + total + "&vat=" + vat + "&exchangerate=" + exchangerate + "&invoiceDate=" + invoiceDate + "&currencyPair=" + currencyPair + "&currencySymbol=" + currencySymbol;
                    fetch(FetchURL, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginTokenNew,
                            "loginuserid": localStorage.getItem("LogedID"),
                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                            "commodityid": localStorage.getItem("CommodityID")
                        },
                    }).then((resp) => {
                        if (resp.status === 200) {
                            resp.json().then((result) => {
                                debugger;
                                this.setState({ ExchangeVAT: result.exchangeRateVat });
                                this.setState({ ExchangeTotal: result.exchangeRateTotal });
                                this.setState({ Description: result.bodyText });
                            });
                        } else {
                            this.setState({ ExchangeVAT: 0 });
                            this.setState({ ExchangeTotal: 0 });
                            this.setState({ Description: "N/A" });
                        }
                    });
                });
            } else {
                this.setState({ ExchangeVAT: 0 });
                this.setState({ ExchangeTotal: 0 });
                this.setState({ Description: "N/A" });
            }
        });
    }

    ResetInvoicePopup() {
        this.setState({ TradeList: [] });
        this.setState({ CurrencyPairList: [] });
        this.setState({ ToAddressList: [] });
        this.setState({ BankList: [] });
        this.setState({ DistributionList: [] });
        this.setState({ BankID: "" });
        this.setState({ Currency: "" });
        this.setState({ InvoiceNo: "" });
        this.setState({ CompanyName: "" });
        this.setState({ CompanyAddress: "" });
        this.setState({ ToCompanyName: "" });
        this.setState({ ToCompanyAddress: "" });
        this.setState({ InvoiceDate: "" });
        this.setState({ DueDate: "" });
        this.setState({ TotalVAT: 0 });
        this.setState({ NewTotalValue: 0 });
        this.setState({ TotalValue: 0 });
        this.setState({ CurrencySymbol: "" });
        this.setState({ ExchangeCurrencySymbol: "" });
        this.setState({ VAT: "" });
        this.setState({ VATID: "0" });
        this.setState({ ExchangeRate: "" });
        this.setState({ ExchangeVAT: "" });
        this.setState({ ExchangeTotal: "" });
        this.setState({ Description: "N/A" });
    }

    BindPopupList = (event) => {
        this.ResetInvoicePopup();
        let CompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));

        let SelectedTradeInvoiceList = [];
        this.state.AllTradeInvoiceList.forEach(TradeInvoiceItem => {
            if (TradeInvoiceItem.isChecked)
                SelectedTradeInvoiceList.push(TradeInvoiceItem.id);
        })

        if (SelectedTradeInvoiceList.length > 0) {
            this.inputElement.click();
            let FetchURL = config.apiUrl + "Invoice/AddInvoicePopup";

            var paramData = {
                "LoginCompanyId": parseInt(CompanyID),
                "ToCompanyId": parseInt(this.state.Company),
                "tradeids": SelectedTradeInvoiceList,
                "commodityId": parseInt(CommodityID)
            };

            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(FetchURL, {
                method: 'POST',
                body: JSON.stringify(paramData),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((result) => {
                        debugger;
                        this.setState({ BankList: result.bankList });
                        this.setState({ TradeList: result.tradeList });
                        this.setState({ CurrencyPairList: result.currencyPairList });
                        this.setState({ Currency: result.currency });
                        this.setState({ InvoiceNo: result.invoiceNo });
                        this.setState({ CompanyName: result.loginCompanyName });
                        this.setState({ CompanyAddress: result.loginCompanyAddress });
                        this.setState({ ToCompanyName: result.toCompanyName });
                        this.setState({ InvoiceDate: result.invoiceDate });
                        this.setState({ DueDate: result.dueDate });
                        this.setState({ TotalVAT: result.total });
                        this.setState({ NewTotalValue: result.total });
                        this.setState({ TotalValue: result.total });
                        this.setState({ CurrencySymbol: result.currencySymbol });
                        this.setState({ ExchangeCurrencySymbol: result.currencySymbol });
                        this.setState({ currencyId: result.currencyId });
                        this.setState({ VAT: "" });
                        this.setState({ ExchangeRate: "" });
                        this.setState({ ExchangeVAT: "" });
                        this.setState({ ExchangeTotal: "" });

                        if (result.bankList != null) {
                            this.setState({ BankCurrency: result.bankList[0].accountType });
                            this.setState({ BankID: result.bankList[0].id });
                        }

                        if (result.toCompanyAddress != null) {
                            this.setState({ ToAddressList: result.toCompanyAddress });
                            this.setState({ ToCompanyAddress: result.toCompanyAddress[0].value });
                        }
                    });
                }
            });

            let FetchBankURL = config.apiUrl + "Company/" + this.state.Company + "/distributionlist/" + CommodityID + "/Invoice";

            fetch(FetchBankURL, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((result) => {
                        this.setState({ DistributionList: result })
                    });
                }
            });
        } else {
            swal({
                title: "Sorry!",
                text: "Please selct atleast one trade.",
                icon: "error",
                button: "okay",
            });
        }
    }

    SaveCreateInvoice() {
        let CompanyID = localStorage.getItem("EditCompanyID");
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        var AddURL = config.apiUrl + "invoice/AddInvoice";

        let Tradeids = [];
        this.state.AllTradeInvoiceList.forEach(TradeInvoiceItem => {
            if (TradeInvoiceItem.isChecked)
                Tradeids.push(TradeInvoiceItem.id);
        })

        var val = {
            loginCompanyId: parseInt(CompanyID),
            ToCompanyId: parseInt(this.state.Company),
            ToCompanyAddressId: parseInt(this.state.ToCompanyAddress),
            BankId: this.state.BankID,
            Tradeids: Tradeids,
            CommodityId: CommodityID,
            Total: this.state.TotalValue,
            InvoiceNo: this.state.InvoiceNo,
            InvoiceDate: this.state.InvoiceDate,
            DueDate: this.state.DueDate,
            CurrencyPair: this.state.CurrencyPairID,
            ExchangeRate: this.state.ExchangeRate,
            ExchangeVAT: this.state.ExchangeVAT,
            ExchangeTotal: this.state.ExchangeTotal.toString(),
            BodyText: this.state.Description,
            ExchangeCurrencyId: 0,
            ExchangeCurrencyPair: this.state.ExchangeTotal.toString(),
            Vat: this.state.VatID.toString(),
            CurrencyId: this.state.currencyId.toString(),
        }

        const data = JSON.stringify(val);
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(AddURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {
            if (resp.status === 200) {
                swal({
                    title: "Good job!",
                    text: "Invoice Created successfully",
                    icon: "success",
                    button: "Okay!",
                });
                this.BindAllTradeInvoiceList();
                // resp.json().then((results) => {
                //     if (results == true) {
                //         swal({
                //             title: "Good job!",
                //             text: "Invoice Created successfully",
                //             icon: "success",
                //             button: "Okay!",
                //         });
                //         this.BindAllTradeInvoiceList();
                //     } else {
                //         swal({
                //             title: "Sorry!",
                //             text: "Invoice was not Created successfully",
                //             icon: "error",
                //             button: "okay",
                //         });
                //     }
                // });
            } else {
                swal({
                    title: "Sorry!",
                    text: "Invoice was not Created successfully",
                    icon: "error",
                    button: "okay",
                });
            }
        });
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.BindCompanyList();
            this.BindInstrumentList();
            this.BindPeriodList();
            this.BindAllTradeInvoiceList();
        } else {
            this.props.history.push('/login');
        }
    }

    render() {
        var rows = [], i = -1, len = 30;
        while (++i <= len) rows.push(i);

        const columns = [
            // {
            //     name: <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" checked={this.state.MainCheckBox} />,
            //     selector: '',
            //     sortable: false,
            //     width: "50px",
            //     cell: item => <input key={item.dealID} onClick={this.handleCheckChieldElement} type="checkbox" checked={item.isChecked} value={item.dealID} />
            // },
            {
                name: 'Deal ID',
                selector: 'dealID',
                sortable: true,
                right: false,
                width: "100px",
            },
            {
                name: 'Trade Date',
                selector: 'tradedDate',
                sortable: true,
                right: false,
                width: "180px",
                cell: item => <span>{moment(item.tradedDate).format("DD/MM/YYYY HH:mm:ss")}</span>
            },
            {
                name: 'Instrument',
                selector: 'instrument',
                sortable: false,
                width: "180px",
            },
            {
                name: 'Period',
                selector: 'period',
                sortable: true,
                right: false,
                width: "150px",
            },
            {
                name: 'Volume',
                selector: 'volume',
                sortable: true,
                right: false,
                width: "90px",
            },
            {
                name: 'Total Volume',
                selector: 'totalVolume',
                sortable: true,
                right: false,
                width: "130px",
            },
            {
                name: 'Price',
                selector: 'price',
                sortable: true,
                right: false,
                width: "80px",
            },
            {
                name: 'Status',
                selector: '',
                sortable: false,
                width: "150px",
                cell: item => item.isBuyerInvoiceStatus == true ? <span className='badge badge-success rounded'>Invoiced</span> : <span className='badge badge-danger rounded'>Not Invoiced</span>
            },
            {
                name: 'Inv. Date',
                selector: 'buyerInvoiceDate',
                sortable: false,
                width: "180px",
                // cell: item => item.buyerInvoiceDate != null ? <span>{moment(item.buyerInvoiceDate).format("DD/MM/YYYY HH:mm:ss")}</span> : ""
            },
            {
                name: 'Due Date',
                selector: 'buyerDueDate',
                sortable: false,
                width: "180px",
                // cell: item => item.buyerDueDate != null ? <span>{moment(item.buyerDueDate).format("DD/MM/YYYY HH:mm:ss")}</span> : ""
            },
            {
                name: 'Invoice No',
                selector: 'buyerInvoiceNo',
                sortable: false,
                width: "150px",
            },
            {
                name: 'Buyer Bro Fee',
                selector: 'buyerBroFee',
                sortable: true,
                right: false,
                width: "130px",
            },
            {
                name: 'Buyer',
                selector: 'buyer',
                sortable: true,
                right: false,
                width: "200px",
            },
            {
                name: 'Seller',
                selector: 'seller',
                sortable: true,
                right: false,
                width: "200px",
            },
            {
                name: 'Seller Bro Fee',
                selector: 'sellerBroFee',
                sortable: true,
                right: false,
                width: "130px",
            },
            {
                name: 'Invoice No',
                selector: 'sellerInvoiceNo',
                sortable: false,
                width: "150px",
            },
            {
                name: 'Due Date',
                selector: 'sellerDueDate',
                sortable: false,
                width: "180px",
                // cell: item => item.sellerDueDate != null ? <span>{moment(item.sellerDueDate).format("DD/MM/YYYY HH:mm:ss")}</span> : ""
            },
            {
                name: 'Inv. Date',
                selector: 'sellerInvoiceDate',
                sortable: false,
                width: "180px",
                // cell: item => item.sellerInvoiceDate != null ? <span>{moment(item.sellerInvoiceDate).format("DD/MM/YYYY HH:mm:ss")}</span> : ""
            },
            {
                name: 'Status',
                selector: 'isSellerInvoiceStatus',
                sortable: false,
                width: "150px",
                cell: item => item.isSellerInvoiceStatus == true ? <span className='badge badge-success rounded'>Invoiced</span> : <span className='badge badge-danger rounded'>Not Invoiced</span>
            }
            // ,{
            //     name: 'Actions',
            //     selector: '',
            //     sortable: false,
            //     width: "120px",
            //     cell: row => <a type="button" className="btn btn-primary btn-sm mr-2" href={"/Trade-List-View?id=" + row.id}><i className="ti-eye mr-1" /> View</a>
            // }
        ];

        return (

            <div className="layout-wrapper">
                <div id="pageloaddiv" style={{ display: 'none' }}>
                    <span><img src="../assets/loader.gif" width={150} height={150} /></span>
                </div>
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />


                    <div className="content-body">
                        <div className="content ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <h3 className="mb-2">All Trade Invoices</h3>
                                                <div className="mb-2 ml-auto">
                                                <button type="button" className="btn btn-primary ml-2 mr-2" data-toggle="modal" data-target=".bd-example-modal-lg"> <i className="ti-filter mr-1" /> Filters</button>
                                                <input type="text" className="form-control clsSearchBar mb-3" placeholder="Search here..." onKeyUp={this.onSearch} value={this.state.SearchValue} onChange={(e) => { this.setState({ SearchValue: e.target.value }); }} />

                                                </div>
                                            </div>

                                            {/* <div className="table-responsive" style={{ maxHeight: this.state.Height }}> */}
                                            <div className="table-responsive">
                                                <DataTable
                                                    noDataComponent=""
                                                    columns={columns}
                                                    data={this.state.AllTradeInvoiceList}
                                                    pagination="true"
                                                    paginationServer
                                                    paginationPerPage={this.state.PerPageCount}
                                                    highlightOnHover={true}
                                                    paginationTotalRows={this.state.TotalCount}
                                                    paginationRowsPerPageOptions={["50", "100", "150", "200", this.state.TotalCount.toString()]}
                                                    onChangePage={this.handlePageChange}
                                                    onChangeRowsPerPage={this.handlePageCountChange}
                                                    onSort={this.handleColumnSort}
                                                />
                                            </div>
                                            {/* <div className="table-">
                                        <table className="table table-striped table-bordered" style={{ overflowX: 'auto', display: 'block' }}>
                                            <thead>
                                                <tr>
                                                    <th><input type="checkbox" id="chkSelectAllStatus" /></th>
                                                    <th>Deal ID</th>
                                                    <th>Traded Date</th>
                                                    <th>Instrument </th>
                                                    <th>Period</th>
                                                    <th>Volume</th>
                                                    <th>Total Volume</th>
                                                    <th>Price(MWH)</th>
                                                    <th>Status</th>
                                                    <th>Buyer Bro Fee</th>
                                                    <th>Buyer</th>
                                                    <th>Seller</th>
                                                    <th>Seller Bro Fee</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><input type="checkbox" id="chkSelectAllStatus" /></td>
                                                    <td>133238</td>
                                                    <td>31/08/2020 09:59:36</td>
                                                    <td>EEX Hungarian Base Fin Anon</td>
                                                    <td>Thu 21/05/20</td>
                                                    <td>20</td>
                                                    <td>480</td>
                                                    <td>19.00</td>
                                                    <td><span className="badge badge-success rounded">Invoiced</span></td>
                                                    <td>€ 0.00000</td>
                                                    <td>ARRACO GM Trader</td>
                                                    <td>EDF Trading Ltd</td>
                                                    <td>€ 0.000</td>
                                                    <td><span className="badge badge-danger rounded">Not Invoiced</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" id="chkSelectAllStatus" /></td>
                                                    <td>123458</td>
                                                    <td>31/08/2020 09:59:36</td>
                                                    <td>EEX Hungarian Base Fin Anon</td>
                                                    <td>Thu 21/05/20</td>
                                                    <td>20</td>
                                                    <td>480</td>
                                                    <td>19.00</td>
                                                    <td><span className="badge badge-danger rounded">Not Invoiced</span></td>
                                                    <td>€ 0.00000</td>
                                                    <td>ARRACO GM Trader</td>
                                                    <td>EDF Trading Ltd</td>
                                                    <td>€ 0.000</td>
                                                    <td><span className="badge badge-success rounded">Invoiced</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" id="chkSelectAllStatus" /></td>
                                                    <td>133238</td>
                                                    <td>31/08/2020 09:59:36</td>
                                                    <td>EEX Hungarian Base Fin Anon</td>
                                                    <td>Thu 21/05/20</td>
                                                    <td>20</td>
                                                    <td>480</td>
                                                    <td>19.00</td>
                                                    <td><span className="badge badge-success rounded">Invoiced</span></td>
                                                    <td>€ 0.00000</td>
                                                    <td>ARRACO GM Trader</td>
                                                    <td>EDF Trading Ltd</td>
                                                    <td>€ 0.000</td>
                                                    <td><span className="badge badge-danger rounded">Not Invoiced</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" id="chkSelectAllStatus" /></td>
                                                    <td>133238</td>
                                                    <td>31/08/2020 09:59:36</td>
                                                    <td>EEX Hungarian Base Fin Anon</td>
                                                    <td>Thu 21/05/20</td>
                                                    <td>20</td>
                                                    <td>480</td>
                                                    <td>19.00</td>
                                                    <td><span className="badge badge-success rounded">Invoiced</span></td>
                                                    <td>€ 0.00000</td>
                                                    <td>ARRACO GM Trader</td>
                                                    <td>EDF Trading Ltd</td>
                                                    <td>€ 0.000</td>
                                                    <td><span className="badge badge-danger rounded">Not Invoiced</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" id="chkSelectAllStatus" /></td>
                                                    <td>133238</td>
                                                    <td>31/08/2020 09:59:36</td>
                                                    <td>EEX Hungarian Base Fin Anon</td>
                                                    <td>Thu 21/05/20</td>
                                                    <td>20</td>
                                                    <td>480</td>
                                                    <td>19.00</td>
                                                    <td><span className="badge badge-danger rounded">Not Invoiced</span></td>
                                                    <td>€ 0.00000</td>
                                                    <td>ARRACO GM Trader</td>
                                                    <td>EDF Trading Ltd</td>
                                                    <td>€ 0.000</td>
                                                    <td><span className="badge badge-success rounded">Invoiced</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" id="chkSelectAllStatus" /></td>
                                                    <td>133238</td>
                                                    <td>31/08/2020 09:59:36</td>
                                                    <td>EEX Hungarian Base Fin Anon</td>
                                                    <td>Thu 21/05/20</td>
                                                    <td>20</td>
                                                    <td>480</td>
                                                    <td>19.00</td>
                                                    <td><span className="badge badge-success rounded">Invoiced</span></td>
                                                    <td>€ 0.00000</td>
                                                    <td>ARRACO GM Trader</td>
                                                    <td>EDF Trading Ltd</td>
                                                    <td>€ 0.000</td>
                                                    <td><span className="badge badge-danger rounded">Not Invoiced</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" id="chkSelectAllStatus" /></td>
                                                    <td>133238</td>
                                                    <td>31/08/2020 09:59:36</td>
                                                    <td>EEX Hungarian Base Fin Anon</td>
                                                    <td>Thu 21/05/20</td>
                                                    <td>20</td>
                                                    <td>480</td>
                                                    <td>19.00</td>
                                                    <td><span className="badge badge-success rounded">Invoiced</span></td>
                                                    <td>€ 0.00000</td>
                                                    <td>ARRACO GM Trader</td>
                                                    <td>EDF Trading Ltd</td>
                                                    <td>€ 0.000</td>
                                                    <td><span className="badge badge-success rounded">Invoiced</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" id="chkSelectAllStatus" /></td>
                                                    <td>133238</td>
                                                    <td>31/08/2020 09:59:36</td>
                                                    <td>EEX Hungarian Base Fin Anon</td>
                                                    <td>Thu 21/05/20</td>
                                                    <td>20</td>
                                                    <td>480</td>
                                                    <td>19.00</td>
                                                    <td><span className="badge badge-danger rounded">Not Invoiced</span></td>
                                                    <td>€ 0.00000</td>
                                                    <td>ARRACO GM Trader</td>
                                                    <td>EDF Trading Ltd</td>
                                                    <td>€ 0.000</td>
                                                    <td><span className="badge badge-danger rounded">Not Invoiced</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" id="chkSelectAllStatus" /></td>
                                                    <td>133238</td>
                                                    <td>31/08/2020 09:59:36</td>
                                                    <td>EEX Hungarian Base Fin Anon</td>
                                                    <td>Thu 21/05/20</td>
                                                    <td>20</td>
                                                    <td>480</td>
                                                    <td>19.00</td>
                                                    <td><span className="badge badge-success rounded">Invoiced</span></td>
                                                    <td>€ 0.00000</td>
                                                    <td>ARRACO GM Trader</td>
                                                    <td>EDF Trading Ltd</td>
                                                    <td>€ 0.000</td>
                                                    <td><span className="badge badge-success rounded">Invoiced</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="checkbox" id="chkSelectAllStatus" /></td>
                                                    <td>133238</td>
                                                    <td>31/08/2020 09:59:36</td>
                                                    <td>EEX Hungarian Base Fin Anon</td>
                                                    <td>Thu 21/05/20</td>
                                                    <td>20</td>
                                                    <td>480</td>
                                                    <td>19.00</td>
                                                    <td><span className="badge badge-danger rounded">Not Invoiced</span></td>
                                                    <td>€ 0.00000</td>
                                                    <td>ARRACO GM Trader</td>
                                                    <td>EDF Trading Ltd</td>
                                                    <td>€ 0.000</td>
                                                    <td><span className="badge badge-danger rounded">Not Invoiced</span></td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ./ content */}
                        <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Search Filters</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-md-12">
                                            <form className="row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1 " className="font-weight-800">Company</label>
                                                    <select className="form-control" value={this.state.Company} onChange={(e) => { this.setState({ Company: e.target.value }); }} onClick={() => this.BindAllTradeInvoiceList()} >
                                                        <option value=''>Select</option>
                                                        {this.state.CompanyList == null ? "" :
                                                            this.state.CompanyList.map(item => (
                                                                <option value={item.id} >{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Buyer/Seller</label>
                                                    <select className="form-control" value={this.state.BuyerSeller} onChange={(e) => { this.setState({ BuyerSeller: e.target.value }); }} onClick={() => this.BindAllTradeInvoiceList()}>
                                                        <option value=''>Select</option>
                                                        <option value='buyer'>Buyer</option>
                                                        <option value='seller'>Seller</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Traded Date (Start)</label>
                                                    {/* <input type="date" name="daterangepicker" className="form-control" value={this.state.TradedStartDate} onChange={(e) => { this.setState({ TradedStartDate: e.target.value }); }} onKeyUp={() => this.BindCreateInvoiceList()} /> */}
                                                    <input type="date" name="daterangepicker" className="form-control" value={this.state.TradedStartDate} onChange={this.onChangeStartDate} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Traded Date (End)</label>
                                                    {/* <input type="date" name="daterangepicker" className="form-control" value={this.state.TradedEndDate} onChange={(e) => { this.setState({ TradedEndDate: e.target.value }); }} onKeyUp={() => this.BindCreateInvoiceList()} onBlur={() => this.BindCreateInvoiceList()} /> */}
                                                    <input type="date" name="daterangepicker" className="form-control" value={this.state.TradedEndDate} onChange={this.onChangeEndDate} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Instrument</label>
                                                    {/* <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Instrument" value={this.state.InstrumentName} onChange={(e) => { this.setState({ InstrumentName: e.target.value }); }} onKeyUp={() => this.BindAllTradeInvoiceList()} /> */}
                                                    <div>
                                                        <Multiselect
                                                            options={this.state.InstrumentList}
                                                            selectedValues={this.state.InstrumentName}
                                                            onSelect={(value, item) => {
                                                                this.onSelectInstrument(value, item);
                                                            }}
                                                            onRemove={(value, item) => {
                                                                this.onRemoveInstrument(value, item);
                                                            }}
                                                            displayValue="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Period</label>
                                                    {/* <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Period" value={this.state.PeriodName} onChange={(e) => { this.setState({ PeriodName: e.target.value }); }} onKeyUp={() => this.BindAllTradeInvoiceList()} /> */}
                                                    <div>
                                                        <Multiselect
                                                            options={this.state.PeriodList}
                                                            selectedValues={this.state.PeriodName}
                                                            onSelect={(value, item) => {
                                                                this.onSelectPeriod(value, item);
                                                            }}
                                                            onRemove={(value, item) => {
                                                                this.onRemovePeriod(value, item);
                                                            }}
                                                            displayValue="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Price</label>
                                                    {/* <input type="number" className="form-control" id="exampleInputPassword1" placeholder="Price" value={this.state.Price} onChange={(e) => { this.setState({ Price: e.target.value }); }} onKeyUp={() => this.BindAllTradeInvoiceList()} /> */}
                                                    <div className="autosearch-div">
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-Price' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.Price}
                                                            items={this.state.PriceList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectPrice(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangePrice(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Total Volume</label>
                                                    {/* <input type="number" className="form-control" id="exampleInputPassword1" placeholder="Total Volume" value={this.state.Volume} onChange={(e) => { this.setState({ Volume: e.target.value }); }} onKeyUp={() => this.BindAllTradeInvoiceList()} /> */}
                                                    <div className="autosearch-div">
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-Volume' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.Volume}
                                                            items={this.state.TotalVolumeList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectVolume(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangeVolume(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Buyer Bro. Fee</label>
                                                    {/* <input type="number" className="form-control" id="exampleInputPassword1" placeholder="Buyer Bro. Fee" value={this.state.BuyerBroFees} onChange={(e) => { this.setState({ BuyerBroFees: e.target.value }); }} onKeyUp={() => this.BindAllTradeInvoiceList()} /> */}
                                                    <div className="autosearch-div">
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-BuyerBroFees' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.BuyerBroFees}
                                                            items={this.state.BuyerRateList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectBuyerFees(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangeBuyerFees(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Seller Bro. Fee</label>
                                                    {/* <input type="number" className="form-control" id="exampleInputPassword1" placeholder="Seller  Bro. Fee" value={this.state.SellerBroFees} onChange={(e) => { this.setState({ SellerBroFees: e.target.value }); }} onKeyUp={() => this.BindAllTradeInvoiceList()} /> */}
                                                    <div className="autosearch-div">
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-SellerBroFees' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.SellerBroFees}
                                                            items={this.state.SellerRateList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectSellerFees(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangeSellerFees(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Invoice No</label>
                                                    {/* <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Invoice No" value={this.state.InvoiceNo} onChange={(e) => { this.setState({ InvoiceNo: e.target.value }); }} onKeyUp={() => this.BindAllTradeInvoiceList()} /> */}
                                                    <div className="autosearch-div">
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-InvoiceNo' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.InvoiceNo}
                                                            items={this.state.InvoiceNoList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectInvoiceNo(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangeInvoiceNo(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary mb-2" data-dismiss="modal" onClick={() => this.ResetFilter()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default AllTradeInvoices;