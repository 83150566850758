import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import config from './config';
import swal from 'sweetalert';
import { Link, Route, Switch } from "react-router-dom";
import queryString from 'query-string';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Autocomplete from 'react-autocomplete';
import { Multiselect } from 'multiselect-react-dropdown';

class CreateInvoice extends Component {

    constructor() {
        super();
        this.state = {
            CreateInvoiceList: [],
            CompanyList: [],
            InstrumentList: [],
            PeriodList: [],
            FilterPeriodList: [],
            FilterInstrumentList: [],
            PriceList: [],
            TotalVolumeList: [],
            SellerRateList: [],
            BuyerRateList: [],
            ERateList: [],
            Company: "0",
            BuyerSeller: "",
            //TradedStartDate: moment(new Date).add(-1, 'M').format("YYYY-MM-DD"),
            //TradedEndDate: moment(new Date).add(1, 'M').format("YYYY-MM-DD"),
            TradedStartDate: moment(new Date).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            TradedEndDate: moment(new Date).subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
            InstrumentName: "",
            PeriodName: "",
            Price: "",
            Volume: "",
            BuyerBroFees: "",
            SellerBroFees: "",
            InstrumentValue: "0",
            PeriodValue: "0",
            PriceValue: "0",
            VolumeValue: "",
            BuyerBroFeesValue: "0",
            SellerBroFeesValue: "0",
            MainCheckBox: false,
            Height: '0px',
            BankList: [],
            TradeList: [],
            CurrencyPairList: [],
            DistributionList: [],
            ToAddressList: [],
            Currency: "",
            BankID: 0,
            VatID: 0,
            CurrencyPairID: "0",
            CurrencyPairText: "",
            invoiceNo: "",
            CompanyName: "",
            CompanyAddress: "",
            ToCompanyName: "",
            ToCompanyAddress: "",
            BankCurrency: "",
            InvoiceDate: "",
            DueDate: "",
            TotalVAT: 0,
            VAT: "",
            currencyId: "0",
            NewTotalValue: 0,
            TotalValue: 0,
            CurrencySymbol: "",
            ExchangeCurrencySymbol: "",
            ExchangeRate: "0",
            ExchangeVAT: "",
            ExchangeTotal: "",
            Description: "",
            ExchangeCurrencyId: "",
            ExchangeCurrencyPair: "",
            CurrentPageNo: 1,
            PerPageCount: 50,
            SortColumn: "tradedDate",
            SortColumnType: "Desc",
            TotalCount: 0,
            ExchangeText: "",
            isFirst: true,
        }
    }

    handlePageChange = event => {
        this.setState(
            { CurrentPageNo: event },
            function () {
                console.log(this.state.CurrentPageNo);
                this.BindCreateInvoiceList();
            }
        );
    }

    handlePageCountChange = event => {
        this.setState(
            { PerPageCount: event },
            function () {
                console.log(this.state.PerPageCount);
                this.BindCreateInvoiceList();
            }
        );
    }

    handleColumnSort = event => {
        this.setState(
            { SortColumn: event.selector },
            function () {
                let SortType = "Desc";
                if (this.state.SortColumnType == "Desc") {
                    SortType = "Asc";
                }

                this.setState(
                    { SortColumnType: SortType },
                    function () {
                        console.log(this.state.SortColumnType);
                        console.log(this.state.SortColumn);
                        this.BindCreateInvoiceList();
                    }
                )
            }
        );
    }

    onSelectPeriod(selectedList, selectedItem) {
        this.setState(
            { FilterPeriodList: selectedList },
            function () {
                console.log(this.state.PeriodName);
                this.BindCreateInvoiceList();
            }
        );
    }

    onRemovePeriod(selectedList, removedItem) {
        this.setState(
            { FilterPeriodList: selectedList },
            function () {
                console.log(this.state.PeriodName);
                this.BindCreateInvoiceList();
            }
        );
    }

    onSelectInstrument(selectedList, selectedItem) {
        this.setState(
            { FilterInstrumentList: selectedList },
            function () {
                console.log(this.state.InstrumentName);
                this.BindCreateInvoiceList();
            }
        );
    }

    onRemoveInstrument(selectedList, removedItem) {
        this.setState(
            { FilterInstrumentList: selectedList },
            function () {
                console.log(this.state.InstrumentName);
                this.BindCreateInvoiceList();
            }
        );
    }

    handleOnSelectInstrument(value, item) {
        this.setState(
            { InstrumentValue: value },
            function () {
                console.log(this.state.InstrumentValue);
                this.setState(
                    { InstrumentName: item.text },
                    function () {
                        console.log(this.state.InstrumentName);
                        this.BindCreateInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangePeriod(event, value) {
        this.setState({ PeriodValue: "0" });
        this.setState(
            { PeriodName: value },
            function () {
                console.log(this.state.PeriodName);
                this.BindPeriodList();
                if (this.state.PeriodName == "" || this.state.PeriodName == null) {
                    this.BindCreateInvoiceList();
                }
            }
        );
    }

    handleOnSelectPeriod(value, item) {
        this.setState(
            { PeriodValue: value },
            function () {
                console.log(this.state.PeriodValue);
                this.setState(
                    { PeriodName: item.text },
                    function () {
                        console.log(this.state.PeriodName);
                        this.BindCreateInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangePrice(event, value) {
        this.setState({ PriceValue: "0" });
        this.setState(
            { Price: value },
            function () {
                console.log(this.state.Price);
                this.BindPriceList();
                if (this.state.Price == "" || this.state.Price == null) {
                    this.BindCreateInvoiceList();
                }
            }
        );
    }

    handleOnSelectPrice(value, item) {
        this.setState(
            { PriceValue: value },
            function () {
                console.log(this.state.PriceValue);
                this.setState(
                    { Price: item.text },
                    function () {
                        console.log(this.state.Price);
                        this.BindCreateInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangeVolume(event, value) {
        this.setState({ VolumeValue: "" });
        this.setState(
            { Volume: value },
            function () {
                console.log(this.state.Volume);
                this.BindTotalVolumeList();
                if (this.state.Volume == "" || this.state.Volume == null) {
                    this.BindCreateInvoiceList();
                }
            }
        );
    }

    handleOnSelectVolume(value, item) {
        this.setState(
            { VolumeValue: value },
            function () {
                console.log(this.state.VolumeValue);
                this.setState(
                    { Volume: item.text },
                    function () {
                        console.log(this.state.Volume);
                        this.BindCreateInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangeBuyerFees(event, value) {
        this.setState({ BuyerBroFeesValue: "0" });
        this.setState(
            { BuyerBroFees: value },
            function () {
                console.log(this.state.BuyerBroFees);
                this.BindBuyerRateList();
                if (this.state.BuyerBroFees == "" || this.state.BuyerBroFees == null) {
                    this.BindCreateInvoiceList();
                }
            }
        );
    }

    handleOnSelectBuyerFees(value, item) {
        this.setState(
            { BuyerBroFeesValue: value },
            function () {
                console.log(this.state.BuyerBroFeesValue);
                this.setState(
                    { BuyerBroFees: item.text },
                    function () {
                        console.log(this.state.BuyerBroFees);
                        this.BindCreateInvoiceList();
                    }
                );
            }
        );
    }

    handleOnChangeSellerFees(event, value) {
        this.setState({ SellerBroFeesValue: "0" });
        this.setState(
            { SellerBroFees: value },
            function () {
                console.log(this.state.SellerBroFees);
                this.BindSellerRateList();
                if (this.state.SellerBroFees == "" || this.state.SellerBroFees == null) {
                    this.BindCreateInvoiceList();
                }
            }
        );
    }

    handleOnSelectSellerFees(value, item) {
        this.setState(
            { SellerBroFeesValue: value },
            function () {
                console.log(this.state.SellerBroFeesValue);
                this.setState(
                    { SellerBroFees: item.text },
                    function () {
                        console.log(this.state.SellerBroFees);
                        this.BindCreateInvoiceList();
                    }
                );
            }
        );
    }

    handleChangeBankDetail = event => {
        let NewID = event.target.value;
        let BankList = this.state.BankList;

        this.setState(
            { BankID: NewID },
            function () {
                console.log(this.state.BankID);
                let Name = "";
                BankList.forEach(function (item) {
                    if (item.id == NewID) {
                        Name = item.accountTypeName;
                    }
                });

                this.setState(
                    { BankCurrency: Name },
                    function () {
                        console.log(this.state.BankCurrency);
                    }
                );
            }
        );
    }

    ChangeERate() {
        let ToCompID = this.state.Company;
        let ToCompAdd = this.state.ToCompanyAddress;
        var InvDate = moment(this.state.InvoiceDate);
        let Month = InvDate.format('MM');
        let Year = InvDate.format('YYYY');

        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchERateURL = config.apiUrl + "invoice/FindVatAndRate?companyId=" + ToCompID + "&addressId=" + ToCompAdd + "&month=" + Month + "&year=" + Year;
        fetch(FetchERateURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    if (result.vat == 0) {
                        this.setState({ VatID: result.vat });
                        this.setState({ ExchangeRate: "" });
                        this.setState({ CurrencyPairID: "0" });
                        this.setState({ ExchangeVAT: "" });
                        this.setState({ ExchangeTotal: "" });
                        this.setState({ ExchangeText: "" });
                        this.setState({ Description: "N/A" });
                    } else {
                        this.setState({ VatID: result.vat });
                        this.setState({ ExchangeRate: result.rate });
                        this.setState({ CurrencyPairID: "3" });
                    }

                    this.setState({ ExchangeText: result.hmrcratetext });

                    // Call below
                    // CalculateVAT
                    // OnChangeCurrencyPair
                    this.CallVATManually();
                    this.CalculateVATWhenBindWithValue(result.vat);
                });
            }
        });
    }

    CallVATManually() {
        let vat = parseInt(this.state.VATID);
        let total = parseFloat(this.state.TotalVAT);
        let exchangerate = this.state.ExchangeRate;
        let invoiceDate = this.state.InvoiceDate;
        let currencySymbol = this.state.CurrencySymbol;
        let currencyPair = this.state.CurrencyPairText;

        if (exchangerate == "" || exchangerate == null) {
            exchangerate = "0";
        }

        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURLVAT = config.apiUrl + "Invoice/CalculateVat?total=" + total + "&vat=" + vat + "&exchangerate=" + exchangerate + "&invoiceDate=" + invoiceDate + "&currencyPair=" + currencyPair + "&currencySymbol=" + currencySymbol;
        fetch(FetchURLVAT, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ VAT: result.vat });
                    this.setState({ NewTotalValue: result.vatTotal });
                    this.setState({ ExchangeVAT: result.exchangeRateVat });
                    this.setState({ ExchangeTotal: result.exchangeRateTotal });
                    this.setState({ Description: result.bodyText });
                    this.CallERateManually();
                });
            } else {
                var Total = this.state.TotalValue;
                this.setState({ VAT: 0 });
                this.setState({ NewTotalValue: Total });
                this.setState({ ExchangeVAT: 0 });
                this.setState({ ExchangeTotal: 0 });
                this.setState({ Description: "N/A" });
                this.CallERateManually();
            }
        });
    }

    CallERateManually() {
        var index = 0;
        var myText = "";
        let currencyPair1 = this.state.CurrencyPairText;

        this.state.CurrencyPairList.forEach(function (item) {
            if (item.value == currencyPair1) {
                index = item.value;
                myText = item.text;
            }
        });

        this.setState({ CurrencyPairID: index });
        this.setState({ CurrencyPairText: myText });
        if (index != 0) {
            var myLoginToken = localStorage.getItem("AccessToken");
            let FetchCurrencyURL = config.apiUrl + "BrokerageRate/Currency/" + index;
            fetch(FetchCurrencyURL, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((result) => {
                        this.setState({ ExchangeCurrencySymbol: result.symbol });
                        let vat = parseInt(this.state.VatID);
                        let total = parseFloat(this.state.TotalVAT);
                        let exchangerate = this.state.ExchangeRate;
                        let invoiceDate = this.state.InvoiceDate;
                        let currencySymbol = result.symbol;
                        let currencyPair = this.state.CurrencyPairText;

                        if (exchangerate == "" || exchangerate == null) {
                            exchangerate = "0";
                        }

                        let FetchURL = config.apiUrl + "Invoice/CalculateVat?total=" + total + "&vat=" + vat + "&exchangerate=" + exchangerate + "&invoiceDate=" + invoiceDate + "&currencyPair=" + currencyPair + "&currencySymbol=" + currencySymbol;
                        fetch(FetchURL, {
                            method: 'GET',
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + myLoginToken,
                                "loginuserid": localStorage.getItem("LogedID"),
                                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                                "commodityid": localStorage.getItem("CommodityID")
                            },
                        }).then((resp) => {
                            if (resp.status === 200) {
                                resp.json().then((result) => {
                                    this.setState({ ExchangeVAT: result.exchangeRateVat });
                                    this.setState({ ExchangeTotal: result.exchangeRateTotal });
                                    this.setState({ Description: result.bodyText });
                                });
                            } else {
                                this.setState({ ExchangeVAT: 0 });
                                this.setState({ ExchangeTotal: 0 });
                                this.setState({ Description: "N/A" });
                            }
                        });
                    });
                } else {
                    this.setState({ ExchangeVAT: 0 });
                    this.setState({ ExchangeTotal: 0 });
                    this.setState({ Description: "N/A" });
                }
            });
        }



    }

    BindCompanyList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let FetchURL = config.apiUrl + "invoice/CreateInvoice/FilterCompany?commodityid=" + commodityId;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ CompanyList: result })
                });
            }
        });
    }

    BindInstrumentList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.InstrumentName;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterInstrument?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ InstrumentList: result });
                });
            }
        });
    }

    BindPeriodList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.PeriodName;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterPeriod?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ PeriodList: result })
                });
            }
        });
    }

    BindPriceList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.Price;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterPrice?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ PriceList: result })
                });
            }
        });
    }

    BindTotalVolumeList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.Volume;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterTotalVolume?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalVolumeList: result })
                });
            }
        });
    }

    BindBuyerRateList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.BuyerBroFees;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterBuyerRate?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ BuyerRateList: result })
                });
            }
        });
    }

    BindSellerRateList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let search = this.state.SellerBroFees;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterSellerRate?commodityid=" + commodityId + "&search=" + search;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ SellerRateList: result })
                });
            }
        });
    }

    ResetFilter() {
        // this.setState({ Company: "" });
        // this.setState({ BuyerSeller: "" });
        // this.setState({ TradedStartDate: moment(new Date).subtract(1, 'months').startOf('month').format('YYYY-MM-DD') });
        // this.setState({ TradedEndDate: moment(new Date).subtract(1, 'months').endOf('month').format('YYYY-MM-DD') });
        // this.setState({ InstrumentName: "" });
        // this.setState({ PeriodName: "" });
        // this.setState({ Price: "" });
        // this.setState({ Volume: "" });
        // this.setState({ BuyerBroFees: "" });
        // this.setState({ SellerBroFees: "" });
        // this.setState({ InstrumentValue: "0" });
        // this.setState({ PeriodValue: "0" });
        // this.setState({ PriceValue: "0" });
        // this.setState({ VolumeValue: "" });
        // this.setState({ BuyerBroFeesValue: "0" });
        // this.setState({ SellerBroFeesValue: "0" });
        // this.BindCreateInvoiceResetList();
    }

    handleAllChecked = (event) => {
        let tempCreateInvoiceList = this.state.CreateInvoiceList
        tempCreateInvoiceList.forEach(CreateInvoice => CreateInvoice.isChecked = event.target.checked)
        this.setState({ CreateInvoiceList: tempCreateInvoiceList })
        this.setState({ MainCheckBox: event.target.checked })
    }

    handleCheckChieldElement = (event) => {
        let tempCreateInvoiceList = this.state.CreateInvoiceList;
        let myCheckedCount = 0;
        tempCreateInvoiceList.forEach(CreateInvoice => {
            if (CreateInvoice.dealID == event.target.value) {
                CreateInvoice.isChecked = event.target.checked
            }

            if (CreateInvoice.isChecked)
                myCheckedCount++;
        })

        if (tempCreateInvoiceList.length == myCheckedCount)
            this.setState({ MainCheckBox: true })
        else
            this.setState({ MainCheckBox: false })

        this.setState({ CreateInvoiceList: tempCreateInvoiceList })
    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 250;
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }

    onChangeStartDate = event => {
        this.setState(
            { TradedStartDate: event.target.value },
            function () {
                console.log(this.state.TradedStartDate);
                this.BindCreateInvoiceList();
            }
        );
    };

    onChangeEndDate = event => {
        this.setState(
            { TradedEndDate: event.target.value },
            function () {
                console.log(this.state.TradedEndDate);
                this.BindCreateInvoiceList();
            }
        );
    };

    onChangeCompanyDropdown = (event) => {
        this.setState(
            { Company: event.target.value },
            function () {
                this.BindCreateInvoiceList();
            }
        );
    }

    onChangeBuyerSellerDropdown = (event) => {
        this.setState(
            { BuyerSeller: event.target.value },
            function () {
                this.BindCreateInvoiceList();
            }
        );
    }

    BindCreateInvoiceList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let CompanyId = parseInt(this.state.Company);
        let BuyerSeller = this.state.BuyerSeller;
        let StartDate = this.state.TradedStartDate;
        let EndDate = this.state.TradedEndDate;
        let TradeType = "";
        let InstrumentName = parseInt(this.state.InstrumentValue);
        let PeriodName = parseInt(this.state.PeriodValue);
        let Price = parseInt(this.state.PriceValue);
        let TotalVolume = this.state.Volume.toString();
        let BuyerBroFee = parseInt(this.state.BuyerBroFeesValue);
        let SellerBroFee = parseInt(this.state.SellerBroFeesValue);
        // let Period = 0;
        // let Instrument = 0;
        // let SortingColumnName = "TradedStartDate";
        // let AscDesc = "Asc";
        // let PageNo = 1;
        // let PageSize = -1;

        if (BuyerSeller == "" || BuyerSeller == null || BuyerSeller == undefined) {
            BuyerSeller = "";
        }

        if (StartDate == "" || StartDate == null || StartDate == undefined) {
            StartDate = "";
        }

        if (EndDate == "" || EndDate == null || EndDate == undefined) {
            EndDate = "";
        }
        if (this.state.FilterInstrumentList != null && this.state.FilterInstrumentList.length > 0) {
            var myArray = [];

            this.state.FilterInstrumentList.map(item => (
                myArray.push(item.value)
            ));

            InstrumentName = myArray.toString();
        }

        if (this.state.FilterPeriodList != null && this.state.FilterPeriodList.length > 0) {
            // PeriodName = this.state.FilterPeriodList.toString();

            var myArray = [];

            this.state.FilterPeriodList.map(item => (
                myArray.push(item.value)
            ));

            PeriodName = myArray.toString();
        }

        var myLoginToken = localStorage.getItem("AccessToken");
        // let FetchURL = config.apiUrl + "Invoice/CreateInvoiceList?commodityId=" + commodityId + "&CompanyId=" + CompanyId + "&StartDate=" + StartDate + "&EndDate=" + EndDate + "&Instrument=" + InstrumentName + "&Period=" + PeriodName + "&Price=" + Price + "&TotalVolume=" + TotalVolume + "&BuyerBroFee=" + BuyerBroFee + "&SellerBroFee=" + SellerBroFee + "&TradeType=" + TradeType + "&SortingColumnName=" + SortingColumnName + "&AscDesc=" + AscDesc + "&PageNo=" + PageNo + "&PageSize=" + PageSize;
        let FetchURL = config.apiUrl + "Invoice/CreateInvoiceList?commodityId=" + commodityId + "&CompanyId=" + CompanyId + "&StartDate=" + StartDate + "&EndDate=" + EndDate + "&Instrument=" + InstrumentName + "&Period=" + PeriodName + "&Price=" + Price + "&TotalVolume=" + TotalVolume + "&BuyerBroFee=" + BuyerBroFee + "&SellerBroFee=" + SellerBroFee + "&TradeType=" + TradeType + "&SortingColumnName=" + this.state.SortColumn + "&AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalCount: result.totalRecord });
                    // this.setState({ PerPageCount: result.totalRecord });
                    this.setState({ CreateInvoiceList: result.invoiceListModel });
                    this.setState({ MainCheckBox: false });
                    this.setGridHeight();
                    // if (this.state.isFirst === true) {
                    //     this.setState({ isFirst: false });
                    //     this.bindAllData(result.totalRecord);
                    // }
                });
            } else {
                this.setState({ CreateInvoiceList: [] });
                this.setState({ TotalCount: 0 });
                this.setState({ MainCheckBox: false });
                this.setGridHeight();
            }
        });
    }

    bindAllData(count) {
        this.setState(
            { PerPageCount: count },
            function () {
                this.BindCreateInvoiceList();
            }
        );
    }

    BindCreateInvoiceResetList() {
        this.setState({ CreateInvoiceList: [] });
        this.setState({ TotalCount: 0 });
        this.setGridHeight();
    }

    SetToCompanyAddress = (event) => {
        let Value = event.target.value;
        this.setState(
            { ToCompanyAddress: Value },
            function () {
                console.log(this.state.ToCompanyAddress);
                this.ChangeERate();
            }
        );
    }

    SetInvoiceDate = (event) => {
        let Value = event.target.value;
        this.setState(
            { InvoiceDate: Value },
            function () {
                console.log(this.state.InvoiceDate);
                this.ChangeERate();
            }
        );
    }

    CalculateVATWhenBindWithValue(vatNew) {
        // let vat = parseInt(event.target.value);
        let vat = parseInt(vatNew);
        let total = parseFloat(this.state.TotalVAT);
        let exchangerate = this.state.ExchangeRate;
        let invoiceDate = this.state.InvoiceDate;
        let currencySymbol = this.state.CurrencySymbol;
        let currencyPair = this.state.CurrencyPairText;

        if (exchangerate == "" || exchangerate == null) {
            exchangerate = "0";
        }

        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Invoice/CalculateVat?total=" + total + "&vat=" + vat + "&exchangerate=" + exchangerate + "&invoiceDate=" + invoiceDate + "&currencyPair=" + currencyPair + "&currencySymbol=" + currencySymbol;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ VAT: result.vat });
                    this.setState({ NewTotalValue: result.vatTotal });
                    this.setState({ ExchangeVAT: result.exchangeRateVat });
                    this.setState({ ExchangeTotal: result.exchangeRateTotal });
                    this.setState({ Description: result.bodyText });
                });
            } else {
                var Total = this.state.TotalValue;
                this.setState({ VAT: 0 });
                this.setState({ NewTotalValue: Total });
                this.setState({ ExchangeVAT: 0 });
                this.setState({ ExchangeTotal: 0 });
                this.setState({ Description: "N/A" });
            }
        });
    }

    CalculateVAT = (event) => {
        this.setState({ VatID: event.target.value });
        let vat = parseInt(event.target.value);
        let total = parseFloat(this.state.TotalVAT);
        let exchangerate = this.state.ExchangeRate;
        let invoiceDate = this.state.InvoiceDate;
        let currencySymbol = this.state.CurrencySymbol;
        let currencyPair = this.state.CurrencyPairText;

        if (exchangerate == "" || exchangerate == null) {
            exchangerate = "0";
        }

        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Invoice/CalculateVat?total=" + total + "&vat=" + vat + "&exchangerate=" + exchangerate + "&invoiceDate=" + invoiceDate + "&currencyPair=" + currencyPair + "&currencySymbol=" + currencySymbol;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ VAT: result.vat });
                    this.setState({ NewTotalValue: result.vatTotal });
                    this.setState({ ExchangeVAT: result.exchangeRateVat });
                    this.setState({ ExchangeTotal: result.exchangeRateTotal });
                    this.setState({ Description: result.bodyText });
                });
            } else {
                var Total = this.state.TotalValue;
                this.setState({ VAT: 0 });
                this.setState({ NewTotalValue: Total });
                this.setState({ ExchangeVAT: 0 });
                this.setState({ ExchangeTotal: 0 });
                this.setState({ Description: "N/A" });
            }
        });
    }

    CalculateExchange = (event) => {
        this.setState({ ExchangeRate: event.target.value });
        let vat = parseInt(this.state.VatID);
        let total = parseFloat(this.state.TotalVAT);
        let exchangerate = event.target.value;
        let invoiceDate = this.state.InvoiceDate;
        let currencySymbol = this.state.CurrencySymbol;
        let currencyPair = this.state.CurrencyPairText;

        if (exchangerate == "" || exchangerate == null) {
            exchangerate = "0";
        }

        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Invoice/CalculateVat?total=" + total + "&vat=" + vat + "&exchangerate=" + exchangerate + "&invoiceDate=" + invoiceDate + "&currencyPair=" + currencyPair + "&currencySymbol=" + currencySymbol;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ ExchangeVAT: result.exchangeRateVat });
                    this.setState({ ExchangeTotal: result.exchangeRateTotal });
                    this.setState({ Description: result.bodyText });
                });
            } else {
                this.setState({ ExchangeVAT: 0 });
                this.setState({ ExchangeTotal: 0 });
                this.setState({ Description: "N/A" });
            }
        });
    }

    OnChangeCurrencyPair = (event) => {
        var index = event.nativeEvent.target.selectedIndex;
        var myText = event.nativeEvent.target[index].text;
        this.setState({ CurrencyPairID: event.target.value });
        this.setState({ CurrencyPairText: myText });

        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchCurrencyURL = config.apiUrl + "BrokerageRate/Currency/" + event.target.value;
        fetch(FetchCurrencyURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ ExchangeCurrencySymbol: result.symbol });
                    let vat = parseInt(this.state.VatID);
                    let total = parseFloat(this.state.TotalVAT);
                    let exchangerate = this.state.ExchangeRate;
                    let invoiceDate = this.state.InvoiceDate;
                    let currencySymbol = result.symbol;
                    let currencyPair = this.state.CurrencyPairText;

                    if (exchangerate == "" || exchangerate == null) {
                        exchangerate = "0";
                    }

                    let FetchURL = config.apiUrl + "Invoice/CalculateVat?total=" + total + "&vat=" + vat + "&exchangerate=" + exchangerate + "&invoiceDate=" + invoiceDate + "&currencyPair=" + currencyPair + "&currencySymbol=" + currencySymbol;
                    fetch(FetchURL, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": localStorage.getItem("LogedID"),
                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                            "commodityid": localStorage.getItem("CommodityID")
                        },
                    }).then((resp) => {
                        if (resp.status === 200) {
                            resp.json().then((result) => {
                                this.setState({ ExchangeVAT: result.exchangeRateVat });
                                this.setState({ ExchangeTotal: result.exchangeRateTotal });
                                this.setState({ Description: result.bodyText });
                            });
                        } else {
                            this.setState({ ExchangeVAT: 0 });
                            this.setState({ ExchangeTotal: 0 });
                            this.setState({ Description: "N/A" });
                        }
                    });
                });
            } else {
                this.setState({ ExchangeVAT: 0 });
                this.setState({ ExchangeTotal: 0 });
                this.setState({ Description: "N/A" });
            }
        });
    }

    ResetInvoicePopup() {
        this.setState({ TradeList: [] });
        this.setState({ CurrencyPairList: [] });
        this.setState({ ToAddressList: [] });
        this.setState({ BankList: [] });
        this.setState({ DistributionList: [] });
        this.setState({ BankID: "" });
        this.setState({ Currency: "" });
        this.setState({ InvoiceNo: "" });
        this.setState({ CompanyName: "" });
        this.setState({ CompanyAddress: "" });
        this.setState({ ToCompanyName: "" });
        this.setState({ ToCompanyAddress: "" });
        this.setState({ InvoiceDate: "" });
        this.setState({ DueDate: "" });
        this.setState({ TotalVAT: 0 });
        this.setState({ NewTotalValue: 0 });
        this.setState({ TotalValue: 0 });
        this.setState({ CurrencySymbol: "" });
        this.setState({ ExchangeCurrencySymbol: "" });
        this.setState({ VAT: "" });
        this.setState({ VATID: "0" });
        this.setState({ ExchangeRate: "" });
        this.setState({ ExchangeVAT: "" });
        this.setState({ ExchangeTotal: "" });
        this.setState({ Description: "N/A" });
        this.setState({ TotalCount: 0 });
        this.setState({ CurrentPageNo: 1 });
        this.setState({ PerPageCount: 10 });
        this.setState({ SortColumn: "tradedDate" });
        this.setState({ SortColumnType: "Desc" });
    }

    BindPopupList = (event) => {
        this.ResetInvoicePopup();
        let CompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));

        let SelectedTradeInvoiceList = [];
        this.state.CreateInvoiceList.forEach(TradeInvoiceItem => {
            if (TradeInvoiceItem.isChecked)
                SelectedTradeInvoiceList.push(TradeInvoiceItem.id);
        })

        if (SelectedTradeInvoiceList.length > 0) {

            let FetchURL = config.apiUrl + "Invoice/AddInvoicePopup";

            var paramData = {
                LoginCompanyId: parseInt(CompanyID),
                ToCompanyId: parseInt(this.state.Company),
                tradeids: SelectedTradeInvoiceList,
                commodityId: parseInt(CommodityID)
            };

            const dataPass = JSON.stringify(paramData);

            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(FetchURL, {
                method: 'POST',
                body: dataPass,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((result) => {
                        if (result.success) {
                            if (result.tradeList != null) {
                                if (result.tradeList.length > 0) {
                                    this.inputElement.click();
                                    this.setState({ BankList: result.bankList });
                                    this.setState({ TradeList: result.tradeList });
                                    this.setState({ CurrencyPairList: result.currencyPairList });
                                    this.setState({ Currency: result.currency });
                                    this.setState({ InvoiceNo: result.invoiceNo });
                                    this.setState({ CompanyName: result.loginCompanyName });
                                    this.setState({ CompanyAddress: result.loginCompanyAddress });
                                    this.setState({ ToCompanyName: result.toCompanyName });
                                    this.setState({ InvoiceDate: moment(result.invoiceDate).format('YYYY-MM-DD') });
                                    this.setState({ DueDate: moment(result.dueDate).format('YYYY-MM-DD') });
                                    this.setState({ TotalVAT: result.total });
                                    this.setState({ NewTotalValue: result.total });
                                    this.setState({ TotalValue: result.total });
                                    this.setState({ CurrencySymbol: result.currencySymbol });
                                    this.setState({ ExchangeCurrencySymbol: result.currencySymbol });
                                    this.setState({ currencyId: result.currencyId });
                                    this.setState({ VAT: "" });
                                    this.setState({ ExchangeRate: "" });
                                    this.setState({ ExchangeVAT: "" });
                                    this.setState({ ExchangeTotal: "" });

                                    //                                     if(result.VatID){
                                    // //this.CalculateVAT
                                    //                                     }

                                    if (result.bankList != null) {
                                        this.setState({ BankCurrency: result.bankList[0].accountTypeName });
                                        this.setState({ BankID: result.bankList[0].id });
                                    }

                                    if (result.toCompanyAddress != null) {
                                        this.setState({ ToAddressList: result.toCompanyAddress });
                                        this.setState({ ToCompanyAddress: result.toCompanyAddress[0].value });
                                    }

                                    this.ChangeERate();
                                } else {
                                    swal({
                                        title: "Sorry!",
                                        text: "Please select non invoiced trades.",
                                        icon: "error",
                                        button: "okay",
                                    });
                                }
                            } else {
                                swal({
                                    title: "Sorry!",
                                    text: "Please select non invoiced trades.",
                                    icon: "error",
                                    button: "okay",
                                });
                            }
                        } else {
                            swal({
                                title: "Sorry!",
                                text: result.message,
                                icon: "error",
                                button: "okay",
                            });
                        }
                    });
                }
            });

            let FetchBankURL = config.apiUrl + "Company/" + this.state.Company + "/distributionlist/" + CommodityID + "/Invoice";

            fetch(FetchBankURL, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((result) => {
                        this.setState({ DistributionList: result })
                    });
                }
            });
        } else {
            swal({
                title: "Sorry!",
                text: "Please selct atleast one trade.",
                icon: "error",
                button: "okay",
            });
        }
    }

    SaveCreateInvoice() {
        // let LoginCompanyID = localStorage.getItem("LoginCompanyID");
        let CompanyID = localStorage.getItem("LoginCompanyID");
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let IndividualID = parseInt(localStorage.getItem("LogedID"));
        var AddURL = config.apiUrl + "invoice/AddInvoice";

        let Tradeids = [];
        this.state.CreateInvoiceList.forEach(TradeInvoiceItem => {
            if (TradeInvoiceItem.isChecked)
                Tradeids.push(TradeInvoiceItem.id);
        })

        var val = {
            loginCompanyId: parseInt(CompanyID),
            ToCompanyId: parseInt(this.state.Company),
            ToCompanyAddressId: parseInt(this.state.ToCompanyAddress),
            BankId: this.state.BankID,
            Tradeids: Tradeids,
            CommodityId: CommodityID,
            Total: this.state.TotalValue,
            InvoiceNo: this.state.InvoiceNo,
            InvoiceDate: this.state.InvoiceDate,
            DueDate: this.state.DueDate,
            CurrencyPair: this.state.CurrencyPairID.toString(),
            ExchangeRate: this.state.ExchangeRate,
            ExchangeVAT: this.state.ExchangeVAT,
            ExchangeTotal: this.state.ExchangeTotal.toString(),
            BodyText: this.state.Description,
            ExchangeCurrencyId: 0,
            ExchangeCurrencyPair: this.state.ExchangeTotal.toString(),
            Vat: this.state.VatID.toString(),
            CurrencyId: this.state.currencyId.toString(),
            IndividualId: IndividualID
        }

        const data = JSON.stringify(val);

        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(AddURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {
            if (resp.status === 200) {
                let tempTradeList = this.state.CreateInvoiceList;
                tempTradeList.forEach(TradeItem => {
                    TradeItem.isChecked = false;
                });

                this.setState({
                    MainCheckBox: false,
                    CreateInvoiceList: tempTradeList
                }, function () {
                    console.log(this.state.MainCheckBox);
                })

                swal({
                    title: "Good job!",
                    text: "Invoice Created successfully",
                    icon: "success",
                    button: "Okay!",
                });
                this.ResetInvoicePopup();
                this.BindCreateInvoiceList();
            } else {
                swal({
                    title: "Sorry!",
                    text: "Invoice was not Created successfully",
                    icon: "error",
                    button: "okay",
                });
            }
        });
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.BindCompanyList();
            this.BindInstrumentList();
            this.BindPeriodList();
            this.BindPriceList();
            this.BindTotalVolumeList();
            this.BindBuyerRateList();
            this.BindSellerRateList();
            this.BindCreateInvoiceList();
        } else {
            this.props.history.push('/login');
        }
    }

    render() {
        var rows = [], i = -1, len = 30;
        while (++i <= len) rows.push(i);

        const columns = [
            {
                name: <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" checked={this.state.MainCheckBox} />,
                selector: '',
                sortable: false,
                width: "60px",
                cell: item => <input key={item.dealID} onClick={this.handleCheckChieldElement} type="checkbox" checked={item.isChecked} value={item.dealID} />
            },
            {
                name: 'Deal ID',
                selector: 'dealID',
                sortable: true,
                right: false,
                width: "100px",
            },
            {
                name: 'Trade Date',
                selector: 'tradedDate',
                sortable: true,
                right: false,
                width: "180px",
                cell: item => <span>{moment(item.tradedDate).format("DD/MM/YYYY HH:mm:ss")}</span>
            },
            {
                name: 'Instrument',
                selector: 'instrument',
                sortable: false,
                width: "220px",
            },
            {
                name: 'Period',
                selector: 'period',
                sortable: true,
                right: false,
                width: "150px",
            },
            {
                name: 'Volume',
                selector: 'volume',
                sortable: true,
                right: false,
                width: "90px",
            },
            {
                name: 'Total Volume',
                selector: 'totalVolume',
                sortable: true,
                right: false,
                width: "130px",
            },
            {
                name: 'Price',
                selector: 'price',
                sortable: true,
                right: false,
                width: "80px",
                cell: item => <span>{item.price != "" && item.price != "0" && item.price != undefined && item.price != null ? Number(item.price).toFixed(2) : "0.00"}</span>
            },
            {
                name: 'Status',
                selector: '',
                sortable: false,
                width: "150px",
                cell: item => item.isBuyerCancelledInvoiced == true ? <span className='badge badge-black rounded'>Cancelled</span> : item.isBuyerInvoiceStatus == true ? <span className='badge badge-success rounded'>Invoiced</span> : <span className='badge badge-danger rounded'>Not Invoiced</span>
            },
            {
                name: 'Buyer Bro Fee',
                selector: 'buyerBroFee',
                sortable: true,
                right: false,
                width: "130px",
                cell: item => <span>{item.buyerBroFee != "" && item.buyerBroFee != "0" && item.buyerBroFee != undefined && item.buyerBroFee != null ? Number(item.buyerBroFee).toFixed(4) : "0.0000"}</span>
            },
            {
                name: 'Buyer',
                selector: 'buyer',
                sortable: true,
                right: false,
                width: "250px",
            },
            {
                name: 'Seller',
                selector: 'seller',
                sortable: true,
                right: false,
                width: "250px",
            },
            {
                name: 'Seller Bro Fee',
                selector: 'sellerBroFee',
                sortable: true,
                right: false,
                width: "130px",
                cell: item => <span>{item.sellerBroFee != "" && item.sellerBroFee != "0" && item.sellerBroFee != undefined && item.sellerBroFee != null ? Number(item.sellerBroFee).toFixed(4) : "0.0000"}</span>
            },
            {
                name: 'Status',
                selector: 'isSellerInvoiceStatus',
                sortable: false,
                width: "150px",
                cell: item => item.isSellerCancelledInvoiced == true ? <span className='badge badge-black rounded'>Cancelled</span> : item.isSellerInvoiceStatus == true ? <span className='badge badge-success rounded'>Invoiced</span> : <span className='badge badge-danger rounded'>Not Invoiced</span>
            },
            {
                name: 'Actions',
                selector: '',
                sortable: false,
                width: "120px",
                cell: row => <a type="button" className="btn btn-primary btn-sm mr-2" href={"/Trade-List-View?id=" + row.id}><i className="ti-eye mr-1" /> View</a>
            }
        ];

        return (
            <div className="layout-wrapper">
                <div id="pageloaddiv" style={{ display: 'none' }}>
                    <span><img src="../assets/loader.gif" width={150} height={150} /></span>
                </div>
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />

                    <div className="content-body">
                        <div className="content ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="dis-flex">
                                                <h3 className="mb-4">Create Invoice</h3>
                                                <div className="mb-4 ml-auto">
                                                    {/* <button type="button" className="btn btn-primary ml-2 mb-2 ml-xs-0" data-toggle="modal" data-target=".xeroModel"> <i className="ti-filter mr-1" /> XERO Authentication</button> */}
                                                    {/* <button type="button" className="btn btn-primary ml-2 mb-2 ml-xs-0" onClick={this.checkXeroAuth}> <i className="ti-filter mr-1" /> XERO Authentication</button> */}
                                                    <button type="button" className="btn btn-primary ml-2 mb-2 ml-xs-0" data-toggle="modal" data-target=".searchModel"> <i className="ti-filter mr-1" /> Filters</button>
                                                    {/* <button type="button" className="btn btn-danger ml-2 mb-2" onClick={() => this.MoveToTradeInvoiced()}>Create Trade Invoice</button> */}
                                                    <button type="button" className="btn btn-danger ml-2 mb-2" id="btnCreateInvoice" data-toggle="modal" data-target=".invoiceModel" style={{ display: "none" }} ref={this.btnCreateInvoice} >Create Trade Invoice</button>
                                                    <button type="button" className="btn btn-danger ml-2 mb-2" onClick={this.BindPopupList}>Create Trade Invoice</button>
                                                    <button type="button" className="btn btn-danger ml-2 mb-2" ref={input => this.inputElement = input} data-toggle="modal" data-target=".invoiceModel" style={{ display: 'none' }}>Create Trade Invoice</button>
                                                </div>
                                            </div>
                                            {/* <div className="table-responsive" style={{ maxHeight: this.state.Height }}> */}
                                            <div className="table-responsive">
                                                <DataTable
                                                    noDataComponent=""
                                                    columns={columns}
                                                    data={this.state.CreateInvoiceList}
                                                    pagination="false"
                                                    paginationServer
                                                    paginationPerPage={this.state.PerPageCount}
                                                    highlightOnHover={true}
                                                    paginationTotalRows={this.state.TotalCount}
                                                    paginationRowsPerPageOptions={["50", "100", "150", "200", this.state.TotalCount.toString()]}
                                                    onChangePage={this.handlePageChange}
                                                    onChangeRowsPerPage={this.handlePageCountChange}
                                                    onSort={this.handleColumnSort}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade bd-example-modal-lg xeroModel" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">XERO Authentication</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-md-12">
                                            <form className="row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Traded Date (Start)</label>
                                                    <input type="date" name="daterangepicker" className="form-control" value={this.state.TradedStartDate} onChange={this.onChangeStartDate} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary mb-2" data-dismiss="modal" onClick={() => this.ResetFiltera()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade bd-example-modal-lg searchModel" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Search Filters</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-md-12">
                                            <form className="row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1 " className="font-weight-800">Company</label>
                                                    <select className="form-control" value={this.state.Company} onChange={this.onChangeCompanyDropdown} >
                                                        <option value='0'>Select</option>
                                                        {this.state.CompanyList == null ? "" :
                                                            this.state.CompanyList.map(item => (
                                                                <option value={item.value} >{item.text}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Buyer/Seller</label>
                                                    <select className="form-control" value={this.state.BuyerSeller} onChange={this.onChangeBuyerSellerDropdown}>
                                                        <option value=''>Select</option>
                                                        <option value='buyer'>Buyer</option>
                                                        <option value='seller'>Seller</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Traded Date (Start)</label>
                                                    {/* <input type="date" name="daterangepicker" className="form-control" value={this.state.TradedStartDate} onChange={(e) => { this.setState({ TradedStartDate: e.target.value }); }} onKeyUp={() => this.BindCreateInvoiceList()} /> */}
                                                    <input type="date" name="daterangepicker" className="form-control" value={this.state.TradedStartDate} onChange={this.onChangeStartDate} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Traded Date (End)</label>
                                                    {/* <input type="date" name="daterangepicker" className="form-control" value={this.state.TradedEndDate} onChange={(e) => { this.setState({ TradedEndDate: e.target.value }); }} onKeyUp={() => this.BindCreateInvoiceList()} onBlur={() => this.BindCreateInvoiceList()} /> */}
                                                    <input type="date" name="daterangepicker" className="form-control" value={this.state.TradedEndDate} onChange={this.onChangeEndDate} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Instrument</label>
                                                    <div>
                                                        <Multiselect
                                                            options={this.state.InstrumentList}
                                                            selectedValues={this.state.InstrumentName}
                                                            onSelect={(value, item) => {
                                                                this.onSelectInstrument(value, item);
                                                            }}
                                                            onRemove={(value, item) => {
                                                                this.onRemoveInstrument(value, item);
                                                            }}
                                                            displayValue="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Period</label>
                                                    <div>
                                                        <Multiselect
                                                            options={this.state.PeriodList}
                                                            selectedValues={this.state.PeriodName}
                                                            onSelect={(value, item) => {
                                                                this.onSelectPeriod(value, item);
                                                            }}
                                                            onRemove={(value, item) => {
                                                                this.onRemovePeriod(value, item);
                                                            }}
                                                            displayValue="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Price</label>
                                                    <div className="autosearch-div">
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-Price' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.Price}
                                                            items={this.state.PriceList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectPrice(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangePrice(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Total Volume</label>
                                                    <div className="autosearch-div">
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-Volume' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.Volume}
                                                            items={this.state.TotalVolumeList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectVolume(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangeVolume(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Buyer Bro. Fee</label>
                                                    <div className="autosearch-div">
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-BuyerBroFees' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.BuyerBroFees}
                                                            items={this.state.BuyerRateList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectBuyerFees(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangeBuyerFees(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Seller Bro. Fee</label>
                                                    <div className="autosearch-div">
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-SellerBroFees' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.SellerBroFees}
                                                            items={this.state.SellerRateList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectSellerFees(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangeSellerFees(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary mb-2" data-dismiss="modal" onClick={() => this.ResetFilter()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade bd-example-modal-lg invoiceModel" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static">
                            <div className="modal-dialog modal-lg" style={{ marginLeft: "50px" }}>
                                <div className="modal-content" style={{ width: "1254px" }}>
                                    <div className="modal-header">
                                        <h6 className="modal-title">Confirm Invoice</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.ResetInvoicePopup()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form className="row">
                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">From :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.CompanyName} {", " + this.state.CompanyAddress}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Bank Details :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <select className="form-control" id="CCountry" value={this.state.BankID} onChange={this.handleChangeBankDetail} >
                                                    {this.state.BankList == null ? "" :
                                                        this.state.BankList.map(item => (
                                                            <option value={item.id}>{item.bankName + " " + item.iban}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Bank Currency :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.BankCurrency}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">To :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.ToCompanyName} </label>
                                                <select className="form-control" id="CCountry" value={this.state.ToCompanyAddress} onChange={this.SetToCompanyAddress} >
                                                    {this.state.ToAddressList == null ? "" :
                                                        this.state.ToAddressList.map(item => (
                                                            <option value={item.value}>{item.text}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Invoice No :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <input type="textbox" className="form-control w-200" value={this.state.InvoiceNo} onChange={(e) => { this.setState({ InvoiceNo: e.target.value }); }} />
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Invoice Date :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <input type="date" className="form-control w-200" value={this.state.InvoiceDate} onChange={this.SetInvoiceDate} />
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Due Date :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <input type="date" className="form-control w-200" value={this.state.DueDate} onChange={(e) => { this.setState({ DueDate: e.target.value }); }} />
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Value (Excl. VAT) ({this.state.CurrencySymbol}) :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.TotalVAT != "" && this.state.TotalVAT != "0" && this.state.TotalVAT != undefined && this.state.TotalVAT != null ? Number(this.state.TotalVAT).toFixed(2) : "0.00"}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">VAT :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <select className="form-control w-200" id="DDLVatID" value={this.state.VatID} onChange={this.CalculateVAT} ref={input => this.inputVatIDElement = input}>
                                                    {rows == null ? "" :
                                                        rows.map(item => (
                                                            <option value={item}>{item + "%"}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">VAT ({this.state.CurrencySymbol}) :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>
                                                    {this.state.VAT != "" && this.state.VAT != "0" && this.state.VAT != undefined && this.state.VAT != null ? Number(this.state.VAT).toFixed(2) : "0.00"}
                                                </label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Total Value ({this.state.CurrencySymbol}) :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>
                                                    {this.state.NewTotalValue != "" && this.state.NewTotalValue != "0" && this.state.NewTotalValue != undefined && this.state.NewTotalValue != null ? Number(this.state.NewTotalValue).toFixed(2) : "0.00"}
                                                </label>
                                            </div>

                                            {this.state.VatID != "0" ?
                                                <div className="row">
                                                    <div className="form-group col-md-3 text-right">
                                                        <label className="font-weight-800">Currency Pair :</label>
                                                    </div>
                                                    <div className="form-group col-md-9 p-l-10">
                                                        <select className="form-control w-200" id="ddlCurrencyPair" value={this.state.CurrencyPairID} onChange={this.OnChangeCurrencyPair} ref={input => this.inputCurrencyPairIDElement = input}>
                                                            {this.state.CurrencyPairList == null ? "" :
                                                                this.state.CurrencyPairList.map(item => (
                                                                    <option value={item.value}>{item.text}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-md-3 text-right">
                                                        <label className="font-weight-800">Exchange Rate :</label>
                                                    </div>
                                                    <div className="form-group col-md-9 p-l-10">
                                                        <div className="d-flex">
                                                            <input type="number" class="form-control w-200" value={this.state.ExchangeRate} onChange={this.CalculateExchange} /> <span className="text-danger ml-3 mt-2"><b>{this.state.ExchangeText}</b></span>
                                                        </div>
                                                    </div>

                                                    <div className="form-group col-md-3 text-right">
                                                        <label className="font-weight-800">VAT ({this.state.ExchangeCurrencySymbol}) :</label>
                                                    </div>
                                                    <div className="form-group col-md-9 p-l-10">
                                                        <input type="text" class="form-control w-200" value={this.state.ExchangeVAT} onChange={(e) => { this.setState({ ExchangeVAT: e.target.value }); }} readOnly />
                                                    </div>

                                                    <div className="form-group col-md-3 text-right">
                                                        <label className="font-weight-800">Total Value ({this.state.ExchangeCurrencySymbol}) :</label>
                                                    </div>
                                                    <div className="form-group col-md-9 p-l-10">
                                                        <input type="text" class="form-control w-200" value={this.state.ExchangeTotal} onChange={(e) => { this.setState({ ExchangeTotal: e.target.value }); }} readOnly />
                                                    </div>
                                                </div>
                                                :
                                                ""
                                            }

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Description :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <textarea id="txtDescription" className="form-control" row="3" value={this.state.Description} onChange={(e) => { this.setState({ Description: e.target.value }); }}></textarea>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label className="font-weight-800">Trade Summary :</label>
                                            </div>
                                            <div className="form-group col-md-12 p-l-10">

                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th>Trade Date</th>
                                                                <th>Deal ID</th>
                                                                <th>Description</th>
                                                                <th>Volume</th>
                                                                <th>Total Volume (MWh)</th>
                                                                <th>Price (MWh)</th>
                                                                <th>Buyer</th>
                                                                <th>Seller</th>
                                                                <th>Action</th>
                                                                <th>Broker Fee (EUR)</th>
                                                                <th>Total (EUR)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.TradeList == null ? "" :
                                                                this.state.TradeList.map(item => (
                                                                    <tr>
                                                                        <td>{item.tradedDate}</td>
                                                                        <td>{item.tradeID}</td>
                                                                        <td>{item.instName} {" - " + item.firstSequenceItemName}</td>
                                                                        <td>{item.volume}</td>
                                                                        <td>{item.totalVolume}</td>
                                                                        <td>{item.price != "" && item.price != "0" && item.price != undefined && item.price != null ? Number(item.price).toFixed(2) : "0.00"}</td>
                                                                        <td>{item.buyerCompany}</td>
                                                                        <td>{item.sellerCompany}</td>
                                                                        <td>{item.tradeAction}</td>
                                                                        <td>{item.initAggrBrokerRate != "" && item.initAggrBrokerRate != "0" && item.initAggrBrokerRate != undefined && item.initAggrBrokerRate != null ? Number(item.initAggrBrokerRate).toFixed(5) : "0.00000"}</td>
                                                                        <td>{item.finalBrokerRate != "" && item.finalBrokerRate != "0" && item.finalBrokerRate != undefined && item.finalBrokerRate != null ? Number(item.finalBrokerRate).toFixed(5) : "0.00000"}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Distribution List E-mail :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label></label>
                                            </div>

                                            {/* {this.state.DistributionList == null ? "" :
                                        this.state.DistributionList.map(item => (
                                            <div className="row">
                                                <div className="form-group col-md-3 text-right">
                                                    <label className="font-weight-800">{item.distributionTypeName + " :"}</label>
                                                </div>
                                                <div className="form-group col-md-9 p-l-10">
                                                    <label>{item.email}</label>
                                                </div>
                                            </div>
                                        ))
                                    } */}

                                            {this.state.DistributionList == null ? "" :
                                                this.state.DistributionList.map(item => (
                                                    <div className="d-flex w-100 ">
                                                        <div className="w-23">
                                                            <h2 className="font-weight-800 font-size-13 text-right mt-1">{item.distributionTypeName + " :"}</h2>
                                                        </div>
                                                        <div className="w-77 pl-3">
                                                            <p className="mt-0 ">{item.email}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary mb-2" data-dismiss="modal" onClick={() => this.ResetInvoicePopup()}>Reset</button>
                                        <a type="button" className="btn btn-success mb-2 mr-2" data-dismiss="modal" onClick={() => this.SaveCreateInvoice()}><i className="ti-save mr-1"></i> Confirm</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateInvoice;