import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer';
import config from './config';
import swal from 'sweetalert';
import { Link, Route, Switch } from "react-router-dom";
import DataTable from 'react-data-table-component';

class VAT extends Component {

    constructor() {
        super();
        this.state = {
            VATList: [],
            ERateList: [],
            CountryList: [],
            MonthList: [],
            YearList: [],
            CountryID: 0,
            VATID: 0,
            ERateID: 0,
            YearID: 0,
            MonthID: 0,
            ERate: "",
            Height: '0px',
        }
    }

    handleVATUpdateButton(ID) {
        let FetchURL = config.apiUrl + "invoice/GetVAT/" + ID;
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ CountryID: result.id });
                    this.setState({ VATID: result.vat });
                    // this.setGridHeight();
                });
            }
        });
    }

    handleERateUpdateButton(ID) {
        let FetchURL = config.apiUrl + "invoice/GetExchangeRate/" + ID;
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ MonthID: result.month });
                    this.setState({ YearID: result.year });
                    this.setState({ ERate: result.exchangeRate.toString() });
                    this.setState({ ERateID: result.id });
                });
            }
        });
    }

    ResetVATPopup() {
        this.setState({ CountryID: 0 });
        this.setState({ VATID: 0 });
    }

    ResetERatePopup() {
        this.setState({ YearID: 0 });
        this.setState({ MonthID: 0 });
        this.setState({ ERate: "" });
        this.setState({ ERateID: 0 });
    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 250;
            console.log(myHeight)
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }

    BindCountryList() {

        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(config.apiUrl + 'BrokerageRate/GetCountryList', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then(res => res.json())
            .then(json => this.setState({ CountryList: json }));
    }

    BindVATList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        let FetchURL = config.apiUrl + "invoice/GetVAT";
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ VATList: result });
                    this.setGridHeight();
                });
            } else {
                this.setState({ VATList: [] });
                this.setGridHeight();
            }
        });
    }

    SaveVATDetail() {
        var Country = parseInt(this.state.CountryID);
        var Vat = parseInt(this.state.VATID);
        var retval = true;
        let FetchURL = config.apiUrl + "invoice/UpdateGetVAT";

        if (Country == 0) {
            retval = false;
        }

        if (Vat == 0) {
            retval = false;
        }

        if (retval) {
            var val = {
                id: Country,
                vat: Vat,
            };

            const data = JSON.stringify(val);
            var myLoginToken = localStorage.getItem("AccessToken");

            fetch(FetchURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((results) => {
                        if (results == true) {
                            swal({
                                title: "Done!",
                                text: "VAT details have been saved",
                                icon: "success",
                                button: "Okay!",
                            });
                        } else {
                            swal({
                                title: "Sorry!",
                                text: "VAT details have not been saved",
                                icon: "error",
                                button: "okay",
                            });
                        }
                        this.BindVATList();
                    });
                }
            });
        } else {
            this.ResetVATPopup();
            swal({
                title: "Sorry!",
                text: "Please select both value.",
                icon: "error",
                button: "okay",
            });
        }
    }

    BindYearMonthList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        let FetchURL = config.apiUrl + "invoice/GetExchangeRatePopup";
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ MonthList: result.monthList });
                    this.setState({ YearList: result.yearList });
                });
            } else {
                this.setState({ MonthList: [] });
                this.setState({ YearList: [] });
            }
        });
    }

    BindERateList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        let FetchURL = config.apiUrl + "invoice/GetExchangeRate";
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    debugger;
                    this.setState({ ERateList: result });
                    this.setGridHeight();
                });
            } else {
                this.setState({ ERateList: [] });
                this.setGridHeight();
            }
        });
    }

    SaveERateDetail() {
        var month = parseInt(this.state.MonthID);
        var year = parseInt(this.state.YearID);
        var erate = this.state.ERate;
        var id = parseInt(this.state.ERateID);
        var retval = true;
        let FetchURL = config.apiUrl + "invoice/GetExchangeRate";
        var Method = "POST";

        if (month == 0) {
            retval = false;
        }

        if (year == 0) {
            retval = false;
        }

        if (erate == "" || erate == null || erate == undefined || erate == "0") {
            retval = false;
        }

        if (id > 0) {
            FetchURL = config.apiUrl + "invoice/UpdateGetExchangeRate";
            Method = "POST";
        }

        if (retval) {
            var val = {
                id: id,
                month: month,
                year: year,
                exchangeRate: parseFloat(erate)
            };
            let pageloaddiv = document.getElementById("pageloaddiv");
            pageloaddiv.style.display = "block";
            const data = JSON.stringify(val);
            var myLoginToken = localStorage.getItem("AccessToken");

            fetch(FetchURL, {
                method: Method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
            }).then((resp) => {
                pageloaddiv.style.display = "none";
                if (resp.status === 200) {
                    resp.json().then((results) => {
                        if (results.success == true) {
                            swal({
                                title: "Done!",
                                text: "ERate details have been saved",
                                icon: "success",
                                button: "Okay!",
                            });
                        } else {
                            swal({
                                title: "Sorry!",
                                text: "ERate details have not been saved",
                                icon: "error",
                                button: "okay",
                            });
                        }
                        this.BindERateList();
                    });
                }
            });
        } else {
            this.ResetERatePopup();
            swal({
                title: "Sorry!",
                text: "Please select all value.",
                icon: "error",
                button: "okay",
            });
        }
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.BindCountryList();
            this.BindYearMonthList();
            this.BindVATList();
            this.BindERateList();
        } else {
            this.props.history.push('/login');
        }
    }

    render() {
        const columns = [
            {
                name: 'Country VAT',
                selector: 'name',
                sortable: true,
                // width: "60%",
            },
            {
                name: 'VAT',
                selector: 'vat',
                sortable: true,
                // width: "30%",
            },
            {
                name: 'Actions',
                selector: '',
                sortable: false,
                // width: "10%",
                cell: item => <a type="button" className="btn btn-primary  btn-sm" href onClick={() => this.handleVATUpdateButton(item.id)} value={item.id} data-toggle="modal" data-target=".bd-example-modal-lg"><i className="ti-eye mr-1" /> View</a>
            }
        ];

        const columns1 = [
            {
                name: 'Month',
                selector: 'monthName',
                sortable: true,
                // width: "40%",
            },
            {
                name: 'Year',
                selector: 'year',
                sortable: true,
                // width: "20%",
            },
            {
                name: 'Rate (EUR/GBP)',
                selector: 'exchangeRate',
                sortable: true,
                // width: "30%",
            },
            {
                name: 'Actions',
                selector: '',
                sortable: false,
                // width: "10%",
                cell: item => <a type="button" className="btn btn-primary  btn-sm" href onClick={() => this.handleERateUpdateButton(item.id)} value={item.id} data-toggle="modal" data-target=".Rate"><i className="ti-eye mr-1" /> View</a>
            }
        ];

        return (
            <div className="layout-wrapper">
                <div id="pageloaddiv" style={{ display: 'none' }}>
                    <span><img src="../assets/loader.gif" width={150} height={150} /></span>
                </div>
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />
                    <div className="content-body">
                        <div className="content ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <ul className="nav nav-pills mb-3" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">VAT</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Exchange Rate</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                    <div className="d-flex align-items-center mb-2">
                                                    <div className="mb-2">
                                                        <strong className="text-danger mt-2">
                                                            All countries VAT percentage rates will be set to 0% unless stated in the list below:
                                                        </strong>
                                                    </div>
                                                        <div className="mb-2 ml-auto">
                                                            <button type="button" className="btn btn-secondary ml-0" data-toggle="modal" data-target=".bd-example-modal-lg"> <i className="ti-plus mr-1" /> Add VAT</button>
                                                        </div>

                                                    </div>


                                                    {/* <div className="table-responsive" style={{ maxHeight: this.state.Height }}> */}
                                                    <div className="table-responsive">
                                                        <DataTable
                                                            noDataComponent=""
                                                            columns={columns}
                                                            data={this.state.VATList}
                                                            pagination="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                    <div className="mb-4 ml-">
                                                        <button type="button" className="btn btn-secondary ml-0" data-toggle="modal" data-target=".Rate"> <i className="ti-plus mr-1" /> Add Exchange Rate</button>
                                                    </div>
                                                    {/* <div className="table-responsive" style={{ maxHeight: this.state.Height }}> */}
                                                    <div className="table-responsive">
                                                        <DataTable
                                                            columns={columns1}
                                                            data={this.state.ERateList}
                                                            pagination="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Add VAT</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-md-12">
                                            <form className="row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1 " className="font-weight-800">Country VAT</label>
                                                    <select className="form-control" id="CCountry" value={this.state.CountryID} onChange={(e) => { this.setState({ CountryID: e.target.value }); }}>
                                                        <option value='0'>Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">VAT (%)</label>
                                                    <select className="form-control" value={this.state.VATID} onChange={(e) => { this.setState({ VATID: e.target.value }); }}>
                                                        <option value='0'>Select</option>
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                        <option value={6}>6</option>
                                                        <option value={7}>7</option>
                                                        <option value={8}>8</option>
                                                        <option value={9}>9</option>
                                                        <option value={10}>10</option>
                                                        <option value={11}>11</option>
                                                        <option value={12}>12</option>
                                                        <option value={13}>13</option>
                                                        <option value={14}>14</option>
                                                        <option value={15}>15</option>
                                                        <option value={16}>16</option>
                                                        <option value={17}>17</option>
                                                        <option value={18}>18</option>
                                                        <option value={19}>19</option>
                                                        <option value={20}>20</option>
                                                        <option value={21}>21</option>
                                                        <option value={22}>22</option>
                                                        <option value={23}>23</option>
                                                        <option value={24}>24</option>
                                                        <option value={25}>25</option>
                                                        <option value={26}>26</option>
                                                        <option value={27}>27</option>
                                                        <option value={28}>28</option>
                                                        <option value={29}>29</option>
                                                        <option value={30}>30</option>
                                                        <option value={31}>31</option>
                                                        <option value={32}>32</option>
                                                        <option value={33}>33</option>
                                                        <option value={34}>34</option>
                                                        <option value={35}>35</option>
                                                        <option value={36}>36</option>
                                                        <option value={37}>37</option>
                                                        <option value={38}>38</option>
                                                        <option value={39}>39</option>
                                                        <option value={40}>40</option>
                                                        <option value={41}>41</option>
                                                        <option value={42}>42</option>
                                                        <option value={43}>43</option>
                                                        <option value={44}>44</option>
                                                        <option value={45}>45</option>
                                                        <option value={46}>46</option>
                                                        <option value={47}>47</option>
                                                        <option value={48}>48</option>
                                                        <option value={49}>49</option>
                                                        <option value={50}>50</option>
                                                    </select>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary mb-2" data-dismiss="modal" onClick={() => this.ResetVATPopup()}>Back</button>
                                        <a type="button" className="btn btn-primary mb-2 sweet-success" data-dismiss="modal" onClick={() => this.SaveVATDetail()}>Confirm</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade Rate" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Exchange Rate</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.ResetERatePopup()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-md-12">
                                            <form className="row">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="exampleInputPassword1 " className="font-weight-800">Month</label>
                                                    <select className="form-control" id="CCountry" value={this.state.MonthID} onChange={(e) => { this.setState({ MonthID: e.target.value }); }}>
                                                        <option value={0}>Select</option>
                                                        {this.state.MonthList == null ? "" :
                                                            this.state.MonthList.map(item => (
                                                                <option value={item.value}>{item.text}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Year</label>
                                                    <select className="form-control" id="CCountry" value={this.state.YearID} onChange={(e) => { this.setState({ YearID: e.target.value }); }}>
                                                        <option value={0}>Select</option>
                                                        {this.state.YearList == null ? "" :
                                                            this.state.YearList.map(item => (
                                                                <option value={item.value}>{item.text}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="exampleInputPassword1" className="font-weight-800">Rate (EUR/GBP)</label>
                                                    <input type="number" className="form-control" id="exampleInputPassword1" placeholder="Rate (EUR/GBP)" value={this.state.ERate} onChange={(e) => { this.setState({ ERate: e.target.value }); }} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <input type="hidden" value={this.state.ERateID} />
                                        <button type="button" className="btn btn-secondary mb-2" data-dismiss="modal" onClick={() => this.ResetERatePopup()}>Back</button>
                                        <a type="button" className="btn btn-primary mb-2 sweet-success" data-dismiss="modal" onClick={() => this.SaveERateDetail()}>Confirm</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default VAT;