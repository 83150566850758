import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import { Link, Route, Switch } from "react-router-dom";
import config from './config';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';

class CompanyList extends Component {
    constructor() {
        super();
        this.state = {
            ClientList: [],
            Height: '0px',
            CurrentPageNo: 1,
            PerPageCount: 10,
            SortColumn: "name",
            SortColumnType: "Asc",
            TotalCount: 0,
            SearchValue: '',
        }
    }

    onSearch = (event) => {
        this.setState(
            { SearchValue: event.target.value },
            function () {
                console.log(this.state.SearchValue);
                this.BindClientList();
            }
        );
    }

    handlePageChange = event => {
        this.setState(
            { CurrentPageNo: event },
            function () {
                console.log(this.state.CurrentPageNo);
                this.BindClientList();
            }
        );
    }

    handlePageCountChange = event => {
        this.setState(
            { PerPageCount: event },
            function () {
                console.log(this.state.PerPageCount);
                this.BindClientList();
            }
        );
    }

    handleColumnSort = event => {
        this.setState(
            { SortColumn: event.selector },
            function () {
                let SortType = "Desc";
                if (this.state.SortColumnType == "Desc") {
                    SortType = "Asc";
                }

                this.setState(
                    { SortColumnType: SortType },
                    function () {
                        console.log(this.state.SortColumnType);
                        console.log(this.state.SortColumn);
                        this.BindClientList();
                    }
                )
            }
        );
    }

    BindClientList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CommodityID = localStorage.getItem("CommodityID");
        let FetchURL = config.apiUrl + "AllCompany?CommodityId=" + CommodityID + "&SortingColumnName=" + this.state.SortColumn + "&AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount + "&SearchQuery=" + this.state.SearchValue;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ ClientList: result.companyDto });
                    this.setState({ TotalCount: result.totalRecord });
                    this.setGridHeight();
                });
            } else {
                this.setState({ ClientList: [] });
                this.setState({ TotalCount: 0 });
            }
        });
    }

    ViewClient(ID) {

    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 200;
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.BindClientList();
        } else {
            this.props.history.push('/login');
        }
    }

    render() {

        const columns = [
            {
                name: 'Company Name',
                selector: 'name',
                sortable: true,
                // width: '100px'
            },
            {
                name: 'VAT Number',
                selector: 'vatNo',
                sortable: true,
                right: false,
            },
            {
                name: 'Website',
                selector: 'website',
                sortable: true,
                right: false,
            },
            {
                name: 'FAX Number',
                selector: 'faxNo',
                sortable: true,
                right: false,
            },
            {
                name: 'Full Address',
                selector: 'address',
                sortable: true,
                right: false,
            },
            {
                name: 'Billing Address',
                selector: 'billingAddress',
                sortable: true,
                right: false,
            }
            // ,{
            //     name: 'Actions',
            //     selector: '',
            //     sortable: true,
            //     right: false,
            //     width: "100px",
            //     cell: row => <a type="button" className="btn btn-primary  btn-sm" href={"/Company-Detail?id=" + row.id}><i className="ti-eye mr-1" /> View</a>
            // }
        ];

        return (
            <div className="layout-wrapper">
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />

                    <div className="content-body">
                        <div className="content ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h3 className="mb-2 cls-inline-block">Client Overview</h3>
                                            <input type="text" className="form-control clsSearchBar" placeholder="Search here..." onKeyUp={this.onSearch} value={this.state.SearchValue} onChange={(e) => { this.setState({ SearchValue: e.target.value }); }} />
                                            {/* <div className="table-responsive" style={{ maxHeight: this.state.Height }}> */}
                                            <div className="table-responsive">
                                                <DataTable
                                                    columns={columns}
                                                    data={this.state.ClientList}
                                                    pagination="true"
                                                    paginationServer
                                                    paginationPerPage={this.state.PerPageCount}
                                                    highlightOnHover={true}
                                                    paginationTotalRows={this.state.TotalCount}
                                                    paginationRowsPerPageOptions={["10", "20", "50", "100", this.state.TotalCount.toString()]}
                                                    onChangePage={this.handlePageChange}
                                                    onChangeRowsPerPage={this.handlePageCountChange}
                                                    onSort={this.handleColumnSort}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
export default CompanyList;