import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import config from './config';
import swal from 'sweetalert';
import { Link, Route, Switch } from "react-router-dom";
import DataTable from 'react-data-table-component';
import moment from 'moment';

class TradeList extends Component {

    constructor() {
        super();
        this.state = {
            TradeList: [],
            InstrumentList: [],
            PeriodList: [],
            InstrumentName: null,
            PeriodName: null,
            DealIDFilter: "",
            MainCheckBox: false,
            Height: '0px',
            IndividualID: 0,
            contentUrl: config.contentUrl,
            CurrentPageNo: 1,
            PerPageCount: 50,
            SortColumn: "tradedDate",
            SortColumnType: "Desc",
            TotalCount: 0,
            SearchValue: '',
            CommodityName: ""
        }
    }

    handlePageChange = event => {
        this.setState(
            { CurrentPageNo: event },
            function () {
                console.log(this.state.CurrentPageNo);
                this.BindTradeList();
            }
        );
    }

    handlePageCountChange = event => {
        this.setState(
            { PerPageCount: event },
            function () {
                console.log(this.state.PerPageCount);
                this.BindTradeList();
            }
        );
    }

    handleColumnSort = event => {
        this.setState(
            { SortColumn: event.selector },
            function () {
                let SortType = "Desc";
                if (this.state.SortColumnType == "Desc") {
                    SortType = "Asc";
                }

                this.setState(
                    { SortColumnType: SortType },
                    function () {
                        console.log(this.state.SortColumnType);
                        console.log(this.state.SortColumn);
                        this.BindTradeList();
                    }
                )
            }
        );
    }

    onSearch = (event) => {
        this.setState(
            { SearchValue: event.target.value },
            function () {
                console.log(this.state.SearchValue);
                this.BindTradeList();
            }
        );
    }

    allowOnlyNumber = e => {
        if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
            (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            return;
        }

        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    }

    ResetFilter() {
        this.setState({ InstrumentName: "" });
        this.setState({ PeriodName: "" });
        this.setState({ DealIDFilter: "" });
        this.BindTradeResetList();
    }

    handleAllChecked = (event) => {
        let tempTradeList = this.state.TradeList
        tempTradeList.forEach(TradeItem => TradeItem.isChecked = event.target.checked)
        this.setState({ TradeList: tempTradeList })
        this.setState({ MainCheckBox: event.target.checked })
    }

    handleCheckChieldElement = (event) => {
        let tempTradeList = this.state.TradeList;
        let myCheckedCount = 0;
        tempTradeList.forEach(TradeItem => {
            if (TradeItem.dealID == event.target.value) {
                TradeItem.isChecked = event.target.checked
            }

            if (TradeItem.isChecked)
                myCheckedCount++;
        })

        if (tempTradeList.length == myCheckedCount)
            this.setState({ MainCheckBox: true })
        else
            this.setState({ MainCheckBox: false })

        this.setState({ TradeList: tempTradeList })
    }

    DownloadTradePDF(ID, Status) {
        var URL = config.apiUrl + "Trade/DownloadPDF?id=" + ID + "&loginUserId=" + this.state.IndividualID + "&status=" + Status;
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(URL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            debugger;
            if (resp.status === 200) {
                resp.json().then((result) => {
                    debugger;
                    // const link = document.createElement('a');
                    // link.href = this.state.contentUrl + '' + result.path;
                    // document.body.appendChild(link);
                    // link.click();
                    // document.body.removeChild(link);

                    window.open(this.state.contentUrl + '' + result.path, 'Download');
                });
            } else {
                swal({
                    title: "Sorry!",
                    text: "Trade's PDF is not download.",
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
    }

    MoveToTradeStaging() {
        let SelectedTradeList = [];
        this.state.TradeList.forEach(TradeItem => {
            if (TradeItem.isChecked)
                SelectedTradeList.push(TradeItem.id);
        })

        if (SelectedTradeList.length > 0) {
            swal({
                title: "Are you sure?",
                text: "Are you sure want to move trade in staging section!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = JSON.stringify(SelectedTradeList);
                        var POSTURL = config.apiUrl + "Trade/MoveToTradeStaging";
                        var myLoginToken = localStorage.getItem("AccessToken");

                        fetch(POSTURL, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + myLoginToken,
                                "loginuserid": localStorage.getItem("LogedID"),
                                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                                "commodityid": localStorage.getItem("CommodityID")
                            },
                            body: data,
                            async: false,
                            traditional: true,
                        }).then((resp) => {
                            if (resp.status === 200) {

                                let tempTradeList = this.state.TradeList;
                                tempTradeList.forEach(TradeItem => {
                                    TradeItem.isChecked = false;
                                });

                                this.setState({
                                    MainCheckBox: false,
                                    TradeList: tempTradeList
                                }, function () {
                                    console.log(this.state.MainCheckBox);
                                });

                                swal({
                                    title: "Done!",
                                    text: "Trade's are succesfully moved to Trade Staging.",
                                    icon: "success",
                                    button: "Okay!",
                                });
                            } else {
                                swal({
                                    title: "Sorry!",
                                    text: "Trade's are not moved to Trade Staging.",
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }

                            this.BindTradeList();
                        });
                    }
                });
        } else {
            swal("Sorry! Please select atleast one Trade to move in staging.", {
                icon: "error",
            });
        }
    }

    ExportToCsvTradeList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        var myLoginToken = localStorage.getItem("AccessToken");
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        var date = new Date();
        var LondonDate = date.toLocaleString('en-GB', { timeZone: 'Europe/London' });

        let FetchURL = config.apiUrl + "Trade/ExportToCsvTradeList?commodityId=" + CommodityID;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    window.open(this.state.contentUrl + '' + result.path, 'TradeList');
                });
            }
        });
    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 250;
            console.log(myHeight)
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }
    BindTradeList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let InstrumentName = this.state.InstrumentName;
        let PeriodName = this.state.PeriodName;
        let DealIDFilter = this.state.DealIDFilter;

        if (InstrumentName == "" || InstrumentName == null || InstrumentName == undefined) {
            InstrumentName = "";
        }

        if (PeriodName == "" || PeriodName == null || PeriodName == undefined) {
            PeriodName = "";
        }

        if (DealIDFilter == "" || DealIDFilter == null || DealIDFilter == undefined) {
            DealIDFilter = "0";
        }

        // let FetchURL = config.apiUrl + "Trade/TradeList?commodityId=" + CommodityID + "&InstrumentName=" + InstrumentName + "&PeriodName=" + PeriodName;
        let FetchURL = config.apiUrl + "Trade/TradeList?commodityId=" + CommodityID + "&InstrumentName=" + InstrumentName + "&PeriodName=" + PeriodName + "&DealID=" + parseInt(DealIDFilter) + "&SortingColumnName=" + this.state.SortColumn + "&AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount + "&SearchQuery=" + this.state.SearchValue;
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalCount: result.totalRecord });
                    this.setState({ TradeList: result.tradeList });
                    this.setGridHeight();
                });
            } else {
                this.setState({ TradeList: [] });
                this.setState({ TotalCount: 0 });
                this.setGridHeight();
            }
        });
    }

    BindTradeResetList() {
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let InstrumentName = "";
        let PeriodName = "";
        let DealIDFilter = "0";
        var myLoginToken = localStorage.getItem("AccessToken");

        let FetchURL = config.apiUrl + "Trade/TradeList?commodityId=" + CommodityID + "&InstrumentName=" + InstrumentName + "&PeriodName=" + PeriodName + "&DealID=" + parseInt(DealIDFilter) + "&SortingColumnName=" + this.state.SortColumn + "&AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount + "&SearchQuery=" + this.state.SearchValue;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalCount: result.totalRecord });
                    this.setState({ TradeList: result.tradeList });
                    this.setGridHeight();
                });
            } else {
                this.setState({ TradeList: [] });
                this.setState({ TotalCount: 0 });
                this.setGridHeight();
            }
        });
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.setState({ CommodityName: localStorage.getItem('CommodityName') });
            this.BindTradeList();
            let id = parseInt(localStorage.getItem("LogedID"));
            this.setState({ IndividualID: id });
        } else {
            this.props.history.push('/login');
        }
    }

    render() {

        const columns = [
            {
                name: <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" checked={this.state.MainCheckBox} />,
                selector: '',
                sortable: false,
                width: "60px",
                cell: item => <input key={item.dealID} onClick={this.handleCheckChieldElement} type="checkbox" checked={item.isChecked} value={item.dealID} />
            },
            {
                name: '',
                selector: '',
                sortable: false,
                width: "120px",
                cell: row => <a type="button" className="btn btn-primary  btn-sm" href={"/Trade-List-View?id=" + row.id}><i className="ti-eye mr-1" /> View</a>
            },
            {
                name: 'Deal ID',
                selector: 'dealID',
                sortable: true,
                width: "100px",
            },
            {
                name: 'Trade Date',
                selector: 'tradedDate',
                sortable: true,
                width: "180px",
                cell: item => <span>{moment(item.tradedDate).format("DD/MM/YYYY HH:mm:ss")}</span>
            },
            {
                name: 'Instrument',
                selector: 'instrument',
                sortable: true,
                width: "220px",
            },
            {
                name: 'Period',
                selector: 'period',
                sortable: true,
                width: "125px",
            },
            {
                // name: 'Volume',
                name: this.state.CommodityName == "Eu Power" ? "Volume" : this.state.CommodityName == "Eu Gas" ? "Volume" : this.state.CommodityName == "LNG" ? "Lot(s)" : "",
                selector: 'volume',
                sortable: true,
                width: "100px",
            },
            {
                // name: 'Total Volume',
                name: this.state.CommodityName == "Eu Power" ? "Total Volume" : this.state.CommodityName == "Eu Gas" ? "Total Volume" : this.state.CommodityName == "LNG" ? "Total Volume (MMBtu)" : "",
                selector: 'totalVolume',
                sortable: true,
                width: "150px",
            },
            {
                // name: 'Price',
                name: this.state.CommodityName == "Eu Power" ? "Price (MWH)" : this.state.CommodityName == "Eu Gas" ? "Price (MWH)" : this.state.CommodityName == "LNG" ? "Price (USD)" : "",
                selector: 'price',
                sortable: true,
                width: "130px",
                cell: item => <span>{item.price != "" && item.price != "0" && item.price != undefined && item.price != null ? this.state.CommodityName == "Eu Gas" || this.state.CommodityName == "LNG" ? Number(item.price).toFixed(3) : Number(item.price).toFixed(2) : this.state.CommodityName == "Eu Gas" || this.state.CommodityName == "LNG" ? "0.000" : "0.00"}</span>
            },
            {
                name: 'Buyer Init/Aggr',
                selector: 'buyer_Init_Aggr',
                sortable: true,
                width: "150px",
            },
            {
                name: 'Buyer Trader Name',
                selector: 'buyerTraderName',
                sortable: true,
                width: "220px",
            },
            {
                name: '',
                selector: '',
                sortable: false,
                width: "50px",
                // cell: row => <a href={this.state.contentUrl + "api/Trade/DownloadPDF?id=" + row.id + "&loginUserId=" + this.state.IndividualID + "&status=buyer"} target="_blank"><i class="fa fa-file-pdf-o text-danger pdf-icon-font"></i></a>
                cell: row => <a href onClick={() => this.DownloadTradePDF(row.id, 'buyer')}><i class="fa fa-file-pdf-o text-danger pdf-icon-font"></i></a>
            },
            {
                name: 'Buyer',
                selector: 'buyer',
                sortable: true,
                width: "250px",
            },
            {
                name: 'Seller',
                selector: 'seller',
                sortable: true,
                width: "250px",
            },
            {
                name: '',
                selector: '',
                sortable: false,
                width: "50px",
                //cell: row => <a href={this.state.contentUrl + "api/Trade/DownloadPDF?id=" + row.id + "&loginUserId=" + this.state.IndividualID + "&status=seller"} target="_blank"><i class="fa fa-file-pdf-o text-danger pdf-icon-font"></i></a>
                cell: row => <a href onClick={() => this.DownloadTradePDF(row.id, 'seller')}><i class="fa fa-file-pdf-o text-danger pdf-icon-font"></i></a>
            },
            {
                name: 'Seller Trader Name',
                selector: 'sellerTraderName',
                sortable: true,
                width: "220px",
            },
            {
                name: 'Seller Init/Aggr',
                selector: 'seller_Init_Aggr',
                sortable: true,
                width: "150px",
            },
            {
                name: 'Init Sleeve',
                selector: 'initSleeve',
                sortable: true,
                width: "120px",
                cell: row => row.initSleeve == true ? "Yes" : ""
            },
            {
                name: 'Agg Sleeve',
                selector: 'aggSleeve',
                sortable: true,
                width: "120px",
                cell: row => row.aggSleeve == true ? "Yes" : ""
            },
            {
                name: 'From Broken Spread',
                selector: 'fromBrokenSpread',
                sortable: true,
                width: "170px",
                cell: row => row.fromBrokenSpread == true ? "Yes" : ""
            },
            {
                name: 'Init Bro Rate',
                selector: 'initBroRate',
                sortable: true,
                width: "130px",
                cell: item => <span>{item.initBroRate != "" && item.initBroRate != "0" && item.initBroRate != undefined && item.initBroRate != null ? Number(item.initBroRate).toFixed(5) : "0.00000"}</span>
            },
            {
                name: 'Aggr Bro Rate',
                selector: 'aggrBroRate',
                sortable: true,
                width: "130px",
                cell: item => <span>{item.aggrBroRate != "" && item.aggrBroRate != "0" && item.aggrBroRate != undefined && item.aggrBroRate != null ? Number(item.aggrBroRate).toFixed(5) : "0.00000"}</span>
            },
            {
                name: 'Buyer Bro Fee',
                selector: 'buyerBroFee',
                sortable: true,
                width: "130px",
                cell: item => <span>{item.buyerBroFee != "" && item.buyerBroFee != "0" && item.buyerBroFee != undefined && item.buyerBroFee != null ? Number(item.buyerBroFee).toFixed(3) : "0.000"}</span>
            },
            {
                name: 'Seller Bro Fee',
                selector: 'sellerBroFee',
                sortable: true,
                width: "130px",
                cell: item => <span>{item.sellerBroFee != "" && item.sellerBroFee != "0" && item.sellerBroFee != undefined && item.sellerBroFee != null ? Number(item.sellerBroFee).toFixed(3) : "0.000"}</span>
            }
        ];

        return (
            <div className="layout-wrapper">
                <div id="pageloaddiv" style={{ display: 'none' }}>
                    <span><img src="../assets/loader.gif" width={150} height={150} /></span>
                </div>
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />

                    <div className="content-body">
                        <div className="content ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="dis-flex">
                                                <h3 className="mb-2">Trade List</h3>
                                                <div className="mb-2 ml-auto">
                                                    <button type="button" className="btn btn-primary ml-2 mr-2 mb-2 ml-xs-0" data-toggle="modal" data-target=".bd-example-modal-lg"> <i className="ti-filter mr-1" /> Filters</button>
                                                    <button type="button" className="btn btn-danger mr-2 mb-2 ml-xs-0" onClick={() => this.MoveToTradeStaging()}> <i className="ti-move mr-1" /> Move to Trading Staging </button>
                                                    <button type="button" className="btn btn-success ml-0 mr-2 mb-2" onClick={() => this.ExportToCsvTradeList()}> <i className="ti-file mr-1" /> Export to Csv</button>
                                                </div>
                                            </div>
                                            {/* <div className="table-responsive" style={{ maxHeight: this.state.Height }}> */}
                                            <div className="table-responsive" >
                                                <div className="mt-0 ">
                                                    <input type="text" className="form-control clsSearchBar mb-3" placeholder="Search here..." onKeyUp={this.onSearch} value={this.state.SearchValue} onChange={(e) => { this.setState({ SearchValue: e.target.value }); }} />
                                                </div>
                                                <DataTable
                                                    noDataComponent=""
                                                    columns={columns}
                                                    data={this.state.TradeList}
                                                    pagination="true"
                                                    paginationServer
                                                    paginationPerPage={this.state.PerPageCount}
                                                    highlightOnHover={true}
                                                    paginationTotalRows={this.state.TotalCount}
                                                    paginationRowsPerPageOptions={["50", "100", "150", "200", this.state.TotalCount.toString()]}
                                                    onChangePage={this.handlePageChange}
                                                    onChangeRowsPerPage={this.handlePageCountChange}
                                                    onSort={this.handleColumnSort}
                                                />

                                                {/* <table className="table table-striped table-bordered" style={{ width: '100%', marginBottom: '1rem', color: '#212529', display: 'block', overflow: 'auto', borderRadius: '11px 11px 0 0' }}>
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th><input type="checkbox" onClick={this.handleAllChecked} value="checkedall" checked={this.state.MainCheckBox} /></th>
                                                    <th>Deal ID</th>
                                                    <th>Traded Date</th>
                                                    <th>Instrument </th>
                                                    <th>Period</th>
                                                    <th>Volume</th>
                                                    <th>Total Volume</th>
                                                    <th>Price(MWH)</th>
                                                    <th>Buyer Init/Aggr</th>
                                                    <th>Buyer Trader Name</th>
                                                    <th>Buyer</th>
                                                    <th>Seller</th>
                                                    <th>Seller Trader Name</th>
                                                    <th>Seller Init/Aggr</th>
                                                    <th>Init Sleeve</th>
                                                    <th>Aggr Sleeve</th>
                                                    <th>From Broken Spread</th>
                                                    <th>Init Bro Rate</th>
                                                    <th>Aggr Bro Rate</th>
                                                    <th>Buyer Bro Fee</th>
                                                    <th>Seller Bro Fee</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.TradeList == null ? "" :
                                                    this.state.TradeList.map(item => (
                                                        <tr>
                                                            <td>
                                                                <input key={item.dealID} onClick={this.handleCheckChieldElement} type="checkbox" checked={item.isChecked} value={item.dealID} />
                                                            </td>
                                                            <th>{item.dealID}</th>
                                                            <th>{item.tradedDate}</th>
                                                            <th>{item.instrument} </th>
                                                            <th>{item.period}</th>
                                                            <th>{item.volume}</th>
                                                            <th>{item.totalVolume}</th>
                                                            <th>{item.price}</th>
                                                            <th>{item.buyer_Init_Aggr}</th>
                                                            <th>{item.buyer_TraderName}</th>
                                                            <th>{item.buyer}</th>
                                                            <th>{item.seller}</th>
                                                            <th>{item.sellerTraderName}</th>
                                                            <th>{item.seller_Init_Aggr}</th>
                                                            <th>{item.initSleeve}</th>
                                                            <th>{item.aggSleeve}</th>
                                                            <th>{item.fromBrokenSpread}</th>
                                                            <th>{item.initBroRate}</th>
                                                            <th>{item.aggrBroRate}</th>
                                                            <th>{item.buyerBroFee}</th>
                                                            <th>{item.sellerBroFee}</th>
                                                            <td>
                                                                <button type="button" className="btn btn-primary btn-sm mr-2">View</button></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Search Filters</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-md-12">
                                            <form className="row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1">Instrument</label>
                                                    <input type="text" name="InstrumentName" id="InstrumentName" placeholder="Instrument" className="form-control" value={this.state.InstrumentName} onChange={(e) => { this.setState({ InstrumentName: e.target.value }); }} onKeyUp={() => this.BindTradeList()} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1">Period</label>
                                                    <input type="text" name="PeriodName" id="PeriodName" className="form-control" placeholder="Period" value={this.state.PeriodName} onChange={(e) => { this.setState({ PeriodName: e.target.value }); }} onKeyUp={() => this.BindTradeList()} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1">Deal ID</label>
                                                    <input type="text" name="DealIDFilter" id="DealIDFilter" className="form-control" placeholder="Deal ID" value={this.state.DealIDFilter} onChange={(e) => { this.setState({ DealIDFilter: e.target.value }); }} onKeyUp={() => this.BindTradeList()} onKeyDown={this.allowOnlyNumber} />
                                                </div>

                                                {/* <div className="form-group col-md-6">
                                            <label htmlFor="exampleInputPassword1">Company</label>
                                            <select className="js-example-basic-single">
                                                <option>Select</option>
                                                <option value={451}>A2A S.p.a</option>
                                                <option value={468}>AET - Azienda Elettrica Ticinese</option>
                                                <option value={2567}>Alperia Trading S.R.L</option>
                                                <option value={419}>Alpiq AG</option>
                                                <option value={2603}>Alpiq AG 2</option>
                                                <option value={422}>Alpiq Energy SE</option>
                                                <option value={2668}>AOT Energy Switzerland AG</option>
                                                <option value={438}>ARRACO GM Trader</option>
                                                <option value={378}>Axpo Italia Spa</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="exampleInputPassword1">Invoice Number</label>
                                            <input type="number" className="form-control" id="exampleInputPassword1" placeholder="Invoice Number" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="exampleInputPassword1">Invoice Start Date</label>
                                            <input type="text" name="daterangepicker" className="form-control" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="exampleInputPassword1">Invoice Due Date</label>
                                            <input type="text" name="daterangepicker" className="form-control" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="exampleInputPassword1">Total Value</label>
                                            <input type="number" className="form-control" id="exampleInputPassword1" placeholder="Total Value" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="exampleInputPassword1">Status</label>
                                            <select className="js-example-basic-single">
                                                <option>Select Status</option>
                                                <option value={450}>Invoiced</option>
                                                <option value={451}>Cancelled</option>
                                                <option>Paid</option>
                                            </select>
                                        </div> */}
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.BindTradeList()}>Search</button> */}
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.ResetFilter()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default TradeList;