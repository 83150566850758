import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import { Link, Route, Switch } from "react-router-dom";

class Userdetails extends Component {
    render() {
        return (
            <div className="wrapper-pro">
                <Sidebar />
                <div className="content-inner-all">
                    <Header />
                    <div className="mobile-menu-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="mobile-menu">
                                        <nav id="dropdown">
                                            <ul className="mobile-menu-nav">
                                                <li>
                                                    <Link to="/">Home  <span className="admin-project-icon adminpro-icon " /></Link>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#demo" href="javascript:void(0);">Mailbox <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="demo" className="collapse dropdown-header-top">
                                                        <li><a href="inbox.html">Inbox</a>
                                                        </li>
                                                        <li><a href="view-mail.html">View Mail</a>
                                                        </li>
                                                        <li><a href="compose-mail.html">Compose Mail</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#others" href="javascript:void(0);">Miscellaneous <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="others" className="collapse dropdown-header-top">
                                                        <li><a href="profile.html">Profile</a>
                                                        </li>
                                                        <li><a href="contact-client.html">Contact Client</a>
                                                        </li>
                                                        <li><a href="contact-client-v.1.html">Contact Client v.1</a>
                                                        </li>
                                                        <li><a href="project-list.html">Project List</a>
                                                        </li>
                                                        <li><a href="project-details.html">Project Details</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#Miscellaneousmob" href="JavaScript:Void(0);">Interface <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="Miscellaneousmob" className="collapse dropdown-header-top">
                                                        <li><a href="google-map.html">Google Map</a>
                                                        </li>
                                                        <li><a href="data-maps.html">Data Maps</a>
                                                        </li>
                                                        <li><a href="pdf-viewer.html">Pdf Viewer</a>
                                                        </li>
                                                        <li><a href="x-editable.html">X-Editable</a>
                                                        </li>
                                                        <li><a href="code-editor.html">Code Editor</a>
                                                        </li>
                                                        <li><a href="tree-view.html">Tree View</a>
                                                        </li>
                                                        <li><a href="preloader.html">Preloader</a>
                                                        </li>
                                                        <li><a href="images-cropper.html">Images Cropper</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#Chartsmob" href="JavaScript:Void(0);">Charts <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="Chartsmob" className="collapse dropdown-header-top">
                                                        <li><a href="bar-charts.html">Bar Charts</a>
                                                        </li>
                                                        <li><a href="line-charts.html">Line Charts</a>
                                                        </li>
                                                        <li><a href="area-charts.html">Area Charts</a>
                                                        </li>
                                                        <li><a href="rounded-chart.html">Rounded Charts</a>
                                                        </li>
                                                        <li><a href="c3.html">C3 Charts</a>
                                                        </li>
                                                        <li><a href="sparkline.html">Sparkline Charts</a>
                                                        </li>
                                                        <li><a href="peity.html">Peity Charts</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#Tablesmob" href="JavaScript:Void(0);">Tables <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="Tablesmob" className="collapse dropdown-header-top">
                                                        <li><a href="static-table.html">Static Table</a>
                                                        </li>
                                                        <li><a href="data-table.html">Data Table</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#formsmob" href="JavaScript:Void(0);">Forms <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="formsmob" className="collapse dropdown-header-top">
                                                        <li><a href="basic-form-element.html">Basic Form Elements</a>
                                                        </li>
                                                        <li><a href="advance-form-element.html">Advanced Form Elements</a>
                                                        </li>
                                                        <li><a href="password-meter.html">Password Meter</a>
                                                        </li>
                                                        <li><a href="multi-upload.html">Multi Upload</a>
                                                        </li>
                                                        <li><a href="tinymc.html">Text Editor</a>
                                                        </li>
                                                        <li><a href="dual-list-box.html">Dual List Box</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#Appviewsmob" href="JavaScript:Void(0);">App views <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="Appviewsmob" className="collapse dropdown-header-top">
                                                        <li><a href="basic-form-element.html">Basic Form Elements</a>
                                                        </li>
                                                        <li><a href="advance-form-element.html">Advanced Form Elements</a>
                                                        </li>
                                                        <li><a href="password-meter.html">Password Meter</a>
                                                        </li>
                                                        <li><a href="multi-upload.html">Multi Upload</a>
                                                        </li>
                                                        <li><a href="tinymc.html">Text Editor</a>
                                                        </li>
                                                        <li><a href="dual-list-box.html">Dual List Box</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a data-toggle="collapse" data-target="#Pagemob" href="JavaScript:Void(0);">Pages <span className="admin-project-icon adminpro-icon adminpro-down-arrow" /></a>
                                                    <ul id="Pagemob" className="collapse dropdown-header-top">
                                                        <li><a href="login.html">Login</a>
                                                        </li>
                                                        <li><a href="register.html">Register</a>
                                                        </li>
                                                        <li><a href="captcha.html">Captcha</a>
                                                        </li>
                                                        <li><a href="checkout.html">Checkout</a>
                                                        </li>
                                                        <li><a href="contact.html">Contacts</a>
                                                        </li>
                                                        <li><a href="review.html">Review</a>
                                                        </li>
                                                        <li><a href="order.html">Order</a>
                                                        </li>
                                                        <li><a href="comment.html">Comment</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Mobile Menu end */}
                    {/* Breadcome start*/}
                    <div className="breadcome-area mg-b-30 des-" style={{ display: '' }}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="breadcome-list map-mg-t-40-gl shadow-reset">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <div className="breadcome-heading">
                                                    <form role="search" className>
                                                        <input type="text" placeholder="Search..." className="form-control" />
                                                        <a href><i className="fa fa-search" /></a>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <ul className="breadcome-menu">
                                                    <li>
                                                        <Link to="/">EU Power</Link><span className="bread-slash">/</span>
                                                    </li>
                                                    <li><span className="bread-blod">User Details</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Breadcome End*/}
                    {/* login Start*/}
                    <div className="data-table-area mg-b-15">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="welcome-wrapper shadow-reset res-mg-t mg-b-30">
                                        <div className="welcome-adminpro-title mg-b-30">
                                            <h1>My Details</h1>
                                        </div>
                                        <div className="adminpro-message-list mg-b-30">
                                            <ul className="message-list-menu">
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-user" /></span> <span className="message-info">Name </span> <span className="message-time">Tom Roberts</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-industry" /></span> <span className="message-info">Company </span> <span className="message-time">ARRACO GLOBAL MARKETS LTD</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-phone" /></span> <span className="message-info">Tel No. 1 </span> <span className="message-time">+ 44 (0)2078589026</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-phone" /></span> <span className="message-info">Tel No. 2 </span> <span className="message-time">(0)7481009400</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-mobile" /></span> <span className="message-info">Mob No.</span> <span className="message-time">+ 44 (0)7481009400</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-fax" /></span> <span className="message-info">Fax No</span> <span className="message-time">null</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-skype" /></span> <span className="message-info">Skype ID </span> <span className="message-time">Tom Roberts123456</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-yahoo" /></span> <span className="message-info">ICE ID </span> <span className="message-time">
                                                    tomr_araxco</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-location-arrow" /></span> <span className="message-info">Address </span> <span className="message-time">
                                                    The Light Bulb , Filament way</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-building-o" /></span> <span className="message-info">City </span> <span className="message-time">London</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-building" /></span> <span className="message-info">State </span> <span className="message-time">Null</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-building" /></span> <span className="message-info">Postcode</span> <span className="message-time">SW184GQ</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-flag" /></span> <span className="message-info">Country </span> <span className="message-time">UK</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-cogs" /></span> <span className="message-info">System Privilege </span> <span className="message-time">Broker</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-user" /></span> <span className="message-info">Role </span> <span className="message-time">Broker</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-envelope" /></span> <span className="message-info">Response E-Mail </span> <span className="message-time">Null</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-envelope-o" /></span> <span className="message-info">Login / Email </span> <span className="message-time">tom@araxcommodities.com</span>
                                                </li>
                                                <li><span className="message-serial message-cl-one"><i className="fa fa-lock" /></span> <span className="message-info">Password </span> <span className="message-time">**************</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <button type="button" className="btn btn-custon-rounded-four btn-success">Update</button>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="welcome-wrapper shadow-reset res-mg-t mg-b-30">
                                        <div className="welcome-adminpro-title mg-b-30">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h1>Company Details</h1>
                                                </div>
                                                <div className="col-md-6">
                                                    <Link to="/Add-Company" className="btn btn-success ml-2 pull-right login-submit-cs "><i className="fa fa-plus-circle" />&nbsp;Add New Company</Link>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="adminpro-message-list mg-b-30">
                                            <ul className="message-list-menu">
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-industry" /></span> <span className="message-info">Company Name </span> <span className="message-time">Tom Roberts</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-envelope" /></span> <span className="message-info">Company Letterhead </span> <span className="message-time"><a href><i className="fa fa-file-pdf-o" aria-hidden="true" /> Download PDF </a></span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-globe" /></span> <span className="message-info">Website </span> <span className="message-time">www.arraco.co.uk</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-envelope-o" /></span> <span className="message-info">E-Mail</span> <span className="message-time">Desk@arraco.co.uk</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-phone" /></span> <span className="message-info">Tel No.</span> <span className="message-time">
                                                    + 44 (0)2078589026</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-map-marker" /></span> <span className="message-info">Address </span> <span className="message-time">
                                                    First Floor, Unit 2 Dolphin House,</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-building" /></span> <span className="message-info">City </span> <span className="message-time">London</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-building-o" /></span> <span className="message-info">State </span> <span className="message-time">Null</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-building" /></span> <span className="message-info">Postcode</span> <span className="message-time">SW18 1DF</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-industry" /></span> <span className="message-info">Company Reg No</span> <span className="message-time">09783536</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-file-text" /></span> <span className="message-info">VAT No </span> <span className="message-time">GB232281241</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-file" /></span> <span className="message-info">Tax ID No. </span> <span className="message-time">Null</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-location-arrow" /></span> <span className="message-info">Billing Address</span> <span className="message-time">Null</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-building" /></span> <span className="message-info">City </span> <span className="message-time">London</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-building-o" /></span> <span className="message-info">State </span> <span className="message-time">Null</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-building" /></span> <span className="message-info">Postcode</span> <span className="message-time">SW18 1DF</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-flag" /></span> <span className="message-info">Country </span> <span className="message-time">UK</span>
                                                </li>
                                                <li><span className="message-serial message-cl-three"><i className="fa fa-cogs" /></span> <span className="message-info">System Privilages </span> <span className="message-time">Broker</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="sparkline10-list shadow-reset mg-t-30">
                                        <div className="sparkline10-hd">
                                            <div className="main-sparkline10-hd">
                                                <h1>Bank Details</h1>
                                                <div className="sparkline10-outline-icon">
                                                    <span className="sparkline10-collapse-link"><i className="fa fa-chevron-up" /></span>
                                                    <span><i className="fa fa-wrench" /></span>
                                                    <span className="sparkline10-collapse-close"><i className="fa fa-times" /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sparkline10-graph">
                                        <div className="static-table-list">
                                            <table className="table border-table">
                                                <thead>
                                                    <tr>
                                                        <th>Bank Name</th>
                                                        <th> Account Type</th>
                                                        <th> Account No.</th>
                                                        <th>IBAN</th>
                                                        <th>BIC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Barclays Business</td>
                                                        <td>EUR</td>
                                                        <td>43687522</td>
                                                        <td>GB79 BUKB 20414143687522</td>
                                                        <td>BUKBGB22</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rabobank</td>
                                                        <td>JPY</td>
                                                        <td>NL93 ABNA 0591 3539 54</td>
                                                        <td>GB17 BARC 2006 0544 0673 33</td>
                                                        <td>RABONL2U</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button type="button" className="mg-t-30 btn btn-custon-rounded-four btn-success">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        );
    }
}

export default Userdetails;