import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import config from './config';
import swal from 'sweetalert';
import { Link, Route, Switch } from "react-router-dom";
import DataTable from 'react-data-table-component';
import moment from 'moment';

class TradeStaging extends Component {

    constructor() {
        super();
        this.state = {
            TradeStagingList: [],
            InstrumentList: [],
            PeriodList: [],
            InstrumentName: "",
            PeriodName: "",
            DealIDFilter: "",
            MainCheckBox: false,
            Height: '0px',
            CurrentPageNo: 1,
            PerPageCount: 50,
            SortColumn: "tradedDate",
            SortColumnType: "Desc",
            TotalCount: 0,
            isShowRefreshLoader: false,
            SearchValue: '',
            RefreshTime: '',
            CommodityName: ""
        }
    }

    handlePageChange = event => {
        this.setState(
            { CurrentPageNo: event },
            function () {
                console.log(this.state.CurrentPageNo);
                this.BindTradeStagingList();
            }
        );
    }

    handlePageCountChange = event => {
        this.setState(
            { PerPageCount: event },
            function () {
                console.log(this.state.PerPageCount);
                this.BindTradeStagingList();
            }
        );
    }

    handleColumnSort = event => {
        this.setState(
            { SortColumn: event.selector },
            function () {
                let SortType = "Desc";
                if (this.state.SortColumnType == "Desc") {
                    SortType = "Asc";
                }

                this.setState(
                    { SortColumnType: SortType },
                    function () {
                        console.log(this.state.SortColumnType);
                        console.log(this.state.SortColumn);
                        this.BindTradeStagingList();
                    }
                )
            }
        );
    }

    onSearch = (event) => {
        this.setState(
            { SearchValue: event.target.value },
            function () {
                console.log(this.state.SearchValue);
                this.BindTradeStagingList();
            }
        );
    }

    ResetFilter() {
        this.setState({ InstrumentName: "" });
        this.setState({ PeriodName: "" });
        this.setState({ DealIDFilter: "" });
        this.BindTradeStagingResetList();
    }

    handleAllChecked = (event) => {
        let tempTradeStagingList = this.state.TradeStagingList
        tempTradeStagingList.forEach(TradeStaging => TradeStaging.isChecked = event.target.checked)
        this.setState({ TradeStagingList: tempTradeStagingList })
        this.setState({ MainCheckBox: event.target.checked })
    }

    handleCheckChieldElement = (event) => {
        let tempTradeStagingList = this.state.TradeStagingList;
        let myCheckedCount = 0;
        tempTradeStagingList.forEach(TradeStaging => {
            if (TradeStaging.dealID == event.target.value) {
                TradeStaging.isChecked = event.target.checked
            }

            if (TradeStaging.isChecked)
                myCheckedCount++;
        })

        if (tempTradeStagingList.length == myCheckedCount)
            this.setState({ MainCheckBox: true })
        else
            this.setState({ MainCheckBox: false })

        this.setState({ TradeStagingList: tempTradeStagingList })
    }

    allowOnlyNumber = e => {
        if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
            (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            return;
        }

        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    }

    MoveToTradeList() {

        let SelectedTradeList = [];
        this.state.TradeStagingList.forEach(TradeStaging => {
            if (TradeStaging.isChecked)
                SelectedTradeList.push(TradeStaging.id);
        })

        if (SelectedTradeList.length > 0) {
            swal({
                title: "Are you sure?",
                text: "Are you sure want to move trade in list section!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.setState({ isShowRefreshLoader: true });
                        const data = JSON.stringify(SelectedTradeList);
                        var POSTURL = config.apiUrl + "Trade/MoveToTradeList";
                        var myLoginToken = localStorage.getItem("AccessToken");

                        fetch(POSTURL, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + myLoginToken,
                                "loginuserid": localStorage.getItem("LogedID"),
                                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                                "commodityid": localStorage.getItem("CommodityID")
                            },
                            body: data,
                            async: false,
                            traditional: true,
                        }).then((resp) => {
                            this.setState({ isShowRefreshLoader: false });
                            if (resp.status === 200) {

                                let tempTradeList = this.state.TradeStagingList;
                                tempTradeList.forEach(TradeItem => {
                                    TradeItem.isChecked = false;
                                });

                                this.setState({
                                    MainCheckBox: false,
                                    TradeStagingList: tempTradeList
                                }, function () {
                                    console.log(this.state.MainCheckBox);
                                })

                                swal({
                                    title: "Done!",
                                    text: "Trade's are succesfully moved to Trade List.",
                                    icon: "success",
                                    button: "Ok!",
                                });
                            } else {
                                swal({
                                    title: "Sorry!",
                                    text: "Trade's are not moved to Trade List.",
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                            this.BindTradeStagingList();
                        });
                    }
                });
        } else {
            swal("Sorry! Please select atleast one Trade to move in list.", {
                icon: "error",
            });
        }
    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 250;
            console.log(myHeight)
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }

    BindInstrumentList() {
        // let commodityId = parseInt(localStorage.getItem("CommodityID"));
        // let FetchURL = config.apiUrl + "Invoice/CreateInvoice/FilterInstrument?commodityid=" + commodityId;
        // fetch(FetchURL, {
        //     method: 'GET',
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // }).then((resp) => {
        //     if (resp.status === 200) {
        //         resp.json().then((result) => {
        //             this.setState({ InstrumentList: result })
        //         });
        //     }
        // });
    }

    BindPeriodList() {
        // let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        // let InstrumentName = this.state.InstrumentName;
        // let PeriodName = this.state.PeriodName;

        // if (InstrumentName == "" || InstrumentName == null || InstrumentName == undefined) {
        //     InstrumentName = "";
        // }

        // if (PeriodName == "" || PeriodName == null || PeriodName == undefined) {
        //     PeriodName = "";
        // }

        // let FetchURL = config.apiUrl + "Trade/TradeStagingList?commodityId=" + CommodityID + "&InstrumentName=" + InstrumentName + "&PeriodName=" + PeriodName;
        // fetch(FetchURL, {
        //     method: 'GET',
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // }).then((resp) => {
        //     if (resp.status === 200) {
        //         resp.json().then((result) => {
        //             this.setState({ TradeStagingList: result });
        //             this.setGridHeight();
        //         });
        //     }
        // });
    }

    RefreshClick = event => {
        this.setState({ isShowRefreshLoader: true });
        let FetchURL = config.apiUrl + "Trade/Download";
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            timeout: 250000
        }).then((resp) => {
            this.SetRefreshtime();
            if (resp.status === 200) {
                resp.json().then((result) => {
                    if (!result.success) {
                        if (result.isManualPrice == false) {
                            swal({
                                title: "Sorry!",
                                text: result.message,
                                icon: "error",
                                button: "Ok!",
                            });
                        } else {

                            let ManualIDString = result.manualAdded.join(', ');

                            swal({
                                title: "The trade listed below have been modified manually. Click ‘Overwrite to updated from Trayport or ‘Cancel’ to manage this trade to Trade List.",
                                text: ManualIDString,
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                                buttons: ['Cancel', 'Overwrite']
                            }).then((willDelete) => {
                                if (willDelete) {
                                    var myLoginToken = localStorage.getItem("AccessToken");
                                    let FetchURLManual = config.apiUrl + "Trade/Download?isManualPrice=true";
                                    fetch(FetchURLManual, {
                                        method: 'GET',
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Authorization": "Bearer " + myLoginToken,
                                            "loginuserid": localStorage.getItem("LogedID"),
                                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                                            "commodityid": localStorage.getItem("CommodityID")
                                        },
                                    }).then((respManual) => {
                                        this.SetRefreshtime();
                                        if (respManual.status === 200) {
                                            respManual.json().then((resultManual) => {
                                                if (!resultManual.success) {
                                                    swal({
                                                        title: "Sorry!",
                                                        text: resultManual.message,
                                                        icon: "error",
                                                        button: "Ok!",
                                                    });
                                                } else {
                                                    swal({
                                                        title: "Completed!",
                                                        text: "Refresh is completed now.",
                                                        icon: "success",
                                                        button: "Ok!",
                                                    });

                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    } else if (result.success) {
                        swal({
                            title: "Completed!",
                            text: "Refresh is completed now.",
                            icon: "success",
                            button: "Ok!",
                        });
                    }
                    this.setState({ isShowRefreshLoader: false });
                    this.BindTradeStagingList();
                });
            }
        });
    }

    RefreshClickOld() {
        let FetchURL = config.apiUrl + "Trade/Download";
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    if (!result) {
                        swal({
                            title: "Sorry!",
                            text: "Something went wrong!",
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    this.BindTradeStagingList();
                });
            }
        });
    }

    BindTradeStagingList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let InstrumentName = this.state.InstrumentName;
        let PeriodName = this.state.PeriodName;
        let DealIDFilter = this.state.DealIDFilter;

        if (InstrumentName == "" || InstrumentName == null || InstrumentName == undefined) {
            InstrumentName = "";
        }

        if (PeriodName == "" || PeriodName == null || PeriodName == undefined) {
            PeriodName = "";
        }

        if (DealIDFilter == "" || DealIDFilter == null || DealIDFilter == undefined) {
            DealIDFilter = "0";
        }

        let FetchURL = config.apiUrl + "Trade/TradeStagingList?commodityId=" + CommodityID + "&InstrumentName=" + InstrumentName + "&PeriodName=" + PeriodName + "&DealID=" + parseInt(DealIDFilter) + "&SortingColumnName=" + this.state.SortColumn + "&AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount + "&SearchQuery=" + this.state.SearchValue;
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            this.setState({ isShowRefreshLoader: false });
            let pageloaddiv1 = document.getElementById("pageloaddiv");
            pageloaddiv1.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalCount: result.totalRecord });
                    this.setState({ TradeStagingList: result.tradeList });
                    this.setGridHeight();
                });
            } else {
                this.setState({ TradeStagingList: [] });
                this.setState({ TotalCount: 0 });
                this.setGridHeight();
            }
        });
    }

    BindTradeStagingResetList() {
        let InstrumentName = "";
        let PeriodName = "";
        let DealIDFilter = "0";
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let FetchURL = config.apiUrl + "Trade/TradeStagingList?commodityId=" + CommodityID + "&InstrumentName=" + InstrumentName + "&PeriodName=" + PeriodName + "&DealID=" + parseInt(DealIDFilter) + "&SortingColumnName=" + this.state.SortColumn + "&AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount + "&SearchQuery=" + this.state.SearchValue;
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalCount: result.totalRecord });
                    this.setState({ TradeStagingList: result.tradeList });
                    this.setGridHeight();
                });
            }
        });
    }

    SetRefreshtime() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Trade/LastRefreshDate";
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ RefreshTime: result.value });
                });
            }
        });
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.setState({ isShowRefreshLoader: true });
            this.setState({ CommodityName: localStorage.getItem('CommodityName') });
            this.SetRefreshtime();
            this.BindInstrumentList();
            this.BindPeriodList();
            this.BindTradeStagingList();

        } else {
            this.props.history.push('/login');
        }
    }

    render() {

        const columns = [
            {
                name: <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" checked={this.state.MainCheckBox} />,
                selector: '',
                sortable: false,
                width: "60px",
                // width: "3%",
                cell: item => item.isManualPrice == true ? <input key={item.dealID} onClick={this.handleCheckChieldElement} type="checkbox" checked={item.isChecked} value={item.dealID} /> : <input key={item.dealID} onClick={this.handleCheckChieldElement} type="checkbox" checked={item.isChecked} value={item.dealID} />
            },
            {
                name: '',
                selector: '',
                sortable: false,
                width: "100px",
                // width: "4%",
                cell: row => <a type="button" className="btn btn-primary  btn-sm" href={"/Trade-Staging-View?id=" + row.id}><i className="ti-eye mr-1" /> View</a>
            },
            {
                name: 'Deal ID',
                selector: 'dealID',
                sortable: true,
                width: "100px",
                // width: "4%",
            },
            {
                name: 'Trade Date',
                selector: 'tradedDate',
                sortable: true,
                width: "200px",
                // width: "7%",
                cell: item => <span>{moment(item.tradedDate).format("DD/MM/YYYY HH:mm:ss")}</span>
            },
            {
                name: 'Instrument',
                selector: 'instrument',
                sortable: true,
                width: "220px",
                // width: "6%",
            },
            {
                name: 'Period',
                selector: 'period',
                sortable: true,
                width: "150px",
                // width: "4%",
            },
            {
                // name: 'Volume',
                name: this.state.CommodityName == "Eu Power" ? "Volume" : this.state.CommodityName == "Eu Gas" ? "Volume" : this.state.CommodityName == "LNG" ? "Lot(s)" : "",
                selector: 'volume',
                sortable: true,
                width: "100px",
                // width: "3%",
            },
            {
                // name: 'Total Volume',
                name: this.state.CommodityName == "Eu Power" ? "Total Volume" : this.state.CommodityName == "Eu Gas" ? "Total Volume" : this.state.CommodityName == "LNG" ? "Total Volume (MMBtu)" : "",
                selector: 'totalVolume',
                sortable: true,
                width: "150px",
                // width: "5%",
            },
            {
                // name: 'Price',
                name: this.state.CommodityName == "Eu Power" ? "Price (MWH)" : this.state.CommodityName == "Eu Gas" ? "Price (MWH)" : this.state.CommodityName == "LNG" ? "Price (USD)" : "",
                selector: 'price',
                sortable: true,
                width: "130px",
                // width: "4%",
                cell: item => <span>{item.price != "" && item.price != "0" && item.price != undefined && item.price != null ? this.state.CommodityName == "Eu Gas" || this.state.CommodityName == "LNG" ? Number(item.price).toFixed(3) : Number(item.price).toFixed(2) : this.state.CommodityName == "Eu Gas" || this.state.CommodityName == "LNG" ? "0.000" : "0.00"}</span>
            },
            {
                name: 'Buyer Init/Aggr',
                selector: 'buyer_Init_Aggr',
                sortable: true,
                width: "150px",
                // width: "5%",
            },
            {
                name: 'Buyer Trader Name',
                selector: 'buyerTraderName',
                sortable: true,
                width: "220px",
                // width: "8%",
            },
            {
                name: 'Buyer',
                selector: 'buyer',
                sortable: true,
                width: "250px",
                // width: "5%",
            },
            {
                name: 'Seller',
                selector: 'seller',
                sortable: true,
                width: "220px",
                // width: "6%",
            },
            {
                name: 'Seller Trader Name',
                selector: 'sellerTraderName',
                sortable: true,
                width: "220px",
                // width: "5%",
            },
            {
                name: 'Seller Init/Aggr',
                selector: 'seller_Init_Aggr',
                sortable: true,
                width: "150px",
                // width: "5%",
            },
            {
                name: 'Init Sleeve',
                selector: 'strInitSleeve',
                sortable: true,
                width: "120px",
                // width: "4%",
                // cell: row => row.initSleeve == true ? "Yes" : ""
            },
            {
                name: 'Agg Sleeve',
                selector: 'strAggrSleeve',
                sortable: true,
                width: "120px",
                // width: "4%",
                // cell: row => row.aggSleeve == true ? "Yes" : ""
            },
            {
                name: 'From Broken Spread',
                selector: 'strFromBrokenSpread',
                sortable: true,
                width: "170px",
                // width: "4%",
                // cell: row => row.fromBrokenSpread == true ? "Yes" : ""
            },
            {
                name: 'Init Bro Rate',
                selector: 'initBroRate',
                sortable: false,
                width: "130px",
                // width: "4%",
                cell: item => <span>{item.initBroRate >= 0 && item.initBroRate != null ? Number(item.initBroRate).toFixed(5) : <p className='text-danger p-t-15 text-bold'>Set Rate</p>}</span>
            },
            {
                name: 'Aggr Bro Rate',
                selector: 'aggrBroRate',
                sortable: true,
                right: false,
                width: "130px",
                // width: "4%",
                cell: item => <span>{item.aggrBroRate >= 0 && item.aggrBroRate != null ? Number(item.aggrBroRate).toFixed(5) : <p className='text-danger p-t-15 text-bold'>Set Rate</p>}</span>
            },
            {
                name: 'Buyer Bro Fee',
                selector: 'buyerBroFee',
                sortable: true,
                right: false,
                width: "130px",
                // width: "3%",
                cell: item => <span>{item.buyerBroFee != "" && item.buyerBroFee != "0" && item.buyerBroFee != undefined && item.buyerBroFee != null ? Number(item.buyerBroFee).toFixed(3) : "0.000"}</span>
            },
            {
                name: 'Seller Bro Fee',
                selector: 'sellerBroFee',
                sortable: true,
                right: false,
                width: "130px",
                // width: "3%",
                cell: item => <span>{item.sellerBroFee != "" && item.sellerBroFee != "0" && item.sellerBroFee != undefined && item.sellerBroFee != null ? Number(item.sellerBroFee).toFixed(3) : "0.000"}</span>
            },
        ];

        const conditionalRowStyles = [
            {
                when: row => row.isReturnFromLive == true,
                style: {
                    color: '#ED1C24'
                },
            },
            {
                when: row => row.isManualPrice == true,
                style: {
                    width: '100%',
                    borderLeft: '3px solid #a00',
                    position: 'relative',
                    left: 0
                },
            },
        ];

        return (
            <div className="layout-wrapper">
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />

                    <div className="content-body">
                        <div id="pageloaddiv" style={{ display: this.state.isShowRefreshLoader === true ? 'block' : 'none' }}>
                            <span><img src="../assets/loader.gif" width={150} height={150} /></span>
                        </div>
                        <div className="content ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="dis-flex">
                                                <h3 className="mb-2">Trade Staging</h3>
                                                <div className="mb-2 ml-auto">
                                                    <button type="button" className="btn btn-primary ml-2 mr-2 mb-2 ml-xs-0" data-toggle="modal" data-target=".bd-example-modal-lg"> <i className="ti-filter mr-1" /> Filters</button>
                                                    {/* <a type="button" className="btn btn-success mb-2  mr-2" href onClick={() => this.RefreshClick()}> <i className="ti-reload mr-1" />Refresh</a> */}
                                                    {!this.state.isShowRefreshLoader && (
                                                        <a type="button" className="btn btn-success mb-2  mr-2" href onClick={this.RefreshClick} > <i className="ti-reload mr-1" />Refresh</a>
                                                    )}

                                                    {this.state.isShowRefreshLoader && (
                                                        <button class="btn btn-success mb-2  mr-2" type="button" disabled="">
                                                            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                                            Loading...
                                                        </button>
                                                        // <a type="button" className="btn btn-success mb-2  mr-2" href > <i className="ti-reload mr-1" /> </a>
                                                    )}

                                                    <button type="button" className="btn btn-danger mb-2 mr-2 ml-xs-0" onClick={() => this.MoveToTradeList()}> <i className="ti-move mr-1" /> Move To Trade List </button>
                                                </div>
                                            </div>
                                            <div className="row mb-3 align-items-center">
                                                <div className='col-md-8'><b>Refresh Time:</b> {this.state.RefreshTime}</div>

                                                <div className='col-md-4'>
                                                    <input type="text" className="form-control clsSearchBar" placeholder="Search here..." onKeyUp={this.onSearch} value={this.state.SearchValue} onChange={(e) => { this.setState({ SearchValue: e.target.value }); }} />
                                                </div>
                                            </div>

                                            {/* <div className="table-responsive" style={{ maxHeight: this.state.Height }}> */}
                                            <div className="table-responsive">

                                                <DataTable
                                                    noDataComponent=""
                                                    columns={columns}
                                                    data={this.state.TradeStagingList}
                                                    pagination="true"
                                                    paginationServer
                                                    paginationPerPage={this.state.PerPageCount}
                                                    highlightOnHover={true}
                                                    paginationTotalRows={this.state.TotalCount}
                                                    paginationRowsPerPageOptions={["50", "100", "150", "200", this.state.TotalCount.toString()]}
                                                    onChangePage={this.handlePageChange}
                                                    onChangeRowsPerPage={this.handlePageCountChange}
                                                    onSort={this.handleColumnSort}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Search Filters</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-md-12">
                                            <form className="row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1">Instrument</label>
                                                    <input type="text" name="InstrumentName" placeholder="Instrument" className="form-control" value={this.state.InstrumentName} onChange={(e) => { this.setState({ InstrumentName: e.target.value }); }} onKeyUp={() => this.BindTradeStagingList()} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1">Period</label>
                                                    <input type="text" name="PeriodName" className="form-control" placeholder="Period" value={this.state.PeriodName} onChange={(e) => { this.setState({ PeriodName: e.target.value }); }} onKeyUp={() => this.BindTradeStagingList()} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1">Deal ID</label>
                                                    <input type="text" name="DealIDFilter" id="DealIDFilter" className="form-control" placeholder="Deal ID" value={this.state.DealIDFilter} onChange={(e) => { this.setState({ DealIDFilter: e.target.value }); }} onKeyUp={() => this.BindTradeStagingList()} onKeyDown={this.allowOnlyNumber} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.ResetFilter()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default TradeStaging;