import React, { Component } from 'react';

class Register extends Component {
    render() {
        return (
            <div className="auth-block">
                <div className="auth-cover" style={{ background: 'url(assets/media/image/auth.jpg)' }} />
                <div className="auth-form">
                    <div className="form-wrapper">
                        {/* logo */}
                        <div id="logo">
                            <img src="assets/media/image/logo.png" alt="logo" style={{ width: 250 }} />
                        </div>
                        <h4>Create account</h4>
                        {/* form */}
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-lg" placeholder="Firstname" required autofocus />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control form-control-lg" placeholder="Lastname" required />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control form-control-lg" placeholder="Email" required />
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="password" className="form-control form-control-lg" placeholder="Password" required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="password" className="form-control form-control-lg" placeholder="Repeat Password" required />
                                    </div>
                                </div>
                            </div>
                            <a className="btn btn-primary btn-lg" href="javascript:void(0);">Register</a>
                            <hr />
                            <p className="text-muted">Already have an account?</p>
                            <a href="/Login" className="btn btn-light btn-sm">Sign in!</a>
                        </form>
                        {/* ./ form */}
                    </div>
                </div>
            </div>

        );
    }
}

export default Register;