import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import config from './config';
import DataTable from 'react-data-table-component';
import queryString from 'query-string';
import moment from 'moment';
import swal from 'sweetalert';

const columns = [
    {
        name: 'Date and Time',
        selector: 'dateCreated',
        sortable: true,
        // width: "200px",
        width: "25%",
        cell: item => <span>{moment(item.dateCreated).format("DD/MM/YYYY HH:mm:ss")}</span>
    },
    {
        name: 'Action',
        selector: 'logAction',
        sortable: true,
        // width: "200px",
        width: "25%",
    },
    {
        name: 'Details',
        selector: 'logDetails',
        sortable: true,
        // width: "1550px",
        width: "50%",
    },
];

class index extends Component {
    constructor() {
        super();
        this.state = {
            LoginInfo: [],
            CommodityName: null,
            Height: '0px',
            CurrentPageNo: 1,
            PerPageCount: 50,
            SortColumn: "dateCreated",
            SortColumnType: "Desc",
            TotalCount: 0,
        }
    }

    handlePageChange = event => {
        this.setState(
            { CurrentPageNo: event },
            function () {
                console.log(this.state.CurrentPageNo);
                this.BindLoginInfo();
            }
        );
    }

    handlePageCountChange = event => {
        this.setState(
            { PerPageCount: event },
            function () {
                console.log(this.state.PerPageCount);
                this.BindLoginInfo();
            }
        );
    }

    handleColumnSort = event => {
        this.setState(
            { SortColumn: event.selector },
            function () {
                let SortType = "Desc";
                if (this.state.SortColumnType == "Desc") {
                    SortType = "Asc";
                }

                this.setState(
                    { SortColumnType: SortType },
                    function () {
                        console.log(this.state.SortColumnType);
                        console.log(this.state.SortColumn);
                        this.BindLoginInfo();
                    }
                )
            }
        );
    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 250;
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }

    BindLoginInfo() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        var myLoginToken = localStorage.getItem("AccessToken");
        let CommodityID = localStorage.getItem("CommodityID");
        let IndividualID = localStorage.getItem("LogedID");
        let FetchLogURL = config.apiUrl + "LogInfo?AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount + "&CommodityId=" + CommodityID + "&IndividualId=" + IndividualID;
        // let FetchLogURL = config.apiUrl + "LogInfo?AscDesc=Desc&PageNo=1&PageSize=5000&CommodityId=" + CommodityID + "&IndividualId=" + IndividualID;
        fetch(FetchLogURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalCount: result.totalRecord });
                    this.setState({ LoginInfo: result.logInfoDetailDto });
                    this.setGridHeight();
                });
            }
        });
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken");
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {

            var iSFirstLogin = localStorage.getItem("iSFirstLogin");

            this.setState({ CommodityName: localStorage.getItem('CommodityName') })
            this.BindLoginInfo();
            if (iSFirstLogin == "1") {
                localStorage.removeItem('iSFirstLogin');
                window.location.reload();
            } else {
                let params = queryString.parse(this.props.location.search);
                if (params != null) {
                    debugger;
                    let client_id = "CAD5672B1804407CBF8D537297D1B291";
                    let client_secret = "K9Z5Otw1FwSCaf2U4qlHFD_brYSpCTA4VKGTeloGc7FldM01";
                    var AuthorizationToken = window.btoa(client_id + ':' + client_secret);
                    console.log("Authorization Token: " + AuthorizationToken);
                    debugger;
                    if (params.code !== undefined && params.code !== null && params.code !== "") {
                        let InvoiceURL = config.apiUrl + "invoice/xerotoken";
                        let objLogin = {
                            tokenurl: "https://identity.xero.com/connect/token",
                            code: params.code,
                            redirecturi: "https://euv2.totikasystems.com/home",
                            authorization_code: AuthorizationToken
                        };

                        const data = JSON.stringify(objLogin);

                        fetch(InvoiceURL, {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + myLoginToken,
                            },
                            body: data,
                            async: false,
                            traditional: true,
                        }).then((resps) => {
                            debugger;
                            if (resps.status === 200) {
                                resps.json().then((results) => {
                                    debugger;
                                    if (results == true) {
                                        swal({
                                            title: "Configured!",
                                            text: "XERO integration configured successfully.",
                                            icon: "success",
                                            button: "Ok!",
                                        });
                                        this.props.history.push({
                                            pathname: '/Home'
                                        });
                                    }
                                });
                            } else {
                                swal({
                                    title: "Sorry!",
                                    text: "XERO integration didn't configured.",
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });


                        // let refreshToken = params.code;
                        // console.log("Refresh Token: " + refreshToken);
                        // debugger;
                        // let FetchLogURL = "https://identity.xero.com/connect/token";
                        // var PostData = "grant_type=authorization_code&code=" + refreshToken + "&redirect_uri=https://localhost:3000/home";
                        // fetch(FetchLogURL, {
                        //     method: 'POST',
                        //     headers: {
                        //         "Content-Type": "application/x-www-form-urlencoded",
                        //         "Authorization": "Basic " + AuthorizationToken,
                        //     },
                        //     body: PostData,
                        // }).then((resp) => {
                        //     debugger;
                        //     if (resp.status === 200) {
                        //         resp.json().then((result) => {
                        //             debugger;
                        //             let access_token = result.access_token;
                        //             let refresh_token = result.refresh_token;
                        //             let InvoiceURL = config.apiUrl + "invoice/xeroconfig?accesstoken=" + access_token + "&refreshtoken=" + refresh_token;

                        //             fetch(InvoiceURL, {
                        //                 method: 'GET',
                        //                 headers: {
                        //                     "Content-Type": "application/json",
                        //                     "Authorization": "Bearer " + myLoginToken,
                        //                 },
                        //             }).then((resps) => {
                        //                 debugger;
                        //                 if (resps.status === 200) {
                        //                     resps.json().then((results) => {
                        //                         debugger;
                        //                         if (results == true) {

                        //                             swal({
                        //                                 title: "Configured!",
                        //                                 text: "XERO integration configured successfully.",
                        //                                 icon: "success",
                        //                                 button: "Ok!",
                        //                             });

                        //                             this.props.history.push({
                        //                                 pathname: '/Home'
                        //                             });
                        //                         }
                        //                     });
                        //                 }else{
                        //                     swal({
                        //                         title: "Sorry!",
                        //                         text: "XERO integration didn't configured.",
                        //                         icon: "error",
                        //                         button: "Ok!",
                        //                     });
                        //                 }
                        //             });

                        //         });
                        //     }else{
                        //         swal({
                        //             title: "Sorry!",
                        //             text: "XERO integration didn't configured.",
                        //             icon: "error",
                        //             button: "Ok!",
                        //         });
                        //     }
                        // });
                    } else if (params.access_token != null && params.access_token != "" && params.access_token != undefined) {
                        debugger;
                    }
                }
            }
        } else {
            this.props.history.push('/login');
        }
    }

    render() {
        return (
            <div className="layout-wrapper">
                <div id="pageloaddiv" style={{ display: 'none' }}>
                    <span><img src="../assets/loader.gif" width={150} height={150} /></span>
                </div>
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />

                    <div className="content-body">
                        <div className="content ">
                            <div className="page-header">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    <h3 className="mb-0">{this.state.CommodityName}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 d-none">
                                    <div className="card h-100">
                                        <div className="card-body text-left">
                                            <a type="button" className="btn btn-primary mr-2 mb-2" href="javascript:void(0);">Client Overview</a>
                                            <a type="button" className="btn btn-success mr-2 mb-2" href="javascript:void(0);">Trade Staging</a>
                                            <a type="button" className="btn btn-secondary mr-2 mb-2" href="javascript:void(0);">Trade List</a>
                                            <a type="button" className="btn btn-info mr-2 mb-2" href="javascript:void(0);">Broker Free</a>
                                            <a type="button" className="btn btn-dark mr-2 mb-2" href="javascript:void(0);">Create Invoice</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <h3 className="mb-4">Recent Activity</h3>
                                    {/* <div className="table-responsive" style={{ maxHeight: this.state.Height }}> */}
                                    <div className="table-responsive">
                                        <DataTable
                                            noDataComponent=""
                                            columns={columns}
                                            data={this.state.LoginInfo}
                                            pagination="true"
                                            paginationServer
                                            paginationPerPage={this.state.PerPageCount}
                                            highlightOnHover={true}
                                            paginationTotalRows={this.state.TotalCount}
                                            paginationRowsPerPageOptions={["50", "100", "150", "200", this.state.TotalCount.toString()]}
                                            onChangePage={this.handlePageChange}
                                            onChangeRowsPerPage={this.handlePageCountChange}
                                            onSort={this.handleColumnSort}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default index;