import React, { Component } from 'react';
import config from './config';
import swal from 'sweetalert';
import { Link, Route, Switch } from "react-router-dom";
import { useHistory } from 'react-router-dom';

class RecoverPassword extends Component {

    constructor() {
        super();
        this.state = {
            Email: "",
        }
    }

    ForgotPassword = event => {
        if (this.state.Email != null && this.state.Email != "" && this.state.Email != undefined) {
            let objLogin = {
                Email: this.state.Email,
                // RememberMe: false
            };

            const data = JSON.stringify(objLogin);
            var POSTURL = config.apiUrl + "forgotpassword";

            fetch(POSTURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
                body: data,
                async: false,
                traditional: true,
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((results) => {
                        debugger;
                        if (results.success == true) {
                            this.props.history.push('/login');
                        } else {
                            swal({
                                title: "Sorry!",
                                text: results.message,
                                icon: "error",
                                button: "Okay!",
                            });
                        }

                        // localStorage.setItem('AccessToken', results.token);
                        // localStorage.setItem('LoginRoleName', results.roleName);
                        // localStorage.setItem('LogedID', results.id);
                        // localStorage.setItem('LoginEmailID', results.email);
                        // localStorage.setItem('LoginCompanyID', results.companyId);
                        // localStorage.setItem('LoginName', results.firstName + " " + results.lastName);

                        // var myLoginToken = localStorage.getItem("AccessToken");
                        // let FtechURL = config.apiUrl + "Commodity";
                        // fetch(FtechURL, {
                        //     method: 'GET',
                        //     headers: {
                        //         'Content-Type': 'application/json',
                        //         "Authorization": "Bearer " + myLoginToken,
                        //         "loginuserid": localStorage.getItem("LogedID"),
                        //         "logincompanyid": localStorage.getItem("LoginCompanyID"),
                        //         "commodityid": localStorage.getItem("CommodityID")
                        //     },
                        // }).then((resp) => {
                        //     if (resp.status === 200) {
                        //         resp.json().then((resultss) => {
                        //             if (resultss != null) {
                        //                 if (resultss.length > 0) {
                        //                     localStorage.setItem('CommodityID', resultss[0].id);
                        //                     localStorage.setItem('CommodityName', resultss[0].name);
                        //                 }
                        //             }
                        //             localStorage.setItem('iSFirstLogin', "1");
                        //             this.props.history.push('/home');
                        //         });
                        //     }
                        // });
                    });
                } else {
                    swal({
                        title: "Failed!",
                        text: "Sorry! Email is incorrect",
                        icon: "error",
                        button: "Okay!",
                    });

                    localStorage.removeItem('AccessToken');
                    localStorage.removeItem('LoginRoleName');
                    localStorage.removeItem('LogedID');
                    localStorage.removeItem('LoginEmailID');
                    localStorage.removeItem('LoginCompanyID');
                    localStorage.removeItem('LoginName');
                    localStorage.removeItem('CommodityID');
                    localStorage.removeItem('CommodityName');
                    localStorage.removeItem('iSFirstLogin');
                }
            });
        }
    };

    componentDidMount() {
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('LoginRoleName');
        localStorage.removeItem('LogedID');
        localStorage.removeItem('LoginEmailID');
        localStorage.removeItem('LoginCompanyID');
        localStorage.removeItem('LoginName');
        localStorage.removeItem('CommodityID');
        localStorage.removeItem('CommodityName');
        localStorage.removeItem('iSFirstLogin');
    }

    render() {
        return (
            <div className="auth-block">
                <div className="auth-cover" style={{ background: 'url(assets/media/image/auth.jpg)' }} />
                <div className="auth-form">
                    <div className="form-wrapper">
                        <div id="logo">
                            <img src="assets/media/image/logo.png" alt="logo" style={{ width: 250 }} />
                        </div>
                        <h4>Forgot password</h4>
                        <form>
                            <div className="form-group">
                                {/* <input type="text" className="form-control form-control-lg" placeholder="Username or email" required autofocus /> */}
                                <input type="text" className="form-control form-control-lg" placeholder="Username or email" value={this.state.Email} onChange={(e) => { this.setState({ Email: e.target.value }); }} required autofocus />
                            </div>
                            <button className="btn btn-primary btn-lg" onClick={() => this.ForgotPassword()} type="button">Submit</button>
                            <hr />
                            <p className="text-muted">Take a different action.</p>
                            {/* <a href="/Registration" className="btn btn-sm btn-light mr-1">Register now!</a>
                             or */}
                            <a href="/Login" className="btn btn-sm btn-light ml-1">Login!</a>
                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

export default RecoverPassword;