import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import { Link, Route, Switch } from "react-router-dom";
import config from './config';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';

class ClientOverview extends Component {
    constructor() {
        super();
        this.state = {
            ClientList: [],
            Height: '0px',
            CurrentPageNo: 1,
            PerPageCount: 50,
            SortColumn: "name",
            SortColumnType: "Asc",
            TotalCount: 0,
            SearchValue: '',
        }
    }

    onSearch = (event) => {
        this.setState(
            { SearchValue: event.target.value },
            function () {
                console.log(this.state.SearchValue);
                debugger;
                this.BindClientList();
            }
        );
    }

    handlePageChange = event => {
        this.setState(
            { CurrentPageNo: event },
            function () {
                console.log(this.state.CurrentPageNo);
                this.BindClientList();
            }
        );
    }

    handlePageCountChange = event => {
        this.setState(
            { PerPageCount: event },
            function () {
                console.log(this.state.PerPageCount);
                this.BindClientList();
            }
        );
    }

    handleColumnSort = event => {
        this.setState(
            { SortColumn: event.selector },
            function () {
                let SortType = "Desc";
                if (this.state.SortColumnType == "Desc") {
                    SortType = "Asc";
                }

                this.setState(
                    { SortColumnType: SortType },
                    function () {
                        console.log(this.state.SortColumnType);
                        console.log(this.state.SortColumn);
                        this.BindClientList();
                    }
                )
            }
        );
    }

    BindClientList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        var myLoginToken = localStorage.getItem("AccessToken");
        let CommodityID = localStorage.getItem("CommodityID");
        let FetchURL = config.apiUrl + "AllCompany?CommodityId=" + CommodityID + "&SortingColumnName=" + this.state.SortColumn + "&AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount + "&SearchQuery=" + this.state.SearchValue;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ ClientList: result.companyDto });
                    this.setState({ TotalCount: result.totalRecord });
                    this.setGridHeight();
                });
            } else {
                this.setState({ ClientList: [] });
                this.setState({ TotalCount: 0 });
            }
        });
    }

    ViewClient(ID) {

    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 200;
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.BindClientList();
        } else {
            this.props.history.push('/login');
        }
    }

    ExportToCsvTradeList() {
        let LoginCompanyID = localStorage.getItem("LoginCompanyID");
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "ExportClientOverview?id=" + LoginCompanyID + "&commodityid=" + localStorage.getItem("CommodityID");
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    window.open(config.contentUrl + result.path, 'ClientOverview');
                });
            }
        });
    }

    render() {
        const columns = [
            {
                name: '',
                selector: '',
                sortable: true,
                right: false,
                width: '100px',
                // width: '10%',
                cell: row => <a type="button" className="btn btn-primary  btn-sm" href={"/Company-Detail?id=" + row.id}><i className="ti-eye mr-1" /> View</a>
            },
            {
                name: 'Company',
                selector: 'name',
                sortable: true,
                width: '330px',
                // width: '20%',
            },
            {
                name: 'Country',
                selector: 'countryName',
                sortable: true,
                right: false,
                width: '170px',
                // width: '10%',
            },
            {
                name: 'System Privilege',
                selector: 'systemPrivileges',
                sortable: true,
                right: false,
                width: '200px',
                // width: '10%',
            },
            {
                name: 'Address',
                selector: 'address',
                sortable: true,
                right: false,
                width: '280px',
                // width: '20%',
            },
            {
                name: 'Website',
                selector: 'website',
                sortable: true,
                right: false,
                width: '250px'
                // width: '15%',
            },
            {
                name: 'Company Reg No',
                selector: 'companyRegNo',
                sortable: true,
                right: false,
                width: '220px'
                // width: '15%',
            },
        ];

        return (
            <div className="layout-wrapper">
                <div id="pageloaddiv" style={{ display: 'none' }}>
                    <span><img src="../assets/loader.gif" width={150} height={150} /></span>
                </div>
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />

                    <div className="content-body">
                        <div className="content ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-2 p-3">
                                                <div className="mr-auto">
                                                    <h3 className="mb-2 cls-inline-block">Client Overview</h3>
                                                </div>

                                                <div className="text-right d-flex ml-auto">
                                                <input type="text" className="form-control clsSearchBar mr-2" placeholder="Search here..." onKeyUp={this.onSearch} value={this.state.SearchValue} onChange={(e) => { this.setState({ SearchValue: e.target.value }); }} />
                                                    <button onClick={() => this.ExportToCsvTradeList()} type="button" className="btn btn-success ml-0 mr-2 mb-2" > <i className="ti-file mr-1" /> Export to Csv</button>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <DataTable
                                                    noDataComponent=""
                                                    columns={columns}
                                                    data={this.state.ClientList}
                                                    pagination="true"
                                                    paginationServer
                                                    paginationPerPage={this.state.PerPageCount}
                                                    highlightOnHover={true}
                                                    paginationTotalRows={this.state.TotalCount}
                                                    paginationRowsPerPageOptions={["50", "100", "150", "200", this.state.TotalCount.toString()]}
                                                    onChangePage={this.handlePageChange}
                                                    onChangeRowsPerPage={this.handlePageCountChange}
                                                    onSort={this.handleColumnSort}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientOverview;