import React, { Component } from 'react';
import config from './config';
import swal from 'sweetalert';
import queryString from 'query-string';
import { Link, Route, Switch } from "react-router-dom";
import { useHistory } from 'react-router-dom';

class ChangePassword extends Component {

    constructor() {
        super();
        this.state = {
            // Email: "",
            Password: "",
            ConfirmPassword: "",
            Code: ""
        }
    }

    ChangePassword = event => {
        if (this.state.Password != null && this.state.Password != "" && this.state.Password != undefined && this.state.ConfirmPassword != null && this.state.ConfirmPassword != "" && this.state.ConfirmPassword != undefined) {

            if (this.state.Password == this.state.ConfirmPassword) {
                let objLogin = {
                    Password: this.state.Password,
                    ConfirmPassword: this.state.ConfirmPassword,
                    Code: this.state.Code,
                };

                const data = JSON.stringify(objLogin);
                var POSTURL = config.apiUrl + "resetpassword";

                fetch(POSTURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    },
                    body: data,
                    async: false,
                    traditional: true,
                }).then((resp) => {
                    debugger;
                    if (resp.status === 200) {
                        resp.json().then((results) => {
                            debugger;
                            if (results.success == true) {
                                this.props.history.push('/login');
                            } else {
                                swal({
                                    title: "Sorry!",
                                    text: results.message,
                                    icon: "error",
                                    button: "Okay!",
                                });
                            }
                        });
                    } else {
                        swal({
                            title: "Failed!",
                            text: "Sorry! Password reset failed.",
                            icon: "error",
                            button: "Okay!",
                        });

                        localStorage.removeItem('AccessToken');
                        localStorage.removeItem('LoginRoleName');
                        localStorage.removeItem('LogedID');
                        localStorage.removeItem('LoginEmailID');
                        localStorage.removeItem('LoginCompanyID');
                        localStorage.removeItem('LoginName');
                        localStorage.removeItem('CommodityID');
                        localStorage.removeItem('CommodityName');
                        localStorage.removeItem('iSFirstLogin');
                    }
                });
            } else {
                swal({
                    title: "Sorry!",
                    text: "Both password are not matched.",
                    icon: "error",
                    button: "Okay!",
                });
            }
        }
    };

    componentDidMount() {
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('LoginRoleName');
        localStorage.removeItem('LogedID');
        localStorage.removeItem('LoginEmailID');
        localStorage.removeItem('LoginCompanyID');
        localStorage.removeItem('LoginName');
        localStorage.removeItem('CommodityID');
        localStorage.removeItem('CommodityName');
        localStorage.removeItem('iSFirstLogin');

        let params = queryString.parse(this.props.location.search);
        if (params != null) {
            var code = params.code;
            if (code != null && code != "" && code != undefined) {
                this.setState({ Code: code });
            }
        }
    }

    render() {
        return (
            <div className="auth-block">
                <div className="auth-cover" style={{ background: 'url(assets/media/image/auth.jpg)' }} />
                <div className="auth-form">
                    <div className="form-wrapper">
                        <div id="logo">
                            <img src="assets/media/image/logo.png" alt="logo" style={{ width: 250 }} />
                        </div>
                        <h4>Change password</h4>
                        <form>
                            {/* <div className="form-group">
                                <input type="text" className="form-control form-control-lg" placeholder="Username or email" value={this.state.Email} onChange={(e) => { this.setState({ Email: e.target.value }); }} required autofocus />
                            </div> */}
                            <div className="form-group">
                                <input type="password" className="form-control form-control-lg" placeholder="Password" value={this.state.Password} onChange={(e) => { this.setState({ Password: e.target.value }); }} required />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control form-control-lg" placeholder="Confirm Password" value={this.state.ConfirmPassword} onChange={(e) => { this.setState({ ConfirmPassword: e.target.value }); }} required />
                            </div>
                            <input type="hidden" value={this.state.Code} id="Code" name="Code" />
                            <button className="btn btn-primary btn-lg" onClick={() => this.ChangePassword()} type="button">Submit</button>
                            <hr />
                            <p className="text-muted">Take a different action.</p>
                            <a href="/Login" className="btn btn-sm btn-light ml-1">Login!</a>
                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

export default ChangePassword;