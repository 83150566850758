import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import { Link, Route, Switch } from "react-router-dom";
import config from './config';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';

class Contacts extends Component {
    constructor() {
        super();
        this.state = {
            ContactList: [],
            Height: '0px',
            CurrentPageNo: 1,
            PerPageCount: 10,
            SortColumn: "firstName",
            SortColumnType: "Asc",
            TotalCount: 0,
            SearchValue: '',
        }
    }

    onSearch = (event) => {
        this.setState(
            { SearchValue: event.target.value },
            function () {
                console.log(this.state.SearchValue);
                this.BindContactList();
            }
        );
    }

    handlePageChange = event => {
        this.setState(
            { CurrentPageNo: event },
            function () {
                console.log(this.state.CurrentPageNo);
                this.BindContactList();
            }
        );
    }

    handlePageCountChange = event => {
        this.setState(
            { PerPageCount: event },
            function () {
                console.log(this.state.PerPageCount);
                this.BindContactList();
            }
        );
    }

    handleColumnSort = event => {
        this.setState(
            { SortColumn: event.selector },
            function () {
                let SortType = "Desc";
                if (this.state.SortColumnType == "Desc") {
                    SortType = "Asc";
                }

                this.setState(
                    { SortColumnType: SortType },
                    function () {
                        console.log(this.state.SortColumnType);
                        console.log(this.state.SortColumn);
                        this.BindContactList();
                    }
                )
            }
        );
    }

    BindContactList() {
        debugger;
        var myLoginToken = localStorage.getItem("AccessToken");
        let CommodityID = localStorage.getItem("CommodityID");
        let FetchURL = config.apiUrl + "AllIndividual?SortingColumnName=" + this.state.SortColumn + "&AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount + "&SearchQuery=" + this.state.SearchValue;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    debugger;
                    this.setState({ ContactList: result.individualList });
                    this.setState({ TotalCount: result.totalRecord });
                    this.setGridHeight();
                });
            } else {
                this.setState({ ContactList: [] });
                this.setState({ TotalCount: 0 });
            }
        });
    }

    ViewClient(ID) {

    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 200;
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.BindContactList();
        } else {
            this.props.history.push('/login');
        }
    }

    render() {
        const columns = [
            {
                name: 'Contact',
                selector: '',
                sortable: true,
                width: '150px',
                cell: row => <span>{row.firstName + " " + row.lastName}</span>
            },
            {
                name: 'Company',
                selector: 'companyName',
                sortable: true,
                width: '100px'
            },
            {
                name: 'Email',
                selector: 'vatemailNo',
                sortable: true,
                right: false,
                width: '200px',
            },
            {
                name: 'Telephone General',
                selector: 'telephone1',
                sortable: true,
                right: false,
                width: '200px',
            },
            {
                name: 'Mobile',
                selector: 'mobileNo',
                sortable: true,
                right: false,
                width: '150px',
            },
            {
                name: 'Skype',
                selector: 'skypeId',
                sortable: true,
                right: false,
                width: '150px',
            },
            {
                name: 'ICE ID',
                selector: 'yahooId',
                sortable: true,
                right: false,
                width: '150px',
            },
            {
                name: 'Address',
                selector: 'add1',
                sortable: true,
                right: false,
                width: '200px',
                cell: row => <span>{row.add1 != null ? row.add1 : + " " + row.add2 != null ? row.add2 : + " " + row.add3 != null ? row.add3 : ""}</span>
            },
            {
                name: 'Role',
                selector: 'systemPrivileges',
                sortable: true,
                right: false,
                width: '150px',
            }
            // ,{
            //     name: 'Actions',
            //     selector: '',
            //     sortable: true,
            //     right: false,
            //     width: "100px",
            //     cell: row => <a type="button" className="btn btn-primary  btn-sm" href={"/Company-Detail?id=" + row.id}><i className="ti-eye mr-1" /> View</a>
            // }
        ];

        return (
            <div className="layout-wrapper">
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />

                    <div className="content-body">
                        <div className="content ">
                            <div className="row">
                                <div className="col-lg-12 text-right">
                                    <a href="ContactDetail" type="button" className="btn btn-primary mb-2"><i className="ti-plus mr-1" /> Add New</a>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h3 className="mb-2 cls-inline-block">Contacts</h3>
                                            <input type="text" className="form-control clsSearchBar" placeholder="Search here..." onKeyUp={this.onSearch} value={this.state.SearchValue} onChange={(e) => { this.setState({ SearchValue: e.target.value }); }} />
                                            <div className="table-responsive">
                                                <DataTable
                                                    columns={columns}
                                                    data={this.state.ContactList}
                                                    pagination="true"
                                                    paginationServer
                                                    paginationPerPage={this.state.PerPageCount}
                                                    highlightOnHover={true}
                                                    paginationTotalRows={this.state.TotalCount}
                                                    paginationRowsPerPageOptions={["10", "20", "50", "100", this.state.TotalCount.toString()]}
                                                    onChangePage={this.handlePageChange}
                                                    onChangeRowsPerPage={this.handlePageCountChange}
                                                    onSort={this.handleColumnSort}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
export default Contacts;