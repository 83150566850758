export default {
    // apiUrl: "https://totikav2.api2.eagletechsolutions.uk/api/",
    // contentUrl: "https://totikav2.api2.eagletechsolutions.uk/",
    apiUrl: "https://apiv2.totikasystems.com/api/",
    contentUrl: "https://apiv2.totikasystems.com/",
    // apiUrl: "http://www.totikav2api.eagletechsolutions.uk/api/",
    // contentUrl: "http://www.totikav2api.eagletechsolutions.uk/",
    // apiUrl: "http://localhost:59666/api/",
    // contentUrl: "http://localhost:59666/",
    CompanyID:1
}