import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import config from './config';
import swal from 'sweetalert';
import { Link, Route, Switch } from "react-router-dom";
import moment from 'moment';
import DataTable from 'react-data-table-component';

class BankHoliday extends Component {

    constructor() {
        super();
        this.state = {
            HolidayList: [],
            Height: '0px',
        }
    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 250;
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }

    BindHolidayList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchURL = config.apiUrl + "Trade/BankHoliday";
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    debugger;
                    this.setState({ HolidayList: result })
                    this.setGridHeight();
                });
            }
        });
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.BindHolidayList();
        } else {
            this.props.history.push('/login');
        }
    }

    render() {

        const columns = [
            {
                name: 'Holiday Date',
                selector: 'holidayDate',
                sortable: true,
                right: false,
                // width: "20%",
                cell: item => <span>{moment(item.holidayDate).format("DD/MM/YYYY")}</span>
            },
            {
                name: 'Day',
                selector: 'holidayDay',
                sortable: true,
                right: false,
                // width: "50%",
            },
            {
                name: 'Holiday Type',
                selector: 'holidayType',
                sortable: true,
                right: false,
                // width: "30%",
            }
        ];

        return (
            <div className="layout-wrapper">
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />
                    <div className="content-body">
                        <div className="content ">
                            <div className="page-header">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    <h3 className="mb-0">Bank Holiday</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* <div className="table-responsive" style={{ maxHeight: this.state.Height }}> */}
                                            <div className="table-responsive">
                                                <DataTable
                                                    noDataComponent=""
                                                    columns={columns}
                                                    data={this.state.HolidayList}
                                                    pagination="true"
                                                />
                                            </div>
                                            {/* <table id="example1" className="table table-striped table-bordered d-xs-block overflow-xs-auto">
                                        <thead>
                                            <tr>
                                                <th>Holiday Date</th>
                                                <th>Day</th>
                                                <th>Holiday Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>28/12/2020</td>
                                                <td>Monday</td>
                                                <td>Christmas Day</td>
                                            </tr>
                                            <tr>
                                                <td>25/12/2020</td>
                                                <td>Monday</td>
                                                <td>Summer bank holiday</td>
                                            </tr>
                                            <tr>
                                                <td>31/08/2020</td>
                                                <td>Monday</td>
                                                <td>Spring bank holiday</td>
                                            </tr>
                                            <tr>
                                                <td>25/05/2020</td>
                                                <td>Monday</td>
                                                <td> Early May bank holiday (VE day)</td>
                                            </tr>
                                            <tr>
                                                <td>08/05/2020</td>
                                                <td>Monday</td>
                                                <td>Easter Monday</td>
                                            </tr>
                                            <tr>
                                                <td>13/04/2020</td>
                                                <td>Monday</td>
                                                <td>Good Friday</td>
                                            </tr>
                                            <tr>
                                                <td>10/04/2020</td>
                                                <td>Monday</td>
                                                <td>New Year’s Day</td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default BankHoliday;