import React, { Component } from 'react';
import { Link, Route, Switch } from "react-router-dom";

class Sidebar extends Component {
    render() {
        return (
            <div className="sidebar-group">
                <div className="sidebar" id="settings">
                    <div className="sidebar-header">
                        Settings
                            <a className="btn-sidebar-close" href="javascript:void(0);">
                            <i className="ti-close" />
                        </a>
                    </div>
                    <div className="sidebar-content">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch5" />
                                    <label className="custom-control-label" htmlFor="customSwitch5">Remember next visits</label>
                                </div>
                            </li>
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch6" />
                                    <label className="custom-control-label" htmlFor="customSwitch6">Enable report
              generation.</label>
                                </div>
                            </li>
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" defaultChecked />
                                    <label className="custom-control-label" htmlFor="customSwitch1">Allow notifications.</label>
                                </div>
                            </li>
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch2" />
                                    <label className="custom-control-label" htmlFor="customSwitch2">Hide user requests</label>
                                </div>
                            </li>
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch3" defaultChecked />
                                    <label className="custom-control-label" htmlFor="customSwitch3">Speed up demands</label>
                                </div>
                            </li>
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch4" defaultChecked />
                                    <label className="custom-control-label" htmlFor="customSwitch4">Hide menus</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar-action">
                        <a href="javascript:void(0);" className="btn btn-primary">All Settings</a>
                    </div>
                </div>
                <div className="sidebar" id="chats">
                    <div className="sidebar-header">
                        Chats (2)
                    <a className="btn-sidebar-close" href="javascript:void(0);">
                            <i className="ti-close" />
                        </a>
                    </div>
                    <div className="sidebar-content">
                        <div className="list-group list-group-flush">
                            <a href="javascript:void(0);" className="list-group-item d-flex px-0 align-items-start">
                                <div className="pr-3">
                                    <span className="avatar avatar-state-danger">
                                        <img src="assets/media/image/user/women_avatar3.jpg" className="rounded-circle" alt="image" />
                                    </span>
                                </div>
                                <div className="flex-grow- 1">
                                    <h6 className="mb-1 text-success">Nolly Palombi</h6>
                                    <span className="text-muted">
                                        <i className="fa fa-image mr-1" /> Photo
            </span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="badge bg-success badge-pill ml-auto mb-2">3</span>
                                    <span className="small text-muted">Yesterday</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item d-flex px-0 align-items-start">
                                <div className="pr-3">
                                    <span className="avatar avatar-state-warning">
                                        <img src="assets/media/image/user/man_avatar4.jpg" className="rounded-circle" alt="image" />
                                    </span>
                                </div>
                                <div className="flex-grow-1">
                                    <p className="mb-1">Lorry Henstridge</p>
                                    <span className="text-muted">
                                        <i className="fa fa-video-camera mr-1" /> Video
            </span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="small text-muted">Last week</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item px-0 d-flex align-items-start">
                                <div className="pr-3">
                                    <div className="avatar avatar-state-danger">
                                        <span className="avatar-title bg-info rounded-circle">T</span>
                                    </div>
                                </div>
                                <div>
                                    <p className="mb-1">Towney Stredder</p>
                                    <span className="text-muted">Hello how are you?</span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="small text-muted">2:32 PM</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item px-0 d-flex align-items-start">
                                <div className="pr-3">
                                    <div className="avatar avatar-state-success">
                                        <img src="assets/media/image/user/women_avatar1.jpg" className="rounded-circle" alt="image" />
                                    </div>
                                </div>
                                <div>
                                    <h6 className="mb-1 text-success">Hobie Kember</h6>
                                    <span className="text-muted">Hi!</span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="badge bg-success badge-pill ml-auto mb-2">3</span>
                                    <span className="small text-muted">08:27 PM</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item d-flex px-0 align-items-start">
                                <div className="pr-3">
                                    <span className="avatar avatar-state-warning">
                                        <img src="assets/media/image/user/women_avatar2.jpg" className="rounded-circle" alt="image" />
                                    </span>
                                </div>
                                <div className="flex-grow- 1">
                                    <p className="mb-1">Kristofer Abramowitch</p>
                                    <span className="text-muted">
                                        <i className="fa fa-image mr-1" /> Photo
            </span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="small text-muted">Yesterday</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item d-flex px-0 align-items-start">
                                <div className="pr-3">
                                    <span className="avatar avatar-state-info">
                                        <img src="assets/media/image/user/man_avatar1.jpg" className="rounded-circle" alt="image" />
                                    </span>
                                </div>
                                <div className="flex-grow-1">
                                    <p className="mb-1">Cirstoforo Velten</p>
                                    <span className="text-muted">
                                        <i className="fa fa-video-camera mr-1" /> Video
            </span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="small text-muted">Last week</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item d-flex px-0 align-items-start">
                                <div className="pr-3">
                                    <span className="avatar avatar-state-secondary">
                                        <span className="avatar-title bg-secondary rounded-circle">C</span>
                                    </span>
                                </div>
                                <div>
                                    <p className="mb-1">Charmaine Massie</p>
                                    <span className="text-muted">Hello how are you?</span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="small text-muted">2:32 PM</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item d-flex px-0 align-items-start">
                                <div className="pr-3">
                                    <span className="avatar avatar-state-success">
                                        <img src="assets/media/image/user/man_avatar2.jpg" className="rounded-circle" alt="image" />
                                    </span>
                                </div>
                                <div>
                                    <p className="mb-1">Cyril Ralston</p>
                                    <span className="text-muted">Hi!</span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="small text-muted">08:27 PM</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item d-flex px-0 align-items-start">
                                <div className="pr-3">
                                    <span className="avatar avatar-state-danger">
                                        <img src="assets/media/image/user/women_avatar3.jpg" className="rounded-circle" alt="image" />
                                    </span>
                                </div>
                                <div className="flex-grow- 1">
                                    <p className="mb-1">Therine Attwool</p>
                                    <span className="text-muted">
                                        <i className="fa fa-image mr-1" /> Photo
            </span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="small text-muted">Yesterday</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item d-flex px-0 align-items-start">
                                <div className="pr-3">
                                    <span className="avatar avatar-state-warning">
                                        <img src="assets/media/image/user/man_avatar4.jpg" className="rounded-circle" alt="image" />
                                    </span>
                                </div>
                                <div className="flex-grow-1">
                                    <p className="mb-1">Ruprecht Lait</p>
                                    <span className="text-muted">
                                        <i className="fa fa-video-camera mr-1" /> Video
            </span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="small text-muted">Last week</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item d-flex px-0 align-items-start">
                                <div className="pr-3">
                                    <span className="avatar avatar-state-info">
                                        <img src="assets/media/image/user/man_avatar1.jpg" className="rounded-circle" alt="image" />
                                    </span>
                                </div>
                                <div className="flex-grow-1">
                                    <p className="mb-1">Cosme Hubbold</p>
                                    <span className="text-muted">
                                        <i className="fa fa-video-camera mr-1" /> Video
            </span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="small text-muted">Last week</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" className="list-group-item d-flex px-0 align-items-start">
                                <div className="pr-3">
                                    <span className="avatar avatar-state-secondary">
                                        <span className="avatar-title bg-secondary rounded-circle">M</span>
                                    </span>
                                </div>
                                <div>
                                    <p className="mb-1">Mallory Darch</p>
                                    <span className="text-muted">Hello how are you?</span>
                                </div>
                                <div className="text-right ml-auto d-flex flex-column">
                                    <span className="small text-muted">2:32 PM</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="sidebar-action">
                        <a href="javascript:void(0);" className="btn btn-primary mr-2">New Chat</a>
                        <a href="javascript:void(0);" className="btn btn-secondary">All Chats</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sidebar;