import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import config from './config';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import queryString from 'query-string';
import Select from 'react-select';

var update = require('immutability-helper');


class Profile extends Component {
    constructor() {
        super();
        this.state = {
            Banks: [],
            LoginInfo: [],
            CountryList: [],
            AccountTypeList: [],
            RoleList: [],
            userid: null,
            userfirstName: null,
            userlastName: null,
            useradd1: null,
            useradd2: null,
            useradd3: null,
            usercity: null,
            usercompanies: null,
            usercompanyName: null,
            usercountry: null,
            usercountryname: null,
            usercreatedBy: null,
            userdateCreated: null,
            useremail: null,
            useremailHome: null,
            useremailWork: null,
            userfax: null,
            userfaxCode: null,
            userfaxCountry: null,
            userfaxCountrySelected: null,
            userindividualCompanyMap: null,
            userisDelete: null,
            usermobileNo: null,
            usermobileNoCode: null,
            usermobileNoCountry: null,
            usermobileNoSelected: null,
            userpostcode: null,
            userresponseEmail: null,
            userskypeId: null,
            userstate: null,
            usertelephone1Selected: null,
            usertelephone1: null,
            usertelephone1Code: null,
            usertelephone1Country: null,
            usertelephone2: null,
            usertelephone2Code: null,
            usertelephone2Selected: null,
            usertelephone2Country: null,
            userupdatedBy: null,
            userupdatedDate: null,
            useryahooId: null,
            usersystemPrivileges: null,
            userroleName: null,
            userroleId: null,
            userRoleIdSelected: null,
            userPassword: null,
            userCPassword: null,
            AddNewRole: null,
            companydetailid: 0,
            companydetailname: null,
            companydetailcompanyId: 0,
            companydetailcopmanyLetterHeadPath: null,
            companydetailcompanyLogoPath: null,
            companydetailcompanyRegNo: null,
            companydetailwebsite: null,
            companydetailemail: null,
            companydetailtelNo: null,
            companydetailfaxNo: null,
            companydetailaddress: null,
            companydetailcity: null,
            companydetailpostcode: null,
            companydetailstate: null,
            companydetailcountry: null,
            companydetailcountryname: null,
            companydetailvatNo: null,
            companydetailtaxIdNo: null,
            companydetailsystemPrivileges: null,
            companydetailBillingAddress: null,
            companydetailBillingCity: null,
            companydetailBillingState: null,
            companydetailBillingPostcode: null,
            companydetailBillingCountry: null,
            companyDetailBillingCountrySelected: null,
            contentUrl: config.contentUrl,
            userCountrySelected: null,
            companyDetailCountrySelected: null,
            CurrentPageNo: 1,
            PerPageCount: 50,
            SortColumn: "dateCreated",
            TotalCount: 0,
            ProfileImage: '',
            isMyDetail: true,
            AccountNoError: "",
            BICError: "",
            IBANError: "",
            FNameError: "",
            LNameError: "",
            BankCountry: null,
            accountTypeSelected: null,
            CompanyTelCountrySelected: null,
            CompanyFaxCountrySelected: null,
            CompanyTelCountry: null,
            CompanyFaxCountry: null,
            CompanyTelCode: null,
            CompanyFaxCode: null,
        }
    }

    handlePageChange = event => {
        this.setState(
            { CurrentPageNo: event },
            function () {
                console.log(this.state.CurrentPageNo);
                this.BindLoginInfo();
            }
        );
    }

    handlePageCountChange = event => {
        this.setState(
            { PerPageCount: event },
            function () {
                console.log(this.state.PerPageCount);
                this.BindLoginInfo();
            }
        );
    }

    handleColumnSort = event => {
        this.setState(
            { SortColumn: event.selector },
            function () {
                console.log(this.state.SortColumn);
                this.BindLoginInfo();
            }
        );
    }

    onChangeProfileImage = event => {
        let CompanyID = localStorage.getItem("LoginCompanyID");
        let LogedID = localStorage.getItem("LogedID");
        let FetchURL = config.apiUrl + "Company/" + CompanyID + "/Individual/" + LogedID + "/ProfileImage";

        const formData = new FormData();
        formData.append("files", event.target.files[0]);
        formData.append("id", LogedID);

        fetch(FetchURL, {
            method: 'POST',
            body: formData,
            processData: false,
            contentType: false,
            // headers: {
            //     'Content-Type': 'application/json',
            //     "Authorization": "Bearer " + myLoginToken,
            //     "loginuserid": localStorage.getItem("LogedID"),
            //     "logincompanyid": localStorage.getItem("LoginCompanyID"),
            //     "commodityid": localStorage.getItem("CommodityID")
            // },
        }).then((resp) => {
            if (resp.status === 200) {
                this.BindUserDetails();
            }
        });
    };

    onChangeCompanyLogo = event => {
        let FetchURL = config.apiUrl + "companylogo";
        let CompanyID = localStorage.getItem("LoginCompanyID");

        const formData = new FormData();
        formData.append("files", event.target.files[0]);
        formData.append("id", CompanyID);
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(FetchURL, {
            method: 'POST',
            body: formData,
            processData: false,
            contentType: false,
            // headers: {
            //     'Content-Type': 'application/json',
            //     "Authorization": "Bearer " + myLoginToken,
            //     "loginuserid": localStorage.getItem("LogedID"),
            //     "logincompanyid": localStorage.getItem("LoginCompanyID"),
            //     "commodityid": localStorage.getItem("CommodityID")
            // },
        }).then((resp) => {
            if (resp.status === 200) {
                this.BindCompanyDetails();
            }
        });
    };

    onChangeCompanyLetterHeadPath = event => {
        let FetchURL = config.apiUrl + "companyletterhead";
        let CompanyID = localStorage.getItem("LoginCompanyID");
        const formData = new FormData();
        formData.append("files", event.target.files[0]);
        formData.append("id", CompanyID);
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchURL, {
            method: 'POST',
            body: formData,
            processData: false,
            // contentType: false, headers: {
            //     'Content-Type': 'application/json',
            //     "Authorization": "Bearer " + myLoginToken,
            //     "loginuserid": localStorage.getItem("LogedID"),
            //     "logincompanyid": localStorage.getItem("LoginCompanyID"),
            //     "commodityid": localStorage.getItem("CommodityID")
            // },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ companydetailcopmanyLetterHeadPath: result });
                });
            }
        });
    };

    SetTelephone1Country = (event) => {
        this.setState({ usertelephone1Selected: event });
        this.setState({ usertelephone1Country: event.value });
        this.setState({ usertelephone1Code: "+" + event.code });
    }

    SetTelephone2Country = (event) => {
        this.setState({ usertelephone2Selected: event });
        this.setState({ usertelephone2Country: event.value });
        this.setState({ usertelephone2Code: "+" + event.code });
    }

    SetMobileNoCountry = (event) => {
        this.setState({ usermobileNoSelected: event });
        this.setState({ usermobileNoCountry: event.value });
        this.setState({ usermobileNoCode: "+" + event.code });
    }

    SetFaxCountry = (event) => {
        this.setState({ userfaxCountrySelected: event });
        this.setState({ userfaxCountry: event.value });
        this.setState({ userfaxCode: "+" + event.code });
    }

    ChnageSameAsCompany = (event) => {
        if (event.target.checked) {
            this.setState({ companydetailBillingAddress: this.state.companydetailaddress });
            this.setState({ companydetailBillingCity: this.state.companydetailcity });
            this.setState({ companydetailBillingState: this.state.companydetailstate });
            this.setState({ companydetailBillingPostcode: this.state.companydetailpostcode });

            if (this.state.companydetailcountry !== null && this.state.companydetailcountry !== undefined && this.state.companydetailcountry !== "") {
                let countryDetails = this.state.CountryList.find(country => country.value === this.state.companydetailcountry);
                if (countryDetails !== undefined) {
                    this.setState({ companyDetailBillingCountrySelected: countryDetails });
                }
            }

            this.setState({ companydetailBillingCountry: this.state.companydetailcountry });
        }
    }

    BindRoleList() {
        let CompanyID = localStorage.getItem("LoginCompanyID");
        let IndividualID = localStorage.getItem("LogedID");
        var FetchURL = config.apiUrl + "Company/" + CompanyID + "/Individual/" + IndividualID + "/Role?individualId=" + IndividualID;
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    var ListOfRoles = [];
                    result.map((item) => {
                        ListOfRoles.push({
                            value: item.id,
                            label: item.roleName
                        });
                    });

                    this.setState({ RoleList: ListOfRoles });
                });
            }
        });
    }

    BindCountryList() {
        let FetchURL = config.apiUrl + "Country";
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    var ListOfCountry = [];
                    result.map((item) => {
                        let CountryId = item.id.toString();
                        ListOfCountry.push({
                            value: CountryId,
                            label: item.name,
                            code: item.code,
                            isO3: item.isO3,
                            iso: item.iso,
                            numcode: item.numcode,
                        });
                    });

                    this.setState({ CountryList: ListOfCountry });
                });
            }
        });
    }

    BindAccountTypeList() {
        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
        let FetchURL = config.apiUrl + "BrokerageRate/GetCurrencyList/" + CommodityID;
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    var ListOfAcounts = [];
                    result.map((item) => {
                        ListOfAcounts.push({
                            value: item.id,
                            label: item.name,
                        });
                    });

                    this.setState({ AccountTypeList: ListOfAcounts });
                });
            }
        });
    }

    BindLoginInfo() {
        let CommodityID = localStorage.getItem("CommodityID");
        let IndividualID = localStorage.getItem("LogedID");
        var myLoginToken = localStorage.getItem("AccessToken");
        let FetchLogURL = config.apiUrl + "LogInfo?AscDesc=Desc&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount + "&CommodityId=" + CommodityID + "&IndividualId=" + IndividualID;
        // let FetchLogURL = config.apiUrl + "LogInfo?AscDesc=Desc&PageNo=" + 1 + "&PageSize=" + 5000 + "&CommodityId=" + CommodityID + "&IndividualId=" + IndividualID;
        fetch(FetchLogURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalCount: result.totalRecord });
                    this.setState({ LoginInfo: result.logInfoDetailDto });
                });
            }
        });
    }

    SaveRoleDetail() {

        let CompanyID = localStorage.getItem("LoginCompanyID");
        let IndividualID = parseInt(localStorage.getItem("LogedID"));
        var AddUserURL = config.apiUrl + "Company/" + CompanyID + "/Individual/" + IndividualID + "/AddRole";

        var val = {
            Id: 0,
            IndividualId: IndividualID,
            RoleName: this.state.AddNewRole
        }

        const data = JSON.stringify(val);
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(AddUserURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {

            if (resp.status === 200) {
                resp.json().then((results) => {
                    this.setState({ AddNewRole: "" })

                    swal({
                        title: "Done!",
                        text: "Role added have been successfully",
                        icon: "success",
                        button: "Okay!",
                    });

                    this.BindRoleList();
                });
            } else {
                swal({
                    title: "Sorry!",
                    text: "Role name has not been added",
                    icon: "error",
                    button: "okay",
                });
            }
        });
    }

    BindUserDetails() {
        let CompanyID = localStorage.getItem("LoginCompanyID");
        let IndividualID = localStorage.getItem("LogedID");
        var FetchCompanyURL = config.apiUrl + "Company/" + CompanyID + "/Individual/" + IndividualID;
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchCompanyURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {

                    this.setState({ userid: result.id });
                    this.setState({ userfirstName: result.firstName });
                    this.setState({ userlastName: result.lastName });
                    this.setState({ useradd1: result.add1 });
                    this.setState({ useradd2: result.add2 });
                    this.setState({ useradd3: result.add3 });
                    this.setState({ usercity: result.city });
                    this.setState({ usercompanies: result.companies });
                    this.setState({ usercompanyName: result.companyName });
                    if (result.country !== null && result.country !== undefined && result.country !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.country);
                        if (countryDetails !== undefined) {
                            this.setState({ userCountrySelected: countryDetails });
                        }
                    }
                    this.setState({ usercountry: result.country });
                    this.setState({ usercountryname: result.countryName });
                    this.setState({ usercreatedBy: result.createdBy });
                    this.setState({ userdateCreated: result.dateCreated });
                    this.setState({ useremail: result.email });
                    this.setState({ useremailHome: result.emailHome });
                    this.setState({ useremailWork: result.emailWork });
                    this.setState({ userfax: result.fax });

                    if (result.faxCode !== null && result.faxCode !== undefined && result.faxCode !== "") {
                        if (result.faxCode.includes("+")) {
                            this.setState({ userfaxCode: result.faxCode });
                        } else {
                            this.setState({ userfaxCode: "+" + result.faxCode });
                        }
                    }

                    if (result.faxCountry !== null && result.faxCountry !== undefined && result.faxCountry !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.faxCountry);
                        if (countryDetails !== undefined) {
                            this.setState({ userfaxCountrySelected: countryDetails });
                        }
                    }

                    this.setState({ userfaxCountry: result.faxCountry });
                    this.setState({ userindividualCompanyMap: result.individualCompanyMap });
                    this.setState({ userisDelete: result.isDelete });
                    this.setState({ userlastName: result.lastName });
                    this.setState({ usermobileNo: result.mobileNo });
                    if (result.mobileNoCode !== null && result.mobileNoCode !== undefined && result.mobileNoCode !== "") {
                        if (result.mobileNoCode.includes("+")) {
                            this.setState({ usermobileNoCode: result.mobileNoCode });
                        } else {
                            this.setState({ usermobileNoCode: "+" + result.mobileNoCode });
                        }
                    }

                    if (result.mobileNoCountry !== null && result.mobileNoCountry !== undefined && result.mobileNoCountry !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.mobileNoCountry);
                        if (countryDetails !== undefined) {
                            this.setState({ usermobileNoSelected: countryDetails });
                        }
                    }

                    this.setState({ usermobileNoCountry: result.mobileNoCountry });
                    this.setState({ userpostcode: result.postcode });
                    this.setState({ userresponseEmail: result.responseEmail });
                    this.setState({ userskypeId: result.skypeId });
                    this.setState({ userstate: result.state });
                    this.setState({ usertelephone1: result.telephone1 });
                    if (result.telephone1Code !== null && result.telephone1Code !== undefined && result.telephone1Code !== "") {
                        if (result.telephone1Code.includes("+")) {
                            this.setState({ usertelephone1Code: result.telephone1Code });
                        } else {
                            this.setState({ usertelephone1Code: "+" + result.telephone1Code });
                        }
                    }

                    if (result.telephone1Country !== null && result.telephone1Country !== undefined && result.telephone1Country !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.telephone1Country);
                        if (countryDetails !== undefined) {
                            this.setState({ usertelephone1Selected: countryDetails });
                        }
                    }

                    this.setState({ usertelephone1Country: result.telephone1Country });
                    this.setState({ usertelephone2: result.telephone2 });
                    if (result.telephone2Code !== null && result.telephone2Code !== undefined && result.telephone2Code !== "") {
                        if (result.telephone2Code.includes("+")) {
                            this.setState({ usertelephone2Code: result.telephone2Code });
                        } else {
                            this.setState({ usertelephone2Code: "+" + result.telephone2Code });
                        }
                    }

                    if (result.telephone2Country !== null && result.telephone2Country !== undefined && result.telephone2Country !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.telephone2Country);
                        if (countryDetails !== undefined) {
                            this.setState({ usertelephone2Selected: countryDetails });
                        }
                    }

                    this.setState({ usertelephone2Country: result.telephone2Country });
                    this.setState({ userupdatedBy: result.updatedBy });
                    this.setState({ userupdatedDate: result.updatedDate });
                    this.setState({ useryahooId: result.yahooId });
                    this.setState({ usersystemPrivileges: result.systemPrivileges });
                    if (result.roleId !== null && result.roleId !== undefined && result.roleId !== "" && result.roleId !== 0) {
                        let roleDetails = this.state.RoleList.find(role => role.value === result.roleId);
                        if (roleDetails !== undefined) {
                            this.setState({ userRoleIdSelected: roleDetails });
                        }
                    }
                    this.setState({ userroleId: result.roleId });
                    this.setState({ userroleName: result.roleName });

                    if (result.profileImage == "" || result.profileImage == null || result.profileImage == undefined) {
                        this.setState({ ProfileImage: "https://www.kindpng.com/picc/m/421-4212356_user-white-icon-png-transparent-png.png" });
                    } else {
                        document.getElementById("image1").src = config.contentUrl + result.profileImage;
                        document.getElementById("image2").src = config.contentUrl + result.profileImage;
                        localStorage.setItem("ProfileImage", result.profileImage);
                        this.setState({ ProfileImage: this.state.contentUrl + '' + result.profileImage });
                    }
                });
            }
        });
    }

    SaveUserDetail() {

        let CompanyID = localStorage.getItem("LoginCompanyID");
        // let CompanyPersonalID = this.state.CompanyPersonalID;
        var AddUserURL = config.apiUrl + "Company/" + CompanyID + "/UpdateIndividual";

        var val = {
            Id: this.state.userid,
            firstName: this.state.userfirstName,
            lastName: this.state.userlastName,
            email: this.state.useremail,
            telephone1Country: this.state.usertelephone1Country,
            telephone1Code: this.state.usertelephone1Code.toString(),
            telephone1: this.state.usertelephone1,
            telephone2Country: this.state.usertelephone2Country,
            telephone2Code: this.state.usertelephone2Code.toString(),
            telephone2: this.state.usertelephone2,
            mobileNoCountry: this.state.usermobileNoCountry,
            mobileNoCode: this.state.usermobileNoCode.toString(),
            mobileNo: this.state.usermobileNo,
            emailWork: this.state.useremailWork,
            emailHome: this.state.useremailHome,
            faxCountry: this.state.userfaxCountry,
            faxCode: this.state.userfaxCode.toString(),
            fax: this.state.userfax,
            skypeId: this.state.userskypeId,
            yahooId: this.state.useryahooId,
            add1: this.state.useradd1,
            add2: this.state.useradd2,
            add3: this.state.useradd3,
            city: this.state.usercity,
            state: this.state.userstate,
            country: this.state.usercountry,
            postcode: this.state.userpostcode,
            isCompanyPersonnel: false,
            responseEmail: this.state.userresponseEmail,
            roleName: this.state.userroleId != '' ? this.state.userroleId.toString() : ''
        }

        const data = JSON.stringify(val);

        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(AddUserURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((results) => {
                    if (results == true) {
                        swal({
                            title: "Done!",
                            text: "User details have been updated",
                            icon: "success",
                            button: "Okay!",
                        });
                        var btnCloseUserDetails = document.getElementById("btnCloseUserDetails");
                        btnCloseUserDetails.click();
                    } else {
                        swal({
                            title: "Sorry!",
                            text: "User details has not been updated",
                            icon: "error",
                            button: "okay",
                        });
                    }
                    this.BindUserDetails();
                });
            }
        });

    }

    SaveChangePassword() {
        var Password = this.state.userPassword;
        var CPassword = this.state.userCPassword;
        var retval = true;

        if (Password == null || Password == "" || Password == undefined) {
            retval = false;
        }

        if (CPassword == null || CPassword == "" || CPassword == undefined) {
            retval = false;
        }

        if (retval) {
            let objChangePassword = {
                Id: this.state.userid,
                OldPassword: this.state.userPassword,
                NewPassword: this.state.userCPassword
            };

            const data = JSON.stringify(objChangePassword);
            let FetchURL = config.apiUrl + "changepassword";
            var myLoginToken = localStorage.getItem("AccessToken");
            fetch(FetchURL, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
                body: data,
                async: false,
                traditional: true,
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((result) => {

                        if (result == true) {
                            swal({
                                title: "Done!",
                                text: "Password updated successfully.",
                                icon: "success",
                                button: "Okay!",
                            });
                        } else {
                            swal({
                                title: "Sorry!",
                                text: "Password not updated successfully.",
                                icon: "error",
                                button: "Okay!",
                            });
                        }

                        this.ClearChangePassword();
                    })
                } else {
                    swal({
                        title: "Failed!",
                        text: "Sorry! Password not change.",
                        icon: "error",
                        button: "Okay!",
                    });
                    this.ClearChangePassword();
                }
            });
        } else {
            swal({
                title: "Failed!",
                text: "Sorry! All fields are required.",
                icon: "error",
                button: "Okay!",
            });
            this.ClearChangePassword();
        }
    }

    ClearChangePassword() {
        this.setState({ userPassword: "" });
        this.setState({ userCPassword: "" });
    }

    BindCompanyDetails() {
        let CompanyID = localStorage.getItem("LoginCompanyID");
        let FetchCompanyURL = config.apiUrl + "Company?Id=" + CompanyID;
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(FetchCompanyURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    if (result.telCountry !== null && result.telCountry !== undefined && result.telCountry !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.telCountry);
                        if (countryDetails !== undefined) {
                            this.setState({ CompanyTelCountrySelected: countryDetails });
                        }
                    }

                    if (result.faxCountry !== null && result.faxCountry !== undefined && result.faxCountry !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.faxCountry);
                        if (countryDetails !== undefined) {
                            this.setState({ CompanyFaxCountrySelected: countryDetails });
                        }
                    }

                    this.setState({ CompanyTelCountry: result.telCountry });

                    if (result.telCode !== null && result.telCode !== undefined && result.telCode !== "") {
                        if (result.telCode.includes("+")) {
                            this.setState({ CompanyTelCode: result.telCode });
                        } else {
                            this.setState({ CompanyTelCode: "+" + result.telCode });
                        }
                    }

                    if (result.faxCode !== null && result.faxCode !== undefined && result.faxCode !== "") {
                        if (result.faxCode.includes("+")) {
                            this.setState({ CompanyFaxCode: result.faxCode });
                        } else {
                            this.setState({ CompanyFaxCode: "+" + result.faxCode });
                        }
                    }

                    this.setState({ CompanyFaxCountry: result.faxCountry });

                    this.setState({ companydetailid: result.id });
                    this.setState({ companydetailname: result.name });
                    this.setState({ companydetailcompanyId: result.companyId });
                    this.setState({ companydetailcopmanyLetterHeadPath: result.copmanyLetterHeadPath });
                   
                    fetch(config.contentUrl + result.companyLogoPath, {            
                        type:'HEAD',           
                    }).then((resp) => {
                        if (resp.status === 404) {   
                            this.setState({ companydetailcompanyLogoPath: null });                        
                        }
                        else{
                            this.setState({ companydetailcompanyLogoPath: result.companyLogoPath });
                        }
                    });

                    this.setState({ companydetailcompanyRegNo: result.companyRegNo });
                    this.setState({ companydetailwebsite: result.website });
                    this.setState({ companydetailemail: result.email });
                    this.setState({ companydetailtelNo: result.telNo });
                    this.setState({ companydetailfaxNo: result.faxNo });
                    this.setState({ companydetailaddress: result.address });
                    this.setState({ companydetailcity: result.city });
                    this.setState({ companydetailpostcode: result.postcode });
                    this.setState({ companydetailstate: result.state });
                    if (result.country !== null && result.country !== undefined && result.country !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.value === result.country);
                        if (countryDetails !== undefined) {
                            this.setState({ companyDetailCountrySelected: countryDetails });
                        }
                    }
                    this.setState({ companydetailcountry: result.country });
                    this.setState({ companydetailcountryname: result.countryName });
                    this.setState({ companydetailvatNo: result.vatNo });
                    this.setState({ companydetailtaxIdNo: result.taxIdNo });
                    this.setState({ companydetailsystemPrivileges: result.systemPrivileges });

                    this.setState({ companydetailBillingAddress: result.billingAddress });
                    this.setState({ companydetailBillingCity: result.billingCity });
                    this.setState({ companydetailBillingState: result.billingState });
                    this.setState({ companydetailBillingPostcode: result.billingPostcode });
                    if (result.billingCountry !== null && result.billingCountry !== undefined && result.billingCountry !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.label === result.billingCountry);
                        if (countryDetails !== undefined) {
                            this.setState({ companyDetailBillingCountrySelected: countryDetails });
                        }
                    }
                    this.setState({ companydetailBillingCountry: result.billingCountry });
                });
            }
        });

    }

    UpdateComapanyDetail() {
        let CompanyID = localStorage.getItem("LoginCompanyID");
        let companydetailid = this.state.companydetailid;
        var AddBankURL = config.apiUrl + "UpdateCompany";

        var val = {
            Id: companydetailid,
            name: this.state.companydetailname,
            companyId: CompanyID,
            copmanyLetterHeadPath: this.state.companydetailcopmanyLetterHeadPath,
            companyLogoPath: this.state.companydetailcompanyLogoPath,
            companyRegNo: this.state.companydetailcompanyRegNo,
            website: this.state.companydetailwebsite,
            email: this.state.companydetailemail,
            telNo: this.state.companydetailtelNo,
            faxNo: this.state.companydetailfaxNo,
            address: this.state.companydetailaddress,
            city: this.state.companydetailcity,
            postcode: this.state.companydetailpostcode,
            state: this.state.companydetailstate,
            country: this.state.companydetailcountry,
            vatNo: this.state.companydetailvatNo,
            taxIdNo: this.state.companydetailtaxIdNo,
            systemPrivileges: this.state.companydetailsystemPrivileges,
            billingAddress: this.state.companydetailBillingAddress,
            billingCity: this.state.companydetailBillingCity,
            billingState: this.state.companydetailBillingState,
            billingPostcode: this.state.companydetailBillingPostcode,
            billingCountry: this.state.companydetailBillingCountry,
            telCountry: this.state.CompanyTelCountry,
            faxCountry: this.state.CompanyFaxCountry,
            telCode: this.state.CompanyTelCode,
            faxCode: this.state.CompanyFaxCode
        }

        const data = JSON.stringify(val);
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(AddBankURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {

            if (resp.status === 200) {
                resp.json().then((results) => {
                    swal({
                        title: "Done!",
                        text: "Company details have been updated",
                        icon: "success",
                        button: "Okay!",
                    });
                    this.BindCompanyDetails();
                });
            }
        });
    }

    BindBanks() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CompanyID = localStorage.getItem("LoginCompanyID");
        let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/Bank";
        fetch(FetchBankURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {

            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ Banks: result })
                });
            }
        });
        this.ResetBank();
    }

    onchangeAccountNo = (event) => {
        this.setState({ accountNumber: event.target.value });
    }

    onchangeBIC = (event) => {
        this.setState({ bic: event.target.value });
    }

    onchangeIBAN = (event) => {
        this.setState({ iban: event.target.value });
    }

    SaveBank() {


        let CompanyID = localStorage.getItem("LoginCompanyID");
        var AddBankURL = config.apiUrl + "Company/" + CompanyID + "/bank";
        let BankID = this.state.BankID;
        var Method = "POST";
        let id = 0;

        if (BankID > 0) {
            id = BankID;
            AddBankURL = config.apiUrl + "Company/" + CompanyID + "/UpdateBank";
            Method = "POST";
        }

        var val = {
            Id: id,
            CompanyId: parseInt(CompanyID),
            bankName: this.state.bankName,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            postcode: this.state.postcode,
            country: this.state.country,
            accountType: this.state.accountType,
            accountNumber: this.state.accountNumber,
            bic: this.state.bic,
            iban: this.state.iban,
        }

        const data = JSON.stringify(val);
        var myLoginToken = localStorage.getItem("AccessToken");
        fetch(AddBankURL, {
            method: Method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
            body: data,
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((results) => {
                    if (results == true) {
                        swal({
                            title: "Good job!",
                            text: "Bank have been added",
                            icon: "success",
                            button: "Okay!",
                        });
                        this.BindBanks();
                        var btnCloseBank = document.getElementById("btnCloseBank");
                        btnCloseBank.click();
                    } else {
                        swal({
                            title: "Sorry!",
                            text: "Bank has not been added",
                            icon: "error",
                            button: "okay",
                        });
                    }
                });
            }
        });

    }

    EditBank(id) {
        var myLoginToken = localStorage.getItem("AccessToken");
        let CompanyID = localStorage.getItem("LoginCompanyID");
        let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/Bank/" + id;
        fetch(FetchBankURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ BankID: result.id });
                    this.setState({ companyId: result.companyId });
                    this.setState({ bankName: result.bankName });
                    this.setState({ address: result.address });
                    this.setState({ city: result.city });
                    this.setState({ state: result.state });
                    if (result.country !== null && result.country !== undefined && result.country !== "") {
                        let countryDetails = this.state.CountryList.find(country => country.label === result.country);
                        if (countryDetails !== undefined) {
                            this.setState({ BankCountry: countryDetails });
                        }
                    }
                    this.setState({ country: result.country });
                    this.setState({ postcode: result.postcode });
                    if (result.accountType !== null && result.accountType !== undefined && result.accountType !== "") {
                        let AccountDetails = this.state.AccountTypeList.find(country => country.label === result.accountType);
                        if (AccountDetails !== undefined) {
                            this.setState({ accountTypeSelected: AccountDetails });
                        }
                    }
                    this.setState({ accountType: result.accountType });
                    this.setState({ accountNumber: result.accountNumber });
                    this.setState({ bic: result.bic });
                    this.setState({ iban: result.iban });
                });
            }
        });
    }

    DeleteBank(ID) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover bank deatil!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let CompanyID = localStorage.getItem("LoginCompanyID");
                    var myLoginToken = localStorage.getItem("AccessToken");
                    let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/DeleteBank/" + ID;
                    fetch(FetchBankURL, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + myLoginToken,
                            "loginuserid": localStorage.getItem("LogedID"),
                            "logincompanyid": localStorage.getItem("LoginCompanyID"),
                            "commodityid": localStorage.getItem("CommodityID")
                        },
                    }).then((resp) => {
                        swal("Bank Account have been deleted!", {
                            icon: "success",
                        });
                        this.BindBanks();
                    });
                    this.ResetBank();
                } else {
                    swal("Bank Account have been safe!", {
                        icon: "error",
                    });
                }
            });
    }

    ResetBank() {
        this.setState({ BankID: 0 });
        this.setState({ companyId: 0 });
        this.setState({ bankName: null });
        this.setState({ address: null });
        this.setState({ city: null });
        this.setState({ state: null });
        this.setState({ country: "" });
        this.setState({ postcode: null });
        this.setState({ accountType: "" });
        this.setState({ accountNumber: "" });
        this.setState({ bic: "" });
        this.setState({ iban: "" });
        this.setState({ AccountNoError: "" });
        this.setState({ BICError: "" });
        this.setState({ IBANError: "" });
        document.getElementById("BankName").value = null;
        document.getElementById("BankAddress").value = null;
        document.getElementById("BankCity").value = null;
        document.getElementById("BankState").value = null;
        document.getElementById("BankPostcode").value = null;
        document.getElementById("BankCountry").value = "";
        document.getElementById("BankAccountType").value = "";
        document.getElementById("BankAccountNo").value = null;
        document.getElementById("BankBIC").value = null;
        document.getElementById("BankIBAN").value = null;
        document.getElementById("BankID").value = 0;

        this.BindCompanyDetails();
    }

    ChangeCurrentTab(myPage) {
        this.setState(
            { isMyDetail: myPage },
            function () {
                console.log(this.state.isMyDetail);
            }
        );
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            this.BindCountryList();
            this.BindAccountTypeList();
            this.BindRoleList();
            this.BindCompanyDetails();
            this.BindUserDetails();
            this.BindLoginInfo();
            this.BindBanks();

            let params = queryString.parse(this.props.location.search);
            if (params != null) {
                var companydetail = params.companydetail;
                if (companydetail != null && companydetail != "" && companydetail != undefined) {
                    this.setState({ isMyDetail: false });
                }
            }
        } else {
            this.props.history.push('/login');
        }
    }

    onChangeMobileNo = (event) => {
        this.setState(
            { usermobileNo: event },
            function () {

            }
        );
    }

    onChangeTelNo = (event) => {
        this.setState(
            { usertelephone1: event },
            function () {
            }
        );
    }

    onChangeLastName = (event) => {
        this.setState({ userlastName: event.target.value });
    }

    onChangeFirstName = (event) => {
        this.setState({ userfirstName: event.target.value });
    }

    SetCountry = (event) => {
        this.setState({ userCountrySelected: event });
        this.setState({ usercountry: event.value });
    }

    SetRole = (event) => {
        this.setState({ userRoleIdSelected: event });
        this.setState({ userroleId: event.value });
    }

    SetCompanyCountry = (event) => {
        this.setState({ companyDetailCountrySelected: event });
        this.setState({ companydetailcountry: event.value });
    }

    SetCompanyBillingCountry = (event) => {
        this.setState({ companyDetailBillingCountrySelected: event });
        this.setState({ companydetailBillingCountry: event.label });
    }

    SetBankCountry = (event) => {
        this.setState({ BankCountry: event });
        this.setState({ country: event.label });
    }

    SetAccountType = (event) => {
        this.setState({ accountTypeSelected: event });
        this.setState({ accountType: event.label });
    }

    SetCompanyTelephone1Country = (event) => {
        this.setState({ CompanyTelCountrySelected: event });
        this.setState({ CompanyTelCountry: event.value });
        this.setState({ CompanyTelCode: "+" + event.code });
    }

    SetCompanyFaxCountry = (event) => {
        this.setState({ CompanyFaxCountrySelected: event });
        this.setState({ CompanyFaxCountry: event.value });
        this.setState({ CompanyFaxCode: "+" + event.code });
    }

    render() {

        const NoBorderStyles = {
            borderTop: 'none',
        };

        const columns = [
            {
                name: 'Date and Time',
                selector: 'dateCreated',
                sortable: true,
                // width: "200px",
                width: "50%",
                cell: item => <span>{moment(item.dateCreated).format("DD/MM/YYYY HH:mm:ss")}</span>
            },
            // {
            //     name: 'Action',
            //     selector: 'logAction',
            //     sortable: true,
            //     width: "200px",
            // },
            {
                name: 'Details',
                selector: 'logDetails',
                sortable: true,
                // width: "1550px",
                width: "50%",
            },
        ];

        const BankListColumns = [
            {
                name: 'Bank Name',
                selector: 'bankName',
                sortable: true,
                right: false,
                width: "25%"
            },
            {
                name: 'Account Type',
                selector: 'accountTypeName',
                sortable: true,
                right: false,
                width: "15%"
            },
            {
                name: 'Account No',
                selector: 'accountNumber',
                sortable: true,
                right: false,
                width: "15%"
            },
            {
                name: 'IBAN',
                selector: 'iban',
                sortable: true,
                right: false,
                width: "15%"
            },
            {
                name: 'BIC',
                selector: 'bic',
                sortable: true,
                right: false,
                width: "10%"
            },
            {
                name: '',
                selector: '',
                sortable: true,
                right: false,
                width: "20%",
                cell: item => <div class="d-flex"> <a type="button" className="btn-action btn btn-primary  btn-sm" data-toggle="modal" data-target=".add-bank" onClick={() => this.EditBank(item.id)}><i className="ti-reload mr-1" /> Update</a> <a type="button" className="btn-action btn btn-danger  btn-sm sweet-multiple ml-1" onClick={() => this.DeleteBank(item.id)}><i className="ti-trash mr-1" /> Delete</a></div>
            },
        ];

        return (
            <div className="layout-wrapper">
                <div id="pageloaddiv" style={{ display: 'none' }}>
                    <span><img src="../assets/loader.gif" width={150} height={150} /></span>
                </div>
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />
                    <div className="content-body">
                        <div className="content p-0">
                            <div className="profile-container custom-shadow" style={{ background: 'url(assets/media/image/image1.jpg)' }}>
                                <div className="text-center mb-5">
                                    <figure className="rounded-circle mainlogowhite">
                                        {this.state.companydetailcompanyLogoPath != null
                                            && this.state.companydetailcompanyLogoPath != ""
                                            && this.state.companydetailcompanyLogoPath != undefined ?
                                            <img src={this.state.contentUrl + '' + this.state.companydetailcompanyLogoPath}
                                                className="rounded-circle logoheight" alt="..." onError="assets/media/image/logo.png" />
                                            :
                                            <img src="assets/media/image/logo.png" className="heightnull" alt="..." />
                                        }
                                        {/* <img src="assets/media/image/logo.png" className="heightnull" alt="..." /> */}
                                    </figure>

                                    <div>
                                        <h4 className="mb-0 mt-3">{this.state.userfirstName} {this.state.userlastName}</h4>

                                    </div>
                                </div>
                                <div className="profile-menu">

                                    {this.state.isMyDetail ?
                                        <ul className="nav nav-pills justify-content-center" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="home-tab22" data-toggle="tab" href="#home" role="tab" aria-selected="true" onClick={() => this.ChangeCurrentTab(true)}>My Details</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="earnings-tab" data-toggle="tab" href="#earnings" role="tab" aria-selected="false" onClick={() => this.ChangeCurrentTab(false)}>Company Details</a>
                                            </li>
                                        </ul>
                                        :
                                        <ul className="nav nav-pills justify-content-center" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link" id="home-tab22" data-toggle="tab" href="#home" role="tab" aria-selected="false" onClick={() => this.ChangeCurrentTab(true)}>My Details</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link active" id="earnings-tab" data-toggle="tab" href="#earnings" role="tab" aria-selected="true" onClick={() => this.ChangeCurrentTab(false)}>Company Details</a>
                                            </li>
                                        </ul>
                                    }

                                </div>
                            </div>
                            <div className="p-4">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="tab-content" id="myTabContent">

                                            {/* <div className="tab-pane fade show active" id="home" role="tabpanel"> */}
                                            <div className={"tab-pane fade " + (this.state.isMyDetail ? 'show active' : '')} id="home" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <h4 className="mb-4">User Details</h4>
                                                        <div className="card" style={{ maxHeight: 904 }}>
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-hover">
                                                                        {/* <thead className="thead-dark">
                                                                            <tr>
                                                                                <th width="15%" style={{ textAlign: "left" }}>Fields</th>
                                                                                <th width="1%" style={{ textAlign: "center" }}></th>
                                                                                <th width="34%" style={{ textAlign: "left" }}>Details</th>
                                                                                <th width="15%" style={{ textAlign: "left" }}>Fields</th>
                                                                                <th width="1%" style={{ textAlign: "center" }}></th>
                                                                                <th width="34%" style={{ textAlign: "left" }}>Details</th>
                                                                            </tr>
                                                                        </thead> */}
                                                                        <tbody>

                                                                            {/* <tr>
                                                                                <td style={{ bordertop: 'none' }}>Raj</td>
                                                                            </tr> */}


                                                                            <tr>
                                                                                <td className="font-weight-800" style={NoBorderStyles}>Name </td>
                                                                                <td style={NoBorderStyles}>:</td>
                                                                                <td style={NoBorderStyles}>{this.state.userfirstName} {this.state.userlastName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Company </td>
                                                                                <td>:</td>
                                                                                {/* <td>{this.state.usercompanyName} </td> */}
                                                                                <td>{this.state.companydetailname} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Tel No. 1 </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.usertelephone1Code} {this.state.usertelephone1} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Tel No. 2 </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.usertelephone2Code} {this.state.usertelephone2} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Mob No. </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.usermobileNoCode} {this.state.usermobileNo} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Fax No. </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.userfaxCode} {this.state.userfax} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Skype ID </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.userskypeId} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">ICE ID </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.useryahooId} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Address </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.useradd1}, {this.state.useradd2}, {this.state.useradd3} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">City </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.usercity} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">State </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.userstate} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Postcode </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.userpostcode} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Country </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.usercountryname} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">System Privilege </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.usersystemPrivileges} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Role </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.userroleName} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Response E-Mail </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.userresponseEmail}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Login / Email </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.useremail}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Password </td>
                                                                                <td>:</td>
                                                                                <td>********</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {/* <a type="button" className="btn btn-primary mt-3" data-toggle="modal" data-target=".user-detail" onClick={() => this.EditCompanyPersonal(this.state.id)}><i className="ti-reload mr-1" /> Update</a> */}
                                                                <a style={{ float: "right" }} type="button" className="btn btn-primary mt-3" data-toggle="modal" data-target=".user-detail" onClick={() => this.BindUserDetails()}><i className="ti-reload mr-1" /> Update</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 recentActivity" >
                                                        <h4 className="mb-4">Recent Activity</h4>
                                                        <DataTable
                                                            noDataComponent=""
                                                            columns={columns}
                                                            data={this.state.LoginInfo}
                                                            pagination="true"
                                                            paginationServer
                                                            paginationPerPage={this.state.PerPageCount}
                                                            highlightOnHover={true}
                                                            paginationTotalRows={this.state.TotalCount}
                                                            paginationRowsPerPageOptions={["50", "100", "150", "200", this.state.TotalCount.toString()]}
                                                            onChangePage={this.handlePageChange}
                                                            onChangeRowsPerPage={this.handlePageCountChange}
                                                            onSort={this.handleColumnSort}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            {/* <div className="tab-pane fade" id="earnings" role="tabpanel"> */}
                                            <div className={"tab-pane fade " + (this.state.isMyDetail ? '' : 'show active')} id="earnings" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="d-flex">
                                                            <h4 className="mb-4">Company Details</h4>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-hover">
                                                                        {/* <thead className="thead-dark">
                                                                            <tr>
                                                                                <th width="7%" style={{ textAlign: "left" }}>Fields</th>
                                                                                <th width="1%" style={{ textAlign: "center" }}></th>
                                                                                <th width="17%" style={{ textAlign: "left" }}>Details</th>
                                                                                <th width="7%" style={{ textAlign: "left" }}>Fields</th>
                                                                                <th width="1%" style={{ textAlign: "center" }}></th>
                                                                                <th width="17%" style={{ textAlign: "left" }}>Details</th>
                                                                                <th width="7%" style={{ textAlign: "left" }}>Fields</th>
                                                                                <th width="1%" style={{ textAlign: "center" }}></th>
                                                                                <th width="17%" style={{ textAlign: "left" }}>Details</th>
                                                                                <th width="7%" style={{ textAlign: "left" }}>Fields</th>
                                                                                <th width="1%" style={{ textAlign: "center" }}></th>
                                                                                <th width="17%" style={{ textAlign: "left" }}>Details</th>
                                                                            </tr>
                                                                        </thead> */}
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="font-weight-800" style={NoBorderStyles}>Company Name </td>
                                                                                <td style={NoBorderStyles}>:</td>
                                                                                <td style={NoBorderStyles}>{this.state.companydetailname}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Company Reg No </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailcompanyRegNo} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Website </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailwebsite}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">E-Mail </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailemail} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Tel No </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailtelNo}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Fax No </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailfaxNo} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Address </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailaddress}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">City </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailcity} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Postcode </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailpostcode}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">State </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailstate} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Country </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailcountryname}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">VAT No </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailvatNo} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Tax ID No </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailtaxIdNo}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">System Privilege </td>
                                                                                <td>:</td>
                                                                                <td>{this.state.companydetailsystemPrivileges} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Company Letterhead </td>
                                                                                <td>:</td>
                                                                                {this.state.companydetailcopmanyLetterHeadPath === "" || this.state.companydetailcopmanyLetterHeadPath === null ?
                                                                                    (
                                                                                        <td></td>
                                                                                    ) : (
                                                                                        <td>
                                                                                            <a href={this.state.contentUrl + '' + this.state.companydetailcopmanyLetterHeadPath} target="_blank"><i class="fa fa-file-pdf-o text-danger pdf-icon-font"></i>
                                                                                            </a>
                                                                                        </td>
                                                                                    )}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="font-weight-800">Company Logo </td>
                                                                                <td>:</td>
                                                                                {this.state.companydetailcompanyLogoPath === "" || this.state.companydetailcompanyLogoPath === null ?
                                                                                    (
                                                                                        <td>
                                                                                            <img src="assets/media/image/logo.png" className="display-logo" />
                                                                                        </td>
                                                                                    ) : (
                                                                                        <td>
                                                                                            <img src={this.state.contentUrl + '' + this.state.companydetailcompanyLogoPath} className="display-logo" />
                                                                                        </td>
                                                                                    )}
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <a style={{ float: "right", marginRight: "16px" }} type="button" className="btn btn-primary mb-3" data-toggle="modal" data-target=".add-company" onClick={() => this.BindCompanyDetails()}><i className="ti-reload mr-1" /> Update</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mybank-box">
                                                            <div className="d-flex">
                                                                <h4 className="mb-4">Bank Account(s)</h4>
                                                            </div>
                                                            <div className="card mt-2">
                                                                <div className="table-responsive">
                                                                    <DataTable
                                                                        columns={BankListColumns}
                                                                        data={this.state.Banks}
                                                                        pagination="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="ml-auto">
                                                                    <a type="button" className="btn btn-primary mb-2" data-toggle="modal" data-target=".add-bank" onClick={() => this.ResetBank()}><i className="ti-plus mr-1" /> Add Bank</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        <div className="modal fade add-company" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Update Company Detail</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.BindCompanyDetails()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Company Name</label>
                                                    <input type="text" className="form-control col-md-9" id="CCompany" placeholder="Company Name" value={this.state.companydetailname} disabled />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Website</label>
                                                    <input type="text" className="form-control col-md-9" id="CWebSite" placeholder="Website" value={this.state.companydetailwebsite} onChange={(e) => { this.setState({ companydetailwebsite: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">E- Mail</label>
                                                    <input type="email" className="form-control col-md-9" id="CEmail" placeholder="Email" value={this.state.companydetailemail} onChange={(e) => { this.setState({ companydetailemail: e.target.value }); }} />
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">Tel No.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.CompanyTelCountrySelected}
                                                            onChange={this.SetCompanyTelephone1Country}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Tel Code"
                                                            id="telephone1Code"
                                                            value={this.state.CompanyTelCode}
                                                            onChange={(e) => { this.setState({ CompanyTelCode: e.target.value }); }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Tel No."
                                                            id="telephone1"
                                                            value={this.state.companydetailtelNo}
                                                            onChange={(e) => { this.onChangeTelNo(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">Fax No.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.CompanyFaxCountrySelected}
                                                            onChange={this.SetCompanyFaxCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="text" className="form-control" placeholder="FAX Code" id="faxCode" value={this.state.CompanyFaxCode} onChange={(e) => { this.setState({ CompanyFaxCode: e.target.value }); }} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="number"
                                                            className="form-control" placeholder="FAX No." id="fax"
                                                            value={this.state.companydetailfaxNo}
                                                            onChange={(e) => { this.setState({ companydetailfaxNo: e.target.value }); }} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Address</label>
                                                    <input type="text" className="form-control col-md-9" id="CAddress" placeholder="Address" value={this.state.companydetailaddress} onChange={(e) => { this.setState({ companydetailaddress: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">City</label>
                                                    <input type="text" className="form-control col-md-9" id="CCity" placeholder="City" value={this.state.companydetailcity} onChange={(e) => { this.setState({ companydetailcity: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">State</label>
                                                    <input type="text" className="form-control col-md-9" id="CState" placeholder="State" value={this.state.companydetailstate} onChange={(e) => { this.setState({ companydetailstate: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Postcode</label>
                                                    <input type="text" className="form-control col-md-9" id="CPostcode" placeholder="Postcode" value={this.state.companydetailpostcode} onChange={(e) => { this.setState({ companydetailpostcode: e.target.value }); }} />
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Country</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.companyDetailCountrySelected}
                                                            onChange={this.SetCompanyCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Company Reg No.</label>
                                                    <input type="number" className="form-control col-md-9" id="CCompanyRegNo" placeholder="Company Reg  No." value={this.state.companydetailcompanyRegNo} onChange={(e) => { this.setState({ companydetailcompanyRegNo: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">VAT No.</label>
                                                    <input type="number" className="form-control col-md-9" id="CVatNo" placeholder="VAT  No." value={this.state.companydetailvatNo} onChange={(e) => { this.setState({ companydetailvatNo: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Tax ID No.</label>
                                                    <input type="number" className="form-control col-md-9" id="CTaxNo" placeholder="Tax ID  No." value={this.state.companydetailtaxIdNo} onChange={(e) => { this.setState({ companydetailtaxIdNo: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">System Privileges</label>
                                                    <input type="text" className="form-control col-md-9" id="CSystemPrivileges" defaultValue="Broker" disabled value={this.state.companydetailsystemPrivileges} onChange={(e) => { this.setState({ companydetailsystemPrivileges: e.target.value }); }} readonly />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Company Logo</label>
                                                    <input type="file" className="form-control col-md-9" id="CCompanyLogo" onChange={this.onChangeCompanyLogo} />
                                                    {/* <div className="custom-file col-md-9">
                                                        <input type="file" className="custom-file-input11" id="CCCompanyLogo" onChange={this.onChangeCompanyLogo} />
                                                        <label className="custom-file-label1" htmlFor="CCCompanyLogo">Choose file</label>
                                                    </div> */}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Company Letterhead</label>
                                                    <input type="file" className="form-control col-md-9" id="CCompanyLetterhead" onChange={this.onChangeCompanyLetterHeadPath} />
                                                    {/* <div className="custom-file col-md-9">
                                                        <input type="file" className="custom-file-input" id="CCompanyLetterhead" onChange={this.onChangeCompanyLetterHeadPath} />
                                                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                                    </div> */}
                                                </div>

                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Same As Company</label>
                                                    <div className="custom-file col-md-9">
                                                        <input type="checkbox" id="SameAsCompany" name="SameAsCompany" onChange={this.ChnageSameAsCompany} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-8"></div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Billing Address</label>
                                                    <input type="text" className="form-control col-md-9" id="BillingAddress" value={this.state.companydetailBillingAddress} onChange={(e) => { this.setState({ companydetailBillingAddress: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">City</label>
                                                    <input type="text" className="form-control col-md-9" id="BillingCity" value={this.state.companydetailBillingCity} onChange={(e) => { this.setState({ companydetailBillingCity: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">State</label>
                                                    <input type="text" className="form-control col-md-9" id="BillingState" value={this.state.companydetailBillingState} onChange={(e) => { this.setState({ companydetailBillingState: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Postcode</label>
                                                    <input type="text" className="form-control col-md-9" id="BillingPostcode" value={this.state.companydetailBillingPostcode} onChange={(e) => { this.setState({ companydetailBillingPostcode: e.target.value }); }} />
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Country</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.companyDetailBillingCountrySelected}
                                                            onChange={this.SetCompanyBillingCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <input type="hidden" value={this.state.companydetailid} id="companydetailid" />
                                        <input type="hidden" value={this.state.companydetailcompanyLogoPath} id="companyLogoPath" />
                                        <input type="hidden" value={this.state.companydetailcopmanyLetterHeadPath} id="copmanyLetterHeadPath" />
                                        <button type="button" className="btn btn-primary mb-2" data-dismiss="modal" onClick={() => this.UpdateComapanyDetail()} id="btnCompanySave">Save</button>
                                        <button type="button" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.BindCompanyDetails()} id="btnResetCompany">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade add-bank" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">{this.state.BankID > 0 ? 'Update Bank Account' : 'Add Bank Account'}</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.ResetBank()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Bank Name</label>
                                                    <input type="text" className="form-control col-md-9" id="BankName" placeholder="Bank Name" value={this.state.bankName} onChange={(e) => { this.setState({ bankName: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Address</label>
                                                    <input type="text" className="form-control col-md-9" id="BankAddress" placeholder="Address" value={this.state.address} onChange={(e) => { this.setState({ address: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">City</label>
                                                    <input type="text" className="form-control col-md-9" id="BankCity" placeholder="City" value={this.state.city} onChange={(e) => { this.setState({ city: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">State</label>
                                                    <input type="text" className="form-control col-md-9" id="BankState" placeholder="State" value={this.state.state} onChange={(e) => { this.setState({ state: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Postcode</label>
                                                    <input type="text" className="form-control col-md-9" id="BankPostcode" placeholder="Postcode" value={this.state.postcode} onChange={(e) => { this.setState({ postcode: e.target.value }); }} />
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Country</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.BankCountry}
                                                            onChange={this.SetBankCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Account Type</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Account Type"
                                                            value={this.state.accountTypeSelected}
                                                            onChange={this.SetAccountType}
                                                            options={this.state.AccountTypeList}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row" style={{ display: 'none' }}>
                                                    <label className="font-weight-800 col-md-3">Country</label>
                                                    <select className="form-control col-md-9" id="BankCountry" value={this.state.country} onChange={(e) => { this.setState({ country: e.target.value }); }}>
                                                        <option value="">Select</option>
                                                        {this.state.CountryList == null ? "" :
                                                            this.state.CountryList.map(item => (
                                                                <option value={item.id} attr-code={item.code} attr-iso3={item.isO3} attr-iso={item.iso} attr-numcode={item.numcode}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group row" style={{ display: 'none' }}>
                                                    <label className="font-weight-800 col-md-3">Account Type</label>
                                                    <select className="form-control col-md-9" id="BankAccountType" value={this.state.accountType} onChange={(e) => { this.setState({ accountType: e.target.value }); }}>
                                                        <option value="">Select</option>
                                                        {this.state.AccountTypeList == null ? "" :
                                                            this.state.AccountTypeList.map(item => (
                                                                <option value={item.id} >{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Account No.</label>
                                                    <input type="number"
                                                        className="form-control col-md-9"
                                                        id="BankAccountNo" placeholder="Account No."
                                                        value={this.state.accountNumber}
                                                        onChange={this.onchangeAccountNo}
                                                    />
                                                    {this.state.AccountNoError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.AccountNoError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">BIC</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        id="BankBIC" placeholder="BIC"
                                                        value={this.state.bic}
                                                        onChange={this.onchangeBIC}
                                                    />
                                                    {this.state.BICError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.BICError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">IBAN</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        id="BankIBAN"
                                                        placeholder="IBAN"
                                                        value={this.state.iban}
                                                        onChange={this.onchangeIBAN}
                                                    />
                                                    {this.state.IBANError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.IBANError}</span>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <input type="hidden" value={this.state.BankID} id="BankID" />
                                        <a type="button" className="btn btn-primary mb-2" onClick={() => this.SaveBank()}>Save</a>
                                        <button type="button" id="btnCloseBank" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.ResetBank()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade user-detail" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Update User Details</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.BindUserDetails()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">First Name</label>
                                                    <input type="text"
                                                        className="form-control col-md-9"
                                                        placeholder="Full Name" id="firstName"
                                                        value={this.state.userfirstName}
                                                        onChange={this.onChangeFirstName}
                                                    />
                                                    {this.state.FNameError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.FNameError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Last Name</label>
                                                    <input type="email"
                                                        className="form-control col-md-9"
                                                        placeholder="Last Name" id="lastName"
                                                        id="lastName" value={this.state.userlastName}
                                                        onChange={this.onChangeLastName}
                                                    />
                                                    {this.state.LNameError.length > 0 &&
                                                        <span style={{ color: "red", fontWeight: 600, marginLeft: "auto" }}>{this.state.LNameError}</span>}
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Email</label>
                                                    <input type="email" className="form-control col-md-9" placeholder="Email" id="email" readonly="" id="email" value={this.state.useremail} onChange={(e) => { this.setState({ useremail: e.target.value }); }} />
                                                    <a href="javascript:void(0)" className="clsRole col-md-12 text-right" data-toggle="modal" data-target=".change-password" >Change Password </a>
                                                </div>
                                                {/* <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Work Email</label>
                                                    <input type="email" className="form-control" placeholder="Email Work" id="emailWork" id="emailWork" value={this.state.useremailWork} onChange={(e) => { this.setState({ useremailWork: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Home Email</label>
                                                    <input type="email" className="form-control" placeholder="Email Home" id="emailHome" id="emailHome" value={this.state.useremailHome} onChange={(e) => { this.setState({ useremailHome: e.target.value }); }} />
                                                </div> */}
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">Tel No 1.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.usertelephone1Selected}
                                                            onChange={this.SetTelephone1Country}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Tel Code"
                                                            id="telephone1Code"
                                                            value={this.state.usertelephone1Code}
                                                            onChange={(e) => { this.setState({ usertelephone1Code: e.target.value }); }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Tel No."
                                                            id="telephone1"
                                                            value={this.state.usertelephone1}
                                                            onChange={(e) => { this.onChangeTelNo(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">Tel No 2.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.usertelephone2Selected}
                                                            onChange={this.SetTelephone2Country}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="text" className="form-control" placeholder="Tel Code" id="telephone2Code" value={this.state.usertelephone2Code} onChange={(e) => { this.setState({ usertelephone2Code: e.target.value }); }} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="number" className="form-control" placeholder="Tel No." id="telephone2" value={this.state.usertelephone2} onChange={(e) => { this.setState({ usertelephone2: e.target.value }); }} />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">MOB No.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.usermobileNoSelected}
                                                            onChange={this.SetMobileNoCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="text" className="form-control" placeholder="MOB Code" id="mobileNoCode" value={this.state.usermobileNoCode} onChange={(e) => { this.setState({ usermobileNoCode: e.target.value }); }} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="number"
                                                            className="form-control"
                                                            placeholder="MOB No."
                                                            id="mobileNo"
                                                            value={this.state.usermobileNo}
                                                            onChange={(e) => { this.onChangeMobileNo(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="col-md-2 font-weight-800 mr-5">Fax No.</label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.userfaxCountrySelected}
                                                            onChange={this.SetFaxCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="text" className="form-control" placeholder="FAX Code" id="faxCode" value={this.state.userfaxCode} onChange={(e) => { this.setState({ userfaxCode: e.target.value }); }} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input type="number" className="form-control" placeholder="FAX No." id="fax" value={this.state.userfax} onChange={(e) => { this.setState({ userfax: e.target.value }); }} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Skype ID</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="Skype ID" id="skypeId" value={this.state.userskypeId} onChange={(e) => { this.setState({ userskypeId: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">ICE ID</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="ICE ID" id="yahooId" value={this.state.useryahooId} onChange={(e) => { this.setState({ useryahooId: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Address</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="Address" id="add1" value={this.state.useradd1} onChange={(e) => { this.setState({ useradd1: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">City</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="City" id="city" value={this.state.usercity} onChange={(e) => { this.setState({ usercity: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">State</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="State" id="state" value={this.state.userstate} onChange={(e) => { this.setState({ userstate: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Postcode</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="Postcode" id="postcode" value={this.state.userpostcode} onChange={(e) => { this.setState({ userpostcode: e.target.value }); }} />
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Country</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.userCountrySelected}
                                                            onChange={this.SetCountry}
                                                            options={this.state.CountryList}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Response Email</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="responseEmail" id="responseEmail" value={this.state.userresponseEmail} onChange={(e) => { this.setState({ userresponseEmail: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">System Privilege</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="System Privilege" id="responseEmail" value={this.state.usersystemPrivileges} onChange={(e) => { this.setState({ usersystemPrivileges: e.target.value }); }} readonly='' />
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-3">
                                                        <label className="font-weight-800 ">Role</label>
                                                    </div>
                                                    <div className="col-md-9 pl-0 pr-0">
                                                        <Select
                                                            placeholder="Country"
                                                            value={this.state.userRoleIdSelected}
                                                            onChange={this.SetRole}
                                                            options={this.state.RoleList}
                                                        />
                                                    </div>
                                                    <a href="javascript:void(0)" className="clsRole col-md-12 text-right" data-toggle="modal" data-target=".add-role" >Add New Role</a>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Profile Image</label>
                                                    <input type="file" className="form-control col-md-9" id="CCompanyLogo" onChange={this.onChangeProfileImage} />
                                                    {/* <div className="custom-file col-md-9">
                                                        <input type="file" className="custom-file-input1" id="CCompanyLogo" onChange={this.onChangeProfileImage} />
                                                        <label className="custom-file-label1" htmlFor="CCompanyLogo">Choose file</label>
                                                    </div> */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <a type="button" className="btn btn-primary mb-2 sweet-success" onClick={() => this.SaveUserDetail()}>Save</a>
                                        <button id="btnCloseUserDetails" type="button" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.BindUserDetails()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade change-password" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-md model-role">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Change Password</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.ClearChangePassword()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-4">Email</label>
                                                    <input type="text" className="form-control col-md-8" placeholder="Email" id="firstName" readonly value={this.state.useremail} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-4">Old Password</label>
                                                    <input type="text" className="form-control col-md-8" placeholder="Old Password" id="firstName" value={this.state.userPassword} onChange={(e) => { this.setState({ userPassword: e.target.value }); }} />
                                                </div>
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-4">New Password</label>
                                                    <input type="text" className="form-control col-md-8" placeholder="New Password" id="firstName" value={this.state.userCPassword} onChange={(e) => { this.setState({ userCPassword: e.target.value }); }} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <a type="button" className="btn btn-primary mb-2 sweet-success" data-dismiss="modal" onClick={() => this.SaveChangePassword()}>Save</a>
                                        <button type="button" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.ClearChangePassword()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade add-role" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" >
                            <div className="modal-dialog modal-md model-role">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Add New Role</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.BindRoleList()}>
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className>
                                            <form className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="font-weight-800 col-md-3">Role Name</label>
                                                    <input type="text" className="form-control col-md-9" placeholder="Full Name" id="firstName" value={this.state.AddNewRole} onChange={(e) => { this.setState({ AddNewRole: e.target.value }); }} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer mb-2">
                                        <a type="button" className="btn btn-primary mb-2 sweet-success" data-dismiss="modal" onClick={() => this.SaveRoleDetail()}>Save</a>
                                        <button type="button" className="btn btn-danger mb-2" data-dismiss="modal" onClick={() => this.BindRoleList()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;