import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import config from './config';
import swal from 'sweetalert';
import Navigation from './Navigation';
import { Link, Route, Switch } from "react-router-dom";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Autocomplete from 'react-autocomplete';

class ManageInvoice extends Component {

    constructor() {
        super();
        this.state = {
            InvoiceList: [],
            CompanyList: [],
            TotalVolumeList: [],
            InvoiceNoList: [],
            InvoiceStatusList: ['Invoiced', 'Cancelled', 'Paid'],
            TradeList: [],
            DistributionList: [],
            Company: "0",
            MainCheckBox: false,
            // InvoiceStartDate: "2020/07/01",
            // InvoiceEndDate: "2020/12/31",
            // InvoiceDueStartDate: "2020/07/01",
            // InvoiceDueEndDate: "2020/12/31",
            InvoiceStartDate: "",
            InvoiceEndDate: "",
            InvoiceDueStartDate: "",
            InvoiceDueEndDate: "",
            FilterInvoiceNo: "",
            FilterInvoiceStatus: "",
            FilterTotalValue: "",
            CompanyName: "",
            CompanyAddress: "",
            BankName: "",
            IBAN: "",
            BankCurrency: "",
            ToCompanyName: "",
            ToCompanyAddress: "",
            InvoiceNo: "",
            InvoiceDate: "",
            DueDate: "",
            Description: "",
            CurrencySymbol: "",
            TotalValue: "",
            VAT: "",
            TotalVAT: "",
            ChnagedStatus: "",
            IndividualID: 0,
            contentUrl: config.contentUrl,
            CurrentPageNo: 1,
            PerPageCount: 50,
            SortColumn: "tradedDate",
            SortColumnType: "Desc",
            TotalCount: 0,
            Height: '0px',
        }
    }

    handlePageChange = event => {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        this.setState(
            { CurrentPageNo: event },
            function () {
                console.log(this.state.CurrentPageNo);
                this.BindInvoiceList();
            }
        );
    }

    handlePageCountChange = event => {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        this.setState(
            { PerPageCount: event },
            function () {
                console.log(this.state.PerPageCount);
                this.BindInvoiceList();
            }
        );
    }

    handleColumnSort = event => {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        this.setState(
            { SortColumn: event.selector },
            function () {
                let SortType = "Desc";
                if (this.state.SortColumnType == "Desc") {
                    SortType = "Asc";
                }

                this.setState(
                    { SortColumnType: SortType },
                    function () {
                        console.log(this.state.SortColumnType);
                        console.log(this.state.SortColumn);
                        this.BindInvoiceList();
                    }
                )
            }
        );
    }

    handleOnChangeVolume(event, value) {
        this.setState(
            { FilterTotalValue: value },
            function () {
                console.log(this.state.FilterTotalValue);
                this.BindTotalVolumeList();
                if (this.state.FilterTotalValue == "" || this.state.FilterTotalValue == null) {
                    this.BindInvoiceList();
                }
            }
        );
    }

    handleOnSelectVolume(value, item) {
        this.setState(
            { FilterTotalValue: value },
            function () {
                console.log(this.state.FilterTotalValue);
                this.BindInvoiceList();
            }
        );
    }

    handleOnChangeInvoiceStatus(event, value) {
        this.setState(
            { FilterInvoiceStatus: value },
            function () {
                console.log(this.state.FilterInvoiceStatus);
                if (this.state.FilterInvoiceStatus == "" || this.state.FilterInvoiceStatus == null) {
                    this.BindInvoiceList();
                }
            }
        );
    }

    handleOnSelectInvoiceStatus(value, item) {
        this.setState(
            { InvoiceStatus: value },
            function () {
                console.log(this.state.InvoiceStatus);
                this.BindInvoiceList();
            }
        );
    }

    handleOnChangeInvoiceNo(event, value) {
        this.setState(
            { FilterInvoiceNo: value },
            function () {
                console.log(this.state.FilterInvoiceNo);
                this.BindInvoiceNoList();
                if (this.state.FilterInvoiceNo == "" || this.state.FilterInvoiceNo == null) {
                    this.BindInvoiceList();
                }
            }
        );
    }

    handleOnSelectInvoiceNo(value, item) {
        this.setState(
            { FilterInvoiceNo: value },
            function () {
                console.log(this.state.FilterInvoiceNo);
                this.BindInvoiceList();
            }
        );
    }

    DownloadInvoicePDF(ID) {
        var URL = config.apiUrl + "invoice/DownloadPDF?id=" + ID + "&loginUserId=" + this.state.IndividualID;
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(URL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            debugger;
            if (resp.status === 200) {
                resp.json().then((result) => {
                    window.open(this.state.contentUrl + '' + result.path, 'Download');
                });
            } else {
                swal({
                    title: "Sorry!",
                    text: "Invoice PDF is not download.",
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
    }

    BindCompanyList() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let FetchURL = config.apiUrl + "invoice/ManageInvoice/FilterCompany?commodityid=" + commodityId;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ CompanyList: result })
                });
            }
        });
    }

    BindTotalVolumeList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let FetchURL = config.apiUrl + "Invoice/ManageInvoice/FilterTotal?commodityid=" + commodityId;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalVolumeList: result })
                });
            }
        });
    }

    BindInvoiceNoList() {
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let FetchURL = config.apiUrl + "Invoice/ManageInvoice/FilterInvoiceNo?commodityid=" + commodityId;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ InvoiceNoList: result })
                });
            }
        });
    }

    handleAllChecked = (event) => {
        let tempCreateInvoiceList = this.state.InvoiceList
        tempCreateInvoiceList.forEach(CreateInvoice => CreateInvoice.isChecked = event.target.checked)
        this.setState({ CreateInvoiceList: tempCreateInvoiceList })
        this.setState({ MainCheckBox: event.target.checked })
    }

    handleCheckChieldElement = (event) => {
        let tempCreateInvoiceList = this.state.InvoiceList;
        let myCheckedCount = 0;
        tempCreateInvoiceList.forEach(CreateInvoice => {
            if (CreateInvoice.id == event.target.value) {
                CreateInvoice.isChecked = event.target.checked
            }

            if (CreateInvoice.isChecked)
                myCheckedCount++;
        })

        if (tempCreateInvoiceList.length == myCheckedCount)
            this.setState({ MainCheckBox: true })
        else
            this.setState({ MainCheckBox: false })

        this.setState({ InvoiceList: tempCreateInvoiceList })
    }

    setGridHeight() {
        let myHeight = window.innerHeight;
        if (myHeight > 0) {
            const headerMainHeight = document.getElementById('headerMain').clientHeight;
            const footerMainHeight = document.getElementById('footerMain').clientHeight;
            myHeight = myHeight - headerMainHeight - footerMainHeight - 250;
            this.setState({ Height: myHeight + "px" });

            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = '.rdt_TableBody { max-height: ' + myHeight + 'px; }';
        }
    }

    ResetFilter() {
        this.setState({ Company: "0" })
        this.setState({ FilterTotalValue: "" })
        this.setState({ InvoiceStartDate: "" })
        this.setState({ InvoiceEndDate: "" })
        this.setState({ InvoiceDueStartDate: "" })
        this.setState({ InvoiceDueEndDate: "" })
        this.setState({ FilterInvoiceNo: "" })
        this.setState({ FilterInvoiceStatus: "" })

        this.setState(
            { FilterInvoiceStatus: "" },
            function () {
                console.log(this.state.CurrentPagFilterInvoiceStatuseNo);
                this.BindInvoiceList();
                this.setGridHeight();
            }
        );


    }

    BindInvoiceList() {
        let commodityId = parseInt(localStorage.getItem("CommodityID"));
        let Company = this.state.Company;
        let InvoiceStartDate = this.state.InvoiceStartDate;
        let InvoiceEndDate = this.state.InvoiceEndDate;
        let InvoiceDueStartDate = this.state.InvoiceDueStartDate;
        let InvoiceDueEndDate = this.state.InvoiceDueEndDate;
        let FilterInvoiceNo = this.state.FilterInvoiceNo;
        let InvoiceStatus = this.state.FilterInvoiceStatus;
        let FilterTotalValue = this.state.FilterTotalValue;

        let FetchURL = config.apiUrl + "Invoice/ManageInvoice?commodityId=" + commodityId + "&invoiceStartDate=" + InvoiceStartDate + "&invoiceEndDate=" + InvoiceEndDate + "&invoiceDueStartDate=" + InvoiceDueStartDate + "&invoiceDueEndDate=" + InvoiceDueEndDate + "&company=" + Company + "&invoiceNo=" + FilterInvoiceNo + "&invoiceStatus=" + InvoiceStatus + "&totalValue=" + FilterTotalValue + "&SortingColumnName=" + this.state.SortColumn + "&AscDesc=" + this.state.SortColumnType + "&PageNo=" + this.state.CurrentPageNo + "&PageSize=" + this.state.PerPageCount;
        var myLoginToken = localStorage.getItem("AccessToken");

        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            let pageloaddiv = document.getElementById("pageloaddiv");
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ TotalCount: result.totalrecord.toString() });
                    this.setState({ InvoiceList: result.invoiceListModel });
                    this.setState({ MainCheckBox: false })
                    this.setGridHeight();
                });
            } else {
                this.setState({ InvoiceList: [] });
                this.setState({ TotalCount: 0 });
                this.setState({ MainCheckBox: false })
                this.setGridHeight();
            }
        });
    }

    ViewInvoicePopup = (event) => {
        var myLoginToken = localStorage.getItem("AccessToken");
        var ID = event.target.value;
        let FetchURL = config.apiUrl + "invoice/ViewInvoice?invoiceid=" + ID;
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((result) => {
                    this.setState({ CompanyName: result.CompanyName })
                    this.setState({ CompanyAddress: result.CompanyAddress })
                    this.setState({ BankName: result.BankName })
                    this.setState({ IBAN: result.IBAN })
                    this.setState({ AccountType: result.AccountType })
                    this.setState({ BankCurrency: result.AccountType })
                    this.setState({ ToCompanyName: result.ToCompanyName })
                    this.setState({ ToCompanyAddress: result.ToCompanyAddress })
                    this.setState({ InvoiceNo: result.InvoiceNo })
                    this.setState({ InvoiceDate: result.InvoiceDate })
                    this.setState({ DueDate: result.DueDate })
                    this.setState({ Description: result.BodyText })
                    this.setState({ CurrencySymbol: result.SymbolCurrancy })
                    this.setState({ TotalValue: result.Total })
                    this.setState({ VAT: result.Vat })
                    this.setState({ TotalVAT: result.Total })
                    this.setState({ TradeList: result.TradeList })
                });
            }
        });
    }

    ChangeInvoicePopup = (event) => {
        let Tradeids = [];
        let invoiceNoList = "";
        this.state.InvoiceList.forEach(InvoiceItem => {
            if (InvoiceItem.isChecked) {
                Tradeids.push(InvoiceItem.id);
                invoiceNoList += InvoiceItem.invoiceNo + ",  ";
            }
        })

        if (Tradeids.length > 0) {
            this.inputStatusElement.click();
        } else {
            swal("Please Select Invoice!", {
                icon: "error",
            });
        }
    }

    ResendInvoicePopup = (event) => {
        let Tradeids = [];
        this.state.InvoiceList.forEach(InvoiceItem => {
            if (InvoiceItem.isChecked) {
                Tradeids.push(InvoiceItem.id);
            }
        })

        if (Tradeids.length > 0) {
            this.inputElement.click();
            var ID = Tradeids[0];
            var myLoginToken = localStorage.getItem("AccessToken");
            let FetchURL = config.apiUrl + "invoice/ViewInvoice?invoiceid=" + ID;
            fetch(FetchURL, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((result) => {
                        this.setState({ CompanyName: result.CompanyName })
                        this.setState({ CompanyAddress: result.CompanyAddress })
                        this.setState({ BankName: result.BankName })
                        this.setState({ IBAN: result.IBAN })
                        this.setState({ AccountType: result.AccountType })
                        this.setState({ BankCurrency: result.AccountType })
                        this.setState({ ToCompanyName: result.ToCompanyName })
                        this.setState({ ToCompanyAddress: result.ToCompanyAddress })
                        this.setState({ InvoiceNo: result.InvoiceNo })
                        this.setState({ InvoiceDate: result.InvoiceDate })
                        this.setState({ DueDate: result.DueDate })
                        this.setState({ Description: result.BodyText })
                        this.setState({ CurrencySymbol: result.SymbolCurrancy })
                        this.setState({ TotalValue: result.Total })
                        this.setState({ VAT: result.Vat })
                        this.setState({ TotalVAT: result.Total })
                        this.setState({ TradeList: result.TradeList });

                        let CompanyID = result.ToCompanyId;
                        let CommodityID = parseInt(localStorage.getItem("CommodityID"));
                        let FetchBankURL = config.apiUrl + "Company/" + CompanyID + "/distributionlist/" + CommodityID + "/Invoice";

                        fetch(FetchBankURL, {
                            method: 'GET',
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + myLoginToken,
                                "loginuserid": localStorage.getItem("LogedID"),
                                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                                "commodityid": localStorage.getItem("CommodityID")
                            },
                        }).then((resp) => {
                            if (resp.status === 200) {
                                resp.json().then((result) => {
                                    this.setState({ DistributionList: result })
                                });
                            }
                        });
                    });
                }
            });

        } else {
            swal("Please Select Invoice!", {
                icon: "error",
            });
        }
    }

    ResendInvoice() {
        let Tradeids = [];
        this.state.InvoiceList.forEach(InvoiceItem => {
            if (InvoiceItem.isChecked) {
                Tradeids.push(InvoiceItem.id);
            }
        })

        if (Tradeids.length > 0) {
            var ID = Tradeids[0];
            let CompanyID = localStorage.getItem("LoginCompanyID");
            var myLoginToken = localStorage.getItem("AccessToken");
            let FetchURL = config.apiUrl + "invoice/ResendInvoice?id=" + ID + "&loginCompanyId=" + CompanyID;
            fetch(FetchURL, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + myLoginToken,
                    "loginuserid": localStorage.getItem("LogedID"),
                    "logincompanyid": localStorage.getItem("LoginCompanyID"),
                    "commodityid": localStorage.getItem("CommodityID")
                },
            }).then((resp) => {
                if (resp.status === 200) {
                    resp.json().then((result) => {
                        if (result == true) {
                            this.resetCheckBox();
                            swal("Resend invoice successfully.", {
                                icon: "success",
                            });
                        } else {
                            swal({
                                title: "Sorry!",
                                text: "Selected invoice is not resend.",
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                        this.BindInvoiceList();
                    });
                }
            });
        }
    }

    CancelInvoicePopup = (event) => {
        let Tradeids = [];
        let invoiceNoList = "";
        this.state.InvoiceList.forEach(InvoiceItem => {
            if (InvoiceItem.isChecked) {
                Tradeids.push(InvoiceItem.id);
                invoiceNoList += InvoiceItem.invoiceNo + ",  ";
            }
        })

        if (Tradeids.length > 0) {
            swal({
                title: "Are You Sure You Want To Cancel The Below Invoice(s)",
                text: invoiceNoList,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = JSON.stringify(Tradeids);
                        var POSTURL = config.apiUrl + "Invoice/CancelledInvoice";
                        var myLoginToken = localStorage.getItem("AccessToken");

                        fetch(POSTURL, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + myLoginToken,
                                "loginuserid": localStorage.getItem("LogedID"),
                                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                                "commodityid": localStorage.getItem("CommodityID")
                            },
                            body: data,
                            async: false,
                            traditional: true,
                        }).then((resp) => {
                            if (resp.status === 200) {
                                this.resetCheckBox();
                                swal({
                                    title: "Done!",
                                    text: "Selected invoice's are cancelled.",
                                    icon: "success",
                                    button: "Ok!",
                                });
                            } else {
                                swal({
                                    title: "Sorry!",
                                    text: "Selected invoice's are not cancelled.",
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                            this.BindInvoiceList();
                        });
                    } else {
                        swal("Selected invoice's are not cancelled!", {
                            icon: "error",
                        });
                    }
                });
        } else {
            swal("Please Select Invoice!", {
                icon: "error",
            });
        }
    }

    SaveChnageInvoiceStatus() {
        let Tradeids = [];
        let invoiceNoList = "";

        this.state.InvoiceList.forEach(InvoiceItem => {
            if (InvoiceItem.isChecked) {
                Tradeids.push(InvoiceItem.id);
                invoiceNoList += InvoiceItem.invoiceNo + ",  ";
            }
        })

        if (Tradeids.length > 0) {
            swal({
                title: "Are You Sure You Want To Paid The Below Invoice(s)",
                text: invoiceNoList,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = JSON.stringify(Tradeids);
                        var POSTURL = config.apiUrl + "Invoice/Paid";
                        var myLoginToken = localStorage.getItem("AccessToken");

                        fetch(POSTURL, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + myLoginToken,
                                "loginuserid": localStorage.getItem("LogedID"),
                                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                                "commodityid": localStorage.getItem("CommodityID")
                            },
                            body: data,
                            async: false,
                            traditional: true,
                        }).then((resp) => {
                            if (resp.status === 200) {
                                this.resetCheckBox();
                                swal({
                                    title: "Done!",
                                    text: "Selected Invoice(s) are Paid.",
                                    icon: "success",
                                    button: "Ok!",
                                });
                            } else {
                                swal({
                                    title: "Sorry!",
                                    text: "Selected Invoice(s) are not Paid.",
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                            this.BindInvoiceList();
                        });
                    } else {
                        swal("Selected Invoice(s) are not Paid!", {
                            icon: "error",
                        });
                    }
                });
        } else {
            swal("Please Select Invoice!", {
                icon: "error",
            });
        }
    }

    resetCheckBox() {
        let tempTradeList = this.state.InvoiceList;
        tempTradeList.forEach(TradeItem => {
            TradeItem.isChecked = false;
        });

        this.setState({
            MainCheckBox: false,
            InvoiceList: tempTradeList
        }, function () {
            console.log(this.state.MainCheckBox);
        });
    }

    componentDidMount() {
        var myLoginToken = localStorage.getItem("AccessToken")
        if (myLoginToken != null && myLoginToken != "" && myLoginToken != undefined) {
            let id = parseInt(localStorage.getItem("LogedID"));
            this.setState({ IndividualID: id });
            this.BindCompanyList();
            this.BindInvoiceNoList();
            this.BindTotalVolumeList();
            this.BindInvoiceList();
        } else {
            this.props.history.push('/login');
        }
    }

    ExportToCSV() {
        let pageloaddiv = document.getElementById("pageloaddiv");
        pageloaddiv.style.display = "block";
        var myLoginToken = localStorage.getItem("AccessToken");
        let commodityId = localStorage.getItem("CommodityID");
        let FetchURL = config.apiUrl + "Invoice/ExportToCsvManageInvoice?commodityId=" + commodityId
        fetch(FetchURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + myLoginToken,
                "loginuserid": localStorage.getItem("LogedID"),
                "logincompanyid": localStorage.getItem("LoginCompanyID"),
                "commodityid": localStorage.getItem("CommodityID")
            },
        }).then((resp) => {
            pageloaddiv.style.display = "none";
            if (resp.status === 200) {
                resp.text().then((result) => {
                    if (result != "" && result != null && result != undefined) {
                        window.open(config.contentUrl + '' + result, 'InvoiceList');
                    }
                });
            }
        });
    }

    render() {

        const columns = [
            {
                name: <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" checked={this.state.MainCheckBox} />,
                selector: '',
                sortable: false,
                width: "50px",
                cell: item => <input key={item.id} onClick={this.handleCheckChieldElement} type="checkbox" checked={item.isChecked} value={item.id} />
            },
            {
                name: '',
                selector: '',
                sortable: false,
                width: "110px",
                cell: row => <button type="button" className="btn btn-primary btn-sm mr-2" data-toggle="modal" data-target=".viewInvoice" value={row.id} onClick={this.ViewInvoicePopup}><i className="ti-eye mr-1" /> View</button>
            },
            {
                name: 'Invoice Date',
                selector: 'invoiceDate',
                sortable: true,
                right: false,
                width: "160px",
                cell: item => <span>{moment(item.invoiceDate).format("DD/MM/YYYY")}</span>
            },
            {
                name: 'Due Date',
                selector: 'dueDate',
                sortable: true,
                right: false,
                width: "160px",
                cell: item => <span>{moment(item.dueDate).format("DD/MM/YYYY")}</span>
            },
            {
                name: 'Company',
                selector: 'toCompanyName',
                sortable: false,
                width: "270px",
            },
            {
                name: 'Total (EUR)',
                selector: 'total',
                sortable: true,
                right: false,
                width: "120px",
            },
            {
                name: 'VAT (EUR)',
                selector: 'vat',
                sortable: true,
                right: false,
                width: "110px",
            },
            {
                name: 'VAT (GBP)',
                selector: 'exchangeVat',
                sortable: true,
                right: false,
                width: "100px",
            },
            {
                name: 'Total Value (EUR)',
                selector: 'finalTotal',
                sortable: true,
                right: false,
                width: "150px",
            },
            {
                name: 'Invoice No.',
                selector: 'invoiceNo',
                sortable: true,
                right: false,
                width: "130px",
            },
            {
                name: 'Status',
                selector: '',
                sortable: false,
                width: "100px",
                cell: item => item.paymentStatus == "Invoiced" ? <span className='badge badge-success rounded'>{item.paymentStatus}</span> : item.paymentStatus == "Paid" ? <span className='badge badge-success1 rounded'>{item.paymentStatus}</span> : <span className='badge badge-blank rounded'>{item.paymentStatus}</span>
            },
            {
                name: '',
                selector: '',
                sortable: true,
                right: false,
                width: "50px",
                cell: row => <a href onClick={() => this.DownloadInvoicePDF(row.id)}><i class="fa fa-file-pdf-o text-danger pdf-icon-font"></i></a>
            }
        ];

        return (
            <div className="layout-wrapper">
                <div id="pageloaddiv" style={{ display: 'none' }}>
                    <span><img src="../assets/loader.gif" width={150} height={150} /></span>
                </div>
                <Sidebar />
                <Header />
                <div className="content-wrapper">
                    <Navigation />
                    <div className="content-body">
                        <div className="content ">
                            {/* <div className="page-header">
                    </div> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="dis-flex">
                                                <h3 className="mb-4">Manage Invoice</h3>
                                                <div className="mb-4 ml-auto">
                                                    <button type="button" className="btn btn-primary ml-2 mb-2 ml-xs-0" data-toggle="modal" data-target=".searchInvoice"> <i className="ti-filter mr-1" /> Filters</button>
                                                    <button type="button" className="btn btn-danger ml-2 mb-2 " onClick={this.CancelInvoicePopup}>Cancel Invoice</button>
                                                    <button type="button" className="btn btn-info ml-2 mb-2 ml-xs-0" onClick={this.ResendInvoicePopup}>Resend Invoice</button>
                                                    <button type="button" className="btn btn-danger ml-2 mb-2" ref={input => this.inputElement = input} data-toggle="modal" data-target=".resendInvoice" style={{ display: 'none' }}>Resend Invoice</button>
                                                    <button type="button" className="btn btn-warning ml-2 mb-2" onClick={this.ChangeInvoicePopup}>Change Status</button>
                                                    <button type="button" className="btn btn-danger ml-2 mb-2" ref={input => this.inputStatusElement = input} data-toggle="modal" data-target=".changeInvoiceStatus" style={{ display: 'none' }}>Change Status</button>
                                                    <button type="button" className="btn btn-success ml-2 mb-2" onClick={this.ExportToCSV}><i class="ti-file mr-1"></i> Export to Csv</button>
                                                </div>
                                            </div>
                                            {/* <div className="table-responsive" style={{ maxHeight: this.state.Height }}> */}
                                            <div className="table-responsive">
                                                <DataTable
                                                    noDataComponent=""
                                                    columns={columns}
                                                    data={this.state.InvoiceList}
                                                    pagination="true"
                                                    paginationServer
                                                    paginationPerPage={this.state.PerPageCount}
                                                    highlightOnHover={true}
                                                    paginationTotalRows={this.state.TotalCount}
                                                    paginationRowsPerPageOptions={["50", "100", "150", "200", this.state.TotalCount.toString()]}
                                                    onChangePage={this.handlePageChange}
                                                    onChangeRowsPerPage={this.handlePageCountChange}
                                                    onSort={this.handleColumnSort}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade bd-example-modal-lg searchInvoice" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Search Filters</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="col-md-12">
                                            <form className="row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="exampleInputPassword1 " className="font-weight-800">Company</label>
                                                    <select className="form-control" id="ddlCompany" value={this.state.Company} onChange={(e) => { this.setState({ Company: e.target.value }); }} onClick={() => this.BindInvoiceList()} >
                                                        <option value='0'>Select</option>
                                                        {this.state.CompanyList == null ? "" :
                                                            this.state.CompanyList.map(item => (
                                                                <option value={item.value} >{item.text}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="FilterTotalValue" className="font-weight-800">Total Value</label>
                                                    {/* <input type="text" className="form-control" id="FilterTotalValue" placeholder="Total Value" value={this.state.FilterTotalValue} onChange={(e) => { this.setState({ FilterTotalValue: e.target.value }); }} onKeyUp={() => this.BindInvoiceList()} /> */}
                                                    {/* <select className="form-control" id="ddlCompany" value={this.state.FilterTotalValue} onChange={(e) => { this.setState({ FilterTotalValue: e.target.value }); }} onClick={() => this.BindInvoiceList()} >
                                                <option value=''>Select</option>
                                                {this.state.TotalVolumeList == null ? "" :
                                                    this.state.TotalVolumeList.map(item => (
                                                        <option value={item.value} >{item.text}</option>
                                                    ))
                                                }
                                            </select> */}

                                                    <div>
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-FilterTotalValue' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.FilterTotalValue}
                                                            items={this.state.TotalVolumeList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectVolume(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangeVolume(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="InvoiceDateStart" className="font-weight-800">Invoice Date Start</label>
                                                    <input type="date" id="InvoiceDateStart" name="daterangepicker" className="form-control" value={this.state.InvoiceStartDate} onChange={(e) => { this.setState({ InvoiceStartDate: e.target.value }); }} onKeyUp={() => this.BindInvoiceList()} />
                                                    {/* <input type="text" name="daterangepicker" className="form-control" value={this.state.TradedStartDate} onChange={(e) => { this.setState({ TradedStartDate: e.target.value }); }} onKeyUp={() => this.BindCreateInvoiceList()} /> */}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="InvoiceDateEnd" className="font-weight-800">End</label>
                                                    <input type="date" id="InvoiceDateEnd" name="daterangepicker" className="form-control" value={this.state.InvoiceEndDate} onChange={(e) => { this.setState({ InvoiceEndDate: e.target.value }); }} onKeyUp={() => this.BindInvoiceList()} />
                                                    {/* <input type="text" name="daterangepicker" className="form-control" value={this.state.TradedEndDate} onChange={(e) => { this.setState({ TradedEndDate: e.target.value }); }} onKeyUp={() => this.BindCreateInvoiceList()} /> */}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="InvoiceDueDateStart" className="font-weight-800">Invoice Due Date Start</label>
                                                    <input type="date" id="InvoiceDueDateStart" name="daterangepicker" className="form-control" value={this.state.InvoiceDueStartDate} onChange={(e) => { this.setState({ InvoiceDueStartDate: e.target.value }); }} onKeyUp={() => this.BindInvoiceList()} />
                                                    {/* <input type="text" name="daterangepicker" className="form-control" value={this.state.TradedStartDate} onChange={(e) => { this.setState({ TradedStartDate: e.target.value }); }} onKeyUp={() => this.BindCreateInvoiceList()} /> */}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="InvoiceDueEndDate" className="font-weight-800">End</label>
                                                    <input type="date" id="InvoiceDueEndDate" name="daterangepicker" className="form-control" value={this.state.InvoiceDueEndDate} onChange={(e) => { this.setState({ InvoiceDueEndDate: e.target.value }); }} onKeyUp={() => this.BindInvoiceList()} />
                                                    {/* <input type="text" name="daterangepicker" className="form-control" value={this.state.TradedEndDate} onChange={(e) => { this.setState({ TradedEndDate: e.target.value }); }} onKeyUp={() => this.BindCreateInvoiceList()} /> */}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="FilterInvoiceNo" className="font-weight-800">Invoice Number</label>
                                                    {/* <input type="text" className="form-control" id="FilterInvoiceNo" placeholder="Instrument" value={this.state.FilterInvoiceNo} onChange={(e) => { this.setState({ FilterInvoiceNo: e.target.value }); }} onKeyUp={() => this.BindInvoiceList()} /> */}
                                                    {/* <select className="form-control" id="ddlCompany" value={this.state.FilterInvoiceNo} onChange={(e) => { this.setState({ FilterInvoiceNo: e.target.value }); }} onClick={() => this.BindInvoiceList()} >
                                                <option value=''>Select</option>
                                                {this.state.InvoiceNoList == null ? "" :
                                                    this.state.InvoiceNoList.map(item => (
                                                        <option value={item.value} >{item.text}</option>
                                                    ))
                                                }
                                            </select> */}
                                                    <div>
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-FilterInvoiceNo' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.FilterInvoiceNo}
                                                            items={this.state.InvoiceNoList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectInvoiceNo(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangeInvoiceNo(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item.value}>{item.text}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="InvoiceStatus" className="font-weight-800">Status</label>
                                                    {/* <select className="form-control" id="ddlInvoiceStatus" value={this.state.FilterInvoiceStatus} onChange={(e) => { this.setState({ FilterInvoiceStatus: e.target.value }); }} onClick={() => this.BindInvoiceList()} >
                                                <option value='Invoiced'>Invoiced</option>
                                                <option value='Cancelled'>Cancelled</option>
                                                <option value='Paid'>Paid</option>
                                            </select> */}
                                                    <div>
                                                        <Autocomplete
                                                            inputProps={{ id: 'states-autocomplete-FilterInvoiceStatus' }}
                                                            inputProps={{ className: 'form-control' }}
                                                            value={this.state.FilterInvoiceStatus}
                                                            items={this.state.InvoiceStatusList}
                                                            getItemValue={(item) => item.value}
                                                            onSelect={(value, item) => {
                                                                this.handleOnSelectInvoiceStatus(value, item);
                                                            }}
                                                            onChange={(event, value) => {
                                                                this.handleOnChangeInvoiceStatus(event, value);
                                                            }}
                                                            renderMenu={Name => (
                                                                <div className="clsAutocompleteList">
                                                                    {Name}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div className="auto-options" key={item}>{item}</div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary mb-2" data-dismiss="modal" onClick={() => this.ResetFilter()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade bd-example-modal-lg viewInvoice" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">View Invoice</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form className="row">
                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">From :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.CompanyName} {", " + this.state.CompanyAddress}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Bank Details :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.BankName} {", " + this.state.IBAN}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Bank Currency :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.BankCurrency}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">To :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.ToCompanyName} {", " + this.state.ToCompanyAddress}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Invoice No :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.InvoiceNo}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Invoice Date :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.InvoiceDate}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Due Date :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.DueDate}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Description :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.Description}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Value (Excl. VAT) ({this.state.CurrencySymbol}) :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.TotalVAT} </label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">VAT ({this.state.CurrencySymbol}) :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.VAT}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Total Value ({this.state.CurrencySymbol}) :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.TotalValue}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Trade Summary :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                            </div>

                                            <div className="form-group col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th>Trade Date</th>
                                                                <th>Deal ID</th>
                                                                <th>Description</th>
                                                                <th>Volume</th>
                                                                <th>Total Volume (MWh)</th>
                                                                <th>Price (MWh)</th>
                                                                <th>Buyer</th>
                                                                <th>Seller</th>
                                                                <th>Action</th>
                                                                <th>Broker Fee (EUR)</th>
                                                                <th>Total (EUR)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.TradeList == null ? "" :
                                                                this.state.TradeList.map(item => (
                                                                    <tr>
                                                                        <td>{item.TradedDate}</td>
                                                                        <td>{item.TradeID}</td>
                                                                        <td>{item.InstName} {" - " + item.FirstSequenceItemName}</td>
                                                                        <td>{item.Volume}</td>
                                                                        <td>{item.TotalVolume}</td>
                                                                        <td>{item.Price}</td>
                                                                        <td>{item.BuyerCompany}</td>
                                                                        <td>{item.SellerCompany}</td>
                                                                        <td>{item.TradeAction}</td>
                                                                        {/* <td>{item.InitAggrBrokerRate != "" && item.InitAggrBrokerRate != "0" && item.InitAggrBrokerRate != undefined && item.InitAggrBrokerRate != null ? Number(item.InitAggrBrokerRate).toFixed(5) : "0.00000"}</td>
                                                                        <td>{item.FinalBrokerRate != "" && item.FinalBrokerRate != "0" && item.FinalBrokerRate != undefined && item.FinalBrokerRate != null ? Number(item.FinalBrokerRate).toFixed(5) : "0.00000"}</td> */}
                                                                        <td>{item.InitAggrBrokerRate != "" && item.InitAggrBrokerRate != "0" && item.InitAggrBrokerRate != undefined && item.InitAggrBrokerRate != null ? Number(item.InitAggrBrokerRate).toFixed(5) : "0.00000"}</td>
                                                                        <td>{item.FinalBrokerRate != "" && item.FinalBrokerRate != "0" && item.FinalBrokerRate != undefined && item.FinalBrokerRate != null ? Number(item.FinalBrokerRate).toFixed(5) : "0.00000"}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger mb-2" data-dismiss="modal" >Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade bd-example-modal-lg resendInvoice" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Resend Invoice</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form className="row">
                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">From :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.CompanyName} {", " + this.state.CompanyAddress}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Bank Details :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.BankName} {", " + this.state.IBAN}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Bank Currency :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.BankCurrency}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">To :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.ToCompanyName} {", " + this.state.ToCompanyAddress}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Invoice No :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.InvoiceNo}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Invoice Date :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.InvoiceDate}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Due Date :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.DueDate}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Description :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.Description}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Value (Excl. VAT) ({this.state.CurrencySymbol}) :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.TotalVAT} </label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">VAT ({this.state.CurrencySymbol}) :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.VAT}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Total Value ({this.state.CurrencySymbol}) :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label>{this.state.TotalValue}</label>
                                            </div>

                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Trade Summary :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                            </div>

                                            <div className="form-group col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th>Trade Date</th>
                                                                <th>Deal ID</th>
                                                                <th>Description</th>
                                                                <th>Volume</th>
                                                                <th>Total Volume (MWh)</th>
                                                                <th>Price (MWh)</th>
                                                                <th>Buyer</th>
                                                                <th>Seller</th>
                                                                <th>Broker Fee (EUR)</th>
                                                                <th>Total (EUR)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.TradeList == null ? "" :
                                                                this.state.TradeList.map(item => (
                                                                    <tr>
                                                                        <td>{item.TradedDate}</td>
                                                                        <td>{item.TradeID}</td>
                                                                        <td>{item.InstName} {" - " + item.FirstSequenceItemName}</td>
                                                                        <td>{item.Volume}</td>
                                                                        <td>{item.TotalVolume}</td>
                                                                        <td>{item.Price}</td>
                                                                        <td>{item.BuyerCompany}</td>
                                                                        <td>{item.SellerCompany}</td>
                                                                        <td>{item.InitAggrBrokerRate != "" && item.InitAggrBrokerRate != "0" && item.InitAggrBrokerRate != undefined && item.InitAggrBrokerRate != null ? Number(item.InitAggrBrokerRate).toFixed(5) : "0.00000"}</td>
                                                                        <td>{item.FinalBrokerRate != "" && item.FinalBrokerRate != "0" && item.FinalBrokerRate != undefined && item.FinalBrokerRate != null ? Number(item.FinalBrokerRate).toFixed(5) : "0.00000"}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800">Distribution List E-mail :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <label></label>
                                            </div>

                                            {this.state.DistributionList == null ? "" :
                                                this.state.DistributionList.map(item => (
                                                    <div className="d-flex w-100 ">
                                                        <div className="w-23">
                                                            <h2 className="font-weight-800 font-size-13 text-right mt-1">{item.distributionTypeName + " :"}</h2>
                                                        </div>
                                                        <div className="w-77 pl-3">
                                                            <p className="mt-0 ">{item.email}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger mb-2" data-dismiss="modal" >Close</button>
                                        <button type="button" className="btn btn-success mb-2" data-dismiss="modal" onClick={() => this.ResendInvoice()}>Resend</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade bd-example-modal-lg changeInvoiceStatus" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Change Invoice Status</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                            <i className="ti-close" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form className="row">
                                            <div className="form-group col-md-3 text-right">
                                                <label className="font-weight-800 pt-2">Change Status :</label>
                                            </div>
                                            <div className="form-group col-md-9 p-l-10">
                                                <select className="form-control w-400" id="ddlStatus" value={this.state.ChnagedStatus} onChange={(e) => { this.setState({ ChnagedStatus: e.target.value }); }} onClick={() => this.BindInvoiceList()} >
                                                    <option value='paid'>Paid</option>
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger mb-2" data-dismiss="modal" >Close</button>
                                        <a type="button" className="btn btn-success mb-2 mr-2" data-dismiss="modal" onClick={() => this.SaveChnageInvoiceStatus()}><i className="ti-save mr-1"></i> Confirm</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default ManageInvoice;